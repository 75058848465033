import React from "react";
import { NavLink, useLocation } from "react-router-dom";

// Стили для компонента
const styles = {
  breadcrumbsContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    flexWrap: "wrap", // Для адаптивности на маленьких экранах
    padding: "8px 0",
    fontFamily: "Arial, sans-serif",
    fontSize: "14px",
    color: "var(--color-gray-600, #666)",
  },
  breadcrumbItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  breadcrumbLink: {
    textDecoration: "none",
    color: "var(--color-primary, #007bff)",
    transition: "color 0.2s",
  },
  breadcrumbLinkHover: {
    color: "var(--color-primary-dark, #0056b3)",
  },
  breadcrumbActive: {
    color: "var(--color-gray-800, #333)",
    fontWeight: "600",
  },
  separator: {
    color: "var(--color-gray-400, #999)",
  },
};

// Компонент Breadcrumbs
const Breadcrumbs = ({ path }) => {
  const location = useLocation();

  // Проверка входных данных
  if (!Array.isArray(path) || path.length === 0) {
    return null; // Если путь пустой или не массив, ничего не рендерим
  }

  return (
    <nav style={styles.breadcrumbsContainer} aria-label="breadcrumbs">
      {path.map((breadcrumb, index) => {
        const isLast = index === path.length - 1;
        const isActive = location.pathname === breadcrumb.link;

        return (
          <div key={index} style={styles.breadcrumbItem}>
            {isLast ? (
              // Последний элемент — не ссылка, просто текст
              <span style={styles.breadcrumbActive}>
                {breadcrumb.item || "Unnamed"}
              </span>
            ) : (
              // Предыдущие элементы — ссылки
              <NavLink
                to={breadcrumb.link || "#"}
                style={({ isActive }) =>
                  isActive
                    ? { ...styles.breadcrumbLink, ...styles.breadcrumbActive }
                    : styles.breadcrumbLink
                }
                onMouseOver={(e) =>
                  (e.currentTarget.style.color =
                    styles.breadcrumbLinkHover.color)
                }
                onMouseOut={(e) =>
                  (e.currentTarget.style.color = styles.breadcrumbLink.color)
                }
              >
                {breadcrumb.item || "Unnamed"}
              </NavLink>
            )}
            {!isLast && (
              // Разделитель между элементами (кроме последнего)
              <span style={styles.separator}>/</span>
            )}
          </div>
        );
      })}
    </nav>
  );
};

export default Breadcrumbs;

// import React from "react";
// import { NavLink } from "react-router-dom";

// // Стили для компонента
// const styles = {
//   breadcrumbsContainer: {
//     display: "flex",
//     alignItems: "center",
//     gap: "8px",
//     flexWrap: "wrap", // Для адаптивности на маленьких экранах
//     padding: "8px 0",
//     fontFamily: "Arial, sans-serif",
//     fontSize: "14px",
//     color: "var(--color-gray-600, #666)",
//   },
//   breadcrumbItem: {
//     display: "flex",
//     alignItems: "center",
//     gap: "8px",
//   },
//   breadcrumbLink: {
//     textDecoration: "none",
//     color: "var(--color-primary, #007bff)",
//     transition: "color 0.2s",
//   },
//   breadcrumbLinkHover: {
//     color: "var(--color-primary-dark, #0056b3)",
//   },
//   breadcrumbActive: {
//     color: "var(--color-gray-800, #333)",
//     fontWeight: "600",
//   },
//   separator: {
//     color: "var(--color-gray-400, #999)",
//   },
// };

// // Компонент Breadcrumbs
// const Breadcrumbs = ({ path }) => {
//   // Проверка входных данных
//   if (!Array.isArray(path) || path.length === 0) {
//     return null; // Если путь пустой или не массив, ничего не рендерим
//   }

//   return (
//     <nav style={styles.breadcrumbsContainer} aria-label="breadcrumbs">
//       {path.map((breadcrumb, index) => {
//         const isLast = index === path.length - 1;

//         return (
//           <div key={index} style={styles.breadcrumbItem}>
//             {isLast ? (
//               // Последний элемент — не ссылка, просто текст
//               <span style={styles.breadcrumbActive}>
//                 {breadcrumb.item || "Unnamed"}
//               </span>
//             ) : (
//               // Предыдущие элементы — ссылки
//               <NavLink
//                 to={breadcrumb.link || "#"}
//                 style={styles.breadcrumbLink}
//                 activeStyle={styles.breadcrumbActive} // Для активной ссылки (если требуется)
//                 onMouseOver={(e) =>
//                   (e.currentTarget.style.color =
//                     styles.breadcrumbLinkHover.color)
//                 }
//                 onMouseOut={(e) =>
//                   (e.currentTarget.style.color = styles.breadcrumbLink.color)
//                 }
//               >
//                 {breadcrumb.item || "Unnamed"}
//               </NavLink>
//             )}
//             {!isLast && (
//               // Разделитель между элементами (кроме последнего)
//               <span style={styles.separator}>/</span>
//             )}
//           </div>
//         );
//       })}
//     </nav>
//   );
// };

// export default Breadcrumbs;
