import React from "react";
import "./avatars.css";
import Preloader from "../Preloader/Preloader";
import AnimatedDiv from "../Animation/AnimatedDiv";

const AvatarUser = ({ size, url, isLoading = false }) => {
  const defaultUrl =
    "https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fprofile%2FavatarDefault.svg?alt=media&token=18a44cb0-a166-4f6e-ac52-d5567d616811";
  const backgroundImageUrl = url ? `url(${url})` : `url(${defaultUrl})`;

  return (
    <div
      className="avatar_user"
      style={{
        backgroundImage: backgroundImageUrl,
        width: `${size}px`,
        // height: `${size}px`,
        aspectRatio: "1/1",
        overflow: "hidden",
      }}
    >
      {isLoading && (
        <AnimatedDiv isLoading={isLoading}>
          <Preloader color="--color-white" />
        </AnimatedDiv>
      )}
    </div>
  );
};

export default AvatarUser;
