import React, { useEffect, useState } from "react";
import InputText from "../../../UIComponents/Inputs/InputText";
import Button from "../../../UIComponents/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emailSignUpThunkV4 } from "../../../../reducersToolkit/thunk/emailSignUpThunkV4";
import Preloader from "../../../UIComponents/Preloader/Preloader";

const WidgetSignUp = ({ onClose, onError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isActiveEmail, setIsActiveEmail] = useState(false);
  const [isActivePassword, setIsActivePassword] = useState(false);
  const [isActiveConfirmationPassword, setIsActiveConfirmationPassword] =
    useState(false);
  const [isActiveUserName, setIsActiveUserName] = useState(false);

  const handleSetLogin = (event) => {
    setLogin(event.target.value);
    setError(null);
  };

  const handleSetPassword = (event) => {
    setPassword(event.target.value);
    setError(null);
  };

  const handleSetConfirmationPassword = (event) => {
    setConfirmationPassword(event.target.value);
    setError(null);
  };

  const handleSetUserName = (event) => {
    setUserName(event.target.value);
    setError(null);
  };

  const handleEmailSignUp = () => {
    setLoading(true);
    setError(null);

    dispatch(
      emailSignUpThunkV4({
        email: login,
        password: password,
        userName: userName,
      })
    )
      .unwrap()
      .then(() => {
        navigate("/profile");
        onClose();
      })
      .catch((error) => {
        console.error("Error during registration: ", error.errorMessage);
        setError(error.errorMessage || "Произошла ошибка при регистрации");
        if (onError) onError(error.errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <InputText
          isChangeState={setIsActiveEmail}
          type="email"
          label="Email"
          description="Please enter your name as you would like to be addressed."
          value={login}
          onChange={handleSetLogin}
          disabled={loading}
        />
        <InputText
          isChangeState={setIsActivePassword}
          type="password"
          label="Password"
          description="Please enter your name as you would like to be addressed."
          value={password}
          onChange={handleSetPassword}
          disabled={loading}
        />
        <InputText
          isChangeState={setIsActiveConfirmationPassword}
          type="Password"
          label="Password confirmation"
          description="Please enter your name as you would like to be addressed."
          value={confirmationPassword}
          onChange={handleSetConfirmationPassword}
          disabled={loading}
        />
        <InputText
          isChangeState={setIsActiveUserName}
          type="text"
          label="User name"
          description="Please enter your name as you would like to be addressed."
          value={userName}
          onChange={handleSetUserName}
          disabled={loading}
        />

        {error && (
          <div
            style={{
              color: "var(--color-alarm)",
              padding: "8px",
              fontSize: "14px",
              backgroundColor: "rgba(255, 75, 75, 0.1)",
              borderRadius: "4px",
            }}
          >
            {error}
          </div>
        )}

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "10px 0",
              }}
            >
              <Preloader showText title="Registration in progress..." />
            </div>
          ) : (
            <Button
              active={
                login !== "" &&
                password !== "" &&
                password === confirmationPassword
                //  &&
                // isActiveEmail &&
                // isActivePassword &&
                // isActiveConfirmationPassword
              }
              label="Registration"
              onClick={handleEmailSignUp}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetSignUp;
