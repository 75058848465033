import React from "react";
import Breadcrumbs from "../Breadcrumbs/Breadcrumbs";

const PageBox = ({
  children,
  path,
  title,
  isAlert,
  ContentAlert,
  saveFunction,
  cancelFunction,
}) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            paddingTop: "80px",
            maxWidth: "var(--width-mobile-content)",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
            boxSizing: "border-box",
            marginBottom: "72px",
          }}
        >
          <Breadcrumbs path={path} />

          {title && (
            <h1
              style={{
                width: "100%",
                textAlign: "center",
                padding: "16px 0",
              }}
            >
              {title}
            </h1>
          )}

          {children}
        </div>
      </div>
    </>
  );
};

export default PageBox;
