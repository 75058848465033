import React, { useEffect, useState, useCallback, useRef } from "react";
import "../profile.css";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import { useDispatch } from "react-redux";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import WindowBikeDetailModerationV4 from "../Moderation/WindowBikeDetailModerationV4";
import PhotosGallery from "../../../../UIComponents/PhotosGallery/PhotosGallery";
import { setCurrentPreviewData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import useIsMobile from "../../../../../functions/isMobile";
import Divide from "../../../../UIComponents/Divide/Divide";
import WindowViewBikeDetail from "./WindowViewBikeDetail";
import { Link, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Preloader from "../../../../UIComponents/Preloader/Preloader";
import CountdownTimer from "../../../../UIComponents/Countdown/CountdownTimer";
import Button from "../../../../UIComponents/Buttons/Button";
import BikeItemPreview from "../../../../UIComponents/BikesElements/BikeItemPreview";

// Компонент пагинации
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePreviousPage = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        marginTop: "20px",
        paddingBottom: "20px",
      }}
    >
      <button
        onClick={handlePreviousPage}
        disabled={currentPage === 1}
        style={{
          padding: "8px 16px",
          backgroundColor:
            currentPage === 1
              ? "var(--color-gray-200)"
              : "var(--color-primary)",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: currentPage === 1 ? "not-allowed" : "pointer",
        }}
      >
        Previous
      </button>

      {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
        <button
          key={page}
          onClick={() => onPageChange(page)}
          style={{
            padding: "8px 16px",
            backgroundColor:
              currentPage === page
                ? "var(--color-primary)"
                : "var(--color-gray-200)",
            color: currentPage === page ? "white" : "black",
            border: "none",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        >
          {page}
        </button>
      ))}

      <button
        onClick={handleNextPage}
        disabled={currentPage === totalPages}
        style={{
          padding: "8px 16px",
          backgroundColor:
            currentPage === totalPages
              ? "var(--color-gray-200)"
              : "var(--color-primary)",
          color: "white",
          border: "none",
          borderRadius: "4px",
          cursor: currentPage === totalPages ? "not-allowed" : "pointer",
        }}
      >
        Next
      </button>
    </div>
  );
};

const WidgetBikesList = ({
  mode,
  bikesList,
  bikeState = null,
  closeParent,
  dateInterval,
  itemsPerPage = 6,
  loadType = "lazy",
  userId,
  rentalPointId,
  bikeFilter,
}) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const params = useParams();
  const bikesContainerRef = useRef(null); // Ref для контейнера с байками

  // Используем URL параметры как резервные значения
  const contextUserId = params.userId;
  const contextRentalPointId = params.rentalPointId;

  const [bikesModerationList, setBikesModerationList] = useState([]);
  const [windowBikeDetailModeration, setWindowBikeDetailModeration] =
    useState(false);
  const [windowViewBikeDetail, setWindowViewBikeDetail] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [visibleBikes, setVisibleBikes] = useState([]);
  const [loadedCount, setLoadedCount] = useState(0);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const totalPages = Math.ceil(bikesModerationList?.length / itemsPerPage) || 1;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = bikesModerationList?.slice(startIndex, endIndex) || [];

  const loadMoreBikes = useCallback(
    (count = itemsPerPage) => {
      if (isLoadingMore || loadedCount >= bikesModerationList.length) return;

      // Получаем высоту видимой области окна
      const viewportHeight = window.innerHeight;
      // Получаем текущую позицию прокрутки
      const scrollTop = window.scrollY || document.documentElement.scrollTop;

      // Запоминаем положение первого видимого элемента
      const bikeElements = Array.from(
        document.querySelectorAll(".bike-item-preview")
      );

      if (bikeElements.length === 0) {
        // Если элементов нет, просто загружаем новые
        setIsLoadingMore(true);
        setTimeout(() => {
          const nextCount = Math.min(
            loadedCount + count,
            bikesModerationList.length
          );
          setVisibleBikes(bikesModerationList.slice(0, nextCount));
          setLoadedCount(nextCount);
          setIsLoadingMore(false);
        }, 500);
        return;
      }

      // Находим первый полностью видимый элемент
      let firstVisibleBikeIndex = -1;
      for (let i = 0; i < bikeElements.length; i++) {
        const rect = bikeElements[i].getBoundingClientRect();
        // Элемент полностью или частично видим
        if (rect.top < viewportHeight && rect.bottom > 0) {
          firstVisibleBikeIndex = i;
          break;
        }
      }

      // Если не нашли видимый элемент, используем первый
      if (firstVisibleBikeIndex === -1) {
        firstVisibleBikeIndex = 0;
      }

      // Сохраняем позицию первого видимого элемента относительно окна
      const firstVisibleBike = bikeElements[firstVisibleBikeIndex];
      const firstVisibleBikeRect = firstVisibleBike.getBoundingClientRect();
      const firstVisibleBikeOffsetTop = firstVisibleBikeRect.top;

      setIsLoadingMore(true);
      setTimeout(() => {
        const nextCount = Math.min(
          loadedCount + count,
          bikesModerationList.length
        );
        setVisibleBikes(bikesModerationList.slice(0, nextCount));
        setLoadedCount(nextCount);

        // После обновления DOM, восстанавливаем позицию прокрутки
        setTimeout(() => {
          // Находим тот же элемент после обновления DOM
          const updatedBikeElements = Array.from(
            document.querySelectorAll(".bike-item-preview")
          );
          if (firstVisibleBikeIndex < updatedBikeElements.length) {
            const sameVisibleBike = updatedBikeElements[firstVisibleBikeIndex];
            const newRect = sameVisibleBike.getBoundingClientRect();

            // Вычисляем новую позицию прокрутки, чтобы тот же элемент был в том же положении
            const newScrollTop =
              scrollTop + (newRect.top - firstVisibleBikeOffsetTop);

            // Устанавливаем новую позицию прокрутки
            window.scrollTo({
              top: newScrollTop,
              behavior: "auto",
            });
          }

          setIsLoadingMore(false);
        }, 100);
      }, 500);
    },
    [isLoadingMore, loadedCount, bikesModerationList, itemsPerPage]
  );

  // Применяем фильтрацию и сортировку в зависимости от bikeFilter
  const applyFilterAndSort = useCallback(
    (bikes) => {
      if (!bikes || bikes.length === 0) return [];

      // Создаем копию списка для дальнейшей обработки
      let resultList = [...bikes];

      // Применяем фильтрацию и сортировку в зависимости от bikeFilter
      if (bikeFilter) {
        switch (bikeFilter) {
          case "deleted":
            // Фильтруем только удаленные велосипеды (remove может быть timestamp или boolean)
            resultList = resultList.filter((bike) => {
              // Проверяем наличие поля remove и его значение
              return (
                bike.remove !== null &&
                bike.remove !== undefined &&
                bike.remove !== false
              );
            });
            break;

          case "Recently added first":
            // Сортируем по дате создания (от новых к старым)
            resultList.sort((a, b) => {
              const createdA = a.created || 0;
              const createdB = b.created || 0;
              return createdB - createdA;
            });
            break;

          case "Long ago added first":
            // Сортируем по дате создания (от старых к новым)
            resultList.sort((a, b) => {
              const createdA = a.created || 0;
              const createdB = b.created || 0;
              return createdA - createdB;
            });
            break;

          case "Recently edited":
            // Сортируем по дате последнего обновления (от новых к старым)
            resultList.sort((a, b) => {
              const updatedA = a.lastUpdated || 0;
              const updatedB = b.lastUpdated || 0;
              return updatedB - updatedA;
            });
            break;

          case "long ago edited":
            // Сортируем по дате последнего обновления (от старых к новым)
            resultList.sort((a, b) => {
              const updatedA = a.lastUpdated || 0;
              const updatedB = b.lastUpdated || 0;
              return updatedA - updatedB;
            });
            break;

          default:
            // По умолчанию не применяем дополнительную фильтрацию или сортировку
            break;
        }
      }

      return resultList;
    },
    [bikeFilter]
  );

  useEffect(() => {
    if (bikesList && Array.isArray(bikesList)) {
      // Определяем список для обработки:
      // Если bikeState не указан - используем весь список,
      // иначе фильтруем по статусу
      const filteredByState = bikeState
        ? bikesList.filter((bike) => bike?.status === bikeState)
        : [...bikesList];

      // Разделение на активные и удаленные
      const activeBikes = [];
      const removedBikes = [];

      filteredByState.forEach((bike) => {
        // Проверяем наличие поля remove и его значение
        if (
          bike.remove !== null &&
          bike.remove !== undefined &&
          bike.remove !== false
        ) {
          removedBikes.push(bike);
        } else {
          activeBikes.push(bike);
        }
      });

      // Сортировка активных байков по lastUpdated или created (от новых к старым)
      activeBikes.sort((a, b) => {
        const aDate = a.lastUpdated
          ? new Date(a.lastUpdated)
          : a.created
          ? new Date(a.created)
          : new Date(0);
        const bDate = b.lastUpdated
          ? new Date(b.lastUpdated)
          : b.created
          ? new Date(b.created)
          : new Date(0);
        return bDate - aDate;
      });

      // Сортировка удаленных байков по remove (от новых к старым)
      removedBikes.sort((a, b) => {
        // Обработка различных типов значения remove
        const getRemoveTimestamp = (bike) => {
          if (typeof bike.remove === "number") {
            return bike.remove;
          } else if (bike.remove instanceof Date) {
            return bike.remove.getTime();
          } else if (typeof bike.remove === "string") {
            return new Date(bike.remove).getTime();
          } else if (bike.remove === true) {
            // Если remove === true, но нет timestamp, используем created или текущее время
            return bike.created || Date.now();
          }
          return 0; // Fallback
        };

        const aRemove = getRemoveTimestamp(a);
        const bRemove = getRemoveTimestamp(b);

        return bRemove - aRemove; // От новых к старым
      });

      // Объединение списков: сначала активные, потом удаленные
      let sortedBikes = [...activeBikes, ...removedBikes];
      console.log("Sorted bikes before filter:", sortedBikes);

      // Применяем дополнительную фильтрацию и сортировку на основе bikeFilter
      sortedBikes = applyFilterAndSort(sortedBikes);
      console.log("Sorted bikes after filter:", sortedBikes);

      setBikesModerationList(sortedBikes);
      setCurrentPage(1);
      if (loadType === "lazy") {
        setVisibleBikes(sortedBikes.slice(0, itemsPerPage));
        setLoadedCount(Math.min(itemsPerPage, sortedBikes.length));
      }
    }
  }, [
    bikeState,
    bikesList,
    itemsPerPage,
    loadType,
    bikeFilter,
    applyFilterAndSort,
  ]);

  const handleClose = () => {
    setWindowBikeDetailModeration(false);
    setWindowViewBikeDetail(false);
  };

  const handleEditBike = ({
    bikeId,
    rentalPointId: bikeRentalPointId,
    userId: bikeUserId,
  }) => {
    // Приоритет значений: параметр функции > props компонента > URL параметры
    const effectiveRentalPointId =
      bikeRentalPointId || rentalPointId || contextRentalPointId;
    const effectiveUserId = bikeUserId || userId || contextUserId;

    console.log("Effective IDs:", { effectiveRentalPointId, effectiveUserId });

    if (bikeId && effectiveRentalPointId && effectiveUserId) {
      navigate(
        `/ModerationEditBikeToRentalPoint/${bikeId}/${effectiveRentalPointId}/${effectiveUserId}`
      );
    } else {
      console.error("Missing required parameters:", {
        bikeId,
        rentalPointId: effectiveRentalPointId,
        userId: effectiveUserId,
      });
    }
  };

  const handleNavigateToProductCard = (title, idRootDocument) => {
    const formattedTitle = title.replace(/\s/g, "_");
    navigate(`/productCard/${formattedTitle}/${idRootDocument}`);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <>
      <PopupDirrectory
        isOpen={windowBikeDetailModeration}
        onClose={handleClose}
        title="WindowBikeDetailModerationV4"
      >
        <WindowBikeDetailModerationV4
          mode={mode}
          onClose={handleClose}
          closeParent={closeParent}
        />
      </PopupDirrectory>
      <PopupDirrectory
        isOpen={windowViewBikeDetail}
        onClose={handleClose}
        title="WindowViewBikeDetail"
      >
        <WindowViewBikeDetail
          mode={mode}
          onClose={handleClose}
          closeParent={closeParent}
        />
      </PopupDirrectory>

      <div className="profile-widget__content-box">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {bikesList?.length > 0 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                backgroundColor: "var(--color-white)",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  display: "grid",
                  ...(isMobile
                    ? { gap: "16px", gridTemplateColumns: "repeat(1, 1fr)" }
                    : { gap: "8px", gridTemplateColumns: "repeat(2, 1fr)" }),
                  width: "100%",
                }}
              >
                {loadType === "lazy" ? (
                  <>
                    {visibleBikes.map((bikeItem, index) => (
                      <div key={uuidv4()}>
                        {isMobile && (
                          <Divide
                            title="Bike"
                            number={`${bikeItem?.title} - ${index + 1}`}
                          />
                        )}
                        <BikeItemPreview
                          isRemove={!!bikeItem?.remove}
                          removeTimestamp={bikeItem?.remove}
                          key={index}
                          itemKey={index}
                          month={bikeItem?.rate?.month}
                          bikeStatus={bikeItem?.bikeStatus}
                          title={bikeItem?.title}
                          rate={bikeItem?.rate}
                          photos={bikeItem?.fileUrl}
                          brand={bikeItem?.brand?.title}
                          category={bikeItem?.category?.title}
                          dateInterval={dateInterval}
                          mode={mode}
                          idRootDocument={bikeItem?.idRootDocument}
                          onClick={() =>
                            mode === "view"
                              ? handleNavigateToProductCard(
                                  bikeItem.title,
                                  bikeItem.idRootDocument
                                )
                              : handleEditBike({
                                  bikeId: bikeItem.idRootDocument,
                                  rentalPointId:
                                    bikeItem.idRootDocumentRentalPoint,
                                  userId: bikeItem.idRootDocumentUser,
                                })
                          }
                        />
                      </div>
                    ))}
                    <div
                      style={{
                        ...(isMobile
                          ? { gridColumn: "span 1" }
                          : { gridColumn: "span 2" }),
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      {/* Индикатор загрузки */}
                      {isLoadingMore && (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginTop: "20px",
                          }}
                        >
                          <Preloader />
                        </div>
                      )}

                      {/* Кнопка "Load More" */}
                      {!isLoadingMore &&
                        visibleBikes.length < bikesModerationList.length && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "30px",
                              marginBottom: "20px",
                            }}
                          >
                            <Button
                              label="Load More Bikes"
                              onClick={() => loadMoreBikes()}
                              active={true}
                              type="small"
                            />
                          </div>
                        )}

                      {/* Сообщение о том, что все элементы загружены */}
                      {visibleBikes.length === bikesModerationList.length &&
                        visibleBikes.length > 0 && (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              marginTop: "20px",
                              marginBottom: "20px",
                            }}
                          >
                            <p className="p-light">No more bikes to load</p>
                          </div>
                        )}
                    </div>
                  </>
                ) : (
                  <>
                    {currentItems.map((bikeItem, index) => (
                      <div key={uuidv4()}>
                        {isMobile && (
                          <Divide
                            title="Bike"
                            number={`${bikeItem?.title} - ${
                              startIndex + index + 1
                            }`}
                          />
                        )}
                        <BikeItemPreview
                          isRemove={!!bikeItem?.remove}
                          removeTimestamp={bikeItem?.remove}
                          key={index}
                          itemKey={startIndex + index}
                          month={bikeItem?.rate?.month}
                          bikeStatus={bikeItem?.bikeStatus}
                          title={bikeItem?.title}
                          rate={bikeItem?.rate}
                          photos={bikeItem?.fileUrl}
                          brand={bikeItem?.brand?.title}
                          category={bikeItem?.category?.title}
                          dateInterval={dateInterval}
                          mode={mode}
                          idRootDocument={bikeItem?.idRootDocument}
                          onClick={() =>
                            mode === "view"
                              ? handleNavigateToProductCard(
                                  bikeItem.title,
                                  bikeItem.idRootDocument
                                )
                              : handleEditBike({
                                  bikeId: bikeItem.idRootDocument,
                                  rentalPointId:
                                    bikeItem.idRootDocumentRentalPoint,
                                  userId: bikeItem.idRootDocumentUser,
                                })
                          }
                        />
                      </div>
                    ))}
                    {bikesModerationList?.length > itemsPerPage && (
                      <div style={{ gridColumn: "span 2" }}>
                        <Pagination
                          currentPage={currentPage}
                          totalPages={totalPages}
                          onPageChange={handlePageChange}
                        />
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetBikesList;
