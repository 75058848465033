import styled from "styled-components";

const AnimatedDiv = styled.div.attrs(({ isLoading }) => ({
  style: {
    backgroundColor: isLoading ? "var(--color-gray-100)" : "transparent",
  },
}))`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default AnimatedDiv;
