import { useEffect, useState } from "react";
import Button from "../../../../UIComponents/Buttons/Button";
import TitleBlockSection from "../../../../UIComponents/Titles/TitleBlockSection";
import CountdownTimer from "../../../../UIComponents/Countdown/CountdownTimer";
import IconContainer from "../../../../UIComponents/icons/IconContainer";

const WidgetAllertDelete = ({ onClick, deleteState, title }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0 16px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          gap: "16px",
          alignItems: "center",
        }}
      >
        <IconContainer
          icon={
            <span
              className="material-symbols-outlined"
              style={{
                color: "var(--color-white)",
              }}
            >
              warning
            </span>
          }
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <TitleBlockSection
            title={title}
            titleStyle={{
              color: "var(--color-white)",
            }}
            description={
              <span
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "8px",
                }}
              >
                Полное удаление через:
                <CountdownTimer
                  deleteState={deleteState}
                  interval={604800000}
                />
              </span>
            }
            descriptionStyle={{
              color: "var(--color-white)",
            }}
          />

          {/* <Button
            type="small"
            color="--color-alarm"
            label="Restore"
            active={true}
            onClick={onClick}
            allert={
              <div
                style={{
                  padding: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>Восстанавливаем?</h1>
              </div>
            }
          /> */}
        </div>
      </div>
    </div>
  );
};
export default WidgetAllertDelete;
