import React from "react";

const SkeletonRentalPointLocation = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "16px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          height: "48px",
          width: "48px",
          aspectRatio: "1/1",
          borderRadius: "16px",
          boxSizing: "border-box",
        }}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "70%",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "var(--color-gray-100)",
            height: "8px",
            borderRadius: "100px",
          }}
        />
        <div
          style={{
            width: "70%",
            backgroundColor: "var(--color-gray-100)",
            height: "8px",
            borderRadius: "80px",
          }}
        />
        <div
          style={{
            width: "30%",
            backgroundColor: "var(--color-gray-100)",
            height: "8px",
            borderRadius: "80px",
          }}
        />
      </div>
    </div>
  );
};

export default SkeletonRentalPointLocation;
