import React from "react";
import styled from "styled-components";

const StyledItemWrapper = styled.div`
  position: relative;
  border-radius: 8px;
  background-color: var(--color-gray-100);
  aspect-ratio: 3/2;
`;

const ItemSkelet = () => {
  return <StyledItemWrapper />;
};

const SkeletonBikeRate = () => {
  return (
    <div className="profile-widget__content-box">
      <div className="bikes-rate">
        {Array(12)
          .fill(null)
          .map((_, index) => (
            <ItemSkelet key={index} />
          ))}
      </div>
    </div>
  );
};

export default SkeletonBikeRate;
