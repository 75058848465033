/**
 * Функция для получения вложенного состояния по строковому пути.
 * @param {Object} state - Объект состояния.
 * @param {string} path - Путь к вложенному состоянию в виде строки, разделенной точками или просто строки из одного элемента.
 * @returns {*} - Значение во вложенном состоянии или undefined, если путь некорректен.
 */
export const getNestedState = (state, path) => {
  if (!state || typeof state !== "object") {
    console.error("getNestedState Некорректный объект состояния:", state, path);
    return undefined;
  }

  if (!path || typeof path !== "string") {
    console.error("getNestedState Некорректный путь:", state, path);
    return undefined;
  }

  return path.split(".").reduce((acc, key) => {
    if (!acc) return undefined; // Если на каком-то уровне пустое значение
    return acc[key];
  }, state);
};
