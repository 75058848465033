import React, { useState, useEffect, useRef, useCallback } from "react";
// Удаляем импорт CSS файла
// import "./counter.css";

// Стили для компонента Counter, вынесенные за пределы функции
const styles = {
  contentBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    boxSizing: "border-box",
  },
  controlsBox: {
    fontSize: "1em",
    width: "100%",
    height: "56px",
    boxSizing: "border-box",
    transition: "border-color 0.3s",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  },
  controlsBoxChildren: {
    marginTop: "16px",
  },
  quantityButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "56px",
    height: "56px",
    aspectRatio: "1/1",
    borderRadius: "8px",
    backgroundColor: "violet",
    cursor: "pointer",
    transition: "all 0.3s ease",
    border: "none",
  },
  quantityButtonActive: {
    backgroundColor: "var(--color-gray-100)",
    transition: "background-color 0.2s",
  },
  quantityButtonActiveHover: {
    borderColor: "var(--color-black)",
  },
  quantityButtonDisable: {
    backgroundColor: "var(--color-gray-100)",
    opacity: "0.5",
    cursor: "not-allowed",
  },
  inputTextContentBox: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  inputTextLabel: {
    position: "absolute",
    top: "24%",
    margin: "0 0 0 10px",
    padding: "4px 8px",
    borderRadius: "6px",
    fontSize: "1em",
    color: "var(--color-black)",
    backgroundColor: "var(--color-white)",
    boxSizing: "content-box",
    transition: "top ease-in-out 0.3s, font-size ease-in-out 0.3s",
    textTransform: "capitalize",
  },
  inputTextLabelUp: {
    top: "-18%",
    fontSize: "0.8em",
  },
  counterInputTextWrite: {
    borderWidth: "2px",
    borderStyle: "solid",
    borderColor: "var(--color-gray-200)",
    backgroundColor: "var(--color-white)",
    fontSize: "1em",
    padding: "16px",
    width: "100%",
    height: "56px",
    boxSizing: "border-box",
    transition: "border-color 0.3s",
    borderRadius: "8px",
    MozAppearance: "textfield",
  },
  counterInputTextWriteFocus: {
    outline: "none",
    borderColor: "var(--color-black)",
  },
  descriptionContainer: {
    width: "100%",
  },
  descriptionText: {
    width: "70%",
  },
  unitContainer: {
    position: "absolute",
    right: "16px",
    pointerEvents: "none",
    color: "var(--color-gray-700)",
    fontSize: "1em",
  },
};

// Стили для small режима, вынесенные за пределы функции
const smallStyles = {
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "4px",
    borderRadius: "80px",
  },
  decrementButton: {
    backgroundColor: "var(--color-gray-100)",
    borderRadius: "80px 0 0 80px",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "32px",
    cursor: "pointer",
    border: "0px",
  },
  incrementButton: {
    backgroundColor: "var(--color-gray-100)",
    borderRadius: "0 80px 80px 0",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "32px",
    cursor: "pointer",
    border: "0px",
  },
  inputContainer: {
    backgroundColor: "var(--color-gray-100)",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "32px",
    position: "relative",
  },
  input: {
    backgroundColor: "var(--color-gray-100)",
    padding: "0 8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "32px",
    width: "2ch",
    border: "0",
    fontSize: "1rem",
    fontWeight: "500",
    appearance: "none",
    MozAppearance: "textfield",
  },
  smallUnitContainer: {
    position: "absolute",
    right: "4px",
    pointerEvents: "none",
    color: "var(--color-gray-700)",
    fontSize: "0.8em",
  },
};

const Counter = ({
  maxValue = 0,
  minValue = 0,
  value = 0,
  onChange,
  label,
  description,
  children,
  buttonStyle,
  type,
  unit,
}) => {
  const [count, setCount] = useState(value);
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const isInitialMount = useRef(true);
  const valueRef = useRef(value);
  const onChangeRef = useRef(onChange);
  const isUpdatingFromProps = useRef(false);

  // Обновляем ссылку на onChange при его изменении
  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  // Синхронизируем внутреннее состояние с пропсами только при изменении value
  useEffect(() => {
    if (valueRef.current !== value) {
      isUpdatingFromProps.current = true;
      valueRef.current = value;
      setCount(value);
    }
  }, [value]);

  // Вызываем callback при изменении внутреннего состояния, но не во время обновления из пропсов
  useEffect(() => {
    // Если обновление было вызвано извне (через пропсы), не вызываем onChange
    if (isUpdatingFromProps.current) {
      isUpdatingFromProps.current = false;
      return;
    }

    // Пропускаем первый рендер
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    // Если значение действительно изменилось, вызываем callback
    if (onChangeRef.current && count !== valueRef.current) {
      valueRef.current = count;
      onChangeRef.current(count);
    }
  }, [count]);

  // Увеличиваем значение
  const handleIncrement = useCallback(() => {
    setCount((prevCount) => {
      return maxValue !== undefined
        ? Math.min(prevCount + 1, maxValue)
        : prevCount + 1;
    });
  }, [maxValue]);

  // Уменьшаем значение
  const handleDecrement = useCallback(() => {
    setCount((prevCount) => {
      return Math.max(prevCount - 1, minValue);
    });
  }, [minValue]);

  // Обрабатываем изменение ввода
  const handleChange = useCallback(
    (event) => {
      const newValue = parseInt(event.target.value, 10);
      if (!isNaN(newValue)) {
        const newCount =
          newValue >= minValue &&
          (maxValue === undefined || newValue <= maxValue)
            ? newValue
            : newValue < minValue
            ? minValue
            : maxValue;
        setCount(newCount);
      } else {
        setCount(minValue);
      }
    },
    [minValue, maxValue]
  );

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  // Динамические стили для input в зависимости от состояния фокуса и наличия единицы измерения
  const inputStyle = {
    ...styles.counterInputTextWrite,
    ...(isFocused ? styles.counterInputTextWriteFocus : {}),
    ...(unit ? { paddingRight: "40px" } : {}),
  };

  // Динамические стили для small input в зависимости от наличия единицы измерения
  const smallInputStyle = {
    ...smallStyles.input,
    ...(unit ? { paddingRight: "16px" } : {}),
  };

  return type === "small" ? (
    <div style={smallStyles.container}>
      <button
        onClick={handleDecrement}
        disabled={count <= minValue}
        style={smallStyles.decrementButton}
      >
        <span className="material-symbols-outlined">remove</span>
      </button>

      <div style={smallStyles.inputContainer}>
        <input
          style={smallInputStyle}
          id={label}
          name={label}
          placeholder={label}
          value={count}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={inputRef}
          required={true}
          min={minValue}
        />
        {unit && <div style={smallStyles.smallUnitContainer}>{unit}</div>}
      </div>

      <button
        onClick={handleIncrement}
        disabled={maxValue !== undefined && count >= maxValue}
        style={smallStyles.incrementButton}
      >
        <span className="material-symbols-outlined">add</span>
      </button>
    </div>
  ) : (
    <div style={styles.contentBox}>
      {children}
      <div
        style={{
          ...styles.controlsBox,
          ...(children ? styles.controlsBoxChildren : {}),
        }}
      >
        <button
          style={{
            ...styles.quantityButton,
            ...styles.quantityButtonActive,
            ...(count === minValue ? styles.quantityButtonDisable : {}),
            ...buttonStyle,
          }}
          onClick={handleDecrement}
          disabled={count <= minValue}
        >
          <span className="material-symbols-outlined">remove</span>
        </button>

        <div style={styles.inputTextContentBox}>
          <label
            htmlFor={label}
            style={{
              ...styles.inputTextLabel,
              ...(isFocused || count ? styles.inputTextLabelUp : {}),
            }}
          >
            {label}
          </label>

          <input
            style={inputStyle}
            type="number"
            id={label}
            name={label}
            placeholder={label}
            value={count}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            ref={inputRef}
            required={true}
            min={minValue}
          />

          {unit && <div style={styles.unitContainer}>{unit}</div>}
        </div>

        <button
          style={{
            ...styles.quantityButton,
            ...styles.quantityButtonActive,
            ...(count >= maxValue ? styles.quantityButtonDisable : {}),
            ...buttonStyle,
          }}
          onClick={handleIncrement}
          disabled={maxValue !== undefined && count >= maxValue}
        >
          <span className="material-symbols-outlined">add</span>
        </button>
      </div>

      {description && (
        <div style={styles.descriptionContainer}>
          <p style={styles.descriptionText} className="p-light">
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default Counter;
