import React, { useEffect, useState } from "react";
import PopupDirrectory from "../../../../../UIComponents/Popups/PopupDirrectory";
import AddData from "../../../../../UIComponents/AddData/AddData";
import WindowCountryDetailV4 from "./WindowCountryDetailV4";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../../UIComponents/ContentBox/ContentBox";
import WidgetCountriesListV4 from "./WidgetCountriesListV4";
import { fetchPreviewDataV4 } from "../../../../../../reducersToolkit/thunk/fetchPreviewDataV4";

const WindowCountriesListV4 = () => {
  const dispatch = useDispatch();

  const { loading, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [openWindowCountryDetail, setOpenWindowCountryDetail] = useState(false);
  const [sortedCountries, setSortedCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);

  // FETCH
  const [
    loading_fetchPreviewCountries_WindowCountriesListV4,
    setLoading_fetchPreviewCountries_WindowCountriesListV4,
  ] = useState(false);
  const [
    windowCountriesListV4LoadingFetchPreviewCities,
    setWindowCountriesListV4LoadingFetchPreviewCities,
  ] = useState(false);
  // CREATE
  const [
    windowCountryDetailV4LoadingCreateCoutry,
    setWindowCountryDetailV4LoadingCreateCoutry,
  ] = useState(false);
  // UPDATE
  const [
    windowCountryDetailV4LoadingUpdateCountry,
    setWindowCountryDetailV4LoadingUpdateCountry,
  ] = useState(false);
  // DELETE
  const [
    windowCountryDetailV4LoadingDeleteCountry,
    setWindowCountryDetailV4LoadingDeleteCountry,
  ] = useState(false);
  // LOADING
  const [
    windowCountryDetailV4loadingIconFiles,
    setWindowCountryDetailV4LoadingIconFiles,
  ] = useState(false);

  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: "countriesPreview",
        //
        loadingStateName: "loading_fetchPreviewCountries_WindowCountriesListV4",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "countriesPreview",
      })
    );
  }, [dispatch]);

  useEffect(() => {
    setCountriesList(tempPreviewData.countriesPreview || []);
  }, [tempPreviewData.countriesPreview]);

  useEffect(() => {
    // FETCH
    setLoading_fetchPreviewCountries_WindowCountriesListV4(
      loading.loading_fetchPreviewCountries_WindowCountriesListV4 || false
    );
    setWindowCountriesListV4LoadingFetchPreviewCities(
      loading.windowCountriesListV4LoadingFetchPreviewCities || false
    );
    // CREATE
    setWindowCountryDetailV4LoadingCreateCoutry(
      loading.windowCountryDetailV4LoadingCreateCoutry || false
    );
    // UPDATE
    setWindowCountryDetailV4LoadingUpdateCountry(
      loading.windowCountryDetailV4LoadingUpdateCountry || false
    );
    // DELETE
    setWindowCountryDetailV4LoadingDeleteCountry(
      loading.windowCountryDetailV4LoadingDeleteCountry || false
    );
    // LOADING
    setWindowCountryDetailV4LoadingIconFiles(
      loading.windowCountryDetailV4loadingIconFiles || false
    );
  }, [
    loading.loading_fetchPreviewCountries_WindowCountriesListV4,
    loading.windowCountriesListV4LoadingFetchPreviewCities,
    loading.windowCountryDetailV4LoadingCreateCoutry,
    loading.windowCountryDetailV4LoadingUpdateCountry,
    loading.windowCountryDetailV4LoadingDeleteCountry,
    loading.windowCountryDetailV4loadingIconFiles,
  ]);

  const handleToggleOpenWindowCountryDetail = () => {
    setOpenWindowCountryDetail(true);
  };

  const handleClose = () => {
    setOpenWindowCountryDetail(false);
  };

  useEffect(() => {
    if (Array.isArray(countriesList)) {
      const sortedData = [...countriesList]?.sort((a, b) => {
        return new Date(b.createdat) - new Date(a.createdat);
      });
      setSortedCountries(sortedData);
    }
  }, [countriesList]);

  return (
    <>
      {/* POPUP WINDOW */}
      <PopupDirrectory
        isOpen={openWindowCountryDetail}
        onClose={handleClose}
        title={"Add country V4"}
      >
        <WindowCountryDetailV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOW */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: "8px",
        }}
      >
        <ContentBox
          position="start"
          isLoading={
            loading_fetchPreviewCountries_WindowCountriesListV4 ||
            windowCountriesListV4LoadingFetchPreviewCities ||
            windowCountryDetailV4LoadingCreateCoutry ||
            windowCountryDetailV4LoadingUpdateCountry ||
            windowCountryDetailV4LoadingDeleteCountry ||
            windowCountryDetailV4loadingIconFiles
          }
        >
          {/* <UpdateCatalogButton /> */}

          <AddData
            onClick={() => handleToggleOpenWindowCountryDetail()}
            icon={<span className="material-symbols-outlined">add</span>}
            title="Add country"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          />
        </ContentBox>
        <ContentBox
          isLoading={
            loading_fetchPreviewCountries_WindowCountriesListV4 ||
            windowCountriesListV4LoadingFetchPreviewCities ||
            windowCountryDetailV4LoadingCreateCoutry ||
            windowCountryDetailV4LoadingUpdateCountry ||
            windowCountryDetailV4LoadingDeleteCountry ||
            windowCountryDetailV4loadingIconFiles
          }
        >
          <WidgetCountriesListV4
            isLoading={
              loading_fetchPreviewCountries_WindowCountriesListV4 ||
              windowCountriesListV4LoadingFetchPreviewCities ||
              windowCountryDetailV4LoadingCreateCoutry ||
              windowCountryDetailV4LoadingUpdateCountry ||
              windowCountryDetailV4LoadingDeleteCountry
            }
            countriesList={sortedCountries}
          />
        </ContentBox>
      </div>
    </>
  );
};

export default WindowCountriesListV4;
