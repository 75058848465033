import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import Counter from "../../../../UIComponents/Counter/Counter";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import Button from "../../../../UIComponents/Buttons/Button";
// import { setOrderService } from "../../../../../reducersToolkit/orderSliceV2";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";

const WindowOrderAddToCartAdditionalService = ({
  mode,
  additionalServiceId,
  onClose,
}) => {
  console.log("additionalServiceId", additionalServiceId);
  const dispatch = useDispatch();

  const [service, setService] = useState(null);
  const [orderedService, setOrderedService] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [counter, setCounter] = useState(0);
  const [ordered, setOrdered] = useState(0);

  if (!service) return null;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
        marginBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <div
          style={{
            width: "100%",
          }}
        >
          <h5
            style={{
              width: "80%",
            }}
          >
            {title}
          </h5>
        </div>
        <Counter
          label="Quantity"
          description={`Available to order: ${counter - orderedService}`}
          minValue={0}
          maxValue={counter}
          value={ordered}
          onChange={setOrderedService}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <p className="p-light">Description:</p>
          <p
            style={{
              width: "80%",
            }}
          >
            {description}
          </p>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 0",
              boxSizing: "border-box",
              borderBottom: "1px solid var(--color-gray-200)",
            }}
          >
            <p className="p-light">Price:</p>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {formatCurrencyUSD(service.price)}
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 0",
              boxSizing: "border-box",
              borderBottom: "1px solid var(--color-gray-200)",
            }}
          >
            <p className="p-light">Counter services:</p>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {service.counter || 0}
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "8px 0",
              boxSizing: "border-box",
            }}
          >
            <p className="p-light">Services available:</p>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {service.counter - (service.ordered || 0) || 0}
            </p>
          </div>
        </div>
      </ContentBox>
      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Button
            type="small"
            label="Apply"
            // onClick={addAditionalService}
            active={orderedService > 0 && orderedService !== ordered}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowOrderAddToCartAdditionalService;
