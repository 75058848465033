import { setGlobalError } from "../reducersToolkit/slice/catalogSliceV4";
import interpretErrorV4 from "./interpretErrorV4";

/**
 * Функция для обработки ошибок в thunk
 * @param {Object} error - Объект ошибки
 * @param {Function} dispatch - Функция dispatch из Redux
 * @param {Function} rejectWithValue - Функция для отклонения Promise с ошибкой
 * @returns {Object} Объект с информацией об ошибке для rejectWithValue
 */
export const handleThunkError = (error, dispatch, rejectWithValue) => {
  // Получаем сообщение об ошибке из интерпретатора
  const errorMessage =
    interpretErrorV4(error.code) ||
    error.message ||
    "Произошла неизвестная ошибка";

  // Отправляем сообщение об ошибке в глобальное состояние
  dispatch(setGlobalError(errorMessage));

  // Логируем ошибку
  console.error("Error in thunk:", errorMessage, error);

  // Возвращаем объект с информацией об ошибке для rejectWithValue
  return rejectWithValue({ errorMessage });
};

export default handleThunkError;
