import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../services/firebase/authFirebase";
import handleThunkError from "../../functions/handleThunkError";
import {
  setErrorState,
  setLoadingState,
  updateRootDocuments,
} from "../slice/catalogSliceV4";

// Для хранения функций отписки, связанных с конкретными документами
const unsubscribeMap = new Map();

/**
 * Подписка на изменения в документе Firestore с автоматической отпиской при повторной подписке.
 * @param {Object} params - Параметры подписки.
 * @param {string} params.collectionPath - Путь к коллекции Firestore.
 * @param {string} [params.rootCurrentDocumentState] - Состояние текущего документа в Redux (рекомендуется).
 * @param {string} [params.rootCurrentDocumenState] - Устаревшее состояние текущего документа в Redux (для обратной совместимости).
 * @param {string} params.idRootDocument - ID документа Firestore.
 * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
 * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
 */
export const subscribeRootDocumentsThunkV4 = createAsyncThunk(
  "catalog/subscribeRootDocumentsThunkV4",
  async (
    {
      collectionPath,
      rootCurrentDocumenState, // Старое название (с опечаткой)
      rootCurrentDocumentState, // Новое правильное название
      idRootDocument,
      loadingStateName,
      errorStateName,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log(
        "Инициализация подписки на документ:",
        `${collectionPath}/${idRootDocument}`
      );

      const documentRef = doc(db, ...collectionPath.split("."), idRootDocument);
      console.log("Ссылка на документ Firestore создана:", documentRef);

      // Проверяем наличие существующей подписки и отписываемся, если она есть
      if (unsubscribeMap.has(idRootDocument)) {
        console.log(
          `Отписка от существующей подписки для документа ${idRootDocument}`
        );
        const existingUnsubscribe = unsubscribeMap.get(idRootDocument);
        if (typeof existingUnsubscribe === "function") {
          existingUnsubscribe(); // Вызываем отписку
        }
        unsubscribeMap.delete(idRootDocument); // Удаляем старую подписку из карты
      }

      // Определяем, какой параметр использовать: новое название имеет приоритет
      const stateKey = rootCurrentDocumentState || rootCurrentDocumenState;
      if (!stateKey) {
        console.warn(
          "Не передан ни rootCurrentDocumentState, ни rootCurrentDocumenState. Используется пустое значение."
        );
      }
      console.log("Используемый stateKey:", stateKey);

      // Подписка на документ и получение функции отписки
      const unsubscribe = onSnapshot(
        documentRef,
        (snapshot) => {
          let documentData = {};
          if (snapshot.exists()) {
            documentData = { id: snapshot.id, ...snapshot.data() };
          } else {
            console.warn(`Документ с ID ${idRootDocument} не найден.`);
          }

          // Обновляем данные сразу
          dispatch(
            updateRootDocuments({
              documents: documentData,
              rootCurrentDocumenState: stateKey, // Используем определённый ключ
            })
          );
          dispatch(
            setLoadingState({
              loadingStateName,
              isLoading: false,
            })
          );

          console.log(
            `Данные обновлены для документа ${idRootDocument} (сразу).`
          );
        },
        (error) => {
          handleThunkError(error, dispatch, rejectWithValue);
        }
      );

      // Сохраняем новую функцию отписки в Map
      unsubscribeMap.set(idRootDocument, unsubscribe);

      console.log("Подписка успешно установлена на документ:", idRootDocument);

      // Возвращаем объект без функции отписки для сериализуемости
      return { subscriptionEstablished: true };
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);

/**
 * Функция для отписки от документа Firestore.
 * @param {string} idRootDocument - ID документа Firestore.
 */
export const unsubscribeRootDocument = (idRootDocument) => {
  if (unsubscribeMap.has(idRootDocument)) {
    const unsubscribe = unsubscribeMap.get(idRootDocument);
    if (typeof unsubscribe === "function") {
      unsubscribe();
      console.log(`Отписка от документа с ID ${idRootDocument} выполнена`);
    }
    unsubscribeMap.delete(idRootDocument);
  } else {
    console.log(`Нет подписки для документа с ID ${idRootDocument}`);
  }
};

export const unsubscribeThunkV5 = (key) => {
  if (unsubscribeMap.has(key)) {
    const unsubscribe = unsubscribeMap.get(key);
    unsubscribe(); // Выполняем отписку
    unsubscribeMap.delete(key);
    console.log(`Отписка выполнена для ключа: ${key}`);
  } else {
    console.log(`Нет подписки для ключа: ${key}`);
  }
};
