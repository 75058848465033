import React, { useState, useEffect } from "react";
import "./dropdownList.css";
import { v4 as uuidv4 } from "uuid";
import PopupDirrectory from "../Popups/PopupDirrectory";

const DropdownFilterItem = ({
  options,
  onSelect,
  label,
  fieldView,
  fieldId,
  initialSelectedOption,
  active,
  style,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  useEffect(() => {
    setSelectedOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleClearButtonFilterData = () => {
    setSelectedOption(null);
    onSelect(null);
  };

  const handleToggle = () => {
    setIsOpen(true);
  };

  const handleCloseDropdownList = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        onClick={!initialSelectedOption && active ? handleToggle : undefined}
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          gap: "8px",
          ...(active
            ? {
                cursor: "pointer",
              }
            : {
                cursor: "default",
              }),
        }}
      >
        {/* TITLE */}
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          onClick={initialSelectedOption ? handleToggle : undefined}
        >
          <p
            style={{
              ...(active
                ? {
                    color: "rgba(0, 0, 0, 0.8)",
                    ...style,
                  }
                : {
                    color: "rgba(0, 0, 0, 0.2)",
                  }),
            }}
          >
            {selectedOption ? selectedOption[fieldView] : label}
          </p>
        </div>

        {/* CONTROL */}
        <div
          onClick={handleClearButtonFilterData}
          style={{
            ...(active
              ? {
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                }
              : {
                  backgroundColor: "rgba(255, 255, 255, 0.3)",
                }),
            height: "calc(100% - 8px)",
            aspectRatio: "1/1",

            borderRadius: "80px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {initialSelectedOption ? (
            <span
              style={{
                ...style,
              }}
              className="material-symbols-outlined"
            >
              close_small
            </span>
          ) : (
            <span
              style={{
                // fontSize: "1.2rem",
                ...(active
                  ? {
                      color: "rgba(0, 0, 0, 0.8)",
                    }
                  : {
                      color: "rgba(0, 0, 0, 0.2)",
                    }),
              }}
              className="material-symbols-outlined"
            >
              keyboard_arrow_down
            </span>
          )}
        </div>
      </div>

      <PopupDirrectory
        isOpen={isOpen}
        onClose={handleCloseDropdownList}
        title={label}
      >
        {isOpen && (
          <ul className="dropdown-list__options">
            {options.map((option) => (
              <li
                key={uuidv4()}
                onClick={() => handleOptionSelect(option)}
                style={{
                  ...(selectedOption &&
                    selectedOption[fieldId] === option[fieldId] && {
                      backgroundColor: "var(--color-gray-100)",
                      fontWeight: "500",
                    }),
                }}
              >
                {option[fieldView]}
                {option.title.titleOriginal}
              </li>
            ))}
          </ul>
        )}
      </PopupDirrectory>
    </>
  );
};

export default DropdownFilterItem;
