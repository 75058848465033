const IconContainer = ({
  icon,
  containerStyle,
  iconStyle,
  color,
  size = "48px",
  onClick,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        width: size || "48px",
        aspectRatio: "1/1",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: color || "var(--color-gray-900-alpha-30)",
        borderRadius: "16px",
        color: color || "var(--color-white)",
        boxSizing: "border-box",
        cursor: onClick ? "pointer" : "default",
        ...containerStyle,
      }}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "var(---color-black)",
          ...iconStyle,
        }}
      >
        {icon}
      </span>
    </div>
  );
};

export default IconContainer;
