import { createAsyncThunk } from "@reduxjs/toolkit";
import { collection, onSnapshot } from "firebase/firestore";
import { db } from "../../services/firebase/authFirebase";
import handleThunkError from "../../functions/handleThunkError";
import {
  setErrorState,
  setLoadingState,
  updateDocuments,
} from "../slice/catalogSliceV4";

// Для хранения подписок
const unsubscribeMap = new Map();

/**
 * Подписка на изменения в коллекции Firestore с автоматической отпиской при повторной подписке.
 * @param {Object} params - Параметры подписки.
 * @param {string} params.collectionPath - Путь к коллекции Firestore.
 * @param {string} params.previewCurrentDocumenState - Состояние текущей коллекции в Redux.
 * @param {Array<string>} params.excludedIds - Массив ID документов, которые нужно исключить.
 * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
 * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
 */
export const subscribePreviewDocumentsThunkV4 = createAsyncThunk(
  "catalog/subscribePreviewDocumentsThunkV4",
  async (
    {
      collectionPath,
      previewCurrentDocumenState,
      excludedIds = [],
      loadingStateName,
      errorStateName,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log("Инициализация подписки для коллекции:", collectionPath);

      // Проверяем, существует ли уже подписка для этой коллекции
      if (unsubscribeMap.has(collectionPath)) {
        console.log(`Отписка от существующей подписки для ${collectionPath}`);
        const existingUnsubscribe = unsubscribeMap.get(collectionPath);
        existingUnsubscribe(); // Вызываем отписку
        unsubscribeMap.delete(collectionPath); // Удаляем из карты
      }

      // Создаём запрос к Firestore
      const collectionRef = collection(db, ...collectionPath.split("."));

      // Устанавливаем новую подписку
      const unsubscribe = onSnapshot(
        collectionRef,
        (snapshot) => {
          try {
            const filteredDocuments = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .filter((doc) => !excludedIds.includes(doc.id));

            const combinedData = filteredDocuments.reduce((acc, doc) => {
              if (Array.isArray(doc.data)) {
                return acc.concat(doc.data);
              }
              return acc;
            }, []);

            // Обновляем данные в Redux
            dispatch(
              updateDocuments({
                documents: combinedData,
                previewCurrentDocumenState,
              })
            );
            console.log(`Данные обновлены для коллекции ${collectionPath}.`);

            // Обновляем состояние загрузки
            dispatch(
              setLoadingState({
                loadingStateName,
                isLoading: false,
              })
            );
          } catch (error) {
            handleThunkError(error, dispatch, rejectWithValue);
          }
        },
        (error) => {
          handleThunkError(error, dispatch, rejectWithValue);
        }
      );

      // Сохраняем новую функцию отписки в карту
      unsubscribeMap.set(collectionPath, unsubscribe);

      // Возвращаем объект для информирования о успешной подписке
      return { subscriptionEstablished: true };
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);

/**
 * Thunk для остановки подписки от коллекции Firestore.
 * @param {string} collectionPath - Путь к коллекции Firestore.
 */
export const unsubscribePreviewDocumentsThunkV4 = createAsyncThunk(
  "catalog/unsubscribePreviewDocumentsThunkV4",
  async (collectionPath, { dispatch }) => {
    try {
      // Если подписка существует, отписываемся
      if (unsubscribeMap.has(collectionPath)) {
        const unsubscribe = unsubscribeMap.get(collectionPath);
        unsubscribe(); // Отписываемся
        unsubscribeMap.delete(collectionPath);
        console.log(`Подписка на коллекцию ${collectionPath} отменена`);
      } else {
        console.log(`Нет активной подписки для коллекции ${collectionPath}`);
      }

      return { unsubscribed: true };
    } catch (error) {
      console.error("Ошибка при остановке подписки на Firestore:", error);
      throw error;
    }
  }
);

export const unsubscribeThunkV5 = (key) => {
  if (unsubscribeMap.has(key)) {
    const unsubscribe = unsubscribeMap.get(key);
    unsubscribe(); // Выполняем отписку
    unsubscribeMap.delete(key);
    console.log(`Отписка выполнена для ключа: ${key}`);
  } else {
    console.log(`Нет подписки для ключа: ${key}`);
  }
};
