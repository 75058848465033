import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import PopupWindow from "../Popups/PopupWindow";
import { clearGlobalError } from "../../../reducersToolkit/slice/catalogSliceV4";
import "./ErrorNotification.css";

const ErrorNotification = ({ error, isOpen }) => {
  const dispatch = useDispatch();
  const timerRef = useRef(null);

  const handleClose = () => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
    dispatch(clearGlobalError());
  };

  useEffect(() => {
    if (isOpen) {
      // Установить таймер для автоматического закрытия через 15 секунд
      timerRef.current = setTimeout(() => {
        dispatch(clearGlobalError());
      }, 150000);
    }

    return () => {
      // Очистка таймера при размонтировании компонента
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    };
  }, [isOpen, dispatch]);

  return (
    <PopupWindow
      isOpen={isOpen}
      onClose={handleClose}
      title="Ошибка"
      isLoading={false}
      zIndex={99999}
      className="popup-error"
    >
      <div
        style={{
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <div
          style={{
            width: "100%",
          }}
        >
          <span
            className="material-symbols-outlined"
            style={{ fontSize: "6rem" }}
          >
            warning
          </span>
        </div>
        <h1>{error}</h1>
      </div>
    </PopupWindow>
  );
};

export default ErrorNotification;
