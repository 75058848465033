import React, { useEffect, useState } from "react";
import AddData from "../../../../UIComponents/AddData/AddData";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowUserModerationV4 from "./WindowUserModerationV4";
import CollapsableText from "../../../../UIComponents/Collapsable/CollapsableText";

const WidgetUserModerationV4 = ({ mode, comments, isChangeState }) => {
  const [windowUserModeration, setWindowUserModeration] = useState(false);
  const [latestComment, setLatestComment] = useState(null);

  // Получение последнего комментария
  useEffect(() => {
    if (comments && Array.isArray(comments)) {
      const sortedComments = [...comments].sort((a, b) => {
        const timeA = a.timestamp || a.created || 0;
        const timeB = b.timestamp || b.created || 0;
        return timeB - timeA;
      });

      setLatestComment(sortedComments.length > 0 ? sortedComments[0] : null);
      // setLatestStatus(sortedComments.length > 0 ? sortedComments[0] : null);
    }
  }, [comments]);

  // Открытие окна модерации
  const handleOpenWindowUserModeration = () => {
    setWindowUserModeration(true);
  };

  // Закрытие окна модерации
  const handleClose = () => {
    setWindowUserModeration(false);
  };

  // Завершение модерации
  const handleModerationComplete = () => {
    handleClose();
    if (isChangeState) {
      isChangeState(true);
    }
  };

  // Получение заголовка для статуса
  const getStatusTitle = (status) => {
    switch (status) {
      case "approved":
        return "Approved";
      case "banned":
        return "Banned";
      case "moderation":
      default:
        return "Moderation";
    }
  };

  // Получение описания для статуса
  const getStatusDescription = (status, comment) => {
    // Если есть комментарий, используем его содержимое
    if (comment?.content) {
      return comment.content;
    }

    // Иначе используем стандартное описание для статуса
    switch (status) {
      case "approved":
        return "User has been approved and is accessible in the system.";
      case "banned":
        return "User has been banned and cannot access the system.";
      case "moderation":
      default:
        return "User is under moderation and waiting for approval.";
    }
  };

  return (
    <>
      {/* POPUP WINDOW */}
      <PopupDirrectory
        isOpen={windowUserModeration}
        onClose={handleClose}
        title="User moderation"
      >
        <WindowUserModerationV4
          mode={mode}
          onClose={handleModerationComplete}
          isChangeState={isChangeState}
        />
      </PopupDirrectory>
      {/* POPUP WINDOW */}

      <AddData
        icon={<span className="material-symbols-outlined">add</span>}
        title={getStatusTitle(latestComment?.status)}
        description={
          <CollapsableText
            text={getStatusDescription(latestComment?.status, latestComment)}
          />
        }
        onClick={handleOpenWindowUserModeration}
      />
    </>
  );
};

export default WidgetUserModerationV4;
