// configureStore.js
import { configureStore, combineReducers } from "@reduxjs/toolkit";
import createIdbStorage from "redux-persist-indexeddb-storage";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import thunk from "redux-thunk";

import catalogSliceV4 from "../slice/catalogSliceV4";

// Комбинируем редьюсеры
const rootReducer = combineReducers({
  catalogSliceV4,
  // Добавьте остальные редьюсеры по мере необходимости
});

// Используем IndexedDB вместо localStorage
const persistConfig = {
  key: "root",
  storage: createIdbStorage({ name: "myApp", storeName: "reduxPersist" }), // IndexedDB
  whitelist: [
    // Редьюсеры, которые нужно сохранять в IndexedDB
    "catalogSliceV4",
  ],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Создаем стор
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production", // Включение DevTools в режиме разработки
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          "catalog/subscribePreviewDocumentsThunkV4/fulfilled", // Игнорируем это действие
        ],
        ignoredPaths: ["payload.unsubscribe"], // Игнорируем путь unsubscribe
      },
    }).concat(thunk),
});

// Создаем persistor
const persistor = persistStore(store);

export { store, persistor };

// // configureStore.js
// import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import createIdbStorage from "redux-persist-indexeddb-storage";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";

// import catalogSliceV4 from "../reducersToolkit/catalogSliceV4";

// // Комбинируем редьюсеры
// const rootReducer = combineReducers({
//   catalogSliceV4,
//   // Добавьте остальные редьюсеры по мере необходимости
// });

// // Используем IndexedDB вместо localStorage
// const persistConfig = {
//   key: "root",
//   storage: createIdbStorage({ name: "myApp", storeName: "reduxPersist" }), // IndexedDB
//   whitelist: [
//     // Редьюсеры, которые нужно сохранять в IndexedDB
//     "catalogSliceV4",
//   ],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Создаем стор
// const store = configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== "production", // Включение DevTools в режиме разработки
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [
//           FLUSH,
//           REHYDRATE,
//           PAUSE,
//           PERSIST,
//           PURGE,
//           REGISTER,
//           "catalog/subscribePreviewDocumentsThunkV4/fulfilled", // Игнорируем это действие
//         ],
//         ignoredPaths: ["payload.unsubscribe"], // Игнорируем путь unsubscribe
//       },
//     }),
// });

// // Создаем persistor
// const persistor = persistStore(store);

// export { store, persistor };
// -----------------------------
// // configureStore.js
// import { configureStore, combineReducers } from "@reduxjs/toolkit";
// import createIdbStorage from "redux-persist-indexeddb-storage";
// import {
//   persistStore,
//   persistReducer,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from "redux-persist";

// import catalogSliceV4 from "../reducersToolkit/catalogSliceV4";

// // Комбинируем редьюсеры
// const rootReducer = combineReducers({
//   catalogSliceV4,
//   // Добавьте остальные редьюсеры по мере необходимости
// });

// // Используем IndexedDB вместо localStorage
// const persistConfig = {
//   key: "root",
//   storage: createIdbStorage({ name: "myApp", storeName: "reduxPersist" }), // IndexedDB
//   whitelist: [
//     // Редьюсеры, которые нужно сохранять в IndexedDB
//     "catalogSliceV4",
//   ],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer);

// // Создаем стор
// const store = configureStore({
//   reducer: persistedReducer,
//   devTools: process.env.NODE_ENV !== "production", // Включение DevTools в режиме разработки
//   middleware: (getDefaultMiddleware) =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//         // serializableCheck: false,
//       },
//     }),
// });

// // Создаем persistor
// const persistor = persistStore(store);

// export { store, persistor };
