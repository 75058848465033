import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  getDocs,
  collection,
} from "firebase/firestore";

import { db } from "../../services/firebase/authFirebase";
export const updateFilePriorityThunkV4 = createAsyncThunk(
  "files/updateFilePriorityThunkV4",
  async (
    {
      rootCollectionPath,
      previewCollectionPath,
      previewGeneralCollectionPath,
      metadataDocumentPath,
      metadataGeneralDocumentPath,
      idRootDocument,
      idPreviewDocument = null,
      fieldName,
      filesList,
      currentFile,
      rootCurrentDocumentState,
      previewCurrentDocumenState,
      previewGeneralDocumenState,
      loadingStateName,
      errorStateName,
    },
    { rejectWithValue }
  ) => {
    try {
      // 1. Обновление rootCollectionPath
      const updatedFiles = filesList.map((file) =>
        file.id === currentFile.id
          ? { ...file, priority: true }
          : { ...file, priority: false }
      );

      const rootDocRef = doc(
        db,
        ...rootCollectionPath.split("."),
        idRootDocument
      );
      const updateData = { [fieldName]: updatedFiles };
      await updateDoc(rootDocRef, updateData);

      const rootDocSnap = await getDoc(rootDocRef);
      if (!rootDocSnap.exists()) {
        throw new Error(
          `Документ root с ID ${idRootDocument} не существует после обновления.`
        );
      }
      const rootDocumentData = { idRootDocument, ...rootDocSnap.data() };

      // 2. Поиск idPreviewDocument, если не передан
      if (!idPreviewDocument) {
        const querySnapshot = await getDocs(
          collection(db, ...previewCollectionPath.split("."))
        );
        for (const doc of querySnapshot.docs) {
          const data = doc.data().data || [];
          const foundItem = data.find(
            (item) => item.idRootDocument === idRootDocument
          );
          if (foundItem) {
            idPreviewDocument = doc.id;
            break;
          }
        }
        if (!idPreviewDocument) {
          throw new Error(
            `idPreviewDocument не найден для idRootDocument: ${idRootDocument}`
          );
        }
      }

      // 3. Обновление previewCollectionPath
      const previewDocRef = doc(
        db,
        ...previewCollectionPath.split("."),
        idPreviewDocument
      );
      const previewDocSnap = await getDoc(previewDocRef);
      if (!previewDocSnap.exists()) {
        throw new Error(
          `Документ preview с ID ${idPreviewDocument} не существует.`
        );
      }

      const previewData = previewDocSnap.data();
      const dataArray = previewData.data || [];
      const itemIndex = dataArray.findIndex(
        (item) => item.idRootDocument === idRootDocument
      );
      if (itemIndex === -1) {
        throw new Error(
          `Элемент с idRootDocument ${idRootDocument} не найден в документе preview.`
        );
      }

      const itemToUpdate = dataArray[itemIndex];
      const priorityFile = updatedFiles.find((file) => file.priority);
      itemToUpdate.fileUrl = priorityFile ? priorityFile.url : "";

      dataArray[itemIndex] = itemToUpdate;
      const newDocumentSize = new Blob([JSON.stringify({ data: dataArray })])
        .size;

      await updateDoc(previewDocRef, {
        data: dataArray,
        documentSize: newDocumentSize,
      });
      const previewDocumentData = { idPreviewDocument, ...itemToUpdate };

      // 4. Обновление previewGeneralCollectionPath
      if (previewGeneralCollectionPath) {
        let generalLastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию

        if (metadataGeneralDocumentPath) {
          const generalMetadataDocRef = doc(
            db,
            ...metadataGeneralDocumentPath.split(".")
          );
          const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

          if (generalMetadataDocSnap.exists()) {
            const generalMetadataData = generalMetadataDocSnap.data();
            generalLastUpdatedDocumentId =
              generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
              idPreviewDocument;
          }
        }

        const generalPreviewRef = doc(
          db,
          ...previewGeneralCollectionPath.split("."),
          generalLastUpdatedDocumentId
        );

        await setDoc(
          generalPreviewRef,
          { data: dataArray, documentSize: newDocumentSize },
          { merge: true }
        );
      }

      // 5. Обновление metadataDocumentPath
      if (metadataDocumentPath) {
        const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
        const metadataDocSnap = await getDoc(metadataDocRef);

        let lastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию
        if (metadataDocSnap.exists()) {
          const metadataData = metadataDocSnap.data();
          lastUpdatedDocumentId =
            metadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
            idPreviewDocument;
        }

        await setDoc(
          metadataDocRef,
          {
            lastUpdatedDocument: {
              lastUpdatedDocumentId,
              documentSize: newDocumentSize,
            },
          },
          { merge: true }
        );
      }

      // 6. Обновление metadataGeneralDocumentPath
      if (metadataGeneralDocumentPath) {
        const generalMetadataDocRef = doc(
          db,
          ...metadataGeneralDocumentPath.split(".")
        );
        const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

        let generalLastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию
        if (generalMetadataDocSnap.exists()) {
          const generalMetadataData = generalMetadataDocSnap.data();
          generalLastUpdatedDocumentId =
            generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
            idPreviewDocument;
        }

        await setDoc(
          generalMetadataDocRef,
          {
            lastUpdatedDocument: {
              lastUpdatedDocumentId: generalLastUpdatedDocumentId,
              documentSize: newDocumentSize,
            },
          },
          { merge: true }
        );
      }

      // 7. Возвращаем результат
      return {
        rootDocumentData,
        previewDocumentData,
        rootCollectionPath,
        previewCollectionPath,
        previewGeneralCollectionPath,
        rootCurrentDocumentState,
        previewCurrentDocumenState,
        previewGeneralDocumenState,
        loadingStateName,
        errorStateName,
      };
    } catch (error) {
      console.error("Ошибка при обновлении приоритета файлов:", error);
      return rejectWithValue(error.message);
    }
  }
);
