import React, { useMemo } from "react";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import UserData from "../../../../UIComponents/UserData/UserData";
import WidgetBikeItemDataV4 from "./WidgetBikeItemDataV4";
import WidgetWoucherListV4 from "./WidgetWoucherListV4";
import WidgetSelectReplacementBikeV4 from "./WidgetSelectReplacementBikeV4";

// Константы для стилей
const messageStyles = {
  borderRadius: "16px",
  padding: "16px",
  boxSizing: "border-box",
};

const leftMessageStyle = {
  ...messageStyles,
  borderRadius: "16px 0 16px 16px",
  backgroundColor: "var(--color-gray-100)",
};

const rightMessageStyle = {
  ...messageStyles,
  borderRadius: "0 16px 16px 16px",
  backgroundColor: "var(--color-gray-100)",
};

const canceledMessageStyle = {
  ...rightMessageStyle,
  backgroundColor: "var(--color-alarm)",
  color: "var(--color-white)",
};

const WidgetClientDialogV4 = ({
  // user
  userName,
  userRole,
  userAvatarUrls,
  // rentalPoint
  rentalPointName,
  rentalPointLogoFile,
  rentalPointRootData,
  //
  isMobile,
  rootOrderStatus,
  currentCart,
  order_selectedCompletedRootOrder,
  handleSelectedReplacementBike,
  // handleDeleteReplacementBike,
  // currentStateOrder,
  totalOrders,
}) => {
  console.log("WidgetClientDialogV4 userName", userAvatarUrls);
  // Функция для поиска статуса
  const findStatus = useMemo(() => {
    return (statusToFind) => {
      const foundItem = rootOrderStatus?.find(
        (item) => item.status === statusToFind
      );
      return foundItem
        ? {
            status: true,
            userId: foundItem?.user?.userId,
            timestamp: foundItem?.timestamp,
            userName: foundItem?.user?.userName,
            userAvatarUrls: foundItem?.user?.userAvatarUrls,
          }
        : { status: false, userId: null, timestamp: 0 };
    };
  }, [rootOrderStatus]);

  // Получаем статусы
  const newStatus = findStatus("new");
  const replacementStatus = findStatus("replacement");
  const confirmStatus = findStatus("confirm");
  const canceledStatus = findStatus("canceled");

  return (
    <ContentBox position="start">
      {/* Сообщение от пользователя */}
      <UserData
        type="right"
        date={newStatus.timestamp}
        userName={userName}
        userAvatarUrls={newStatus.userAvatarUrls}
        isMobile={isMobile}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <div style={rightMessageStyle}>
            <p className="p-light">Вы заказали</p>
          </div>

          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            {currentCart?.map((item, index) => (
              <div key={`cart-item-${index}`} style={{ width: "100%" }}>
                <WidgetBikeItemDataV4
                  item={item}
                  index={index}
                  groupItems={currentCart}
                  isMobile={isMobile}
                />
              </div>
            ))}
          </div>
        </div>
      </UserData>

      {/* Ожидание ответа от точки проката */}
      {newStatus.status && (
        <UserData
          type="left"
          date={newStatus.timestamp}
          userName={rentalPointName}
          userAvatarUrls={rentalPointLogoFile}
          isMobile={isMobile}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div style={leftMessageStyle}>
              <p className="p-light">Ожидаем ответа от точки проката</p>
            </div>
          </div>
        </UserData>
      )}

      {/* Предложение замены от точки проката */}
      {(replacementStatus.status || confirmStatus.status) && (
        <UserData
          type="left"
          date={replacementStatus.timestamp}
          userName={rentalPointName}
          userAvatarUrls={rentalPointLogoFile}
          isMobile={isMobile}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div style={leftMessageStyle}>
              <p className="p-light">Точка проката предлагает замену</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              {currentCart?.map((item, index) => (
                <div key={`replacement-${index}`} style={{ width: "100%" }}>
                  <WidgetSelectReplacementBikeV4
                    // onClick={handleSelectedReplacementBike}
                    item={item}
                    indexBikeCurrentCart={index}
                    confirm={confirmStatus.status}
                    // order_selectedCompletedRootOrder={
                    //   order_selectedCompletedRootOrder
                    // }
                    // onClickDelete={handleDeleteReplacementBike}
                    // isMobile={isMobile}
                    // currentStateOrder={currentStateOrder}
                    // currentCart={currentCart}
                  />
                </div>
              ))}
            </div>
          </div>
        </UserData>
      )}

      {/* Ваучер для точки проката */}
      {confirmStatus.status && (
        <UserData
          type="left"
          date={confirmStatus.timestamp}
          userName={rentalPointName}
          userAvatarUrls={rentalPointLogoFile}
          isMobile={isMobile}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div style={leftMessageStyle}>
              <p className="p-light">Ваучеры для вашего заказа</p>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <WidgetWoucherListV4
                isMobile={isMobile}
                userType={userRole}
                currentRentalPointOrders={currentCart}
                totalOrders={totalOrders}
                rentalPointRootData={rentalPointRootData}
              />
            </div>
          </div>
        </UserData>
      )}

      {/* Заказ отменен клиентом */}
      {canceledStatus.status && (
        <UserData
          type="right"
          date={canceledStatus.timestamp}
          userName={canceledStatus.userName}
          userAvatarUrls={canceledStatus.userAvatarUrls}
          isMobile={isMobile}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <div style={canceledMessageStyle}>
              <p className="p-light" style={{ color: "var(--color-white)" }}>
                Вы отменили заказ
              </p>
            </div>
          </div>
        </UserData>
      )}
    </ContentBox>
  );
};

export default React.memo(WidgetClientDialogV4);
