import { useState } from "react";
import { formatCurrencyUSD } from "../../../functions/currencyFormatter";
import { Link } from "react-router-dom";
import PhotosGallery from "../PhotosGallery/PhotosGallery";
import CountdownTimer from "../Countdown/CountdownTimer";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  return date.toLocaleString("en-US", { month: "short" });
};

const replaceSpacesWithUnderscore = (str) => {
  if (typeof str !== "string") return "";
  return str.replace(/\s/g, "_");
};
const BikeItemPreview = ({
  isRemove,
  removeTimestamp,
  month,
  photos,
  rate,
  title,
  onClick,
  itemKey,
  brand,
  category,
  dateInterval,
  mode,
  idRootDocument,
}) => {
  const [isHovered, setIsHovered] = useState(false); // Состояние для наведения

  const monthName = getMonthName(month);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  const startMonth = dateInterval ? new Date(dateInterval[0]).getMonth() : null;
  const endMonth = dateInterval ? new Date(dateInterval[1]).getMonth() : null;
  if (!rate) {
    return null;
  }
  const maxPrice = Math.max(...rate?.map((rateItem) => rateItem?.price));

  const calculateHeight = (price) => {
    const maxHeight = 50;
    return (price / maxPrice) * maxHeight;
  };

  const isDeleted =
    isRemove !== null && isRemove !== undefined && isRemove !== false;

  return (
    <div
      key={itemKey}
      className="bike-item-preview"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: "8px",
        height: "100%",
        cursor: "pointer",
        boxSizing: "border-box",
        position: "relative",
        paddingBottom: "16px",
      }}
    >
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
        }}
      >
        {mode === "view" ? (
          <div style={{ width: "100%", height: "100%" }}>
            <div onClick={onClick}>
              <PhotosGallery
                photos={photos}
                style={isDeleted && { opacity: "0.2" }}
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
            }}
            onClick={onClick}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <PhotosGallery photos={photos} />
              <span
                className="material-symbols-outlined"
                style={{
                  position: "absolute",
                  right: "20px",
                  bottom: "20px",
                  color: "var(--color-white)",
                }}
              >
                link
              </span>

              {isDeleted && (
                <div
                  style={{
                    position: "absolute",
                    top: "4px",
                    bottom: "4px",
                    left: "4px",
                    right: "4px",
                    width: "calc(100% - 8px)",
                    borderRadius: "14px",
                    color: "var(--color-white)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(0, 0, 0, 0.3)",
                    backdropFilter: "blur(10px)",
                    opacity: isHovered ? 0 : 1, // Скрываем при наведении
                    transition: "opacity 0.3s ease", // Плавное исчезновение
                  }}
                  className="p-light"
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        bottom: "16px",
                        left: "16px",
                        right: "16px",
                        color: "var(--color-white)",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "8px",
                        // fontSize: "1rem",
                      }}
                    >
                      <CountdownTimer deleteState={removeTimestamp} />
                      <span className="material-symbols-outlined">link</span>
                    </div>
                    <span
                      className="material-symbols-outlined"
                      style={{
                        position: "absolute",
                        top: "16px",
                        right: "16px",
                        color: "var(--color-white)",
                        fontSize: "3rem",
                        // transform: "translate(-50%, -50%)",
                      }}
                    >
                      auto_delete
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "start",
            gap: "8px",
          }}
        >
          <div
            style={{
              padding: "4px 8px",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
            }}
          >
            <p className="p-light">{brand ? brand : "No brand"}</p>
          </div>
          <div
            style={{
              padding: "4px 8px",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
            }}
          >
            <p className="p-light">{category ? category : "No category"}</p>
          </div>
        </div>
        <h6>
          {title} {isDeleted && "(Deleted)"}
        </h6>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {rate?.map((rateItem, index) => {
          const isMonthInRange =
            startMonth !== null &&
            endMonth !== null &&
            index >= startMonth &&
            index <= endMonth;
          return (
            <div
              key={index}
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "2px",
              }}
            >
              {isMonthInRange && (
                <p className="p-light" style={{ fontSize: "0.6rem" }}>
                  {getMonthName(index)}
                </p>
              )}
              <div
                title={`${getMonthName(index)} — ${formatCurrencyUSD(
                  rateItem?.price
                )}`}
                style={{
                  height: `${calculateHeight(rateItem.price)}px`,
                  minHeight: "8px",
                  width: "100%",
                  borderRadius: "4px",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "flex-end",
                  backgroundColor: isMonthInRange
                    ? "var(--color-success)"
                    : "var(--color-gray-100)",
                  ...(isMonthInRange
                    ? { minHeight: "22px", padding: "2px 4px" }
                    : {}),
                  opacity: isDeleted ? 0.2 : 1,
                }}
              >
                {isMonthInRange && (
                  <p
                    className="p-light"
                    style={{
                      width: "100%",
                      color: "var(--color-white)",
                      fontSize: "0.6rem",
                      textAlign: "center",
                      padding: "2px",
                    }}
                  >
                    {formatCurrencyUSD(rateItem?.price)}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default BikeItemPreview;
