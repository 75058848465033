import React from "react";
import "./addData.css";

import AnimatedDiv from "../Animation/AnimatedDiv";
import SkeletonAddData from "./SkeletonAddData";
import IconContainer from "../icons/IconContainer";
const AddData = ({
  isLoading,
  icon,
  title,
  description,
  onClick,
  children,
}) => {
  return isLoading ? (
    <>
      <SkeletonAddData />
    </>
  ) : (
    <>
      <div className="addData">
        <div className="addData__content-box">
          <IconContainer
            onClick={isLoading ? null : onClick}
            icon={icon}
            iconStyle={{
              color: "var(--color-black)",
            }}
          />
          <div
            style={{
              width: "90%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: "4px",
              // backgroundColor: "violet",
            }}
          >
            <h6 style={{ width: "70%" }}>{title}</h6>
            <div className="p-light">{description}</div>
          </div>
        </div>

        {/* <div
        style={{
          visibility: "hidden",
        }}
      >
        <span className="material-symbols-outlined">chevron_right</span>
      </div> */}
        {children}
      </div>
    </>
  );
};

export default AddData;
