import React, { useEffect, useState } from "react";
import "../profile.css";
import "./rentalPoints.css";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import SubtitleBlockSection from "../../../../UIComponents/SubtitleBlockSection/SubtitleBlockSection";
import WindowEditItemTimetable from "./WindowEditItemTimetable";
import { useSelector } from "react-redux";
import { getWeekDayName } from "../../../../../functions/getWeekDayName";
import WindowEditTimetableModeration from "../Moderation/WindowEditTimetableModeration";
import PreviewItemCard from "../../../../UIComponents/Preview/PreviewItemCard";

const TimeWeekDayItem = ({
  dayWeek,
  openingHours,
  description,
  isHoliday,
  onClick,
}) => {
  const weekdayName = getWeekDayName(dayWeek);

  return (
    <div
      className="rentalPointTimetable__week-day-content-box"
      onClick={onClick}
    >
      <div className="rentalPointTimetable__week-day">
        <p className="p-light">
          #{dayWeek} {weekdayName}
        </p>
        {isHoliday ? <p>Holiday</p> : <p>{openingHours}</p>}
        {!isHoliday && description && (
          <p
            className="p-light"
            style={{
              width: "80%",
            }}
          >
            {description}
          </p>
        )}
      </div>
      <div className="rentalPointTimetable__icon">
        <span className="material-symbols-outlined">edit</span>
      </div>
    </div>
  );
};

const WidgetRentalPointTimetable = ({ mode, timetable, isChangeState }) => {
  // const [isOpen, setIsOpen] = useState(false);
  const [indexDay, setIndexDay] = useState(0);

  const [isOpenRentalPoint, setIsOpenRentalPoint] = useState(false);
  const [isOpenModeration, setIsOpenModeration] = useState(false);

  const handleClose = () => {
    setIsOpenRentalPoint(false);
    setIsOpenModeration(false);
  };

  const handleToggle = (index) => {
    if (mode === "new" || mode === "edit") {
      setIsOpenRentalPoint(true);
    } else if (mode === "moderation" || mode === "moderatorAdded") {
      setIsOpenModeration(true);
    }
    setIndexDay(index);
  };

  return (
    <>
      {/* POPUP WINDOW */}

      <PopupDirrectory
        isOpen={isOpenModeration}
        onClose={handleClose}
        title="Edit timetable Moderation"
      >
        <WindowEditTimetableModeration
          isChangeState={isChangeState}
          mode={mode}
          indexDay={indexDay}
          onClose={handleClose}
        />
      </PopupDirrectory>
      {/* POPUP WINDOW */}

      <div className="profile-widget__content-box">
        <SubtitleBlockSection
          title="Timetable"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "2px",
          }}
        >
          {timetable?.map((timetableItem, index) => (
            <React.Fragment key={`timetable-day-${index}`}>
              <PreviewItemCard
                index={index}
                length={timetable?.length}
                title={
                  timetableItem?.isHoliday ? (
                    <span>{getWeekDayName(index + 1)}&nbsp;—&nbsp;Holiday</span>
                  ) : (
                    <span>
                      {getWeekDayName(index + 1)}&nbsp;—&nbsp;from:&nbsp;
                      <strong>
                        {timetableItem?.openHours}:{timetableItem?.openMinutes}
                      </strong>
                      &nbsp;to:&nbsp;
                      <strong>
                        {timetableItem?.closeHours}:
                        {timetableItem?.closeMinutes}
                      </strong>
                    </span>
                  )
                }
                // LEFT ICIN
                iconLeft={
                  timetableItem?.isHoliday ? (
                    <span className="material-symbols-outlined">close</span>
                  ) : (
                    <span className="material-symbols-outlined">schedule</span>
                  )
                }
                iconLeftStyle={{
                  color: timetableItem?.isHoliday
                    ? "var(--color-alarm)"
                    : "var(--color-black)",
                }}
                containerIconLeftStyle={{
                  backgroundColor: timetableItem?.isHoliday
                    ? "var(--color-alarm-alpha-30)"
                    : "var(--color-gray-900-alpha-30)",
                }}
                // RIGHT ICON
                iconRight={
                  <span className="material-symbols-outlined">edit</span>
                }
                iconRightStyle={{
                  color: timetableItem?.isHoliday
                    ? "var(--color-alarm)"
                    : "var(--color-black)",
                }}
                containerIconRightStyle={{
                  backgroundColor: timetableItem?.isHoliday
                    ? "var(--color-alarm-alpha-30)"
                    : "var(--color-gray-900-alpha-30)",
                }}
                // CONTAINER CARD
                containerCardStyle={{
                  backgroundColor: timetableItem?.isHoliday
                    ? "var(--color-alarm-alpha-30)"
                    : "var(--color-gray-900-alpha-30)",
                }}
                onClick={() => handleToggle(index)}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default WidgetRentalPointTimetable;
