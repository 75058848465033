import { createAsyncThunk } from "@reduxjs/toolkit";
import handleThunkError from "../../functions/handleThunkError";
import { removeFilesV4 } from "../../services/firebase/crudFirestore";
import { doc, getDoc, setDoc, getDocs, collection } from "firebase/firestore";
import { db } from "../../services/firebase/authFirebase";

/**
 * Асинхронный thunk для удаления файлов и обновления связанных документов в Firestore.
 *
 * @async
 * @function deleteFilesThunkV4
 * @param {Object} params - Параметры для операции удаления файлов
 * @param {string} params.fieldName - Имя поля, содержащего файлы (например, "photoFiles")
 * @param {Array<string>} params.fileIds - Массив идентификаторов файлов для удаления
 * @param {string} params.rootCollectionPath - Путь к корневой коллекции документов (например, "bikes")
 * @param {string} params.previewCollectionPath - Путь к коллекции предварительного просмотра (точками разделены вложенные пути)
 * @param {string} [params.previewGeneralCollectionPath] - Путь к общей коллекции предварительного просмотра
 * @param {string} [params.idPreviewDocument] - ID документа предварительного просмотра (если известен)
 * @param {string} params.idRootDocument - ID корневого документа
 * @param {string} [params.metadataDocumentPath] - Путь к документу метаданных
 * @param {string} [params.metadataGeneralDocumentPath] - Путь к документу общих метаданных
 * @param {string} params.rootCurrentDocumentState - Имя состояния для корневого документа в Redux
 * @param {string} params.previewCurrentDocumenState - Имя состояния для документа предварительного просмотра в Redux
 * @param {string} params.previewGeneralDocumenState - Имя состояния для общего документа предварительного просмотра в Redux
 * @param {string} params.loadingStateName - Имя состояния загрузки в Redux
 * @param {string} params.errorStateName - Имя состояния ошибки в Redux
 * @returns {Promise<Object>} Результат операции удаления файлов
 * @throws {Error} Возвращает обработанное сообщение об ошибке через rejectWithValue
 */
export const deleteFilesThunkV4 = createAsyncThunk(
  "files/deleteFilesThunkV4",
  async (
    {
      fieldName,
      fileIds,
      rootCollectionPath,
      previewCollectionPath,
      previewGeneralCollectionPath,
      idPreviewDocument,
      idRootDocument,
      metadataDocumentPath,
      metadataGeneralDocumentPath,
      rootCurrentDocumentState,
      previewCurrentDocumenState,
      previewGeneralDocumenState,
      loadingStateName,
      errorStateName,
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      /**
       * 1. Поиск idPreviewDocument, если не передан.
       * Ищет документ в коллекции предварительного просмотра,
       * который содержит объект с указанным idRootDocument.
       */
      if (!idPreviewDocument) {
        const previewCollectionRef = collection(
          db,
          ...previewCollectionPath.split(".")
        );
        const snapshot = await getDocs(previewCollectionRef);
        snapshot.forEach((doc) => {
          const data = doc.data().data || [];
          if (data.find((item) => item.idRootDocument === idRootDocument)) {
            idPreviewDocument = doc.id;
          }
        });
        if (!idPreviewDocument) {
          throw new Error(
            `Документ с idRootDocument ${idRootDocument} не найден в ${previewCollectionPath}.`
          );
        }
      }

      /**
       * 2. Удаление файлов через сервисную функцию removeFilesV4.
       * Удаляет файлы из хранилища и обновляет документы в Firestore.
       */
      const finalFiles = await removeFilesV4(
        fieldName,
        fileIds,
        rootCollectionPath,
        previewCollectionPath,
        idPreviewDocument,
        idRootDocument
      );

      const {
        rootDocumentData,
        previewDocumentData: updatedPreviewDocumentData,
      } = finalFiles;

      /**
       * 3. Обновление документа в коллекции предварительного просмотра.
       * Находит и обновляет объект с нужным idRootDocument в массиве данных.
       */
      const previewDocRef = doc(
        db,
        ...previewCollectionPath.split("."),
        idPreviewDocument
      );
      const previewDocSnap = await getDoc(previewDocRef);
      if (!previewDocSnap.exists())
        throw new Error(
          `Документ с ID ${idPreviewDocument} не найден в ${previewCollectionPath}.`
        );

      const previewDocumentDataFromFirestore = previewDocSnap.data();
      const newPreviewDataArray = [...previewDocumentDataFromFirestore.data];
      const updatedItemIndex = newPreviewDataArray.findIndex(
        (item) => item.idRootDocument === idRootDocument
      );

      if (updatedItemIndex !== -1) {
        newPreviewDataArray[updatedItemIndex] = updatedPreviewDocumentData;
      }

      // Вычисляем размер документа для метаданных
      const newDocumentSize = new Blob([
        JSON.stringify({ data: newPreviewDataArray }),
      ]).size;

      await setDoc(
        previewDocRef,
        { data: newPreviewDataArray, documentSize: newDocumentSize },
        { merge: true }
      );

      /**
       * 4. Обновление общего документа предварительного просмотра (если указан путь).
       * Используется для синхронизации изменений между разными представлениями данных.
       */
      if (previewGeneralCollectionPath) {
        let generalLastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию

        if (metadataGeneralDocumentPath) {
          const generalMetadataDocRef = doc(
            db,
            ...metadataGeneralDocumentPath.split(".")
          );
          const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

          if (generalMetadataDocSnap.exists()) {
            const generalMetadataData = generalMetadataDocSnap.data();
            generalLastUpdatedDocumentId =
              generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
              idPreviewDocument;
          }
        }

        const generalPreviewRef = doc(
          db,
          ...previewGeneralCollectionPath.split("."),
          generalLastUpdatedDocumentId
        );

        await setDoc(
          generalPreviewRef,
          { data: newPreviewDataArray, documentSize: newDocumentSize },
          { merge: true }
        );
      }

      /**
       * 5. Обновление метаданных в коллекции предварительного просмотра.
       * Сохраняет информацию о последнем обновленном документе и его размере.
       */
      if (metadataDocumentPath) {
        const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
        const metadataDocSnap = await getDoc(metadataDocRef);

        let lastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию
        if (metadataDocSnap.exists()) {
          const metadataData = metadataDocSnap.data();
          lastUpdatedDocumentId =
            metadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
            idPreviewDocument;
        }

        await setDoc(
          metadataDocRef,
          {
            lastUpdatedDocument: {
              lastUpdatedDocumentId, // Сохраняем правильное значение
              documentSize: newDocumentSize,
            },
          },
          { merge: true }
        );
      }

      /**
       * 6. Обновление общих метаданных (если путь указан).
       * Аналогично п.5, но для общих метаданных.
       */
      if (metadataGeneralDocumentPath) {
        const generalMetadataDocRef = doc(
          db,
          ...metadataGeneralDocumentPath.split(".")
        );
        const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

        let generalLastUpdatedDocumentId = idPreviewDocument; // Значение по умолчанию
        if (generalMetadataDocSnap.exists()) {
          const generalMetadataData = generalMetadataDocSnap.data();
          generalLastUpdatedDocumentId =
            generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId ||
            idPreviewDocument;
        }

        await setDoc(
          generalMetadataDocRef,
          {
            lastUpdatedDocument: {
              lastUpdatedDocumentId: generalLastUpdatedDocumentId, // Сохраняем уникальное значение
              documentSize: newDocumentSize,
            },
          },
          { merge: true }
        );
      }

      /**
       * 7. Возвращаем результат операции.
       * Эти данные будут доступны в обработчиках fulfilled.
       */
      return {
        fieldName,
        rootDocumentData,
        previewDocumentData: updatedPreviewDocumentData,
        rootCollectionPath,
        previewCollectionPath,
        previewGeneralCollectionPath,
        idPreviewDocument,
        idRootDocument,
        rootCurrentDocumentState,
        previewCurrentDocumenState,
        previewGeneralDocumenState,
        loadingStateName,
        errorStateName,
      };
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);
