//index.js
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./reducersToolkit/store/configureStore";
import App from "./App";

import "react-toastify/dist/ReactToastify.css";
import "react-phone-number-input/style.css";
import "./styles/main2.css";

const root = createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

// import React from 'react';
// import { createRoot } from 'react-dom/client';
// import { ToastContainer } from 'react-toastify';
// import App from './App';
// import { Provider } from "react-redux"; // Импортируйте Provider

// import store from './store/configureStore';

// import 'react-toastify/dist/ReactToastify.css';
// import 'react-phone-number-input/style.css';
// import './styles/main2.css';

// createRoot(document.getElementById('root')).render(

//   <div>
//     <Provider store={store}>
//       <App />
//       <ToastContainer />
//     </Provider>
//   </div>

// );
