import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import WidgetOrders from "./OrdersV3/WidgetOrders.js"; // Компонент заказов
import WidgetModeration from "./Moderation/WidgetModeration.js"; // Компонент модерации
import ContentBoxV4 from "../../../UIComponents/ContentBox/ContentBoxV4.js";
import WindowCategoriesListV4 from "./DirectoryV3/CategoriesV4/WindowCategoriesListV4.js";
import WindowBrandsListV4 from "./DirectoryV3/BrandsV4/WindowBrandsListV4.js";
import WindowCountriesListV4 from "./DirectoryV3/CountriesV4/WindowCountriesListV4.js";
import PageBox from "../../../UIComponents/ContentBox/PageBox.js";

// Основной компонент страницы профиля версии 3
const PageProfileV3 = ({ breadcrumbPath, title }) => {
  // Получение данных из Redux-состояния через useSelector
  const { loading, tempRootData, tempPreviewData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // Определяем хлебные крошки по умолчанию, если не переданы из пропсов
  const defaultBreadcrumbPath = useMemo(() => {
    return [{ item: "Home", link: "/" }, { item: "Profile" }];
  }, []);

  // Определяем заголовок по умолчанию, если не передан из пропсов
  const defaultTitle = "User Profile";

  // Данные аутентифицированного пользователя из root-коллекции
  const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
  // Уникальный идентификатор пользователя
  const [userId, setUserId] = useState(null);
  // Роль пользователя
  const [userRole, setUserRole] = useState(null);
  // Уведомления пользователей из preview-коллекции
  const [
    general_usersPreviewNotifications,
    setGeneral_usersPreviewNotifications,
  ] = useState(null);
  // Уведомления заказов из preview-коллекции
  const [
    general_ordersPreviewNotifications,
    setGeneral_ordersPreviewNotifications,
  ] = useState(null);

  // --- Обновление данных пользователя из Redux ---
  // Обновляем локальные состояния при изменении данных в store
  useEffect(() => {
    setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
    setUserId(tempRootData?.user_auth_rootCurrentData?.id || null);
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
  }, [tempRootData.user_auth_rootCurrentData]);

  // --- Обновление уведомлений из Redux ---
  useEffect(() => {
    setGeneral_usersPreviewNotifications(
      tempPreviewData?.general_usersPreviewNotifications || null
    );
    setGeneral_ordersPreviewNotifications(
      tempPreviewData?.general_ordersPreviewNotifications || null
    );
  }, [
    tempPreviewData.general_usersPreviewNotifications,
    tempPreviewData?.general_ordersPreviewNotifications,
  ]);

  // --- Рендеринг компонента ---
  return (
    // Основной контейнер страницы
    <PageBox
      path={breadcrumbPath || defaultBreadcrumbPath}
      title={title || defaultTitle}
    >
      {/* Внутренний контейнер с контентом */}
      <div
        style={{
          width: "100%",
          maxWidth: "var(--width-mobile-content)",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "16px",
          minHeight: "500px", // Задаём минимальную высоту, чтобы ячейки могли растянуться
          alignItems: "stretch", // Растягиваем ячейки по высоте
          justifyItems: "stretch", // Растягиваем ячейки по ширине
        }}
      >
        {/* Условное отображение контента для роли "admin" */}
        {userRole === "admin" && (
          <>
            {/* Профиль пользователя */}
            <ContentBoxV4
              label="User profile"
              style={{
                aspectRatio: "1/1",
              }}
              param={user_auth_rootCurrentData?.role || "No role"}
              icon={
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "3em" }}
                >
                  account_circle
                </span>
              }
              execute="/userProfile"
            >
              User name
            </ContentBoxV4>

            {/* Модерация пользователей */}
            <ContentBoxV4
              icon={
                <span
                  className="material-symbols-outlined"
                  style={{ fontSize: "3em" }}
                >
                  shield_person
                </span>
              }
              label={<span>Moderation</span>}
              param={<span>100</span>}
              style={{}}
              execute={
                <WidgetModeration
                  usersModeration={general_usersPreviewNotifications}
                />
              }
            >
              <div
                style={{
                  // color: "var(--color-white)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <h1
                    style={{
                      // color: "var(--color-white)",
                      fontWeight: "100",
                    }}
                  >
                    300
                  </h1>{" "}
                  <p>new</p>
                </div>
              </div>
            </ContentBoxV4>

            {/* Все заказы */}
            <ContentBoxV4
              label={<span>Orders</span>}
              param={
                <span>{general_ordersPreviewNotifications?.length || 0}</span>
              }
              execute={
                <WidgetOrders
                  currentOrders={general_ordersPreviewNotifications}
                />
              }
              // style={{}}
              icon={
                <span
                  className="material-symbols-outlined"
                  style={{
                    // color: "var(--color-white)",
                    fontSize: "3em",
                  }}
                >
                  qr_code_2
                </span>
              }
            >
              <div
                style={{
                  // color: "var(--color-white)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <h1
                    style={{
                      // color: "var(--color-white)",
                      fontWeight: "100",
                    }}
                  >
                    300
                  </h1>{" "}
                  <p>new</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <h1
                    style={{
                      // color: "var(--color-white)",
                      fontWeight: "100",
                    }}
                  >
                    2
                  </h1>{" "}
                  <p>in progress</p>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <h1
                    style={{
                      //  color: "var(--color-white)",
                      fontWeight: "100",
                    }}
                  >
                    80
                  </h1>{" "}
                  <p>closed</p>
                </div>
              </div>
            </ContentBoxV4>

            {/* Фильтры */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                height: "100%",
              }}
            >
              {/* Категории */}
              <ContentBoxV4
                label="Категории"
                // param={100}
                execute={<WindowCategoriesListV4 />}
                icon={
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "3rem" }}
                  >
                    category
                  </span>
                }
                // style={{}}
              >
                {/* <div>100</div> */}
              </ContentBoxV4>

              {/* Бренды */}
              <ContentBoxV4
                label="Бренды"
                // param={100}
                execute={<WindowBrandsListV4 />}
                icon={
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "3rem" }}
                  >
                    brand_family
                  </span>
                }
                // style={{}}
              >
                {/* <div>100</div> */}
              </ContentBoxV4>

              {/* Локации */}
              <ContentBoxV4
                label="Локации"
                // param={100}
                execute={<WindowCountriesListV4 />}
                icon={
                  <span
                    className="material-symbols-outlined"
                    style={{ fontSize: "3rem" }}
                  >
                    location_on
                  </span>
                }
                // style={{}}
              >
                {/* <div>100</div> */}
              </ContentBoxV4>
            </div>
          </>
        )}
        {/* Заглушка для роли "rental" */}
        {userRole === "rental" && <>rental</>}
        {/* Заглушка для роли "client" */}
        {userRole === "client" && <>client</>}
      </div>
    </PageBox>
  );
};

export default PageProfileV3;
