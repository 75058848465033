import React, { useEffect, useState, useMemo } from "react";
import Textarea from "../../../../UIComponents/Textarea/Textarea";
import SegmentedControls from "../../../../UIComponents/SegmentedControls/SegmentedControls";
import { useDispatch, useSelector } from "react-redux";
import { createDataThunkV4 } from "../../../../../reducersToolkit/thunk/createDataThunkV4";
import Button from "../../../../UIComponents/Buttons/Button";
import InputText from "../../../../UIComponents/Inputs/InputText";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/updateDataThunkV4";
import { useLocation } from "react-router-dom";

const WidgetSEO = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // Получаем данные напрямую из Redux - инициализация в APP.JS
  const seo_previewCurrentData = useSelector(
    (state) => state.catalogSliceV4.tempPreviewData?.seo_previewCurrentData
  );

  const [seoSwitch, setSeoSwitch] = useState("view");

  // SEO данные
  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [keywords, setKeywords] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [resolvedTitle, setResolvedTitle] = useState("");
  const [resolvedDescription, setResolvedDescription] = useState("");
  const [resolvedKeywords, setResolvedKeywords] = useState("");
  const [idRootDocument, setIdRootDocument] = useState(null);
  const [isSEO, setIsSEO] = useState(false);
  const [currentSlug, setCurrentSlug] = useState(null);

  // Состояния для отслеживания изменений в каждом поле
  const [titleChanged, setTitleChanged] = useState(false);
  const [descriptionChanged, setDescriptionChanged] = useState(false);
  const [keywordsChanged, setKeywordsChanged] = useState(false);

  // Обновление slug из URL
  useEffect(() => {
    const pathname = location.pathname;
    setCurrentSlug(pathname);
  }, [location.pathname]);

  // SEO обработка
  useEffect(() => {
    if (!seo_previewCurrentData || !currentSlug) return;

    const filteredSEO = seo_previewCurrentData.filter(
      (item) => item?.slug === currentSlug
    );

    if (filteredSEO?.length > 0) {
      const seoData = filteredSEO[0];
      setResolvedTitle(seoData.title || "");
      setResolvedDescription(seoData.description || "");
      setResolvedKeywords(seoData.keywords || "");
      setIdRootDocument(seoData.idRootDocument);
      setIsSEO(
        !!seoData.title ||
          !!seoData.description ||
          !!seoData.keywords ||
          !!seoData.idRootDocument
      );
    } else {
      setResolvedTitle("");
      setResolvedDescription("");
      setResolvedKeywords("");
      setIdRootDocument(null);
      setIsSEO(false);
    }
  }, [seo_previewCurrentData, currentSlug]);

  const createSEO = async () => {
    try {
      const newRootSlugData = {
        title: title,
        description: description,
        keywords: keywords,
        slug: currentSlug,
      };

      const newPreviewSlugData = {
        title: title,
        description: description,
        keywords: keywords,
        slug: currentSlug,
      };

      dispatch(
        createDataThunkV4({
          // Данные которые будут использоваться для создания коллекции в firestore
          rootData: newRootSlugData,
          // idRootDocument:'',
          previewData: newPreviewSlugData,

          // Определяем пути для коллекций и метаданных с точками в качестве разделителей
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "seo",
          previewCollectionPath: `manager_seo`,
          // previewGeneralCollectionPath: "general_seo",

          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: `manager_seo.metadata`,
          // metadataGeneralDocumentPath: "general_seo.metadata",

          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "seo_rootCurrentData",

          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState: "seo_previewCurrentData",

          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_seo_previewCurrentData",
          errorStateName: "error_seo_previewCurrentData",
        })
      )
        .unwrap()
        .then((response) => {
          console.log("seo успешно создан:", response);
          resetFieldChanges();
        })
        .catch((error) => {
          console.error("Ошибка при создании seo:", error);
        });
    } catch (error) {
      console.error("Ошибка при создании или обновлении bikes:", error);
    }
  };

  const editSEO = async () => {
    setIsActive(false);
    try {
      const newRootSlugData = {
        title: title,
        description: description,
        keywords: keywords,
        slug: currentSlug,
      };

      const newPreviewSlugData = {
        title: title,
        description: description,
        keywords: keywords,
        slug: currentSlug,
      };

      dispatch(
        updateDataThunkV4({
          // Указываем id root Элемента
          idRootDocument: idRootDocument,

          // Данные которые будут использоваться для создания коллекции в firestore
          rootData: newRootSlugData,
          // idRootDocument:'',
          previewData: newPreviewSlugData,

          // Определяем пути для коллекций и метаданных с точками в качестве разделителей
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "seo",
          previewCollectionPath: `manager_seo`,
          // previewGeneralCollectionPath: "general_seo",

          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: `manager_seo.metadata`,
          // metadataGeneralDocumentPath: "general_seo.metadata",

          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "seo_rootCurrentData",

          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState: "seo_previewCurrentData",

          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_seo_previewCurrentData",
          errorStateName: "error_seo_previewCurrentData",
        })
      )
        .unwrap()
        .then((response) => {
          console.log("seo успешно обновлен:", response);
          resetFieldChanges();
        })
        .catch((error) => {
          console.error("Ошибка при обновлении seo:", error);
        });
    } catch (error) {
      console.error("Ошибка при создании или обновлении bikes:", error);
    }
  };

  // Функция для сброса состояния изменений полей
  const resetFieldChanges = () => {
    setTitleChanged(false);
    setDescriptionChanged(false);
    setKeywordsChanged(false);
  };

  useEffect(() => {
    setDescription(resolvedDescription || "");
    setTitle(resolvedTitle || "");
    setKeywords(resolvedKeywords || "");
    resetFieldChanges();
  }, [resolvedDescription, resolvedTitle, resolvedKeywords]);

  // Обработчики изменений для каждого поля
  const handleTitleChange = (event) => {
    const newValue = event.target.value;
    setTitle(newValue);
    setTitleChanged(newValue !== resolvedTitle);
  };

  const handleDescriptionChange = (event) => {
    const newValue = event.target.value;
    setDescription(newValue);
    setDescriptionChanged(newValue !== resolvedDescription);
  };

  const handleKeywordsChange = (event) => {
    const newValue = event.target.value;
    setKeywords(newValue);
    setKeywordsChanged(newValue !== resolvedKeywords);
  };

  // Проверка изменений для активации кнопки
  useEffect(() => {
    setIsActive(titleChanged || descriptionChanged || keywordsChanged);
  }, [titleChanged, descriptionChanged, keywordsChanged]);

  return (
    <>
      <SegmentedControls
        options={["view", "SEO"]}
        onSelect={seoSwitch}
        selected={setSeoSwitch}
      />
      {seoSwitch === "SEO" && (
        <>
          <InputText
            type="text"
            label="Title"
            description="Please enter the title for SEO optimization."
            value={title || ""}
            onChange={handleTitleChange}
            isChangeState={setTitleChanged}
            initialValue={resolvedTitle}
          />
          <Textarea
            type="text"
            label="Description"
            description="Please provide the description for SEO optimization."
            value={description || ""}
            onChange={handleDescriptionChange}
            maxLength={2000}
          />
          <Textarea
            type="text"
            label="Keywords"
            description="Please enter keywords for SEO optimization, separated by commas."
            value={keywords || ""}
            onChange={handleKeywordsChange}
            maxLength={2000}
          />

          {isSEO ? (
            <Button
              type="small"
              active={isActive}
              label="Edit"
              onClick={() => editSEO()}
            />
          ) : (
            <Button
              type="small"
              active={isActive}
              label="Save"
              onClick={() => createSEO()}
            />
          )}
        </>
      )}
    </>
  );
};
export default WidgetSEO;
