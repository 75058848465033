import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, signOut } from "firebase/auth";
import handleThunkError from "../../functions/handleThunkError";
import { setCurrentRootData } from "../slice/catalogSliceV4";

// Создаем асинхронный экшн для выхода из учетной записи пользователя
export const logoutThunkV4 = createAsyncThunk(
  "reducersToolkit/logoutThunkV4",
  async (_, { rejectWithValue, dispatch }) => {
    const auth = getAuth();

    try {
      // Проверяем, есть ли текущий авторизованный пользователь
      if (!auth.currentUser) {
        // Если текущий пользователь уже вышел, возвращаем сообщение об этом
        console.warn("No user is currently signed in.");
        return rejectWithValue({
          errorMessage: "No user is currently signed in.",
        });
      }

      // Выполняем операцию выхода из учетной записи
      await signOut(auth);

      // Сбрасываем состояние текущего авторизованного пользователя
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          data: null,
        })
      );

      // Возвращаем успешное выполнение
      return true;
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);
