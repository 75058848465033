import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

// Компоненты UI
import PageBox from "../../../../UIComponents/ContentBox/PageBox";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import Button from "../../../../UIComponents/Buttons/Button";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";

// Виджеты
import WidgetLocation from "./WidgetLocation";
import WidgetRentalPointTimetable from "../RentalPointsV3/WidgetRentalPointTimetable";
import WidgetRentalPointContact from "../RentalPointsV3/WidgetRentalPointContact";
import WidgetRentalPointBasicInformation from "../RentalPointsV3/WidgetRentalPointBasicInformation";
import WidgetRentalPointModeration from "./WidgetRentalPointModeration";
import WidgetListFileV4 from "../../../../UIComponents/UploadFiles/WidgetListFileV4";
import WidgetUploadFilesV4 from "../../../../UIComponents/UploadFiles/WindgetUploadFilesV4";
import WidgetAditionalServicesV4 from "../RentalPointsV3/WidgetAditionalServicesV4";
import WidgetBikeListModerationV4 from "./WidgetBikeListModerationV4";
import WindowBikeDetailModerationV4 from "./WindowBikeDetailModerationV4";

// Компоненты скелетона
import SkeletonTitleDescription from "./SkeletonBikeModeration";
import SkeletonRentalPointLocation from "./SkeletonRentalPointLocation";
import SkeletonRentalPointBikeList from "./SkeletonRentalPointBikeList";
import SkeletonRentalPointAditionalServices from "./SkeletonRentalPointAditionalServices";
import SkeletonRentalPointTimeTable from "./SkeletonRentalPointTimeTable";
import SkeletonRentalPointContacts from "./SkeletonRentalPointContacts";

// Actions, thunks и селекторы Redux
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/fetchRootDataThunkV4";
import { createDataThunkV4 } from "../../../../../reducersToolkit/thunk/createDataThunkV4";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/updateDataThunkV4";
import { deleteDataThunkV4 } from "../../../../../reducersToolkit/thunk/deleteDataThunkV4";
import {
  subscribePreviewDocumentsThunkV4,
  unsubscribePreviewDocumentsThunkV4,
} from "../../../../../reducersToolkit/thunk/subscribePreviewDocumentsThunkV4";
import {
  subscribeRootDocumentsThunkV5,
  unsubscribeRootDocumentsThunkV5,
} from "../../../../../reducersToolkit/thunk/subscribeRootDocumentsThunkV5";
import WidgetAllertDelete from "./WidgetAllertDelete";

/**
 * Компонент управления деталями точки проката с двумя режимами работы
 *
 * @param {string} mode - Режим работы компонента:
 *   - "moderation": Модерация существующей точки проката
 *   - "moderatorAdded": Создание новой точки проката модератором
 * @param {Array} breadcrumbPath - Путь навигации для хлебных крошек
 * @param {string} title - Заголовок страницы
 */
const PageRentalPointDetailModerationV4 = ({ mode, breadcrumbPath, title }) => {
  const dispatch = useDispatch();

  const { userId, rentalPointId } = useParams();
  const navigate = useNavigate();

  // Определение констант для режимов
  const isModerationMode = mode === "moderation";
  const isModeratorAddedMode = mode === "moderatorAdded";

  // 1. ПОЛУЧЕНИЕ ДАННЫХ ИЗ REDUX SLICE
  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  // 2. ФОРМИРОВАНИЕ СТЕКА СОСТОЯНИЙ (STATE)

  // Модальные окна
  const [windowNewBikeDetailModeration, setWindowNewBikeDetailModeration] =
    useState(false);

  // Данные точки проката
  const [basicInformation, setBasicInformation] = useState({});
  const [location, setLocation] = useState({});
  const [country, setCountry] = useState(null);
  const [city, setCity] = useState(null);
  const [contacts, setContacts] = useState({});
  const [contactsEmail, setContactsEmail] = useState([]);
  const [contactsPhone, setContactsPhone] = useState([]);
  const [additionalServices, setAdditionalServices] = useState([]);
  const [timetable, setTimetable] = useState([]);
  const [bikesList, setBikesList] = useState([]);

  // Данные для CRUD операций
  const [rootRentalPointData, setRootRentalPointData] = useState(null);
  const [editRootRentalPointData, setEditRootRentalPointData] = useState(null);
  const [newRootRentalPointData, setNewRootRentalPointData] = useState(null);
  const [isSuccessfulCreationRentalPoint, setIsSuccessfulCreationRentalPoint] =
    useState(false);
  // const [previewRentalPointData, setPreviewRentalPointData] = useState(null);
  const [newPreviewRentalPointData, setNewPreviewRentalPointData] =
    useState(null);
  const [editPreviewRentalPointData, setEditPreviewRentalPointData] =
    useState(null);

  // Модерация
  const [moderation, setModeration] = useState(null);
  const [commentsModerator, setCommentsModerator] = useState(null);
  const [rentalPointStatus, setRentalPointStatus] = useState(null);
  const [deleteState, setDeleteState] = useState(null);

  // Файлы
  const [previewLogoFiles, setPreviewLogoFiles] = useState([]);
  const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);
  const [newLogoFiles, setNewLogoFiles] = useState([]);
  const [newLogoPhotoFiles, setNewLogoPhotoFiles] = useState([]);

  // Состояния загрузки
  const [loadingCreateUploadLogoFiles, setLoadingCreateUploadLogoFiles] =
    useState(false);
  const [loadingCreateUploadPhotoFiles, setLoadingCreateUploadPhotoFiles] =
    useState(false);

  // Флаги изменений контента
  const [isChangeUploadLogoPhotoFiles, setIsChangeUploadLogoPhotoFiles] =
    useState(false);
  const [isChangeUploadLogoFiles, setIsChangeUploadLogoFiles] = useState(false);
  const [isResetUploadLogoPhotoFiles, setIsResetUploadLogoPhotoFiles] =
    useState(false);
  const [isResetUploadLogoFiles, setIsResetUploadLogoFiles] = useState(false);
  const [isChangeBasicInformation, setIsChangeBasicInformation] =
    useState(false);
  const [isChangeBikeModeration, setIsChangeBikeModeration] = useState(false);
  const [isChangelocationRentalPoint, setIsChangelocationRentalPoint] =
    useState(false);
  const [isChangeTimetable, setIsChangeTimetable] = useState(false);
  const [isChangeAditionalServices, setIsChangeAditionalServices] =
    useState(false);
  const [isChangeContacts, setIsChangeContacts] = useState(false);
  const [isDeleteState, setIsDeleteState] = useState(null);

  // Состояние управления кнопками и сбросом
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [
    isActiveSaveButtonNewRentalPoint,
    setIsActiveSaveButtonNewRentalPoint,
  ] = useState(false);

  const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(true);
  const [isReset, setIsReset] = useState(false);

  // Состояния загрузки для CRUD
  const [isLoadingUpdateRentalPoint, setIsLoadingUpdateRentalPoint] =
    useState(false);
  const [isLoadingCreateRentalPoint, setIsLoadingCreateRentalPoint] =
    useState(false);
  const [isLoadingDeleteRentalPoint, setIsLoadingDeleteRentalPoint] =
    useState(false);

  // 3. ИНИЦИАЛИЗАЦИЯ ДАННЫХ В SLICE

  // Инициализация slice для обмена данными между компонентами
  useEffect(() => {
    // Инициализируем временные данные для обмена между компонентами
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_basicInformation",
        data: null,
      })
    );
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_moderation",
        data: null,
      })
    );
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_location",
        data: null,
      })
    );
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_contacts",
        data: null,
      })
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_timetable",
        data: null,
      })
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_aditionalServices",
        data: null,
      })
    );
    // Сброс всех флагов изменений при isReset = true
    if (isReset) {
      setIsChangeBasicInformation(false);
      setIsChangeBikeModeration(false);
      setIsChangelocationRentalPoint(false);
      setIsChangeContacts(false);
      setIsChangeUploadLogoFiles(false);
      setIsChangeUploadLogoPhotoFiles(false);

      // Сброс загруженных файлов
      setNewLogoFiles([]);
      setNewLogoPhotoFiles([]);

      // Сброс флагов сброса для дочерних компонентов
      setIsResetUploadLogoFiles(true);
      setIsResetUploadLogoPhotoFiles(true);

      // Сброс флага сброса
      setIsReset(false);
    }
  }, [dispatch, isReset, isModeratorAddedMode]);

  // 4. ЭФФЕКТЫ С USEEFFECT

  // Отслеживание состояний загрузки файлов
  useEffect(() => {
    setLoadingCreateUploadLogoFiles(
      loading?.loading_create_uploadLogoFiles || false
    );
    setLoadingCreateUploadPhotoFiles(
      loading?.loading_create_uploadPhotoFiles || false
    );
  }, [
    loading?.loading_create_uploadLogoFiles,
    loading?.loading_create_uploadPhotoFiles,
  ]);

  // Загрузка данных байков
  useEffect(() => {
    // Всегда обновляем список байков при получении новых данных из Redux
    setBikesList(tempPreviewData?.previrewRentalPoints || []);
  }, [tempPreviewData?.previrewRentalPoints, rentalPointId]);

  // Загрузка данных в зависимости от режима
  useEffect(() => {
    // Загрузка данных для создания новой точки проката (режим "moderatorAdded")
    if (isModeratorAddedMode) {
      const createData = rootNewData?.rootRentalPointData || {};
      setPreviewLogoFiles(createData?.files?.logoFiles || []);
      setPreviewPhotoFiles(createData?.files?.photoFiles || []);
      setBasicInformation(
        tempData?.tempData_rentalPoint_basicInformation ||
          createData?.basicInformation ||
          {}
      );
      setLocation(
        tempData?.tempData_rentalPoint_location || createData?.location || {}
      );
      setCountry(createData?.location?.country || "");
      setCity(createData?.location?.city || "");

      setAdditionalServices(
        tempData?.tempData_rentalPoint_aditionalServices ||
          createData.additionalServices ||
          []
      );

      setTimetable(
        tempData?.tempData_rentalPoint_timetable || createData?.timetable || []
      );

      setRootRentalPointData(createData || null);
      setCommentsModerator(createData?.statusHistory?.comments || []);

      setRentalPointStatus(
        tempData?.tempData_rentalPoint_moderation?.currentStatus ||
          createData.statusHistory?.currentStatus ||
          "moderation"
      );

      setContacts(createData?.contacts || []);
      setContactsEmail(
        tempData?.tempData_rentalPoint_contacts?.emailAddresses ||
          createData?.contacts?.emailAddresses ||
          []
      );
      setContactsPhone(
        tempData?.tempData_rentalPoint_contacts?.phoneNumbers ||
          createData?.contacts?.phoneNumbers ||
          []
      );
      setModeration(
        tempData?.tempData_rentalPoint_moderation ||
          createData?.statusHistory || {
            currentStatus: "moderation",
            comments: [],
          }
      );
    }
    // Загрузка данных для модерации существующей точки проката (режим "moderation")
    else if (isModerationMode) {
      const moderationData = tempRootData?.selectedBike_rentalPont || {};

      setPreviewLogoFiles(moderationData?.files?.logoFiles || []);
      setPreviewPhotoFiles(moderationData?.files?.photoFiles || []);

      setBasicInformation(
        tempData?.tempData_rentalPoint_basicInformation ||
          moderationData?.basicInformation ||
          {}
      );
      setLocation(
        tempData?.tempData_rentalPoint_location ||
          moderationData?.location ||
          {}
      );
      setCountry(moderationData?.location?.country || "");
      setCity(moderationData?.location?.city || "");

      setContacts(
        tempData.tempData_rentalPoint_contacts || moderationData?.contacts || {}
      );

      setContactsEmail(
        tempData.tempData_rentalPoint_contacts?.emailAddresses ||
          moderationData?.contacts?.emailAddresses ||
          []
      );
      setContactsPhone(
        tempData.tempData_rentalPoint_contacts?.phoneNumbers ||
          moderationData?.contacts?.phoneNumbers ||
          []
      );

      setAdditionalServices(
        tempData?.tempData_rentalPoint_aditionalServices ||
          moderationData?.additionalServices ||
          []
      );

      setTimetable(
        tempData?.tempData_rentalPoint_timetable ||
          moderationData?.timetable ||
          []
      );

      setCommentsModerator(
        tempData?.tempData_rentalPoint_moderation?.comments ||
          moderationData?.statusHistory?.comments ||
          []
      );
      setRentalPointStatus(
        tempData?.tempData_rentalPoint_moderation?.currentStatus ||
          moderationData?.statusHistory?.currentStatus ||
          "moderation"
      );
      setModeration(
        tempData?.tempData_rentalPoint_moderation ||
          moderationData?.statusHistory || {
            currentStatus: "moderation",
            comments: [],
          }
      );
      setIsDeleteState(!!moderationData?.remove || null);
      setDeleteState(moderationData?.remove || null);
    }
  }, [
    mode,
    isModerationMode,
    isModeratorAddedMode,
    rootNewData?.rootRentalPointData,
    tempRootData?.selectedBike_rentalPont,
    tempData?.tempData_rentalPoint_aditionalServices,
    tempData?.tempData_rentalPoint_location,
    tempData?.tempData_rentalPoint_basicInformation,
    tempData?.tempData_rentalPoint_moderation,
    tempData?.tempData_rentalPoint_timetable,
    tempData?.tempData_rentalPoint_contacts,
  ]);

  // 5. ФОРМИРОВАНИЕ ОБЪЕКТОВ ДЛЯ CRUD

  // Формирование объектов для обновления и создания точки проката
  // PREVIEW
  useEffect(() => {
    const createData = tempRootData?.rentalPoint_createRerntalPoint || {};
    const logoFiles = createData.files?.logoFiles || [];
    const iconUrlObject =
      logoFiles.find((file) => file.priority === true) || logoFiles[0];
    const iconUrl = iconUrlObject ? iconUrlObject.url : null;

    // Получаем URL файлов
    const photoFilesUrls = createData.files?.photoFiles
      ? createData.files.photoFiles.map((file) => file.url).join(",")
      : "";

    const licenseFilesUrls = createData.files?.licenseFile
      ? createData.files.licenseFile.map((file) => file.url).join(",")
      : "";

    // Формирование структуры данных для preview документа
    const basePreviewRentalPointData = {
      title: basicInformation?.title?.titleOriginal || "",
      description: (
        basicInformation?.description?.descriptionOriginal || ""
      ).slice(0, 250),
      pointAddress: location?.pointAddress || "",
      iconUrl: iconUrl,
      idRootDocumentUser: userId,
      country: country,
      city: city,
      remove: isDeleteState,
      visibility: false,
      status: rentalPointStatus || "moderation",
      files: {
        licenseFile: licenseFilesUrls,
        photoFiles: photoFilesUrls,
        logoFile: iconUrl,
      },
      totals: {
        bikesModeration:
          tempRootData?.selectedBike_rentalPont?.totals?.bikesModeration || 0,
        bikesApproved:
          tempRootData?.selectedBike_rentalPont?.totals?.bikesApproved || 0,
        bikesBanned:
          tempRootData?.selectedBike_rentalPont?.totals?.bikesBanned || 0,
      },
    };

    if (isModerationMode) {
      // Объект для обновления PREVIEW
      setEditPreviewRentalPointData(basePreviewRentalPointData);
    } else if (isModeratorAddedMode) {
      // Объект для создания PREVIEW
      setNewPreviewRentalPointData(basePreviewRentalPointData);
    }
  }, [
    tempRootData?.rentalPoint_createRerntalPoint,
    basicInformation,
    location,
    rentalPointStatus,
    userId,
    country,
    city,
    isDeleteState,
    isModerationMode,
    isModeratorAddedMode,
    tempRootData?.selectedBike_rentalPont?.totals?.bikesModeration,
    tempRootData?.selectedBike_rentalPont?.totals?.bikesApproved,
    tempRootData?.selectedBike_rentalPont?.totals?.bikesBanned,
  ]);

  // ROOT
  useEffect(() => {
    const baseRootRentalPointData = {
      basicInformation: basicInformation,
      statusHistory: moderation || {
        currentStatus: "moderation",
        comments: [],
      },
      location: location,
      timetable: timetable,
      contacts: {
        emailAddresses: [...contactsEmail],
        phoneNumbers: [...contactsPhone],
      },
      additionalServices: additionalServices,
      dirrectory: {
        cityId: location?.city?.id || "",
        countryId: location?.country?.id || "",
      },
      visibility: false,
      bikesIds: tempRootData?.selectedBike_rentalPont?.bikesIds || [],
      ordersIds: tempRootData?.selectedBike_rentalPont?.ordersIds || [],
    };

    if (isModerationMode) {
      // Объект для обновления ROOT (только обновляем существующие данные)
      setEditRootRentalPointData({
        ...tempRootData?.selectedBike_rentalPont,
        ...baseRootRentalPointData,
        remove: isDeleteState ? Date.now() : null,
      });
    } else if (isModeratorAddedMode) {
      // Объект для создания ROOT (полностью новый объект)
      setNewRootRentalPointData({
        ...baseRootRentalPointData,

        remove: null,
      });
    }
  }, [
    mode,
    isModerationMode,
    isModeratorAddedMode,
    basicInformation,
    location,
    contacts,
    additionalServices,
    timetable,
    moderation,
    tempRootData?.selectedBike_rentalPont,
    tempRootData?.rentalPoint_createRerntalPoint,
    contactsPhone,
    contactsEmail,
    rootNewData.rootRentalPointData,
    isDeleteState,
    tempRootData?.selectedBike_rentalPont?.bikesIds,
    tempRootData?.selectedBike_rentalPont?.ordersIds,
  ]);

  // 6. РЕАЛИЗАЦИЯ CRUD ЧЕРЕЗ USEEFFECT

  // Загрузка данных пользователя
  useEffect(() => {
    if (userId) {
      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: userId,
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "users",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "currentRootUserInRentalPoint",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_selectedUserModeration",
          errorStateName: "currentError",
        })
      );
    }
  }, [dispatch, userId]);

  // Подписка на данные точки проката
  useEffect(() => {
    if (isModerationMode && rentalPointId) {
      console.log(`Выполняем подписку на документ: ${rentalPointId}`);

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV5({
              collectionPath: "rentalPoints",
              rootCurrentDocumentState: "selectedBike_rentalPont",
              idRootDocument: rentalPointId,
              loadingStateName: "loading_rentalPont",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      // Отписка при размонтировании компонента
      return () => {
        dispatch(
          unsubscribeRootDocumentsThunkV5({
            collectionPath: "rentalPoints",
          })
        );
      };
    }
  }, [dispatch, isModerationMode, rentalPointId]);

  // Подписка на велосипеды для выбранной точки проката
  useEffect(() => {
    // Функция для подписки на данные велосипедов
    const subscribeToBikesData = async () => {
      if (isModerationMode && rentalPointId && userId) {
        const collectionPath = `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}`;
        const previewCurrentDocumenState = "previrewRentalPoints";
        const excludedIds = ["metadata"];
        const loadingStateName = "loading_subscribe_bikesPreview";
        const errorStateName = "currentError";

        console.log(`Выполняем подписку для ${collectionPath}`);

        // Сначала отписываемся от предыдущей подписки, если была
        try {
          await dispatch(
            unsubscribePreviewDocumentsThunkV4(collectionPath)
          ).unwrap();
        } catch (error) {
          console.error("Ошибка при отписке", error);
        }

        // Затем устанавливаем новую подписку
        try {
          await dispatch(
            subscribePreviewDocumentsThunkV4({
              collectionPath,
              previewCurrentDocumenState,
              excludedIds,
              loadingStateName,
              errorStateName,
            })
          ).unwrap();
          console.log("Подписка установлена");
        } catch (error) {
          console.error("Ошибка при подписке", error);
        }
      }
    };

    subscribeToBikesData();

    // Отписка при размонтировании компонента
    return () => {
      if (isModerationMode && rentalPointId && userId) {
        const collectionPath = `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}`;
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then(() => {
            console.log("Отписка выполнена");
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      }
    };
  }, [dispatch, isModerationMode, rentalPointId, userId]);

  // 7. ВНУТРЕННЯЯ ЛОГИКА

  // Отслеживание изменений в дочерних компонентах для активации кнопки
  useEffect(() => {
    // Проверяем, есть ли хотя бы одно изменение в любом из компонентов
    const hasAnyChanges =
      isChangeBasicInformation ||
      isChangeBikeModeration ||
      isChangelocationRentalPoint ||
      isChangeTimetable ||
      isChangeAditionalServices ||
      isChangeContacts ||
      isChangeUploadLogoFiles ||
      isChangeUploadLogoPhotoFiles;

    // Устанавливаем активность кнопки
    setIsButtonActive(hasAnyChanges);

    const hasAnyChangesAddedRentalPoint =
      isChangeBasicInformation &&
      isChangelocationRentalPoint &&
      isChangeContacts;

    // Устанавливаем активность кнопки для создания новой точки проката
    setIsActiveSaveButtonNewRentalPoint(hasAnyChangesAddedRentalPoint);
  }, [
    isChangeBasicInformation,
    isChangeBikeModeration,
    isChangelocationRentalPoint,
    isChangeTimetable,
    isChangeAditionalServices,
    isChangeContacts,
    isChangeUploadLogoFiles,
    isChangeUploadLogoPhotoFiles,
  ]);

  // Обновление данных после успешного обновления
  const updateSubscription = async () => {
    if (isModerationMode && rentalPointId && userId) {
      const collectionPath = `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}`;
      const previewCurrentDocumenState = "previrewRentalPoints";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_subscribe_bikesPreview";
      const errorStateName = "currentError";

      // Сначала отписываемся от текущей подписки
      try {
        await dispatch(
          unsubscribePreviewDocumentsThunkV4(collectionPath)
        ).unwrap();
        console.log("Отписка перед повторной подпиской выполнена");
      } catch (error) {
        console.error("Ошибка при отписке перед повторной подпиской", error);
      }

      // Затем выполняем новую подписку для получения свежих данных
      try {
        await dispatch(
          subscribePreviewDocumentsThunkV4({
            collectionPath,
            previewCurrentDocumenState,
            excludedIds,
            loadingStateName,
            errorStateName,
          })
        ).unwrap();
        console.log("Повторная подписка выполнена успешно");
      } catch (error) {
        console.error("Ошибка при повторной подписке", error);
      }
    }
  };

  /**
   * Обновление точки проката
   * @async
   */
  const updateData = async () => {
    if (!rentalPointId) return;

    try {
      setIsLoadingUpdateRentalPoint(true);
      setIsDeleteButtonActive(false);
      await dispatch(
        updateDataThunkV4({
          rootData: {
            ...editRootRentalPointData,
            updated: Date.now(), // установка времени обновления
          },
          previewData: {
            ...editPreviewRentalPointData,
            updated: Date.now(), // установка времени обновления
          },
          newFiles: {
            logoFiles: newLogoFiles,
            photoFiles: newLogoPhotoFiles,
          },
          iconFields: "logoFiles",
          idPreviewDocument: null,
          idRootDocument: rentalPointId,
          rootCollectionPath: "rentalPoints",
          previewCollectionPath: `manager_rentalPointsPrevirew.userIds.${userId}`,
          previewGeneralCollectionPath: "general_rentalPointsPreview",
          metadataDocumentPath: `manager_rentalPointsPrevirew.userIds.${userId}.metadata`,
          metadataGeneralDocumentPath: "general_rentalPointsPreview.metadata",
          rootCurrentDocumentState: "selectedBike_rentalPont",
          previewCurrentDocumenState: "previrewRentalPoints",
          previewGeneralDocumenState: "general_rentalPontPreview",
          loadingStateName: "loading_manager_rentalPoints_update",
          errorStateName: "currentError",
          isChangeStat: null,
        })
      ).unwrap();

      // Сброс всех флагов изменений после успешного обновления
      setIsChangeBasicInformation(false);
      setIsChangeBikeModeration(false);
      setIsChangelocationRentalPoint(false);
      setIsChangeTimetable(false);
      setIsChangeAditionalServices(false);
      setIsChangeContacts(false);
      setIsChangeUploadLogoFiles(false);
      setIsChangeUploadLogoPhotoFiles(false);

      // Обновляем подписку на данные после успешного обновления
      await updateSubscription();

      console.log(`Точка проката успешно обновлена ${rentalPointId}`);
    } catch (error) {
      console.error("Ошибка при обновлении точки проката:", error);
    } finally {
      // Очищаем состояния файлов независимо от результата
      setNewLogoFiles(null);
      setNewLogoPhotoFiles(null);
      // Сбрасываем флаг загрузки
      setIsLoadingUpdateRentalPoint(false);
      setIsDeleteButtonActive(true);
    }
  };

  /**
   * Создание новой точки проката
   * @async
   */
  const createData = async () => {
    try {
      // Устанавливаем состояние загрузки
      setIsLoadingCreateRentalPoint(true);
      setIsActiveSaveButtonNewRentalPoint(false);

      // Корректировка данных для создания
      const timestamp = Date.now();

      // Вызываем thunk для создания данных точки проката
      const result = await dispatch(
        createDataThunkV4({
          rootData: {
            ...newRootRentalPointData,
            created: timestamp,
            updated: timestamp,
          },
          previewData: {
            ...newPreviewRentalPointData,
            createdat: timestamp,
            updated: timestamp,
          },
          files: {
            logoFiles: newLogoFiles,
            photoFiles: newLogoPhotoFiles,
          },
          iconFields: "logoFiles",
          rootCollectionPath: "rentalPoints",
          previewCollectionPath: `manager_rentalPointsPrevirew.userIds.${userId}`,
          previewGeneralCollectionPath: "general_rentalPointsPreview",
          metadataDocumentPath: `manager_rentalPointsPrevirew.userIds.${userId}.metadata`,
          metadataGeneralDocumentPath: "general_rentalPointsPreview.metadata",
          rootCurrentDocumentState: "selectedBike_rentalPont",
          previewCurrentDocumenState: "previrewRentalPoints",
          previewGeneralDocumenState: "general_rentalPontPreview",
          loadingStateName: "loading_manager_rentalPoints_create",
          errorStateName: "currentError",
        })
      ).unwrap();

      console.log("Точка проката успешно создана", result);

      // После успешного создания выполняем переадресацию
      navigate(`/ClientModeration/${userId}`);
    } catch (error) {
      console.error("Ошибка при создании точки проката:", error);
    } finally {
      // В любом случае сбрасываем состояние загрузки
      setIsLoadingCreateRentalPoint(false);
      setIsSuccessfulCreationRentalPoint(true);
    }
  };

  /**
   * Удаление точки проката
   * @async
   */
  const deleteData = async () => {
    try {
      setIsLoadingDeleteRentalPoint(true);
      setIsDeleteButtonActive(false);
      await dispatch(
        deleteDataThunkV4({
          idPreviewDocument: null,
          idRootDocument: rentalPointId,
          rootCollectionPath: "rentalPoints",
          previewCollectionPath: `manager_rentalPointsPrevirew.userIds.${userId}`,
          previewGeneralCollectionPath: "general_rentalPointsPreview",
          metadataDocumentPath: `manager_rentalPointsPrevirew.userIds.${userId}.metadata`,
          metadataGeneralDocumentPath: "general_rentalPointsPreview.metadata",
          rootCurrentDocumentState: "selectedBike_rentalPont",
          previewCurrentDocumentState: "previrewRentalPoints",
          previewGeneralDocumentState: "general_rentalPontPreview",
          loadingStateName: "loading_deleteBike",
          errorStateName: "currentError",
        })
      ).unwrap();

      console.log("Точка проката успешно удалена");

      // После успешного удаления выполняем переадресацию
      // navigate(`/ClientModeration/${userId}`);
    } catch (error) {
      console.error("Ошибка при удалении точки проката:", error);
    } finally {
      setIsLoadingDeleteRentalPoint(false);
      setIsDeleteButtonActive(true);
    }
  };

  // Обработчики событий
  const handleAddRentalPoint = () => {
    createData();
  };

  // Сброс файлов
  const handleCancel = () => {
    setIsResetUploadLogoFiles(true);
    setIsResetUploadLogoPhotoFiles(true);
    setIsReset(true);

    // Деактивируем кнопки после сброса
    setIsButtonActive(false);
    setIsActiveSaveButtonNewRentalPoint(false);

    // Сбрасываем все флаги изменений
    setIsChangeBasicInformation(false);
    setIsChangeBikeModeration(false);
    setIsChangelocationRentalPoint(false);
    setIsChangeTimetable(false);
    setIsChangeAditionalServices(false);
    setIsChangeContacts(false);
    setIsChangeUploadLogoFiles(false);
    setIsChangeUploadLogoPhotoFiles(false);
  };

  // Обновление точки проката
  const handleUpdateRentalPoint = () => {
    // Отключаем кнопку на время обновления
    setIsButtonActive(false);
    setIsActiveSaveButtonNewRentalPoint(false);
    updateData();
  };

  // Удаление точки проката
  const handleDeleteRentalPoint = () => {
    deleteData();
  };

  // Закрытие модального окна добавления нового велосипеда
  const handleClose = () => {
    setWindowNewBikeDetailModeration(false);
  };

  // 8. РЕНДЕРИНГ КОМПОНЕНТА

  return (
    <>
      {/* Модальное окно для добавления нового велосипеда */}
      <PopupDirrectory
        isOpen={windowNewBikeDetailModeration}
        onClose={handleClose}
        title="Add new bike moderation"
      >
        <WindowBikeDetailModerationV4
          mode="moderatorAdded"
          onClose={handleClose}
        />
      </PopupDirrectory>

      {/* Основной контент */}
      <PageBox path={breadcrumbPath} title={title}>
        {isDeleteState && (
          <ContentBox
            style={{
              backgroundColor: "var(--color-alarm)",
              padding: "8px 0",
            }}
          >
            <WidgetAllertDelete
              title="Удаляем точку проката"
              onClick={deleteData}
              deleteState={deleteState}
            />
          </ContentBox>
        )}

        {/* Блок с основной информацией и модерацией */}

        {/* Базовая информация */}
        <ContentBox
          isLoading={
            isLoadingUpdateRentalPoint ||
            isLoadingCreateRentalPoint ||
            isLoadingDeleteRentalPoint ||
            loadingCreateUploadPhotoFiles ||
            loadingCreateUploadLogoFiles
          }
          skeleton={<SkeletonTitleDescription />}
        >
          <WidgetRentalPointBasicInformation
            mode={mode}
            basicInformation={basicInformation}
            isChangeState={setIsChangeBasicInformation}
          />
        </ContentBox>

        {/* Блок модерации - отображается только в режиме moderation */}
        {isModerationMode && (
          <ContentBox
            isLoading={
              isLoadingUpdateRentalPoint ||
              isLoadingCreateRentalPoint ||
              isLoadingDeleteRentalPoint ||
              loadingCreateUploadPhotoFiles ||
              loadingCreateUploadLogoFiles
            }
            skeleton={<SkeletonTitleDescription />}
          >
            <WidgetRentalPointModeration
              mode={mode}
              comments={commentsModerator}
              statusHistory={rentalPointStatus}
              isChangeState={setIsChangeBikeModeration}
            />
          </ContentBox>
        )}

        {/* Файлы логотипов */}
        <ContentBox>
          <WidgetListFileV4
            // isChangeState={setIsChangeUploadLogoFiles}
            isLoading={
              isLoadingUpdateRentalPoint ||
              isLoadingCreateRentalPoint ||
              isLoadingDeleteRentalPoint ||
              loadingCreateUploadPhotoFiles ||
              loadingCreateUploadLogoFiles
            }
            viewFiles={previewLogoFiles}
            fieldName="files.logoFiles"
            rootCollectionPath="rentalPoints"
            previewCollectionPath={`manager_rentalPointsPrevirew.userIds.${userId}`}
            metadataDocumentPath={`manager_rentalPointsPrevirew.userIds.${userId}.metadata`}
            idRootDocument={rentalPointId}
            loadingStateName="loading_create_uploadLogoFiles"
            errorStateName="currentError"
            rootCurrentDocumentState="selectedBike_rentalPont"
            previewCurrentDocumenState="manager_rentalPointsPrevirew"
            mode="edit"
          />

          <WidgetUploadFilesV4
            isChangeState={setIsChangeUploadLogoFiles}
            isLoading={
              isLoadingUpdateRentalPoint ||
              isLoadingCreateRentalPoint ||
              isLoadingDeleteRentalPoint ||
              loadingCreateUploadPhotoFiles ||
              loadingCreateUploadLogoFiles
            }
            id={uuidv4()}
            title="Add logo files"
            description="Logo files for rental point."
            files={(files) => setNewLogoFiles(files)}
            isReset={isResetUploadLogoFiles}
            setIsReset={setIsResetUploadLogoFiles}
          />
        </ContentBox>

        {/* Файлы фотографий */}
        <ContentBox>
          <WidgetListFileV4
            // isChangeState={setIsChangeUploadLogoPhotoFiles}
            isLoading={
              isLoadingUpdateRentalPoint ||
              isLoadingCreateRentalPoint ||
              isLoadingDeleteRentalPoint ||
              loadingCreateUploadPhotoFiles ||
              loadingCreateUploadLogoFiles
            }
            viewFiles={previewPhotoFiles}
            fieldName="files.photoFiles"
            rootCollectionPath="rentalPoints"
            previewCollectionPath={`manager_rentalPointsPrevirew.userIds.${userId}`}
            metadataDocumentPath={`manager_rentalPointsPrevirew.userIds.${userId}.metadata`}
            idRootDocument={rentalPointId}
            loadingStateName="loading_create_uploadPhotoFiles"
            errorStateName="currentError"
            rootCurrentDocumentState="selectedBike_rentalPont"
            previewCurrentDocumenState="manager_rentalPointsPrevirew"
            mode="edit"
          />

          <WidgetUploadFilesV4
            isChangeState={setIsChangeUploadLogoPhotoFiles}
            isLoading={
              isLoadingUpdateRentalPoint ||
              isLoadingCreateRentalPoint ||
              isLoadingDeleteRentalPoint ||
              loadingCreateUploadPhotoFiles ||
              loadingCreateUploadLogoFiles
            }
            id={uuidv4()}
            title="Add photo files"
            description="Photo gallery for rental point."
            files={setNewLogoPhotoFiles}
            isReset={isResetUploadLogoPhotoFiles}
            setIsReset={setIsResetUploadLogoPhotoFiles}
          />
        </ContentBox>

        {/* Информация о местоположении */}
        <ContentBox
          skeleton={<SkeletonRentalPointLocation />}
          isLoading={
            isLoadingUpdateRentalPoint ||
            isLoadingCreateRentalPoint ||
            isLoadingDeleteRentalPoint ||
            loadingCreateUploadPhotoFiles ||
            loadingCreateUploadLogoFiles
          }
        >
          <WidgetLocation
            isChangeState={setIsChangelocationRentalPoint}
            location={location}
            ancorId="ancor1"
            mode={mode}
          />
        </ContentBox>

        {/* Список велосипедов - только после создания точки проката для режима moderation */}
        {!isModeratorAddedMode && (
          <ContentBox
            isLoading={
              isLoadingUpdateRentalPoint ||
              isLoadingCreateRentalPoint ||
              isLoadingDeleteRentalPoint ||
              loadingCreateUploadPhotoFiles ||
              loadingCreateUploadLogoFiles
            }
            skeleton={<SkeletonRentalPointBikeList bikesList={bikesList} />}
          >
            <WidgetBikeListModerationV4 bikesList={bikesList} mode={mode} />
          </ContentBox>
        )}

        {/* Расписание работы */}
        <ContentBox
          isLoading={
            isLoadingUpdateRentalPoint ||
            isLoadingCreateRentalPoint ||
            isLoadingDeleteRentalPoint ||
            loadingCreateUploadPhotoFiles ||
            loadingCreateUploadLogoFiles
          }
          skeleton={<SkeletonRentalPointTimeTable />}
        >
          <WidgetRentalPointTimetable
            mode={mode}
            timetable={timetable}
            isChangeState={setIsChangeTimetable}
          />
        </ContentBox>

        {/* Контактная информация */}
        <ContentBox
          isLoading={
            isLoadingUpdateRentalPoint ||
            isLoadingCreateRentalPoint ||
            isLoadingDeleteRentalPoint ||
            loadingCreateUploadPhotoFiles ||
            loadingCreateUploadLogoFiles
          }
          skeleton={<SkeletonRentalPointContacts />}
        >
          <WidgetRentalPointContact
            contactsEmail={contactsEmail}
            contactsPhone={contactsPhone}
            mode={mode}
            contacts={contacts}
            isChangeState={setIsChangeContacts}
          />
        </ContentBox>

        {/* Дополнительные услуги */}
        <ContentBox
          isLoading={
            isLoadingUpdateRentalPoint ||
            isLoadingCreateRentalPoint ||
            isLoadingDeleteRentalPoint ||
            loadingCreateUploadPhotoFiles ||
            loadingCreateUploadLogoFiles
          }
          skeleton={
            <SkeletonRentalPointAditionalServices
              additionalServices={additionalServices}
            />
          }
        >
          <WidgetAditionalServicesV4
            mode={mode}
            additionalServices={additionalServices}
            isChangeState={setIsChangeAditionalServices}
          />
        </ContentBox>
      </PageBox>

      {/* Панель кнопок внизу страницы */}
      <ButtonsBar>
        {/* Кнопка действия - разная для режимов moderation и moderatorAdded */}
        {isModerationMode ? (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "16px",
              width: "100%",
            }}
          >
            {deleteState ? (
              <Button
                type="small"
                color="--color-success"
                label="Restore"
                active={isDeleteButtonActive && !isButtonActive}
                onClick={handleDeleteRentalPoint}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>Восстанавливаем?</h1>
                  </div>
                }
              />
            ) : (
              <Button
                type="small"
                color="--color-alarm"
                label="Delete"
                active={isDeleteButtonActive && !isButtonActive}
                onClick={handleDeleteRentalPoint}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>Удаляем?</h1>
                  </div>
                }
              />
            )}
            {/* Кнопка отмены - одинаковая для обоих режимов */}
            <Button
              type="small"
              active={isButtonActive}
              label="Отмена"
              color="--color-black"
              onClick={handleCancel}
              allert={
                <h1
                  style={{
                    padding: "80px",
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to discard all changes?
                </h1>
              }
            />
            <Button
              type="small"
              label="Update"
              color="--color-primary"
              active={isButtonActive}
              onClick={handleUpdateRentalPoint}
              allert={
                <div
                  style={{
                    padding: "24px",
                    boxSizing: "border-box",
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                  }}
                >
                  <h1>
                    After approval of this rental point, users will be able to
                    fulfill orders.
                  </h1>
                  <p className="p-light" style={{ width: "70%" }}>
                    You can always change the status of the rental point in case
                    of violation of any conditions or customer complaints.
                  </p>
                </div>
              }
            />
          </div>
        ) : (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
              width: "100%",
            }}
          >
            {/* Кнопка отмены - одинаковая для обоих режимов */}
            <Button
              type="small"
              active={isButtonActive && isActiveSaveButtonNewRentalPoint}
              label="Отмена"
              color="--color-black"
              onClick={handleCancel}
              allert={
                <h1
                  style={{
                    padding: "80px",
                    textAlign: "center",
                  }}
                >
                  Are you sure you want to discard all changes?
                </h1>
              }
            />
            <Button
              type="small"
              label="Create rental point"
              color="--color-primary"
              active={isActiveSaveButtonNewRentalPoint}
              onClick={handleAddRentalPoint}
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>Are you sure you want to add Rental Point?</h1>
                </div>
              }
            />
          </div>
        )}
      </ButtonsBar>
    </>
  );
};

export default PageRentalPointDetailModerationV4;
