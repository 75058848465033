import React, { useEffect, useState } from "react";
import PriorityFile from "../../../../UIComponents/UploadFiles/PriorityFile";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../../../../../functions/isMobile";
import {
  setCurrentPreviewData,
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4.js";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowViewBikeDetail from "../BikesV3/WindowViewBikeDetail";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import WidgetRate from "../../../../Public/ProductCardV2/WidgetRate.js";
import { dateFormat } from "../../../../../functions/convertDate";
import Divide from "../../../../UIComponents/Divide/Divide";
import { truncateText } from "../../../../../functions/truncateText";

const WindowSelectedReplacementBike = ({ onClose }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { tempRootData, tempPreviewData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // ------- tempRootData
  const [currentCart, setCurrentCart] = useState(null);

  const [
    order_selectedCompletedRootOrder,
    setOrder_selectedCompletedRootOrder,
  ] = useState(null);

  // ------- tempRootData
  const [indexBikeCurrentCart, setIndexBikeCurrentCart] = useState(null);
  const [keySlotReplacementBike, setKeySlotReplacementBike] = useState(null);

  // ------- Windows stats
  const [windowBikeDetailModeration, setWindowBikeDetailModeration] =
    useState(false);
  const [windowSelectedReplacement, setWindowSelectedReplacement] =
    useState(false);

  // ------- Внутренняя логика компоненета
  const [mode, setMode] = useState(null);
  const [replacementBikes, setReplacementBikes] = useState(null);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */
  // ------- tempData
  // ------- tempRootData
  useEffect(() => {
    if (tempData?.currentUpdatedOrder) {
      // setOrder_selectedCompletedRootOrder(
      //   tempData?.currentUpdatedOrder || null
      // );
      // setCurrentCart(tempData?.currentUpdatedOrder?.currentCart || null);
    } else {
      setOrder_selectedCompletedRootOrder(
        tempRootData?.order_selectedCompletedRootOrder || null
      );
      setCurrentCart(
        tempRootData?.order_selectedCompletedRootOrder?.currentCart || null
      );
    }
    setIndexBikeCurrentCart(
      tempData?.itemSlotSelectedReplacementBike?.indexBikeCurrentCart || 0
    );
    setKeySlotReplacementBike(
      tempData?.itemSlotSelectedReplacementBike?.keySlotReplacementBike || null
    );
  }, [
    tempRootData?.order_selectedCompletedRootOrder,
    tempData?.currentUpdatedOrder,
    tempData?.itemSlotSelectedReplacementBike,
  ]);

  // const handleSelectedReplacementBike = useCallback(
  //       ({
  //         mode,
  //         indexReplacementBike,
  //         indexBikeCurrentCart,
  //         keySlotReplacementBike,
  //       }) => {
  //         dispatch(
  //           setTempData({
  //             // tempCurrentDocumentState: "itemSlotSelectedReplacementBike",
  //             tempCurrentDocumentState: "currentSlotIds",
  //             data: {
  //               indexReplacementBike,
  //               indexBikeCurrentCart,
  //               keySlotReplacementBike,
  //             },
  //           })
  //         );

  //         setMode(mode);
  //         setWindowSelectedReplacementBike(true);
  //       },
  //       [dispatch]
  //     );

  // console.log(
  //   "WindowSelectedReplacementBike ------- tempData",
  //   indexBikeCurrentCart,
  //   keySlotReplacementBike,
  //   "------- tempRootData",
  //   order_selectedCompletedRootOrder,
  //   currentCart,
  //   "Получаем мз currentCart данные выбранного байка",
  //   indexBikeCurrentCart != null
  //     ? currentCart[indexBikeCurrentCart]
  //     : "Нет данных",

  //   "Получаем данные из currentUpdatedOrder ",
  //   // keySlotReplacementBike && indexBikeCurrentCart != null
  //   //   ? currentCart[indexBikeCurrentCart].replacementBikes[
  //   //       keySlotReplacementBike
  //   //     ]
  //   //   : "Нет данных"
  //   tempData?.currentUpdatedOrder
  // );
  /**
   *
   * Внутренняя логика компонента
   *
   */
  useEffect(() => {
    setReplacementBikes(
      keySlotReplacementBike &&
        indexBikeCurrentCart != null &&
        currentCart[indexBikeCurrentCart]?.replacementBikes[
          keySlotReplacementBike
        ]
    );
  }, [keySlotReplacementBike, indexBikeCurrentCart, currentCart]);

  const handleClose = () => {
    setWindowSelectedReplacement(false);
    setWindowBikeDetailModeration(false);
  };

  const handleSelectedBike = ({
    mode,
    replacementBike,
    orderData,
    indexReplacementBike,
    indexBikeCurrentCart,
    keySlotReplacementBike,
  }) => {
    dispatch(
      setCurrentPreviewData({
        // tempCurrentDocumentState: "replacementSelectedBike",
        currentPreviewDocumenState: "choosingSelectedBike_previewData",
        // data: { replacementBike, orderData },
        data: orderData,
      })
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentSlotIds",
        data: {
          indexReplacementBike,
          indexBikeCurrentCart,
          keySlotReplacementBike,
        },
      })
    );

    setMode(mode);
    setWindowBikeDetailModeration(true);
  };

  const handleDeleteBike = ({
    indexBikeCurrentCart,
    indexDeletingBike,
    indexDeleteSlotBike,
    order_selectedCompletedRootOrder,
  }) => {
    const { currentCart } = order_selectedCompletedRootOrder;

    // Находим объект cartItem по индексу indexBikeCurrentCart
    const cartItem = currentCart[indexBikeCurrentCart];

    // Проверяем, существует ли replacementBikes и нужный ключ
    if (
      cartItem.replacementBikes &&
      cartItem.replacementBikes.hasOwnProperty(indexDeleteSlotBike)
    ) {
      const replacementArray = cartItem.replacementBikes[indexDeleteSlotBike];

      // Создаем новый массив, исключая элемент с индексом indexDeletingBike
      const updatedReplacementArray = [
        ...replacementArray.slice(0, indexDeletingBike), // до индекса
        ...replacementArray.slice(indexDeletingBike + 1), // после индекса
      ];
      // Обновляем объект cartItem с новым массивом replacementBikes
      const updatedCartItem = {
        ...cartItem,
        replacementBikes: {
          ...cartItem.replacementBikes,
          [indexDeleteSlotBike]: updatedReplacementArray,
        },
      };

      // Создаем новый currentCart с обновленным cartItem
      const updatedCurrentCart = [
        ...currentCart.slice(0, indexBikeCurrentCart),
        updatedCartItem,
        ...currentCart.slice(indexBikeCurrentCart + 1),
      ];

      // Обновляем order_selectedCompletedRootOrder с новым currentCart
      const updatedOrder = {
        ...order_selectedCompletedRootOrder,
        currentCart: updatedCurrentCart,
      };

      // Диспатчим обновленные данные
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "order_selectedCompletedRootOrder",
          data: updatedOrder,
        })
      );
    } else {
      console.log("Key with index", indexDeleteSlotBike, "not found.");
    }
  };

  return (
    <div>
      <PopupDirrectory
        isOpen={windowBikeDetailModeration}
        onClose={handleClose}
        title="WindowSelectedReplacementBike->WindowViewBikeDetail"
      >
        <WindowViewBikeDetail
          mode="selected"
          onClose={handleClose}
          closeParent={onClose}
        />
      </PopupDirrectory>

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ContentBox position="one">
            <ReplacementBikes
              replacementBikes={replacementBikes}
              indexBikeCurrentCart={indexBikeCurrentCart}
              keySlotReplacementBike={keySlotReplacementBike}
              order_selectedCompletedRootOrder={
                order_selectedCompletedRootOrder
              }
              onClick={handleSelectedBike}
              onClickDelete={handleDeleteBike}
              isMobile={isMobile}
              currentCart={currentCart}
            />
          </ContentBox>
        </div>
      </div>
    </div>
  );
};

export default WindowSelectedReplacementBike;

const ReplacementBikes = ({
  replacementBikes,
  indexBikeCurrentCart,
  keySlotReplacementBike,
  order_selectedCompletedRootOrder,
  onClick,
  isMobile,
  currentCart,
}) => {
  const [startBookingDates, setStartBookingDates] = useState(null);
  const [endBookingDates, setEndBookingDates] = useState(null);

  useEffect(() => {
    if (indexBikeCurrentCart !== null && currentCart) {
      const startBookingDates =
        currentCart[indexBikeCurrentCart]?.totals?.dateInterval[0];
      setStartBookingDates(startBookingDates);
      const endBookingDates =
        currentCart[indexBikeCurrentCart]?.totals?.dateInterval[1];
      setEndBookingDates(endBookingDates);
    }
  }, [currentCart, indexBikeCurrentCart]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
      }}
    >
      {replacementBikes?.map((bike, index) => (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <Divide title="Option" number={index + 1} />
          </div>

          <div
            key={uuidv4()}
            style={{
              width: "100%",
              display: "grid",
              ...(isMobile
                ? { gridTemplateColumns: "repeat(1, 1fr)" }
                : { gridTemplateColumns: "repeat(2, 1fr)" }),
              gap: "8px",
              boxSizing: "border-box",
              cursor: "pointer",
              borderRadius: "16px",
            }}
            onClick={() =>
              onClick({
                mode: "selected",
                replacementBike: bike,
                orderData: order_selectedCompletedRootOrder,
                indexBikeCurrentCart: indexBikeCurrentCart,
                indexReplacementBike: index,
                keySlotReplacementBike: keySlotReplacementBike,
              })
            }
          >
            <div
              style={{
                position: "relative",
              }}
            >
              {bike.userChoice && (
                <span
                  className="material-symbols-outlined"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                    width: "24px",
                    aspectRatio: "1/1",
                    borderRadius: "50%",
                    backgroundColor: "var(--color-success)",
                    color: "var(--color-white)",
                    right: "8px",
                    top: "8px",
                  }}
                  title="Selected bike"
                >
                  check_small
                </span>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "4px",
                  position: "absolute",
                  left: "8px",
                  top: "8px",
                }}
              >
                <p
                  style={{
                    padding: "2px 8px",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(10px)",
                    borderRadius: "4px",
                    color: "var(--color-white)",
                  }}
                  className="p-light"
                >
                  {bike.directory.brand.title}
                </p>

                <p
                  style={{
                    padding: "2px 8px",
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                    backdropFilter: "blur(10px)",
                    borderRadius: "4px",
                    color: "var(--color-white)",
                  }}
                  className="p-light"
                >
                  {bike.directory.category.title}
                </p>
              </div>

              <PriorityFile
                style={{
                  width: "100%",
                  aspectRatio: "16/9",
                }}
                files={bike?.files?.photoFiles}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: "8px",
                }}
              >
                <h6>{bike.basicInformation.title.titleOriginal}</h6>
              </div>

              <WidgetRate
                startBookingDates={startBookingDates || 0}
                endBookingDates={endBookingDates || 0}
                rate={bike.rate}
              />

              {bike.basicInformation.description.descriptionOriginal && (
                <p className="p-light">
                  {truncateText(
                    bike.basicInformation.description.descriptionOriginal,
                    200
                  )}
                </p>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

// const SelectReplacementBike = ({
//   onClick,
//   onClickDelete,
//   item,
//   indexBikeCurrentCart,
//   order_selectedCompletedRootOrder,
//   isMobile,
// }) => {
//   const sortedData = item?.replacementBikes
//     ? Object.entries(item.replacementBikes).map(([key, value]) => ({
//         [key]: value, // Преобразуем ключ-значение в объект
//       }))
//     : []; // Если replacementBikes нет, возвращаем пустой массив

//   // // Проверка на существование replacementBikes
//   // const sortedData = item?.replacementBikes
//   //   ? Object.entries(item.replacementBikes)
//   //       .sort(([, a], [, b]) => {
//   //         const aLength = a.length;
//   //         const bLength = b.length;

//   //         // Пустые массивы идут первыми
//   //         if (aLength === 0 && bLength === 0) return 0;
//   //         if (aLength === 0) return -1;
//   //         if (bLength === 0) return 1;

//   //         // Сортировка по убыванию длины массива
//   //         return bLength - aLength;
//   //       })
//   //       .map(([key, value]) => ({ [key]: value })) // Преобразуем в массив объектов, чтобы увидеть результат
//   //   : []; // Если replacementBikes нет, возвращаем пустой массив
//   // const [replacementBikes, setReplacementBikes] = useState(null);
//   // const [selectedBikes, setSelectedBikes] = useState(null);

//   const [photoRootBike, setPhotoRootBike] = useState(null);
//   const [titleRootbike, setTitleRootbike] = useState(null);
//   const [quantityBikes, setQuantityBikes] = useState(null);
//   const [titleRentalPoint, setTitleRentalPoint] = useState(null);

//   useEffect(() => {
//     if (item && item.bikeData) {
//       // setReplacementBikes(item?.replacementBikes);
//       // setSelectedBikes(item?.selectedBikes);
//       setPhotoRootBike(item?.bikeData?.files?.photoFiles);
//       setTitleRootbike(item?.bikeData?.basicInformation?.title?.titleOriginal);
//       setTitleRentalPoint(
//         item?.rentalPointRootData?.basicInformation?.title?.titleOriginal ||
//           null
//       );
//       setQuantityBikes(item.totals.quantity || null);
//     }
//   }, [item]);

//   const replacementBikes = item?.replacementBikes
//     ? Object.values(item.replacementBikes)
//     : [];

//   const selectedBikes = item?.selectedBikes
//     ? Object.values(item.selectedBikes)
//     : [];

//   console.log("SelectReplacementBike item", selectedBikes);

//   // Функция для проверки совпадения id
//   const isBikeSelected = (bikeId) => {
//     return selectedBikes.some((selectedSlot) =>
//       selectedSlot.some((selectedBike) => selectedBike.id === bikeId)
//     );
//   };

//   const countReplacementBikes = () => {
//     return replacementBikes.filter((selectedSlot) => selectedSlot.length > 0)
//       .length;
//   };

//   const countStockBike = () => {
//     return replacementBikes.filter((selectedSlot) => selectedSlot.length === 0)
//       .length;
//   };

//   return (
//     <div
//       style={{
//         width: "100%",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         gap: "2px",
//         boxSizing: "border-box",
//       }}
//     >
//       <div
//         style={{
//           width: "100%",
//           backgroundColor: "var(--color-gray-100)",
//           borderRadius: "16px",
//           boxSizing: "border-box",
//         }}
//       >
//         <div
//           style={{
//             width: "100%",
//             padding: "16px 16px 0 16px",
//             boxSizing: "border-box",
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "flex-start",
//             justifyContent: "space-between",
//             gap: "4px",
//           }}
//         >
//           <h5>{titleRootbike}</h5>
//         </div>
//         <div
//           style={{
//             width: "100%",
//             boxSizing: "border-box",
//           }}
//         >
//           Замена
//           {replacementBikes?.map(
//             (slotItem, index) =>
//               slotItem.length > 0 && (
//                 <div
//                   key={uuidv4()}
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",

//                     gap: "8px",
//                     boxSizing: "border-box",
//                   }}
//                 >
//                   <PriorityFile
//                     style={{
//                       borderRadius: "16px",
//                       width: "80px",
//                       aspectRatio: "1/1",
//                     }}
//                     files={photoRootBike}
//                   />
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "row",
//                       position: "relative",
//                     }}
//                   >
//                     {slotItem.map((bike, index) => (
//                       <div
//                         key={uuidv4()}
//                         style={{
//                           position: "absolute",
//                           left: `${index * 25}px`,
//                         }}
//                       >
//                         <PriorityFile
//                           style={{
//                             borderRadius: "50%",
//                             width: "100%",
//                             aspectRatio: "1/1",
//                             border: "2px solid var(--color-gray-100)",
//                           }}
//                           files={bike?.files?.photoFiles}
//                         />
//                         <button
//                           onClick={() =>
//                             onClick({
//                               mode: "selected",
//                               replacementBike: bike,
//                               orderData: item,
//                               indexBikeCurrentCart: indexBikeCurrentCart,
//                               indexReplacementBikes: index,
//                             })
//                           }
//                         >
//                           Selected
//                         </button>
//                         {isBikeSelected(bike.id) && <span>ok</span>}
//                       </div>
//                     ))}
//                   </div>
//                 </div>
//               )
//           )}
//         </div>
//         <div
//           style={{
//             width: "100%",
//             display: "flex",
//             flexDirection: "row",
//             alignItems: "center",
//             justifyContent: "space-between",
//             boxSizing: "border-box",
//             gap: "8px",
//             padding: "16px",
//           }}
//         >
//           {/* <p
//             className="p-light"
//             style={{
//               whiteSpace: "nowrap",
//             }}
//           >
//             Total slots:
//           </p>
//           <span
//             style={{
//               width: "100%",
//               height: "1px",
//               backgroundColor: "var(--color-gray-200)",
//             }}
//           />
//           <p className="p-light">{replacementBikes2?.length}</p> */}
//         </div>
//       </div>
//     </div>
//   );
// };
