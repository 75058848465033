import React, { useEffect, useState } from "react";
import priorityFile from "../../../functions/priorityFile";
import Preloader from "../Preloader/Preloader";

const PriorityFile = React.memo(({ files, style, isLoading }) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    let result;
    if (Array.isArray(files)) {
      // files - массив, используем priorityFile
      result = priorityFile(files);
    } else if (typeof files === "string") {
      // files - строка, значит сразу используем её как url
      result = { url: files };
    } else {
      // Иной случай: null или undefined
      result = null;
    }
    setLogo(result);
  }, [files]);

  return (
    <div
      style={{
        backgroundImage: `url(${logo?.url})`,
        width: "80px",
        // height: "80px",
        borderRadius: "8px",
        boxSizing: "border-box",
        overflow: "hidden",
        backgroundColor: "var(--color-gray-100)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      {isLoading && <Preloader />}
    </div>
  );
});

export default PriorityFile;
