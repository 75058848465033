import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PopupDirrectory from "../Popups/PopupDirrectory";

/**
 * ContentBoxV4 - компонент-контейнер для предварительного просмотра данных и навигации
 *
 * @param {Object} style - дополнительные стили для основного контейнера
 * @param {String} label - заголовок или метка
 * @param {String|Number} param - значение параметра (строка или число)
 * @param {Node} children - дочерние компоненты
 * @param {Node} icon - иконка для отображения рядом с label
 * @param {Function|String|Component} execute - функция, slug или компонент для выполнения/отображения при клике
 * @param {Function} onClickAfter - функция, вызываемая после выполнения execute
 * @returns {JSX.Element}
 */
const ContentBoxV4 = ({
  style,
  label,
  param,
  children,
  icon,
  execute,
  onClickAfter,
}) => {
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");
  const [PopupComponent, setPopupComponent] = useState(null);

  // Определяем тип execute для отображения соответствующей иконки
  const getExecuteType = () => {
    if (!execute) return null;

    // Если execute - это React-компонент или JSX-элемент
    if (
      React.isValidElement(execute) ||
      (typeof execute === "function" &&
        !execute.toString().includes("return") &&
        execute.prototype &&
        execute.prototype.isReactComponent)
    ) {
      return "component";
    }
    // Если execute - это функция
    else if (typeof execute === "function") {
      return "function";
    }
    // Если execute - это slug
    else if (typeof execute === "string") {
      return "slug";
    }

    return null;
  };

  // Рендерим соответствующую иконку в зависимости от типа execute
  const renderExecuteIcon = () => {
    const executeType = getExecuteType();

    if (!executeType) return null;

    switch (executeType) {
      case "slug":
        return <span className="material-symbols-outlined">link</span>;
      case "component":
        return (
          <span className="material-symbols-outlined">open_in_new_down</span>
        );
      case "function":
        return <span className="material-symbols-outlined">check_circle</span>;
      default:
        return null;
    }
  };

  const handleClick = () => {
    if (execute) {
      // Если execute - это React-компонент или JSX-элемент
      if (
        React.isValidElement(execute) ||
        (typeof execute === "function" &&
          !execute.toString().includes("return") &&
          execute.prototype &&
          execute.prototype.isReactComponent)
      ) {
        setPopupComponent(execute);
        setPopupTitle(label || "");
        setIsPopupOpen(true);
      }
      // Если execute - это функция
      else if (typeof execute === "function") {
        execute();
        if (onClickAfter) onClickAfter();
      }
      // Если execute - это slug
      else if (typeof execute === "string") {
        navigate(execute);
        if (onClickAfter) onClickAfter();
      }
    }
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    if (onClickAfter) onClickAfter();
  };

  return (
    <>
      <div
        onClick={execute ? handleClick : undefined}
        style={{
          width: "100%",
          backgroundColor: "var(--color-white)",
          borderRadius: children ? "24px" : "80px",
          padding: "16px 24px",
          boxSizing: "border-box",
          cursor: execute ? "pointer" : "default",
          transition: "background-color 0.2s ease",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative", // Добавляем для позиционирования иконки
          ...style,
        }}
      >
        {(label || param || icon) && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "4px",
              }}
            >
              {label && (
                <h5
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <span>{label}</span>
                  {/* <span>{!children && renderExecuteIcon()}</span> */}
                </h5>
              )}
              {param && <p className="p-light">{param}</p>}
            </div>
            {icon && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  aspectRatio: "1/1",
                  width: "48px",
                  height: "48px",
                }}
              >
                {icon}
              </div>
            )}
          </div>
        )}

        {children && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            {children}
          </div>
        )}

        {/* Иконка в нижнем правом углу в зависимости от типа execute */}
        {execute && (
          <div
            style={{
              position: "absolute",
              bottom: "16px",
              right: "16px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "var(--color-black)",
              fontSize: "20px",
            }}
          >
            {children && renderExecuteIcon()}
          </div>
        )}
      </div>

      {/* Popup для отображения компонента, если execute - это компонент */}
      {PopupComponent && (
        <PopupDirrectory
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          title={popupTitle}
        >
          {React.isValidElement(PopupComponent) ? (
            PopupComponent
          ) : (
            <PopupComponent />
          )}
        </PopupDirrectory>
      )}
    </>
  );
};

export default ContentBoxV4;

// Пример использования для создания карточки пульса:
/*
import React from 'react';
import ContentBoxV4 from './ContentBoxV4';

const HeartRateCard = () => {
  // Иконка пульса
  const heartIcon = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 20L10.695 18.823C6.06 14.67 3 11.88 3 8.4C3 5.61 5.253 3.33 8.1 3.33C9.68 3.33 11.175 4.097 12 5.272C12.825 4.097 14.32 3.33 15.9 3.33C18.747 3.33 21 5.61 21 8.4C21 11.88 17.94 14.67 13.305 18.823L12 20Z" stroke="#000000" strokeWidth="1.5" />
      <path d="M3.5 12H6.5L9 14L15 8L20.5 12" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );

  return (
    <ContentBoxV4
      label="HEART RATE"
      param="Sun"
      icon={heartIcon}
      style={{ maxWidth: '300px', margin: '0 auto' }}
    >
      <div>
        <p style={{ 
          fontSize: '36px', 
          fontWeight: 'bold', 
          margin: '0',
          color: '#333333'
        }}>
          98
        </p>
        <p style={{ 
          fontSize: '14px', 
          color: '#888888', 
          margin: '0',
          fontWeight: '500'
        }}>
          BPM
        </p>
      </div>
    </ContentBoxV4>
  );
};

export default HeartRateCard;
*/
