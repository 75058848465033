import React, { useState, useEffect } from "react";
import "../profile.css";
import AddData from "../../../../UIComponents/AddData/AddData";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowEditEmail from "./WindowEditEmail";
import WindowEditPhone from "./WindowEditPhone";
import WindowEditPhoneModeration from "../Moderation/WindowEditPhoneModeration";
import WindowEditEmailModeration from "../Moderation/WindowEditEmailModeration";

const EmailList = ({ emailAddresses }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {emailAddresses?.map((emailObject, index) => (
        <p key={index}>{emailObject?.email}</p>
      ))}
    </div>
  );
};

// Функция для форматирования номера телефона
const formatPhoneNumber = (phoneNumber) => {
  // Если номер уже отформатирован (содержит displayPhone), возвращаем его
  if (phoneNumber.displayPhone) {
    return phoneNumber.displayPhone;
  }

  // Если номер не содержит цифр или пустой, возвращаем исходное значение
  if (!phoneNumber.phone || phoneNumber.phone.length === 0) {
    return phoneNumber.phone || "No phone number";
  }

  // Получаем только цифры
  const digits = phoneNumber.phone.replace(/\D/g, "");

  // Форматируем номер в зависимости от количества цифр
  if (digits.length <= 1) {
    // Только код страны начинается
    return `+${digits}`;
  } else if (digits.length <= 3) {
    // Код страны (1-3 цифры)
    return `+${digits}`;
  } else if (digits.length <= 6) {
    // Код страны + начало номера
    const countryCode = digits.substring(0, 1);
    const areaCode = digits.substring(1);
    return `+${countryCode} ${areaCode}`;
  } else if (digits.length <= 10) {
    // Стандартный формат для многих стран
    const countryCode = digits.substring(0, 1);
    const areaCode = digits.substring(1, 4);
    const firstPart = digits.substring(4, 7);
    const lastPart = digits.substring(7);
    return `+${countryCode} ${areaCode} ${firstPart}${
      lastPart ? " " + lastPart : ""
    }`;
  } else {
    // Более длинные номера
    const countryCode = digits.substring(0, 1);
    const areaCode = digits.substring(1, 4);
    const firstPart = digits.substring(4, 7);
    const secondPart = digits.substring(7, 11);
    const lastPart = digits.substring(11);
    return `+${countryCode} ${areaCode} ${firstPart} ${secondPart}${
      lastPart ? " " + lastPart : ""
    }`;
  }
};

const PhonesList = ({ phoneNumbers }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {phoneNumbers?.map((phoneObject, index) => (
        <p key={index}>{formatPhoneNumber(phoneObject)}</p>
      ))}
    </div>
  );
};

const WidgetRentalPointContact = ({
  mode,
  contactsEmail,
  contactsPhone,
  isChangeState,
}) => {
  // const [isOpenEmail, setIsOpenEmail] = useState(false);
  // const [isOpenPhone, setIsOpenPhone] = useState(false);
  const [isOpenEmailModeration, setIsOpenEmailModeration] = useState(false);
  const [isOpenPhoneModeration, setIsOpenPhoneModeration] = useState(false);
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [phoneNumbers, srtPhoneNumbers] = useState([]);
  const [isChangeStatePhone, setIsChangeStatePhone] = useState(false);
  const [isChangeStateEmail, setIsChangeStateEmail] = useState(false);

  useEffect(() => {
    isChangeState(isChangeStatePhone || isChangeStateEmail);
  }, [isChangeStatePhone, isChangeStateEmail, isChangeState]);

  useEffect(() => {
    setEmailAddresses(contactsEmail || []);
    srtPhoneNumbers(contactsPhone || []);
  }, [contactsEmail, contactsPhone]);

  const handleClose = () => {
    // setIsOpenEmail(false);
    // setIsOpenPhone(false);
    setIsOpenEmailModeration(false);
    setIsOpenPhoneModeration(false);
  };
  // const handleToggleEmail = () => {
  //   if (mode === "new" || mode === "edit") {
  //     // setIsOpenEmail(true);
  //   } else if (mode === "moderation" || mode === "moderatorAdded") {
  //     setIsOpenEmailModeration(true);
  //   }
  // };

  // const handleTogglePhone = () => {
  //   if (mode === "new" || mode === "edit") {
  //     // setIsOpenPhone(true);
  //   } else if (mode === "moderation" || mode === "moderatorAdded") {
  //     setIsOpenPhoneModeration(true);
  //   }
  // };
  const handleToggleEmail = () => {
    setIsOpenEmailModeration(true);
  };

  const handleTogglePhone = () => {
    setIsOpenPhoneModeration(true);
  };

  return (
    <>
      {/* POPUP WINDOW */}
      {/* <PopupDirrectory
        isOpen={isOpenEmail}
        onClose={handleClose}
        title="Edit emails"
      >
        <WindowEditEmail mode={mode} onClose={handleClose} />
      </PopupDirrectory> */}

      {/* <PopupDirrectory
        isOpen={isOpenPhone}
        onClose={handleClose}
        title="Edit phones"
      >
        <WindowEditPhone mode={mode} onClose={handleClose} />
      </PopupDirrectory> */}

      <PopupDirrectory
        isOpen={isOpenPhoneModeration}
        onClose={handleClose}
        title="Edit phones Moderation"
      >
        <WindowEditPhoneModeration
          mode={mode}
          onClose={handleClose}
          isChangeState={setIsChangeStatePhone}
        />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={isOpenEmailModeration}
        onClose={handleClose}
        title="Edit elails Moderation"
      >
        <WindowEditEmailModeration
          mode={mode}
          onClose={handleClose}
          isChangeState={setIsChangeStateEmail}
        />
      </PopupDirrectory>
      {/* POPUP WINDOW */}
      <div className="profile-widget__content-box">
        <AddData
          icon={
            emailAddresses.length > 0 ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={`${
            emailAddresses.length > 0 ? "Contact emails:" : "Add email"
          }`}
          description={
            emailAddresses.length > 0 ? (
              <EmailList emailAddresses={emailAddresses} />
            ) : (
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            )
          }
          onClick={handleToggleEmail}
        />
        <AddData
          icon={
            phoneNumbers.length > 0 ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={`${phoneNumbers.length > 0 ? "Contact phones:" : "Add phone"}`}
          description={
            phoneNumbers.length > 0 ? (
              <PhonesList phoneNumbers={phoneNumbers} />
            ) : (
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            )
          }
          onClick={handleTogglePhone}
        />
      </div>
    </>
  );
};

export default WidgetRentalPointContact;
