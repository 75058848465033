import React, { useEffect, useState } from "react";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import Button from "../../../../UIComponents/Buttons/Button";
import Counter from "../../../../UIComponents/Counter/Counter";
import { useDispatch, useSelector } from "react-redux";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";

const WindowOrderQuantityBikesV4 = ({ onClose }) => {
  const dispatch = useDispatch();

  const { tempData } = useSelector((state) => state.catalogSliceV4);

  const [countBikes, setCountBikes] = useState(1);
  const [rootFilterData, setRootFilterData] = useState(null);
  const [newRootFilterData, setNewRootFilterData] = useState(null);

  // Инициализация данных из Redux
  useEffect(() => {
    const initialData = tempData?.manage_fdiltersBikes || {};
    setRootFilterData(initialData);
    setCountBikes(initialData.quantityBikes || 1);
  }, [tempData?.manage_fdiltersBikes]);

  // Обновление newRootFilterData при изменении rootFilterData или countBikes
  useEffect(() => {
    setNewRootFilterData({
      ...rootFilterData,
      quantityBikes: countBikes,
    });
  }, [rootFilterData, countBikes]);

  const handleApplyButton = () => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "manage_fdiltersBikes",
        data: newRootFilterData,
      })
    );
    onClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <Counter
            minValue={1}
            maxValue={100}
            value={countBikes}
            onChange={(newCount) => {
              // Только обновление состояния
              if (newCount !== countBikes) {
                setCountBikes(newCount);
              }
            }}
            label="Quantity bikes"
            description="Cabbage soup available"
          />
        </ContentBox>
      </div>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            onClick={onClose}
            color="--color-black"
          />

          <Button
            type="small"
            label="Apply"
            active={true}
            onClick={handleApplyButton}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowOrderQuantityBikesV4;

// import React, { useEffect, useState } from "react";
// import ContentBox from "../WidgetsProfile/ContentBox/ContentBox";
// import ButtonsBar from "../WidgetsProfile/ButtonsBar/ButtonsBar";
// import Button from "../WidgetsProfile/Buttons/Button";
// import WindowCalendar from "../WidgetsProfile/Date/WindowCalendar";
// import Counter from "../WidgetsProfile/Counter/Counter";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   setCurrentRootData,
//   setTempData,
// } from "../../../../../reducersToolkit/catalogSliceV4";

// const WindowOrderQuantityBikesV4 = ({ onClose }) => {
//   const dispatch = useDispatch();

//   const {
//     loading,
//     previewNewData,
//     rootNewData,
//     tempPreviewData,
//     tempRootData,
//     tempData,
//     bikes,
//   } = useSelector((state) => state.catalogSliceV4);

//   const [countBikes, setCountBikes] = useState(1);
//   console.log("Selected Brand changed 100", countBikes);

//   const [rootFilterData, setRootFilterData] = useState(null);
//   const [newRootFilterData, setNewRootFilterData] = useState(null);
//   /**
//    *
//    * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
//    *
//    */

//   useEffect(() => {
//     setRootFilterData(tempData?.manage_fdiltersBikes || null);
//     setCountBikes(tempData?.manage_fdiltersBikes?.quantityBikes || 1);
//   }, [tempData.manage_fdiltersBikes]);

//   /**
//    *
//    * Формирование объектов root и preview
//    *
//    */

//   useEffect(() => {
//     setNewRootFilterData({
//       ...rootFilterData,
//       quantityBikes: countBikes,
//     });
//   }, [rootFilterData, countBikes]);

//   const handleApplyButton = () => {
//     dispatch(
//       setTempData({
//         tempCurrentDocumentState: "manage_fdiltersBikes",
//         data: newRootFilterData,
//       })
//     );
//     onClose();
//   };

//   /**
//    *
//    * Внутренняя логика компонента
//    *
//    */

//   const onChange = (newCount) => {
//     setCountBikes(newCount);
//   };
//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           gap: "8px",
//           paddingBottom: "72px",
//         }}
//       >
//         <ContentBox position="start">
//           <Counter
//             minValue={1}
//             maxValue={100}
//             value={countBikes}
//             onChange={(newCount) => onChange(newCount)}
//             label={`Quntity bikes`}
//             description={`Cabbage soup available`}
//           />
//         </ContentBox>
//       </div>

//       <ButtonsBar>
//         <div
//           style={{
//             width: "100%",
//             display: "grid",
//             gridTemplateColumns: "repeat(2, 1fr)",
//             alignItems: "flex-start",
//             gap: "8px",
//           }}
//         >
//           <Button
//             type="small"
//             label="Cancel"
//             active={true}
//             onClick={onClose}
//             color="--color-black"
//           />

//           <Button
//             type="small"
//             label="Aplay"
//             active={true}
//             onClick={() => handleApplyButton()}
//             // allert={
//             //   <WidgetAlert
//             //     title={`Are you sure you want to add the page to the directory?`}
//             //   />
//             // }
//           />
//         </div>
//       </ButtonsBar>
//     </>
//   );
// };

// export default WindowOrderQuantityBikesV4;
