import React, { useEffect, useState, useRef, useCallback } from "react";
// Удаляем импорты ContextMenu и ContextMenuTest
// import ContextMenu from "../ContentBox/ContextMenu";
// import ContextMenuTest from "../ContentBox/ContextMenuTest";

// --- Стили ---
const buttonBaseStyle = {
  backgroundColor: "transparent",
  height: "48px",
  borderRadius: "12px",
  border: "0",
  // cursor: "pointer", // Управляется через disabled и isActive
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "0 8px",
  color: "var(--color-gray-700)",
  transition: "color 0.3s ease-in-out, background-color 0.2s ease-out",
  zIndex: 2,
  position: "relative",
  flex: "0 0 auto",
  boxSizing: "border-box",
  margin: 0,
  outline: "none",
};

const buttonSelectedTextStyle = {
  color: "var(--color-black)",
};

const markerStyleBase = {
  position: "absolute",
  top: "4px",
  bottom: "4px",
  left: "0",
  height: "48px",
  backgroundColor: "var(--color-white)",
  borderRadius: "12px",
  // boxShadow: "0 1px 3px rgba(0,0,0,0.05), 0 1px 2px rgba(0,0,0,0.1)",
  zIndex: 1,
  transition:
    "transform 0.3s ease-in-out, width 0.3s ease-in-out, background-color 0.2s ease-out",
  willChange: "transform, width",
  opacity: 0,
  width: 0,
};

// Стиль для общего контейнера в неактивном состоянии
const disabledContainerStyle = {
  opacity: 0.6,
  cursor: "not-allowed",
};
// --- /Стили ---

const SegmentedControls = ({
  isActive = true, // По умолчанию компонент активен
  options,
  // Удаляем параметры, связанные с контекстным меню
  // contextMenuOptions,
  // onSelectContextMenu,
  onSelect = null,
  label,
  selected,
  description,
}) => {
  // Удаляем вызов onSelectContextMenu
  // onSelectContextMenu2("deleted");

  // --- Состояния ---
  const [selectedOption, setSelectedOption] = useState(onSelect);
  const [markerStyle, setMarkerStyle] = useState(markerStyleBase);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(false);

  // Удаляем состояния, связанные с контекстным меню
  // const [showContextMenu, setShowContextMenu] = useState(false);
  // const [contextMenuSelectedOption, setContextMenuSelectedOption] = useState(null);
  // const [test, setTest] = useState(null);
  // console.log("SegmentedControls test 100500", test);

  // --- Рефы ---
  const controlsRef = useRef(null);
  const containerRef = useRef(null);
  // Удаляем реф для кнопки контекстного меню
  // const moreButtonRef = useRef(null);
  const isDragging = useRef(false);
  const startX = useRef(0);
  const scrollLeftStart = useRef(0);
  const lastClientX = useRef(0);
  const velocityTracker = useRef([]);
  const lastTimestamp = useRef(0);
  const animationRef = useRef(null);
  const resizeObserver = useRef(null);

  // --- Расчеты ---
  const isDisabled = !isActive; // Вспомогательная переменная для удобства
  const getButtonWidth = useCallback(() => {
    const numOptions = options?.length || 0;
    if (numOptions === 0) return "0%";
    if (numOptions === 1) return "100%";
    if (numOptions === 2) return "50%";
    return "33.33%"; // Для >= 3 опций
  }, [options]);

  const buttonWidth = getButtonWidth();
  const isSwipeable = options && options.length > 3;
  // Удаляем проверку наличия контекстного меню
  // const hasContextMenu = contextMenuOptions && contextMenuOptions.length > 0;

  // --- Функции ---

  // Обновление маркера
  const updateMarkerPosition = useCallback(() => {
    if (!controlsRef.current || !selectedOption) {
      setMarkerStyle((prev) => ({ ...prev, opacity: 0, width: 0 }));
      return;
    }
    const selectedButton = controlsRef.current.querySelector(
      `[data-option-title="${CSS.escape(selectedOption)}"]`
    );
    if (selectedButton) {
      const { offsetLeft, offsetWidth } = selectedButton;
      if (offsetWidth > 0) {
        setMarkerStyle((prev) => ({
          ...prev, // Берем предыдущее состояние стиля (важно для transition)
          opacity: 1,
          width: `${offsetWidth}px`,
          transform: `translateX(${offsetLeft}px)`,
          backgroundColor: isDisabled
            ? "var(--color-gray-200)"
            : "var(--color-white)", // Меняем цвет фона маркера
        }));
      } else {
        // Если ширина 0 (например, при инициализации), пробуем обновить в следующем кадре
        requestAnimationFrame(() => updateMarkerPosition());
      }
    } else {
      // Если кнопка не найдена (например, selectedOption сброшен в null)
      setMarkerStyle((prev) => ({ ...prev, opacity: 0, width: 0 }));
    }
  }, [selectedOption, isDisabled]); // Зависим от selectedOption и isDisabled

  // Функция проверки стрелок
  const checkArrows = useCallback(() => {
    if (!controlsRef.current) return;
    const { scrollLeft, scrollWidth, clientWidth } = controlsRef.current;
    const epsilon = 1;
    setShowLeftArrow(scrollLeft > epsilon);
    setShowRightArrow(scrollLeft < scrollWidth - clientWidth - epsilon);
  }, []);

  // Общий обработчик изменений скролла/размера
  const handleLayoutChange = useCallback(() => {
    if (!controlsRef.current) return;
    checkArrows();
    updateMarkerPosition();
  }, [checkArrows, updateMarkerPosition]);

  // Выбор опции
  const handleOptionChange = useCallback(
    (option) => {
      if (isDisabled) return; // Проверка неактивности
      const optionTitle = typeof option === "string" ? option : option.title;
      setSelectedOption(optionTitle);
      if (selected) {
        selected(optionTitle);
      }
      // Прокрутка к элементу
      const buttonElement = controlsRef.current?.querySelector(
        `[data-option-title="${CSS.escape(optionTitle)}"]`
      );
      if (buttonElement && controlsRef.current) {
        const { offsetLeft, offsetWidth } = buttonElement;
        const { scrollLeft, clientWidth } = controlsRef.current;
        const scrollPadding = 4;
        const elementLeftEdge = offsetLeft - scrollPadding;
        const elementRightEdge = offsetLeft + offsetWidth + scrollPadding;
        let targetScrollLeft = scrollLeft;
        if (elementLeftEdge < scrollLeft) {
          targetScrollLeft = elementLeftEdge;
        } else if (elementRightEdge > scrollLeft + clientWidth) {
          targetScrollLeft = elementRightEdge - clientWidth;
        }
        if (Math.abs(targetScrollLeft - scrollLeft) > 1) {
          controlsRef.current.scrollTo({
            left: targetScrollLeft,
            behavior: "smooth",
          });
        }
      }
    },
    [selected, isDisabled]
  ); // Добавили isDisabled

  // Удаляем обработчики для контекстного меню
  // const handleContextMenuClick = useCallback(...);
  // const handleContextMenuSelect = useCallback(...);
  // const handleContextMenuCancel = useCallback(...);

  // --- Обработчики Скролла и Свайпа ---
  const applyInertia = useCallback(() => {
    if (!controlsRef.current || velocityTracker.current.length === 0) {
      animationRef.current = null;
      return;
    }
    const avgVelocity =
      velocityTracker.current.reduce((sum, v) => sum + v, 0) /
      velocityTracker.current.length;
    velocityTracker.current = [avgVelocity * 0.95]; // Демпфирование
    if (Math.abs(velocityTracker.current[0]) < 0.5) {
      velocityTracker.current = [];
      animationRef.current = null;
      return;
    } // Остановка
    controlsRef.current.scrollLeft -= velocityTracker.current[0];
    animationRef.current = requestAnimationFrame(applyInertia); // Продолжение
  }, []);

  const scrollStep = useCallback(
    () => (controlsRef.current ? controlsRef.current.clientWidth * 0.8 : 300),
    []
  );

  const scrollLeft = useCallback(() => {
    if (isDisabled || !controlsRef.current) return; // Проверка неактивности
    const newScrollLeft = controlsRef.current.scrollLeft - scrollStep();
    controlsRef.current.scrollTo({
      left: Math.max(0, newScrollLeft),
      behavior: "smooth",
    });
  }, [scrollStep, isDisabled]); // Добавили isDisabled

  const scrollRight = useCallback(() => {
    if (isDisabled || !controlsRef.current) return; // Проверка неактивности
    const maxScroll =
      controlsRef.current.scrollWidth - controlsRef.current.clientWidth;
    const newScrollLeft = controlsRef.current.scrollLeft + scrollStep();
    controlsRef.current.scrollTo({
      left: Math.min(maxScroll, newScrollLeft),
      behavior: "smooth",
    });
  }, [scrollStep, isDisabled]); // Добавили isDisabled

  // Touch Handlers
  const handleTouchStart = useCallback(
    (e) => {
      if (isDisabled || isDragging.current || !controlsRef.current) return; // Проверка неактивности
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
      isDragging.current = true;
      startX.current = e.touches[0].clientX;
      lastClientX.current = startX.current;
      scrollLeftStart.current = controlsRef.current.scrollLeft;
      lastTimestamp.current = performance.now();
      velocityTracker.current = [];
    },
    [isDisabled]
  ); // Добавили isDisabled

  const handleTouchMove = useCallback((e) => {
    if (!isDragging.current || !controlsRef.current) return; // Неактивность проверяется в handleTouchStart
    const now = performance.now();
    const elapsed = now - lastTimestamp.current;
    const currentX = e.touches[0].clientX;
    const deltaX = currentX - lastClientX.current;
    if (elapsed > 10) {
      const velocity = (deltaX / elapsed) * 16.7;
      velocityTracker.current.push(velocity);
      if (velocityTracker.current.length > 5) velocityTracker.current.shift();
      lastTimestamp.current = now;
    }
    lastClientX.current = currentX;
    const newScrollLeft = scrollLeftStart.current - (currentX - startX.current);
    controlsRef.current.scrollLeft = newScrollLeft;
  }, []);

  const handleTouchEnd = useCallback(() => {
    if (!isDragging.current) return; // Неактивность проверяется в handleTouchStart
    isDragging.current = false;
    if (velocityTracker.current.length > 0) {
      animationRef.current = requestAnimationFrame(applyInertia);
    }
  }, [applyInertia]);

  // Mouse Handlers
  const handleMouseMove = useCallback((e) => {
    if (!isDragging.current || !controlsRef.current) return; // Неактивность проверяется в handleMouseDown
    const now = performance.now();
    const elapsed = now - lastTimestamp.current;
    const currentX = e.clientX;
    const deltaX = currentX - lastClientX.current;
    if (elapsed > 10) {
      const velocity = (deltaX / elapsed) * 16.7;
      velocityTracker.current.push(velocity);
      if (velocityTracker.current.length > 5) velocityTracker.current.shift();
      lastTimestamp.current = now;
    }
    lastClientX.current = currentX;
    const newScrollLeft = scrollLeftStart.current - (currentX - startX.current);
    controlsRef.current.scrollLeft = newScrollLeft;
  }, []);

  const handleMouseUp = useCallback(() => {
    if (!isDragging.current) return; // Неактивность проверяется в handleMouseDown
    isDragging.current = false;
    document.body.style.cursor = "";
    document.body.style.userSelect = "";
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
    if (velocityTracker.current.length > 0) {
      animationRef.current = requestAnimationFrame(applyInertia);
    }
  }, [handleMouseMove, applyInertia]);

  const handleMouseDown = useCallback(
    (e) => {
      if (isDisabled || e.button !== 0 || !controlsRef.current) return; // Проверка неактивности
      e.preventDefault();
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
        animationRef.current = null;
      }
      isDragging.current = true;
      startX.current = e.clientX;
      lastClientX.current = startX.current;
      scrollLeftStart.current = controlsRef.current.scrollLeft;
      lastTimestamp.current = performance.now();
      velocityTracker.current = [];
      document.body.style.cursor = "grabbing";
      document.body.style.userSelect = "none";
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    },
    [handleMouseMove, handleMouseUp, isDisabled]
  ); // Добавили isDisabled

  // --- Эффекты ---
  useEffect(() => {
    setSelectedOption(onSelect);
  }, [onSelect]);

  useEffect(() => {
    // Обновляем маркер при изменении активности
    updateMarkerPosition();

    const timer = setTimeout(() => {
      handleLayoutChange();
    }, 50);
    const currentControlsRef = controlsRef.current;
    if (currentControlsRef && "ResizeObserver" in window) {
      resizeObserver.current = new ResizeObserver(handleLayoutChange);
      resizeObserver.current.observe(currentControlsRef);
      Array.from(currentControlsRef.children).forEach((child) => {
        if (child.dataset.optionTitle) {
          resizeObserver.current.observe(child);
        }
      });
    }
    if (currentControlsRef) {
      currentControlsRef.addEventListener("scroll", handleLayoutChange, {
        passive: true,
      });
    }
    return () => {
      clearTimeout(timer);
      if (currentControlsRef) {
        currentControlsRef.removeEventListener("scroll", handleLayoutChange);
      }
      if (resizeObserver.current) {
        resizeObserver.current.disconnect();
      }
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.cursor = "";
      document.body.style.userSelect = "";
    };
    // Зависимости включают updateMarkerPosition, которая теперь зависит от isDisabled
  }, [
    options,
    handleLayoutChange,
    handleMouseMove,
    handleMouseUp,
    isDisabled,
    updateMarkerPosition,
  ]);

  // Удаляем эффекты для контекстного меню
  // useEffect(() => {
  //   const handleClickOutside = (event) => {...}
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [showContextMenu]);

  // useEffect(() => {
  //   const handleCloseOnEvent = () => {...}
  //   window.addEventListener("scroll", handleCloseOnEvent, { passive: true });
  //   window.addEventListener("resize", handleCloseOnEvent);
  //   return () => {
  //     window.removeEventListener("scroll", handleCloseOnEvent);
  //     window.removeEventListener("resize", handleCloseOnEvent);
  //   };
  // }, [showContextMenu]);

  // --- Рендеринг ---
  const renderButtons = () =>
    options.map((option) => {
      const optionTitle = typeof option === "string" ? option : option.title;
      const optionComponent =
        typeof option === "string" ? null : option.additions;
      const isSelected = optionTitle === selectedOption;
      return (
        <button
          data-option-title={optionTitle}
          style={{
            ...buttonBaseStyle,
            width: buttonWidth,
            flexShrink: 0,
            ...(isSelected && !isDisabled ? buttonSelectedTextStyle : {}), // Стиль выбранного текста только если активно
            cursor: isDisabled ? "not-allowed" : "pointer",
            color: isDisabled
              ? "var(--color-gray-500)"
              : isSelected
              ? "var(--color-black)"
              : "var(--color-gray-700)", // Цвет текста
          }}
          key={optionTitle}
          onClick={() => handleOptionChange(option)}
          title={optionTitle}
          disabled={isDisabled} // Атрибут disabled
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              textTransform: "capitalize",
              gap: "6px",
            }}
          >
            {optionTitle} {optionComponent}
          </span>
        </button>
      );
    });

  const currentOptionData = options?.find(
    (option) =>
      (typeof option === "string" ? option : option?.title) === selectedOption
  );
  const currentDescription =
    typeof currentOptionData !== "string" && currentOptionData?.description
      ? currentOptionData.description
      : description;

  // --- JSX ---
  if (!options || options.length === 0) return null;

  if (options.length === 1) {
    const singleOptionTitle =
      typeof options[0] === "string" ? options[0] : options[0].title;
    const singleOptionDesc =
      typeof options[0] !== "string" && options[0].description
        ? options[0].description
        : description;
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          ...(isDisabled && disabledContainerStyle),
        }}
      >
        {label && <h6 style={{ marginBottom: 0 }}>{label}</h6>}
        <div
          style={{
            backgroundColor: "var(--color-gray-100)",
            width: "100%",
            height: "56px",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              ...buttonBaseStyle,
              width: "100%",
              color: isDisabled
                ? "var(--color-gray-500)"
                : "var(--color-black)",
              cursor: "default",
              height: "48px",
              backgroundColor: isDisabled
                ? "var(--color-gray-200)"
                : "var(--color-white)",
              borderRadius: "12px",
            }}
          >
            <span style={{ textTransform: "capitalize" }}>
              {singleOptionTitle}
            </span>
            {typeof options[0] !== "string" && options[0].additions}
          </div>
        </div>
        {singleOptionDesc && (
          <p style={{ width: "70%" }} className="p-light">
            {singleOptionDesc}
          </p>
        )}
      </div>
    );
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        ...(isDisabled && disabledContainerStyle),
      }}
    >
      {label && <h6 style={{ marginBottom: 0 }}>{label}</h6>}
      <div
        ref={containerRef}
        style={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
          display: "flex",
          alignItems: "center",
          cursor: isDisabled ? "not-allowed" : "default",
        }}
      >
        {/* Стрелка влево */}
        {isSwipeable && (
          <button
            onClick={scrollLeft}
            aria-label="Scroll left"
            style={{
              position: "absolute",
              left: "8px",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 3,
              backgroundColor: "var(--color-gray-200)",
              border: "none",
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              opacity: showLeftArrow ? 1 : 0,
              pointerEvents: showLeftArrow && isActive ? "auto" : "none",
              cursor: isDisabled ? "not-allowed" : "pointer",
              transition: "opacity 0.2s ease-in-out",
            }}
            disabled={isDisabled}
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "20px", display: "flex" }}
            >
              chevron_left
            </span>
          </button>
        )}

        {/* Главный контейнер */}
        <div
          ref={controlsRef}
          style={{
            position: "relative",
            display: "flex",
            overflowX: isSwipeable ? "auto" : "hidden",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
            WebkitOverflowScrolling: "touch",
            touchAction: isDisabled ? "none" : "pan-x", // Отключаем свайп если неактивно
            backgroundColor: "var(--color-gray-100)",
            width: "100%",
            height: "56px",
            boxSizing: "border-box",
            borderRadius: "16px",
            flex: "1 1 auto",
            padding: "4px",
            zIndex: 0,
            cursor: isDisabled
              ? "not-allowed"
              : isDragging.current
              ? "grabbing"
              : isSwipeable
              ? "grab"
              : "default",
            userSelect: isDisabled ? "none" : "auto", // Разрешаем выделение текста если нужно, когда неактивно? Обычно none
            willChange: "scroll-position",
            paddingLeft: isSwipeable ? "40px" : "4px",
            paddingRight: isSwipeable ? "40px" : "4px",
          }}
          onMouseDown={isSwipeable ? handleMouseDown : undefined}
          onTouchStart={isSwipeable ? handleTouchStart : undefined}
          onTouchMove={isSwipeable ? handleTouchMove : undefined}
          onTouchEnd={isSwipeable ? handleTouchEnd : undefined}
        >
          {/* Маркер */}
          <div
            style={{
              ...markerStyle,
              backgroundColor: isDisabled
                ? "var(--color-gray-200)"
                : "var(--color-white)",
            }}
          />
          {/* Кнопки */}
          {renderButtons()}
        </div>

        {/* Удаляем кнопку контекстного меню */}
        {/* {hasContextMenu && (
          <button>...</button>
        )} */}

        {/* Стрелка вправо */}
        {isSwipeable && (
          <button
            onClick={scrollRight}
            aria-label="Scroll right"
            style={{
              position: "absolute",
              right: "8px", // Убираем проверку hasContextMenu
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 3,
              backgroundColor: "var(--color-gray-200)",
              border: "none",
              borderRadius: "50%",
              width: "32px",
              height: "32px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: 0,
              opacity: showRightArrow ? 1 : 0,
              pointerEvents: showRightArrow && isActive ? "auto" : "none",
              cursor: isDisabled ? "not-allowed" : "pointer",
              transition: "opacity 0.2s ease-in-out",
            }}
            disabled={isDisabled}
          >
            <span
              className="material-symbols-outlined"
              style={{ fontSize: "20px", display: "flex" }}
            >
              chevron_right
            </span>
          </button>
        )}

        {/* Удаляем контекстное меню */}
        {/* <ContextMenuTest /> */}
      </div>

      {/* Description */}
      {currentDescription && (
        <div style={{ width: "100%", marginTop: "-8px" }}>
          {" "}
          <p style={{ width: "70%", margin: 0 }} className="p-light">
            {" "}
            {currentDescription}{" "}
          </p>{" "}
        </div>
      )}
    </div>
  );
};

export default SegmentedControls;
