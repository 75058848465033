import React from "react";
// import { useEffect, useState } from "react";
// import useIsMobile from "../../../../../functions/isMobile";
// import AddData from "../../../../UIComponents/AddData/AddData";
// import AnimatedDiv from "../../../../UIComponents/Animation/AnimatedDiv";
// import Divide from "../../../../UIComponents/Divide/Divide";
import SkeletonAddData from "../../../../UIComponents/AddData/SkeletonAddData";

// const SkeletonItem = ({ key }) => {
//   return (
//     <div
//       key={key}
//       style={{
//         width: "100%",
//         height: "100px",
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "flex-start",
//         justifyContent: "center",
//         gap: "8px",
//         borderRadius: "16px",
//         overflow: "hidden",
//       }}
//     >
//       <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
//     </div>
//   );
// };

const SkeletonRentalPointContacts = () => {
  // const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        boxSizing: "border-box",
      }}
    >
      <SkeletonAddData />
      <SkeletonAddData />
    </div>
  );
};

export default SkeletonRentalPointContacts;
