import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { fetchRootDataThunkV4 } from "./fetchRootDataThunkV4";
import handleThunkError from "../../functions/handleThunkError";

export const emailSignInThunkV4 = createAsyncThunk(
  "reducersToolkit/emailSignInThunkV4",
  async ({ email, password }, { dispatch, rejectWithValue }) => {
    const auth = getAuth();

    try {
      // Аутентифицируем пользователя с помощью Firebase Auth
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );

      // Проверяем, аутентифицирован ли пользователь
      if (!userCredential.user) {
        console.error("User is not authenticated.");
        throw new Error("User is not authenticated.");
      }

      // Чтение данных пользователя из Firestore и получение ID добавленного документа
      await dispatch(
        fetchRootDataThunkV4({
          idRootDocument: userCredential.user.uid,
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "users",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          // rootCurrentDocumentState: "currentUser",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_user_auth_rootCurrentData",
          errorStateName: "error_user_auth_rootCurrentData",
        })
      );

      // Возвращаем данные пользователя вместе с ID добавленного документа
      //  return { currentUser: currentUser };
      return { userId: userCredential.user.uid };
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);
