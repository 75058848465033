import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import "../profile.css";
import { useNavigate } from "react-router-dom";
import PreviewItemCard from "../../../../UIComponents/Preview/PreviewItemCard";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import SegmentedControls from "../../../../UIComponents/SegmentedControls/SegmentedControls";
import ContextMenu from "../../../../UIComponents/ContentBox/ContextMenu";
import AddData from "../../../../UIComponents/AddData/AddData";

const RentalPointsListStatus = ({
  rentalPointsList,
  state = null,
  mode,
  contextSortFilter,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [sortedRentalPoints, setSortedRentalPoints] = useState([]);
  const [currentRentalPointItem, setCurrentRentalPointItem] = useState(null);
  const [
    isOpenWindowRentalPointDetailModeration,
    setIsOpenWindowRentalPointDetailModeration,
  ] = useState(false);

  // Функция для проверки, была ли точка проката создана не более 60 секунд назад
  const isRecentlyCreated = (created) => {
    if (!created) return false;
    const now = Date.now();
    const sixtySecondsAgo = now - 10 * 60 * 1000; // 60 секунд в миллисекундах
    return created > sixtySecondsAgo;
  };

  useEffect(() => {
    if (rentalPointsList?.length > 0) {
      let filteredList = [...rentalPointsList];

      // Применяем дополнительные фильтры из контекстного меню
      if (contextSortFilter === "Deleted") {
        // Фильтруем только удаленные точки (remove == true или 1742543629324)
        filteredList = filteredList.filter((item) => item.remove);
      }

      // Сортировка по времени
      if (contextSortFilter === "Newest First") {
        // Сначала новые (по убыванию created/updated)
        filteredList.sort((a, b) => {
          const aLatestTimestamp = Math.max(a.created || 0, a.updated || 0);
          const bLatestTimestamp = Math.max(b.created || 0, b.updated || 0);
          return bLatestTimestamp - aLatestTimestamp;
        });
      } else if (contextSortFilter === "Oldest First") {
        // Сначала старые (по возрастанию created/updated)
        filteredList.sort((a, b) => {
          const aLatestTimestamp = Math.max(a.created || 0, a.updated || 0);
          const bLatestTimestamp = Math.max(b.created || 0, b.updated || 0);
          return aLatestTimestamp - bLatestTimestamp;
        });
      } else {
        // По умолчанию (без выбора фильтра) - сортируем по убыванию времени создания/обновления
        filteredList.sort((a, b) => {
          const aLatestTimestamp = Math.max(a.created || 0, a.updated || 0);
          const bLatestTimestamp = Math.max(b.created || 0, b.updated || 0);
          return bLatestTimestamp - aLatestTimestamp;
        });
      }

      setSortedRentalPoints(filteredList);
    }
  }, [rentalPointsList, contextSortFilter]);

  const handleToggle = (data) => {
    navigate(
      `/ModerationRentalPoint/${data.idRootDocument}/${data.idRootDocumentUser}`
    );

    if (mode === "moderation") {
      dispatch(
        setTempData({
          tempCurrentDocumentState:
            "rentalPoint_moderation_selectedRentalPoint",
          data: data,
        })
      );

      setCurrentRentalPointItem(data);
      setIsOpenWindowRentalPointDetailModeration(true);
    } else if (mode === "edit") {
      setIsOpen(true);
      setCurrentRentalPointItem(data);
    }
  };

  // Фильтруем точки проката по статусу перед отображением, только если state указан
  const filteredRentalPoints = state
    ? sortedRentalPoints.filter((rentalPoint) => rentalPoint?.status === state)
    : sortedRentalPoints; // Если state не указан, используем весь отсортированный список

  return (
    filteredRentalPoints?.length > 0 && (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "2px",
          boxSizing: "border-box",
        }}
      >
        {filteredRentalPoints.map((rentalPointItem, index) => (
          <PreviewItemCard
            index={index}
            length={filteredRentalPoints.length}
            key={rentalPointItem?.idRootDocument}
            title={rentalPointItem?.title}
            description={rentalPointItem?.pointAddress}
            stateItem={rentalPointItem?.status}
            iconLeft={
              <>
                {rentalPointItem?.status === "approved" && (
                  <span className="material-symbols-outlined">
                    where_to_vote
                  </span>
                )}
                {rentalPointItem?.status === "banned" && (
                  <span className="material-symbols-outlined">
                    wrong_location
                  </span>
                )}
                {rentalPointItem?.status === "moderation" && (
                  <span className="material-symbols-outlined">fmd_bad</span>
                )}
              </>
            }
            iconLeftStyle={{
              color: rentalPointItem.remove
                ? "var(--color-alarm)"
                : "var(--color-black)",
            }}
            containerIconLeftStyle={{
              backgroundColor: isRecentlyCreated(rentalPointItem.created)
                ? "var(--color-success-alpha-30)"
                : rentalPointItem.remove
                ? "var(--color-alarm-alpha-30)"
                : "var(--color-gray-900-alpha-30)",
            }}
            // icon right
            iconRight={<span className="material-symbols-outlined">link</span>}
            iconRightStyle={{
              color: "var(--color-black)",
            }}
            containerIconRightStyle={{}}
            // container card
            containerCardStyle={{
              backgroundColor: isRecentlyCreated(rentalPointItem.created)
                ? "var(--color-success-alpha-30)"
                : rentalPointItem.remove
                ? "var(--color-alarm-alpha-30)"
                : "var(--color-gray-900-alpha-30)",
            }}
            onClick={() => handleToggle(rentalPointItem)}
            itemKey={rentalPointItem?.idRootDocument}
          />
        ))}
      </div>
    )
  );
};

const WidgetRentalPointsListV4 = ({
  rentalPointsList,
  // renalPointState = "moderation",
  mode,
  userId,
}) => {
  const [rentalPointFilterState, setRentalPointFilterState] = useState(null);
  const [contextFilterOption, setContextFilterOption] = useState(null);
  const navigate = useNavigate();

  // Обработчик выбора опции из контекстного меню
  const handleContextMenuSelect = (option) => {
    setContextFilterOption(option);
  };

  // Обработчик отмены выбора (кнопка Cancel)
  const handleContextMenuCancel = () => {
    console.log("handleContextMenuCancel: сбрасываем фильтр");
    setContextFilterOption(null);
  };

  // Обработчик для создания новой точки проката
  const handleCreateRentalPoint = () => {
    navigate(`/ModerationAddRentalPoint/${userId}`);
  };

  return (
    <>
      {/* Add Data component for creating rental points */}

      <AddData
        onClick={handleCreateRentalPoint}
        icon={<span className="material-symbols-outlined">add</span>}
        title="Create rental point"
        description="Add a new rental point for this user."
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {rentalPointsList?.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <SegmentedControls
              options={["moderation", "approved", "banned"]}
              onSelect={rentalPointFilterState}
              selected={setRentalPointFilterState}
            />

            {/* Контекстное меню с встроенной кнопкой сортировки */}
            <ContextMenu
              options={["Deleted", "Newest First", "Oldest First"]}
              onSelect={handleContextMenuSelect}
              onCancel={handleContextMenuCancel}
              selectedOption={contextFilterOption}
              title="Сортировка"
              useInternalToggle={true}
              icon={<span className="material-symbols-outlined">sort</span>}
              buttonStyle={{
                backgroundColor: contextFilterOption
                  ? "var(--color-primary)"
                  : "var(--color-gray-100)",
                color: contextFilterOption
                  ? "var(--color-white)"
                  : "var(--color-black)",
              }}
            />
          </div>
        )}
        {rentalPointFilterState ? (
          <>
            {rentalPointFilterState === "moderation" && (
              <RentalPointsListStatus
                rentalPointsList={rentalPointsList}
                mode={mode}
                state="moderation"
                contextSortFilter={contextFilterOption}
              />
            )}
            {rentalPointFilterState === "approved" && (
              <RentalPointsListStatus
                rentalPointsList={rentalPointsList}
                mode={mode}
                state="approved"
                contextSortFilter={contextFilterOption}
              />
            )}
            {rentalPointFilterState === "banned" && (
              <RentalPointsListStatus
                rentalPointsList={rentalPointsList}
                mode={mode}
                state="banned"
                contextSortFilter={contextFilterOption}
              />
            )}
          </>
        ) : (
          <RentalPointsListStatus
            rentalPointsList={rentalPointsList}
            mode={mode}
            contextSortFilter={contextFilterOption}
          />
        )}
      </div>
    </>
  );
};

export default WidgetRentalPointsListV4;
