import React, { useEffect, useState } from "react";
import AvatarUser from "../../../../UIComponents/Avatars/AvatarUser";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WidgetUploadFilesV4 from "../../../../UIComponents/UploadFiles/WindgetUploadFilesV4";
import { v4 as uuidv4 } from "uuid";

const WidgetUserAvatar = ({
  currentRootUser,
  isLoading,
  fieldName,
  iconAddphoto,
  size = 80,
  mode,
  fileUrl,
  setNewUserAvatar,
  fileUrlAvatar, // Массив объектов с файлами аватара
}) => {
  const [windowUploadFilesModeration, setWindowUploadFilesModeration] =
    useState(false);
  const [selectedAvatarUrl, setSelectedAvatarUrl] = useState(null);

  // Функция для поиска приоритетного файла или первого элемента
  const getPriorityFileUrl = (files) => {
    if (!Array.isArray(files) || files.length === 0) return null;
    const priorityFile = files.find((file) => file.priority === true);
    return priorityFile ? priorityFile.url : files[0].url;
  };

  // Обновление URL аватара при изменении fileUrlAvatar
  useEffect(() => {
    if (fileUrlAvatar) {
      const avatarUrl = getPriorityFileUrl(fileUrlAvatar);
      setSelectedAvatarUrl(avatarUrl);
    } else {
      setSelectedAvatarUrl(fileUrl || null); // Фallback на fileUrl, если fileUrlAvatar отсутствует
    }
  }, [fileUrlAvatar, fileUrl]);

  const handleToggle = () => {
    setWindowUploadFilesModeration(true);
  };

  const handleClose = () => {
    setWindowUploadFilesModeration(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          position: "relative",
        }}
      >
        <AvatarUser
          size={size}
          url={selectedAvatarUrl} // Используем выбранный URL
          isLoading={isLoading}
        />

        {/* {iconAddphoto && (
          <span
            className="material-symbols-outlined"
            onClick={handleToggle}
            style={{
              position: "absolute",
              bottom: "8px",
              cursor: "pointer",
              backgroundColor: "var(--color-white)",
              padding: "8px",
              borderRadius: "80px",
            }}
          >
            photo_camera
          </span>
        )} */}
      </div>
    </>
  );
};

export default WidgetUserAvatar;

// import React, { useEffect, useState } from "react";
// import AvatarUser from "../../../../UIComponents/Avatars/AvatarUser";
// import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
// import WidgetUploadFilesV4 from "../../../../UIComponents/UploadFiles/WindgetUploadFilesV4";
// import { v4 as uuidv4 } from "uuid";

// // import WindowUploadFilesModeration from "../Moderation/WindowUploadFilesModeration";

// const WidgetUserAvatar = ({
//   currentRootUser,
//   isLoading,
//   fieldName,
//   iconAddphoto,
//   size = 80,
//   mode,
//   fileUrl,
//   setNewUserAvatar,
//   fileUrlAvatar
// }) => {
//   const [windowUploadFilesModeration, setWindowUploadFilesModeration] =
//     useState(false);
//   // const [newUserAvatar, setNewUserAvatar] = useState(null);
//   // const [userAvatarUrl, setUserAvatarUrl] = useState([]);

//   // useEffect(() => {
//   //   const priorityFileselected = priorityFile(
//   //     currentRootUser?.files?.[fieldName] || []
//   //   );

//   //   setUserAvatarUrl(priorityFileselected?.url || null);
//   // }, [currentRootUser, fieldName]);

//   const handleToggle = () => {
//     setWindowUploadFilesModeration(true);
//   };

//   const handleClose = () => {
//     setWindowUploadFilesModeration(false);
//   };

//   return (
//     <>
//       {/* POPUP WINDOW */}
//       <PopupDirrectory
//         isOpen={windowUploadFilesModeration}
//         onClose={handleClose}
//         title="Add useer avarar Moderation"
//       >
//         {/* <WindowUploadFilesModeration onClose={handleClose} /> */}
//       </PopupDirrectory>
//       {/* POPUP WINDOW */}

//       <div
//         style={{
//           width: "100%",
//           display: "flex",
//           flexDirection: "column",
//           alignItems: "center",
//           justifyContent: "center",
//           gap: "16px",
//           position: "relative",
//         }}
//       >
//         <AvatarUser size={size} url={fileUrl} isLoading={isLoading} />

//         {iconAddphoto && (
//           <span
//             className="material-symbols-outlined"
//             onClick={handleToggle}
//             style={{
//               position: "absolute",
//               bottom: "8px",
//               cursor: "pointer",
//               backgroundColor: "var(--color-white)",
//               // backgroundColor: "violet",
//               padding: "8px",
//               borderRadius: "80px",
//             }}
//           >
//             photo_camera
//           </span>
//         )}
//       </div>
//       <div
//         style={{
//           width: "100%",
//         }}
//       >
//         <WidgetUploadFilesV4
//           // isLoading={loading}
//           id={uuidv4()}
//           title="Add user awatar"
//           description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
//           files={setNewUserAvatar} // Функция для получения файлов
//           maxWidth={800} // Максимальная ширина изображения
//           maxHeight={600} // Максимальная высота изображения
//           format="PNG" // Формат сжатия
//           quality={80} // Качество сжатия
//         />
//       </div>
//     </>
//   );
// };

// export default WidgetUserAvatar;
