import React, { useEffect, useState } from "react";
import DropdownList from "../../../../UIComponents/DropdownList/DropdownList";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import Button from "../../../../UIComponents/Buttons/Button";
import InputText from "../../../../UIComponents/Inputs/InputText";
import { fetchPreviewDataV4 } from "../../../../../reducersToolkit/thunk/fetchPreviewDataV4";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";

const WindowLocationDetailModeration = ({ mode, onClose, isChangeState }) => {
  const dispatch = useDispatch();

  // Добавляем константы для проверки режима
  const isModerationMode = mode === "moderation";
  const isModeratorAddedMode = mode === "moderatorAdded";

  const { tempPreviewData, tempRootData, tempData } = useSelector(
    (state) => state?.catalogSliceV4 || {}
  );

  const [
    rentalPoint_countriesListPreview,
    setRentalPoint_countriesListPreview,
  ] = useState([]);

  const [rentalPoint_citiesListPreview, setRentalPoint_citiesListPreview] =
    useState([]);

  const [selectLocarion, setSelectLocarion] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [address, setAddress] = useState(null);
  const [newData, setNewData] = useState(null);

  // Добавляем состояния для хранения исходных значений
  const [initialCountry, setInitialCountry] = useState(null);
  const [initialCity, setInitialCity] = useState(null);
  const [initialAddress, setInitialAddress] = useState(null);

  // Состояние для активации кнопки Apply
  const [applyButtonActive, setApplyButtonActive] = useState(false);

  // Начальная загрузка данных
  useEffect(() => {
    let locationData = null;
    if (isModerationMode) {
      locationData =
        tempData.tempData_rentalPoint_location ||
        tempRootData?.selectedBike_rentalPont?.location ||
        null;
    } else if (isModeratorAddedMode) {
      locationData = tempData.tempData_rentalPoint_location || null;
    }

    if (locationData) {
      setSelectLocarion(locationData);
      setAddress(locationData.pointAddress || null);
      setSelectedCountry(locationData.country || null);
      setSelectedCity(locationData.city || null);

      // Сохраняем исходные значения
      setInitialAddress(locationData.pointAddress || null);
      setInitialCountry(locationData.country || null);
      setInitialCity(locationData.city || null);
    }
  }, [
    mode,
    tempData.tempData_rentalPoint_location,
    tempRootData?.selectedBike_rentalPont?.location,
    isModerationMode,
    isModeratorAddedMode,
  ]);

  // Формирование нового объекта данных
  useEffect(() => {
    setNewData({
      ...selectLocarion,
      country: selectedCountry || null,
      city: selectedCity || null,
      pointAddress: address || "",
    });
  }, [selectLocarion, selectedCity, selectedCountry, address]);

  // Логика активации кнопки Apply на основе изменений
  useEffect(() => {
    // Проверяем, изменились ли данные относительно исходных значений
    const hasChanges =
      JSON.stringify(selectedCountry) !== JSON.stringify(initialCountry) ||
      JSON.stringify(selectedCity) !== JSON.stringify(initialCity) ||
      address !== initialAddress;

    // Проверяем, заполнены ли все необходимые поля
    const isComplete =
      selectedCountry && selectedCity && address && address.trim().length > 0;

    // Обновляем состояние кнопки Apply
    setApplyButtonActive(hasChanges && isComplete);
  }, [
    selectedCountry,
    selectedCity,
    address,
    initialCountry,
    initialCity,
    initialAddress,
  ]);

  // Загрузка списка стран
  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        previewCollectionPath: "countriesPreview",
        loadingStateName: "loading_rentalPoint_countriesPreview",
        errorStateName: "currentError",
        previewCurrentDocumenState: "rentalPoint_countriesListPreview",
      })
    );
  }, [dispatch]);

  // Обновление данных из tempPreviewData
  useEffect(() => {
    // Проверяем и форматируем данные стран
    const countries = tempPreviewData?.rentalPoint_countriesListPreview || [];
    console.log("Полученные страны:", countries);
    setRentalPoint_countriesListPreview(countries);

    // Проверяем и форматируем данные городов
    const cities = tempPreviewData?.rentalPoint_citiesListPreview || [];
    console.log("Полученные города:", cities);
    setRentalPoint_citiesListPreview(cities);
  }, [
    tempPreviewData?.rentalPoint_countriesListPreview,
    tempPreviewData?.rentalPoint_citiesListPreview,
  ]);

  // Обработчик выбора страны
  const handleCountrySelect = (data) => {
    console.log("Выбрана страна:", data);
    if (data?.idRootDocument !== selectedCountry?.idRootDocument) {
      setSelectedCountry(data);
      setSelectedCity(null);

      dispatch(
        fetchPreviewDataV4({
          previewCollectionPath: `citiesPreview.countryIds.${data?.idRootDocument}`,
          loadingStateName: "windowCountryDetailV4LoadingFetchPreviewCities",
          errorStateName: "currentError",
          previewCurrentDocumenState: "rentalPoint_citiesListPreview",
        })
      );
    }
  };

  // Обработчик выбора города
  const handleCitySelect = (data) => {
    console.log("Выбран город:", data);
    if (data?.idRootDocument !== selectedCity?.idRootDocument) {
      setSelectedCity(data);
    }
  };

  // Обработчик изменения адреса
  const handleSetAddress = (event) => {
    setAddress(event.target.value);
  };

  // Обработчик сохранения данных
  const handleApply = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    // Сохраняем во временные данные
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_location",
        data: newData,
      })
    );

    onClose();
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          {/* <div
            style={{
              width: "100%",
              aspectRatio: "16/9",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "16px",
              backgroundImage:
                "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2F2024-05-11_11-05-16.png?alt=media&token=abba679b-3c9a-4dee-bd49-2f6872af8eb7)",
              backgroundSize: "auto 300%",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2FselectedIconMapPointShadow.svg?alt=media&token=79f1fe50-77cf-4185-9d00-65e758d0f7fb"
              alt=""
              style={{
                width: "80px",
              }}
            />
          </div> */}

          <InputText
            label="Rental point address"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            value={address || ""}
            onChange={handleSetAddress}
          />
        </ContentBox>

        <ContentBox>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
              width: "100%",
            }}
          >
            <DropdownList
              options={rentalPoint_countriesListPreview}
              onSelect={handleCountrySelect}
              label="Select country"
              description="Please select a country."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCountry}
            />

            <DropdownList
              options={rentalPoint_citiesListPreview}
              onSelect={handleCitySelect}
              label="Select city"
              description="Please select a city."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCity}
            />
          </div>
        </ContentBox>
      </div>
      <ButtonsBar position="end">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            label={applyButtonActive ? "Save location" : "Add location"}
            active={applyButtonActive}
            onClick={handleApply}
            color="--color-primary"
            allert={
              <div
                style={{
                  padding: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>
                  {applyButtonActive
                    ? "Are you sure you want to save the location changes?"
                    : "Are you sure you want to add a location?"}
                </h1>
              </div>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowLocationDetailModeration;
