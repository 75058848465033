import React, { useEffect, useState, useMemo, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import CollapsableText from "../../UIComponents/Collapsable/CollapsableText";
import { useDispatch, useSelector } from "react-redux";
import { fetchRootDataThunkV4 } from "../../../reducersToolkit/thunk/fetchRootDataThunkV4";
import {
  setCurrentRootData,
  setTempData,
} from "../../../reducersToolkit/slice/catalogSliceV4";
import PopupDirrectory from "../../UIComponents/Popups/PopupDirrectory";
import WindowOrderDetailModeration from "../../Private/Login/Profile/OrdersV3/WindowOrderDetailModeration";
import ContentBox from "../../UIComponents/ContentBox/ContentBox";
import WidgetViewBikeDetail from "../../Private/Login/Profile/BikesV3/WidgetViewBikeDetail";
import priorityFile from "../../../functions/priorityFile";
import ButtonsBar from "../../UIComponents/ButtonsBar/ButtonsBar";
import PriorityFile from "../../UIComponents/UploadFiles/PriorityFile";
import { useLocation, useParams } from "react-router-dom";
import WindowCalendarV4 from "../../UIComponents/Date/WindowCalendarV4";
import useIsMobile from "../../../functions/isMobile";
import WindowOrderQuantityBikesV4 from "../../Private/Login/Profile/OrdersV3/WindowOrderQuantityBikesV4";
import Button from "../../UIComponents/Buttons/Button";
import WidgetSEO from "../../Private/Login/Profile/SEO/WidgetSEO";
import SEOContent from "../../UIComponents/SEOContent/SEOContent";
import SEOTags from "../../UIComponents/SEOContent/SEOTags";
import WidgetBikesList from "../../Private/Login/Profile/BikesV3/WidgetBikesList";
import WidgetOrderAdditionalServicesList from "../../Private/Login/Profile/OrdersV3/WidgetOrderAdditionalServicesList";
import PageBox from "../../UIComponents/ContentBox/PageBox";
import { fetchPreviewDataV4 } from "../../../reducersToolkit/thunk/fetchPreviewDataV4";

const truncateToThreeChars = (value) =>
  typeof value === "string" ? value.slice(0, 3) : value;

const filterBikes = (bikes, filters) => {
  return bikes.filter((bike) => {
    const { brand, category, city, country, dateInterval, quantityBikes } =
      filters;

    // Обязательные фильтры на основе текущего filters
    const matchesCountry = bike.country?.id === country?.idRootDocument;
    const matchesCity = bike.city?.id === city?.idRootDocument;
    const matchesBrand = bike.brand?.id === brand?.id;
    const matchesCategory = bike.category?.id === category?.id;

    // Фильтр по диапазону дат
    const matchesDateInterval =
      !dateInterval ||
      (bike.rate &&
        bike.rate.some((rate) => {
          const rateMonthStart = new Date(dateInterval[0]).setMonth(
            rate.month,
            1
          );
          const rateMonthEnd = new Date(rateMonthStart).setMonth(
            rate.month + 1,
            0
          );
          return (
            rate.price > 0 &&
            ((dateInterval[0] >= rateMonthStart &&
              dateInterval[0] <= rateMonthEnd) ||
              (dateInterval[1] >= rateMonthStart &&
                dateInterval[1] <= rateMonthEnd) ||
              (rateMonthStart >= dateInterval[0] &&
                rateMonthEnd <= dateInterval[1]))
          );
        }));

    // Опциональный фильтр по количеству
    const matchesQuantity = !quantityBikes || bike.quantity >= quantityBikes;

    return (
      matchesCountry &&
      matchesCity &&
      matchesBrand &&
      matchesCategory &&
      matchesDateInterval &&
      matchesQuantity
    );
  });
};

// Кастомный хук для загрузки и подписки на данные
// const useBikeData = (bikeId, mode) => {
//   const dispatch = useDispatch();

//   const { tempRootData, tempData, tempPreviewData, loading } = useSelector(
//     (state) => state.catalogSliceV4
//   );

//   // Загружаем данные о велосипеде при наличии ID
//   useEffect(() => {
//     if (bikeId) {
//       console.log("Fetching bike data for ID:", bikeId);
//       dispatch(
//         fetchRootDataThunkV4({
//           idRootDocument: bikeId,
//           rootCollectionPath: "bikes",
//           rootCurrentDocumentState: "selectedBike_rootData",
//           loadingStateName: "loading_bike",
//           errorStateName: "currentError",
//         })
//       );
//     } else {
//       console.log("No bike ID provided, skipping data fetch");
//     }
//   }, [dispatch, bikeId, mode]);

//   // Подписываемся на данные о точке проката
//   useEffect(() => {
//     const idRootDocumentRentalPoint =
//       tempRootData?.selectedBike_rootData?.directory?.rentalPoint
//         ?.idRootDocument || null;

//     if (idRootDocumentRentalPoint) {
//       const subscribeToDocument = async () => {
//         try {
//           const result = await dispatch(
//             subscribeRootDocumentsThunkV5({
//               collectionPath: "rentalPoints",
//               rootCurrentDocumenState: "tempRootData_currentBike",
//               idRootDocument: idRootDocumentRentalPoint,
//               loadingStateName: "loading_RentalPoint",
//               errorStateName: "currentError",
//             })
//           ).unwrap();
//           if (!result.subscriptionEstablished) {
//             console.error("Subscription not established");
//           }
//         } catch (error) {
//           console.error("Error subscribing to document:", error);
//         }
//       };
//       subscribeToDocument();

//       // Отписываемся при размонтировании или изменении точки проката
//       return () => {
//         console.log(
//           "Unsubscribing from rental point:",
//           idRootDocumentRentalPoint
//         );
//         dispatch(
//           unsubscribeRootDocumentsThunkV5({
//             collectionPath: "rentalPoints",
//           })
//         );
//       };
//     } else {
//       // Если нет точки проката, очищаем данные
//       dispatch(
//         setCurrentRootData({
//           rootCurrentDocumentState: "tempRootData_currentBike",
//           data: null,
//         })
//       );
//     }
//   }, [
//     dispatch,
//     tempRootData?.selectedBike_rootData?.directory?.rentalPoint?.idRootDocument,
//   ]);

//   return { tempRootData, tempData, tempPreviewData, loading };
// };

const deepEqual = (obj1, obj2) => {
  if (obj1 === obj2) return true;
  if (
    typeof obj1 !== "object" ||
    typeof obj2 !== "object" ||
    obj1 == null ||
    obj2 == null
  ) {
    return false;
  }
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);
  if (keys1.length !== keys2.length) return false;
  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }
  return true;
};

const getComputedDateInterval = (dateInterval, month) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  const monthNumber = month && !isNaN(Number(month)) ? Number(month) : null;

  if (dateInterval && !isNaN(dateInterval[0]) && !isNaN(dateInterval[1])) {
    return dateInterval;
  }

  let firstDayTimestamp, sevenDaysLaterTimestamp;
  if (monthNumber === currentMonth) {
    const firstDayOfMonth = new Date(currentYear, currentMonth, currentDay);
    firstDayTimestamp = firstDayOfMonth.setHours(0, 0, 0, 0);
    const sevenDaysLater = new Date(firstDayTimestamp);
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 6);
    sevenDaysLaterTimestamp = sevenDaysLater.setHours(23, 59, 59, 999);
  } else {
    const adjustedYear =
      monthNumber < currentMonth ? currentYear + 1 : currentYear;
    const firstDayOfMonth = new Date(adjustedYear, monthNumber || 0, 1);
    firstDayTimestamp = firstDayOfMonth.setHours(0, 0, 0, 0);
    const sevenDaysLater = new Date(firstDayTimestamp);
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 6);
    sevenDaysLaterTimestamp = sevenDaysLater.setHours(23, 59, 59, 999);
  }

  return [firstDayTimestamp, sevenDaysLaterTimestamp];
};

const PageProductCard = ({ mode, onClose, breadcrumbPath, title }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const location = useLocation();
  const { bikeId, bikeTitle } = useParams();

  // Get the idRootDocumentBike from the params

  // const { tempRootData, tempData, tempPreviewData, loading } = useBikeData(
  //   bikeId,
  //   mode
  // );
  // Получение данных из Slice
  const { tempPreviewData, tempRootData, tempData, loading } = useSelector(
    (state) => state.catalogSliceV4
  );
  /**
   * CRUD статусы
   */
  const [tempRootData_currentBike, setTempRootData_currentBike] =
    useState(null);
  /**
   * Стейты для работы со slice в пределах PAGE
   */
  const [tempData_filtersParams, setTempData_filtersParams] = useState(null);
  const [tempData_SEO, setTempData_SEO] = useState(null);
  const [tempData_currentBike, setTempData_currentBike] = useState(null);
  const [tempData_aditionalServices, setTempData_aditionalServices] =
    useState(null);
  const [tempData_rentalPointCurrentBike, setTempData_rentalPointCurrentBike] =
    useState(null);
  const [tempData_similarBikes, setTempData_similarBikes] = useState(null);
  /**
   *
   */
  const [userRole, setUserRole] = useState(null);
  // const [bikeData, setBikeData] = useState({});
  const [dateInterval, setDateInterval] = useState(() => {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setDate(today.getDate() + 6);
    return [today.getTime(), endDate.getTime()];
  });

  const [differenceInDays, setDifferenceInDays] = useState(0);
  const [quantityBikes, setQuantityBikes] = useState(1);

  /**
   * Popups
   */
  const [windowOrderDetailModeration, setWindowOrderDetailModeration] =
    useState(false);
  const [windowOrderDeteIntervalV4, setWindowOrderDeteIntervalV4] =
    useState(false);
  const [windowOrderQuantityBikesV4, setWindowOrderQuantityBikesV4] =
    useState(false);

  useEffect(() => {
    if (tempData?.tempData_filtersParams?.dateInterval) {
      setDateInterval(tempData?.tempData_filtersParams?.dateInterval);
    }
  }, [tempData?.tempData_filtersParams?.dateInterval]);

  /**
   * Налнение useState CRUD
   */
  useEffect(() => {
    if (tempRootData?.tempRootData_currentBike) {
      setTempRootData_currentBike(
        tempRootData?.tempRootData_currentBike || null
      );
    }
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
  }, [
    tempRootData?.tempRootData_currentBike,
    tempRootData?.user_auth_rootCurrentData,
  ]);

  /**
   * CRUD
   */

  // Загружаем данные о велосипеде при наличии ID
  useEffect(() => {
    if (bikeId) {
      console.log("Fetching bike data for ID:", bikeId);

      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: bikeId,
          rootCollectionPath: "bikes",
          rootCurrentDocumentState: "tempRootData_currentBike",
          loadingStateName: "loading_bike",
          errorStateName: "currentError",
        })
      );
    } else {
      console.log("No bike ID provided, skipping data fetch");
    }
  }, [dispatch, bikeId]);

  useEffect(() => {
    if (tempRootData_currentBike?.idRootDocumentRentalPoint) {
      console.log(
        "Fetching bike data for ID:",
        tempRootData_currentBike?.idRootDocumentRentalPoint
      );

      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: tempRootData_currentBike?.idRootDocumentRentalPoint,
          rootCollectionPath: "rentalPoints",
          rootCurrentDocumentState: "tempRootData_currentRentalPoint",
          loadingStateName: "loading_currentRentalPoint",
          errorStateName: "currentError",
        })
      );
    } else {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "tempRootData_currentRentalPoint",
          data: null,
        })
      );
      console.log("No bike ID provided, skipping data fetch");
    }
  }, [dispatch, tempRootData_currentBike?.idRootDocumentRentalPoint]);

  // useEffect(() => {
  //   dispatch(
  //     fetchPreviewDataV4({
  //       // Путь до коллекции указывается из НЕчетного колличества вложенностей
  //       previewCollectionPath: `general_bikesPreview`,
  //       //
  //       loadingStateName: "loading_general_bikesPreview",
  //       errorStateName: "currentError",
  //       // Сохраняет с state МАССИВ объектов как результат выполнения thunk
  //       previewCurrentDocumenState: "tempPreviewData_general_bikesPreview",
  //     })
  //   );
  // }, [dispatch]);

  // Подписываемся на данные о точке проката
  // useEffect(() => {
  //   const idRootDocumentRentalPoint =
  //     tempRootData?.selectedBike_rootData?.directory?.rentalPoint
  //       ?.idRootDocument || null;

  //   if (idRootDocumentRentalPoint) {
  //     const subscribeToDocument = async () => {
  //       try {
  //         const result = await dispatch(
  //           subscribeRootDocumentsThunkV5({
  //             collectionPath: "rentalPoints",
  //             rootCurrentDocumenState: "tempRootData_currentBike",
  //             idRootDocument: idRootDocumentRentalPoint,
  //             loadingStateName: "loading_RentalPoint",
  //             errorStateName: "currentError",
  //           })
  //         ).unwrap();
  //         if (!result.subscriptionEstablished) {
  //           console.error("Subscription not established");
  //         }
  //       } catch (error) {
  //         console.error("Error subscribing to document:", error);
  //       }
  //     };
  //     subscribeToDocument();

  //     // Отписываемся при размонтировании или изменении точки проката
  //     return () => {
  //       console.log(
  //         "Unsubscribing from rental point:",
  //         idRootDocumentRentalPoint
  //       );
  //       dispatch(
  //         unsubscribeRootDocumentsThunkV5({
  //           collectionPath: "rentalPoints",
  //         })
  //       );
  //     };
  //   } else {
  //     // Если нет точки проката, очищаем данные
  //     dispatch(
  //       setCurrentRootData({
  //         rootCurrentDocumentState: "tempRootData_currentBike",
  //         data: null,
  //       })
  //     );
  //   }
  // }, [
  //   dispatch,
  //   tempRootData?.selectedBike_rootData?.directory?.rentalPoint?.idRootDocument,
  // ]);

  // Refs
  const prevParamsRef = useRef();
  const prevDataRef = useRef();
  const prevFilterDataRef = useRef();

  // Определяем хлебные крошки по умолчанию, если не переданы из пропсов
  // const defaultBreadcrumbPath = useMemo(() => {
  //   return [
  //     { item: "Home", link: "/" },
  //     { item: "Catalog", link: "/catalog" },
  //     { item: "Bike Details" },
  //   ];
  // }, []);

  // Определяем заголовок по умолчанию, если не передан из пропсов
  const defaultTitle = useMemo(() => {
    return tempRootData_currentBike?.basicInformation?.title || "Bike Details";
  }, [tempRootData_currentBike?.basicInformation?.title]);

  const filteredBikesMemo = useMemo(() => {
    if (
      !tempPreviewData.tempPreviewData_general_bikesPreview ||
      !tempData_filtersParams
    )
      return [];
    return filterBikes(
      tempPreviewData.tempPreviewData_general_bikesPreview,
      tempData_filtersParams
    );
  }, [
    tempPreviewData.tempPreviewData_general_bikesPreview,
    tempData_filtersParams,
  ]);

  // Обновление slug
  // useEffect(() => {
  //   if (location.pathname) {
  //     setCurrentSlug(location.pathname);
  //   }
  // }, [location.pathname]);

  // Обновление bikeData с проверкой наличия данных
  // useEffect(() => {
  //   if (!tempRootData || !tempData) return;

  //   const selectedBike = tempRootData.selectedBike_rootData;
  //   if (!selectedBike) return;

  //   const newBikeData = {
  //     idRootDocumentRentalPoint:
  //       selectedBike?.directory?.rentalPoint?.idRootDocument || null,

  //     userRole: tempRootData?.user_auth_rootCurrentData?.role || "user",

  //     photoFiles: selectedBike?.files?.photoFiles || [],

  //     basicInformation: selectedBike?.basicInformation || null,

  //     directory: selectedBike?.directory || null,

  //     brandBike: selectedBike?.directory?.brand?.title || null,

  //     rate: selectedBike?.rate || null,

  //     rentalPointRootData: tempRootData?.tempRootData_currentBike || null,

  //     selectedCountry:
  //       tempRootData?.tempRootData_currentBike?.location?.country || null,

  //     selectedCity:
  //       tempRootData?.tempRootData_currentBike?.location?.city || null,

  //     selectedBrand:
  //       tempRootData.selectedBike_rootData?.directory?.brand || null,

  //     selectedCategory:
  //       tempRootData.selectedBike_rootData?.directory?.category || null,

  //     order_selectedCompletedRootOrder:
  //       tempRootData?.order_selectedCompletedRootOrder || null,

  //     userChoice:
  //       tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
  //         tempData?.currentSlotIds?.indexBikeCurrentCart
  //       ]?.replacementBikes?.[bikeData?.keySlotReplacementBike]?.[
  //         bikeData?.indexReplacementBikes || 0
  //       ]?.userChoice || false,

  //     selectedBike_rootData: selectedBike || {},

  //     currentSlotIds: tempData?.currentSlotIds || null,

  //     keySlotReplacementBike:
  //       tempData?.currentSlotIds?.keySlotReplacementBike || null,

  //     indexReplacementBikes:
  //       tempData?.currentSlotIds?.indexReplacementBike || 0,

  //     indexBikeCurrentCart: tempData?.currentSlotIds?.indexBikeCurrentCart || 0,

  //     additionalServices:
  //       tempRootData?.tempRootData_currentBike?.additionalServices || null,
  //   };

  //   setBikeData((prev) => {
  //     // Проверяем, изменились ли данные, чтобы избежать лишних рендеров
  //     if (JSON.stringify(prev) !== JSON.stringify(newBikeData)) {
  //       return newBikeData;
  //     }
  //     return prev;
  //   });
  // }, [
  //   tempRootData,
  //   tempData?.currentSlotIds,
  //   tempData?.tempData_filtersParams,
  //   mode,
  //   bikeData?.indexReplacementBikes,
  //   bikeData?.keySlotReplacementBike,
  //   tempData,
  // ]);

  // Обновление dateInterval и differenceInDays
  useEffect(() => {
    if (!tempData?.tempData_filtersParams) return;

    const interval = tempData.tempData_filtersParams.dateInterval;
    const newInterval = Array.isArray(interval) ? interval : dateInterval;
    const hasChanged =
      JSON.stringify(newInterval) !== JSON.stringify(dateInterval);

    if (hasChanged) {
      setDateInterval(newInterval);
      const start = new Date(newInterval[0]);
      const end = new Date(newInterval[1]);
      const millisecondsInDay = 24 * 60 * 60 * 1000;
      const difference = Math.ceil((end - start) / millisecondsInDay) || 0;
      setDifferenceInDays(difference);
    }
  }, [dateInterval, tempData?.tempData_filtersParams]);
  // tempData?.tempData_filtersParams

  // Обновление количества велосипедов
  useEffect(() => {
    if (tempData?.tempData_filtersParams?.quantityBikes) {
      setQuantityBikes(tempData.tempData_filtersParams.quantityBikes);
    }
  }, [tempData?.tempData_filtersParams?.quantityBikes]);

  // Список всех байков
  // useEffect(() => {
  //   setGeneral_bikesPreviewNotifications(
  //     tempPreviewData?.general_bikesPreviewNotifications || null
  //   );
  // }, [tempPreviewData?.general_bikesPreviewNotifications]);

  // Добавление в корзину
  const addCart = () => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "orderedBike",
        data: tempRootData_currentBike || null,
      })
    );
    setWindowOrderDetailModeration(true);
  };

  // const computedDateInterval = useMemo(() => {
  //   return getComputedDateInterval(dateInterval, month);
  // }, [dateInterval, month]);

  // Формирование tempData_filtersParams
  // useEffect(() => {
  //   const filterData = {
  //     dateInterval,
  //     city: bikeData.selectedCity,
  //     country: bikeData.selectedCountry,
  //     category: bikeData.selectedCategory,
  //     brand: bikeData.selectedBrand,
  //     quantity: quantityBikes,
  //     month,
  //   };

  //   if (!deepEqual(filterData, prevFilterDataRef.current)) {
  //     const data = {
  //       city: filterData.city || null,
  //       country: filterData.country || null,
  //       category: filterData.category || null,
  //       brand: filterData.brand || null,
  //       dateInterval: computedDateInterval,
  //       quantityBikes: filterData.quantity || 1,
  //     };

  //     const currentTempData = tempData?.tempData_filtersParams || {};
  //     if (!deepEqual(data, currentTempData)) {
  //       dispatch(
  //         setTempData({
  //           tempCurrentDocumentState: "tempData_filtersParams",
  //           data,
  //         })
  //       );
  //       setManage_fdiltersBikes(data);
  //     }

  //     prevFilterDataRef.current = filterData;
  //   }
  // }, [
  //   dispatch,
  //   dateInterval,
  //   bikeData.selectedCity,
  //   bikeData.selectedCountry,
  //   bikeData.selectedCategory,
  //   bikeData.selectedBrand,
  //   quantityBikes,
  //   month,
  //   tempData,
  //   computedDateInterval,
  // ]);

  // Открытие popup
  const handleOpenWindowOrderDeteIntervalV4 = () =>
    setWindowOrderDeteIntervalV4(true);

  const handleOpenWindowOrderQuantityBikesV4 = () =>
    setWindowOrderQuantityBikesV4(true);

  // Закрытие popup
  const handleClose = () => {
    setWindowOrderDetailModeration(false);
    setWindowOrderDeteIntervalV4(false);
    setWindowOrderQuantityBikesV4(false);
  };

  return (
    <>
      {/* Добавление в корзину */}
      <PopupDirrectory
        isOpen={windowOrderDetailModeration}
        onClose={handleClose}
        title="WindowOrderDetailModeration"
      >
        <WindowOrderDetailModeration mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* Интервал дат */}
      <PopupDirrectory
        isOpen={windowOrderDeteIntervalV4}
        onClose={handleClose}
        title="WindowCalendarV4"
      >
        <WindowCalendarV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* Количество байков */}
      <PopupDirrectory
        isOpen={windowOrderQuantityBikesV4}
        onClose={handleClose}
        title="WindowOrderQuantityBikesV4"
      >
        <WindowOrderQuantityBikesV4 onClose={handleClose} />
      </PopupDirrectory>

      <SEOTags />

      <PageBox path={breadcrumbPath} title={title || defaultTitle}>
        {/* Фильтры */}
        <ContentBox>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div
              onClick={handleOpenWindowOrderDeteIntervalV4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "80px",
                boxSizing: "border-box",
                cursor: "pointer",
                padding: "4px",
              }}
            >
              <ItemFilters
                title={`${
                  Array.isArray(dateInterval) && dateInterval[0]
                    ? new Date(dateInterval[0]).toLocaleDateString()
                    : ""
                } — ${
                  Array.isArray(dateInterval) && dateInterval[1]
                    ? new Date(dateInterval[1]).toLocaleDateString()
                    : ""
                }`}
                param={
                  differenceInDays > 1
                    ? `${differenceInDays} days`
                    : `${differenceInDays} day`
                }
              />
            </div>

            <div
              onClick={handleOpenWindowOrderQuantityBikesV4}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "80px",
                boxSizing: "border-box",
                cursor: "pointer",
                padding: "4px",
              }}
            >
              <ItemFilters
                title="Quantity bikes:"
                param={
                  quantityBikes > 1
                    ? `${quantityBikes} bikes`
                    : `${quantityBikes} bike`
                }
              />
            </div>
          </div>
        </ContentBox>

        {/* SEO */}
        {userRole === "admin" && (
          <ContentBox>
            <WidgetSEO />
          </ContentBox>
        )}

        {/* Информация о байке */}
        <ContentBox>
          <WidgetViewBikeDetail
            tempRootData_currentBike={tempRootData_currentBike}
            startBookingDates={dateInterval[0] || 0}
            endBookingDates={dateInterval[1] || 0}
          />
        </ContentBox>

        {/* Дополнительные услуги */}
        <ContentBox>
          <WidgetOrderAdditionalServicesList
            additionalServices={
              tempRootData?.tempRootData_currentRentalPoint?.additionalServices
            }
          />
        </ContentBox>

        {/* Точка проката */}
        <ContentBox
          // isLoading={loading.loading_RentalPoint}
          header={
            <HeaderRentalPointPreview
              rentalPointRootData={tempRootData.tempRootData_currentRentalPoint}
              backgroundRentalPoint={
                priorityFile(
                  tempRootData.tempRootData_currentRentalPoint?.files
                    ?.photoFiles
                )?.url
              }
            />
          }
        >
          <RentalPointData
            rentalPointRootData={tempRootData.tempRootData_currentRentalPoint}
          />
        </ContentBox>

        {/* Список похожих байков */}
        <ContentBox>
          <WidgetBikesList
            bikesList={tempPreviewData.tempPreviewData_general_bikesPreview}
            bikeState="approved"
            mode="view"
            closeParent={onClose}
            dateInterval={dateInterval}
          />
        </ContentBox>
        {/* SEO отображение */}
        <ContentBox>
          <SEOContent />
        </ContentBox>
      </PageBox>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "8px",
          }}
        >
          <Button
            type="small"
            label="Add cart"
            active={true}
            onClick={addCart}
          />
        </div>
      </ButtonsBar>
    </>
  );
};

// Остальные компоненты остаются без изменений
const RentalPointData = ({ rentalPointRootData }) => {
  const isMobile = window.innerWidth <= 400;

  return (
    <div
      // key={uuidv4()}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
        padding: "16px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div>
          <h5>{rentalPointRootData?.basicInformation?.title?.titleOriginal}</h5>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.pointAddress}
            </p>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          boxSizing: "border-box",
          gap: "2px",
        }}
      >
        <div
          style={{
            width: "100%",
            gridColumn: "1",
            gridRow: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "var(--color-gray-100)",
            boxSizing: "border-box",
            borderRadius: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              boxSizing: "border-box",
              padding: "16px",
            }}
          >
            {rentalPointRootData?.timetable && (
              <>
                <h6 style={{ width: "100%" }}>Timetable</h6>
                <span className="material-symbols-outlined">alarm_on</span>
              </>
            )}
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "0 16px 16px 16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              gap: "2px",
              boxSizing: "border-box",
            }}
          >
            {rentalPointRootData?.timetable?.map((timetableItem) =>
              !timetableItem.isHoliday ? (
                <div
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p className="p-light">
                    {truncateToThreeChars(
                      timetableItem.weekday.weekdayOriginal
                    )}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />
                  <div
                    className="p-light"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "var(--color-success)",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                      boxSizing: "border-box",
                    }}
                  >
                    <p>
                      {timetableItem?.openHours}:{timetableItem?.openMinutes}
                    </p>{" "}
                    —{" "}
                    <p>
                      {timetableItem?.closeHours}:{timetableItem?.closeMinutes}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p className="p-light">
                    {truncateToThreeChars(
                      timetableItem?.weekday?.weekdayOriginal
                    )}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />
                  <p
                    className="p-light"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "var(--color-black)",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                      boxSizing: "border-box",
                    }}
                  >
                    holiday
                  </p>
                </div>
              )
            )}
          </div>
        </div>
        <div
          style={{
            gridColumn: "2",
            gridRow: "1",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "end",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ContactsList
            contacts={rentalPointRootData?.contacts?.emailAddresses}
            fildname="email"
            title="Emails: "
            isMobile={isMobile}
            icon={<span className="material-symbols-outlined">mail</span>}
          />
        </div>
        <div
          style={{
            gridColumn: "2",
            gridRow: "2",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "end",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ContactsList
            contacts={rentalPointRootData?.contacts?.phoneNumbers}
            fildname="phone"
            title="Phones: "
            isMobile={isMobile}
            icon={<span className="material-symbols-outlined">call</span>}
          />
        </div>
      </div>
      {rentalPointRootData?.basicInformation?.description
        ?.descriptionOriginal && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <h6>Description rental point</h6>
          <CollapsableText
            text={
              rentalPointRootData?.basicInformation?.description
                ?.descriptionOriginal || null
            }
          />
        </div>
      )}
    </div>
  );
};

const HeaderRentalPointPreview = ({
  rentalPointRootData,
  backgroundRentalPoint,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "160px",
        backgroundColor: "var(--color-gray-200)",
        position: "relative",
        backgroundImage: `url('${backgroundRentalPoint}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        padding: "16px 24px 42px 24px",
        boxSizing: "border-box",
        marginBottom: "48px",
      }}
    >
      <div style={{ position: "absolute", left: "24px", bottom: "-48px" }}>
        <PriorityFile
          style={{
            borderRadius: "80px",
            height: "80px",
            width: "80px",
            border: "solid 4px var(--color-white)",
          }}
          files={rentalPointRootData?.files?.logoFiles}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4px",
          position: "absolute",
          right: "24px",
          bottom: "-28px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
              padding: "2px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.country?.title}
            </p>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
              padding: "2px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.city?.title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactsList = ({ contacts, fildname, title, isMobile, icon }) => {
  const [showAll, setShowAll] = useState(false);
  const visibleContacts = contacts?.slice(0, 1);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: contacts?.length > 1 ? "space-between" : "flex-end",
        boxSizing: "border-box",
      }}
    >
      {Array.isArray(contacts) && contacts?.length > 1 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box",
            padding: "16px",
          }}
        >
          <p className="p-light">
            {title && (
              <h6>
                {title}
                {contacts.length}
              </h6>
            )}
          </p>
          {icon ? (
            <span onClick={() => setShowAll(true)}>{icon}</span>
          ) : (
            <span
              onClick={() => setShowAll(true)}
              className="material-symbols-outlined"
            >
              keyboard_arrow_right
            </span>
          )}
        </div>
      )}
      <div
        style={{
          width: "100%",
          padding: "0 16px 8px 16px",
          boxSizing: "border-box",
        }}
      >
        {visibleContacts?.map((contactItem, index) => (
          <div
            key={contactItem.key || index}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "end",
              boxSizing: "border-box",
            }}
          >
            <p
              className="p-light"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "20ch" : "40ch",
                boxSizing: "border-box",
              }}
            >
              {fildname && contactItem[fildname]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const ItemFilters = ({ title, param }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        width: "100%",
        color: "rgba(0, 0, 0, 0.8)",
      }}
    >
      {title}
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          color: "rgba(0, 0, 0, 0.9)",
          fontWeight: "600",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 8px",
          borderRadius: "16px",
        }}
      >
        {param}
      </div>
    </div>
  );
};

export default PageProductCard;
