import React, { useState } from "react";
import "../profile.css";
import "./bikes.css";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import WindowBikePricePerMonthModeration from "../Moderation/WindowBikePricePerMonthModeration";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  const monthName = date.toLocaleString("en-US", { month: "long" });
  return monthName;
};

const WeekMonthItem = ({
  itemKey,
  month,
  rateInDay,
  onClick,
  discount,
  isActive,
}) => {
  const monthName = getMonthName(month);
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth();

  return (
    <div
      key={itemKey}
      className="bikes__month-content-box"
      onClick={onClick}
      style={{
        ...(isActive === false && {
          backgroundColor: "var(--color-gray-300)",
        }),
        ...(isActive !== false &&
          rateInDay > 0 && {
            backgroundColor: "var(--color-success)",
            color: "var(--color-white)",
          }),
      }}
    >
      <div className="bikes__month">
        <p>{monthName}</p>
        {currentMonth === month && (
          <span className="material-symbols-outlined">check_small</span>
        )}
      </div>

      <div className="bikes__rate-in-day">
        <p
          className="p-light"
          style={{
            ...(isActive !== false &&
              rateInDay > 0 && {
                color: "var(--color-white)",
              }),
          }}
        >
          {formatCurrencyUSD(rateInDay)}
        </p>
      </div>
      {discount > 0 && (
        <div
          style={{
            ...(isActive === false
              ? {
                  backgroundColor: "var(--color-gray-400)",
                }
              : {
                  color: "var(--color-black)",
                }),
          }}
          className="bikes__sale"
        >
          <div className="p-light">{`-${discount}% off`}</div>
        </div>
      )}
    </div>
  );
};

const WidgetBikeRateV4 = ({ mode, rate, isChangeState }) => {
  // const [windowBikePricePerMonth, setWindowBikePricePerMonth] = useState(false);

  const [
    windowBikePricePerMonthModeration,
    setWindowBikePricePerMonthModeration,
  ] = useState(false);
  const [indexMonth, setIndexMonth] = useState(null);

  const handleClose = () => {
    setIndexMonth(null);
    // setWindowBikePricePerMonth(false);
    setWindowBikePricePerMonthModeration(false);
  };

  const handleToggle = (index) => {
    if (mode === "edit" || mode === "new") {
      // setWindowBikePricePerMonth(true);
    }
    if (mode === "moderation" || mode === "moderatorAdded") {
      setWindowBikePricePerMonthModeration(true);
    }
    setIndexMonth(index);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      {/* <PopupDirrectory
        isOpen={windowBikePricePerMonth}
        onClose={handleClose}
        title="Edit timetable"
      >
        <WindowBikePricePerMonth
          mode={mode}
          index={indexMonth}
          onClose={handleClose}
        />
      </PopupDirrectory> */}

      <PopupDirrectory
        isOpen={windowBikePricePerMonthModeration}
        onClose={handleClose}
        title="Edit timetable moderation"
      >
        <WindowBikePricePerMonthModeration
          mode={mode}
          index={indexMonth}
          onClose={handleClose}
          isChangeState={isChangeState}
        />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}

      <div className="profile-widget__content-box">
        <div className="bikes-rate">
          {rate?.map((rateItem, index) => (
            <WeekMonthItem
              key={index}
              itemKey={index}
              month={index}
              rateInDay={rateItem.price}
              discount={rateItem.discount}
              isActive={rateItem.isActive}
              onClick={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default WidgetBikeRateV4;
