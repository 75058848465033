import { useEffect, useState } from "react";
import { formatCurrencyUSD } from "../../../functions/currencyFormatter";

const getMonthName = (monthNumber) => {
  const date = new Date(2000, monthNumber, 1);
  return date.toLocaleString("en-US", { month: "short" });
};

// Генерация случайных цен (только если rate не передан)
const generateRandomRates = () => {
  return Array.from({ length: 12 }, () => ({
    price: Math.floor(Math.random() * (100 - 30 + 1)) + 30, // случайное число от 30 до 100
  }));
};

const WidgetRate = ({ rate, startBookingDates, endBookingDates, theme }) => {
  const [displayRates, setDisplayRates] = useState([]);
  const [isRate, setIsRate] = useState(false);
  const [startMonth, setStartMonth] = useState(0);
  const [endMonth, setEndMonth] = useState(0);
  // Обновление месяцев при изменении дат бронирования
  useEffect(() => {
    if (startBookingDates) {
      setStartMonth(new Date(startBookingDates).getMonth());
    }
    if (endBookingDates) {
      setEndMonth(new Date(endBookingDates).getMonth());
    }
  }, [startBookingDates, endBookingDates]);

  useEffect(() => {
    if (Array.isArray(rate) && rate.length > 0) {
      setDisplayRates(rate);
      setIsRate(true);
    } else {
      setDisplayRates(generateRandomRates());
      setIsRate(false);
    }
  }, [rate]);

  // Проверяем, есть ли данные для отображения
  if (!Array.isArray(displayRates) || displayRates.length === 0) {
    return <p>No rate data available.</p>;
  }

  const maxPrice = Math.max(
    ...displayRates.map((rateItem) => rateItem.price),
    1
  );
  const calculateHeight = (price) => (price / maxPrice) * 56;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "end",
          justifyContent: "space-between",
          gap: "2px",
          width: "100%",
        }}
      >
        {displayRates.map((rateItem, index) => (
          <div key={index} style={{ width: "100%" }}>
            {/* Название месяца */}

            {isRate && index >= startMonth && index <= endMonth && (
              <div
                style={{
                  padding: "2px 0",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  boxSizing: "border-box",
                }}
              >
                <p
                  className="p-light"
                  style={{
                    fontSize: "0.6rem",
                  }}
                >
                  {getMonthName(index)}
                </p>
              </div>
            )}
            {/* График цен */}
            <div
              title={`${getMonthName(index)} — ${formatCurrencyUSD(
                rateItem.price
              )}`}
              style={{
                height: `${calculateHeight(rateItem.price)}px`,
                width: "100%",
                borderRadius: "4px",
                backgroundColor:
                  isRate && index >= startMonth && index <= endMonth
                    ? "var(--color-success)" // Если rate передан, включаем подсветку
                    : "var(--color-gray-100)", // Если rate отсутствует, отключаем подсветку
                boxSizing: "border-box",
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "center",
                minHeight: "16px",
              }}
            >
              {isRate && index >= startMonth && index <= endMonth && (
                <p
                  className="p-light"
                  style={{
                    width: "100%",
                    color: isRate ? "var(--color-white)" : "var(--color-black)", // Белый текст если rate передан, иначе черный
                    fontSize: "0.6rem",
                    textAlign: "center",
                    padding: "2px",
                    boxSizing: "border-box",
                  }}
                >
                  {formatCurrencyUSD(rateItem.price)}
                </p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WidgetRate;
