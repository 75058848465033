import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
`;

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  box-sizing: border-box;
`;

const ItemBox = styled.div`
  background-color: var(--color-gray-100);
  height: 48px;
  width: 100%;
  border-radius: 8px;
`;

const ItemText = styled.div`
  width: 70%;
  background-color: var(--color-gray-100);
  height: 8px;
  border-radius: 80px;
`;

const SkeletonBrandCategory = () => {
  return (
    <Container>
      {Array(2)
        .fill(null)
        .map((_, index) => (
          <ItemContainer key={index}>
            <ItemBox />
            <ItemText />
          </ItemContainer>
        ))}
    </Container>
  );
};

export default SkeletonBrandCategory;
