import React from "react";
import TitleBlockSection from "../../UIComponents/Titles/TitleBlockSection";

const PageInform = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "var(--color-gray-100)",
        padding: "16px",
      }}
    >
      <div
        style={{
          paddingTop: "80px",
          maxWidth: "var(--width-mobile-content)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          boxSizing: "border-box",
          position: "relative",
        }}
      >
        <TitleBlockSection
          title="Inform"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse efficitur, nunc eget malesuada tempus, justo leo ullamcorper sapien, ac rhoncus justo magna non turpis. Sed tristique odio eu magna convallis interdum. Pellentesque vitae tortor est."
        />
        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box p-light">
            <h5>Why It's Better to Book Transportation Online?</h5>
            <p>
              In today's world, the internet has become an integral part of our
              daily lives. One of the most crucial tasks that can be
              accomplished online is booking transportation. Regardless of where
              you're heading or how far, here are several compelling reasons why
              it's better to book transportation online:
            </p>
            <div className="custom-list">
              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  One of the primary advantages of booking transportation online
                  is its convenience. You can do it anytime, from anywhere in
                  the world, as long as you have internet access.
                </p>
              </div>

              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Booking transportation online allows you to save both time and
                  money.
                </p>
              </div>

              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  The ability to make reservations in a foreign country without
                  knowing the local language is invaluable. Many rental services
                  not only don't speak your native language but also struggle
                  with English.
                </p>
              </div>

              <div className="custom-list-item">
                <p className="custom-list-point">—</p>
                <p>
                  Online platforms provide access to reviews and ratings from
                  other travelers. This allows you to get an idea of the quality
                  of the service or the transportation you're planning to book
                </p>
              </div>
            </div>
            <p>
              In conclusion, booking transportation online is a convenient,
              fast, and cost-effective way to organize your travels. It gives
              you more control over your plans and allows you to choose from a
              wide range of options.
            </p>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box p-light">
            <h5>Why is a Deposit Required for Rental?</h5>
            <p>
              Most rental providers aim to minimize their risks and require
              something as a deposit for the rented vehicle. It can be a
              passport, its copy, driver's license, cash, or a hold on your
              credit card. You can choose the option that suits you best.
            </p>
            <p>
              Don't be concerned about not getting your deposit back; all rental
              providers listed on this website have been vetted and meet quality
              service requirements.
            </p>
          </div>
        </div>

        <div
          style={{
            width: "100%",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            backgroundColor: "var(--color-white)",
            borderRadius: "24px",
            padding: "24px",
          }}
        >
          <div className="content-box p-light">
            <h5>How to get in touch with customer support?</h5>
            <p>
              You can ask questions about vehicle reservations through the Bike
              For Your Trip customer &nbsp;
              <a href="mailto:hotels@tinkoff.ru">support chat</a>&nbsp; or via
              email at &nbsp;
              <a href="mailto:hotels@tinkoff.ru">help@BikeForYourTrip.com</a>.
              Our staff are available around the clock and will assist with any
              inquiries.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageInform;
