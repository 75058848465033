import React, { useEffect, useState } from "react";
import Button from "../../../../UIComponents/Buttons/Button";
import SegmentedControls from "../../../../UIComponents/SegmentedControls/SegmentedControls";
import { useDispatch, useSelector } from "react-redux";
import { getWeekDayName } from "../../../../../functions/getWeekDayName";
import Textarea from "../../../../UIComponents/Textarea/Textarea";
import DoubleDropdownList from "../../../../UIComponents/DropdownList/DoubleDropdownList";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
// import { setTimetableInModeration } from "../../../../../reducersToolkit/moderationSliceV2";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4";

const WindowEditTimetableModeration = ({
  mode,
  indexDay,
  onClose,
  isChangeState,
}) => {
  const dispatch = useDispatch();
  console.log("indexDay", indexDay);
  const { tempData, tempRootData, rootNewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const isModerationMode = mode === "moderation";
  const isModeratorAddedMode = mode === "moderatorAdded";

  const { newRentalPoint, selectedRentalPoint } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [timetable, setTimetable] = useState([]);
  console.log("WindowEditTimetableModeration timetable", timetable);
  const [openingHoursTime, setOpeningHoursTime] = useState("");
  const [openingMinutesTime, setOpeningMinutesTime] = useState("");

  const [closingHoursTime, setClosingHoursTime] = useState("");
  const [closingMinutesTime, setClosingMinutesTime] = useState("");

  const [selectedDay, setSelectedDay] = useState();
  const [timeTableDescription, setTimeTableDescription] = useState();
  const [historyChanges, setHistoryChanges] = useState({});

  const [newData, setNewData] = useState(null);

  // Добавляем состояния для хранения исходных значений
  const [initialOpeningHours, setInitialOpeningHours] = useState("");
  const [initialOpeningMinutes, setInitialOpeningMinutes] = useState("");
  const [initialClosingHours, setInitialClosingHours] = useState("");
  const [initialClosingMinutes, setInitialClosingMinutes] = useState("");
  const [initialSelectedDay, setInitialSelectedDay] = useState("");
  const [initialDescription, setInitialDescription] = useState("");

  // Состояние для активации кнопки Apply
  const [applyButtonActive, setApplyButtonActive] = useState(false);
  // tempData.tempData_rentalPoint_timetable
  useEffect(() => {
    if (isModeratorAddedMode) {
      setTimetable(
        tempData?.tempData_rentalPoint_timetable ||
          rootNewData?.rootRentalPointData?.timetable ||
          []
      );
    } else if (isModerationMode) {
      setTimetable(
        tempData?.tempData_rentalPoint_timetable ||
          tempRootData?.selectedBike_rentalPont?.timetable ||
          []
      );
    }
  }, [
    tempData?.tempData_rentalPoint_timetable,
    rootNewData?.rootRentalPointData?.timetable,
    tempRootData?.selectedBike_rentalPont,
    isModeratorAddedMode,
    isModerationMode,
  ]);
  // console.log("currentTimetable timetable", timetable);
  useEffect(() => {
    const currentTimetable = timetable[indexDay] || {};
    setOpeningHoursTime(currentTimetable.openHours || "");
    setOpeningMinutesTime(currentTimetable.openMinutes || "");
    setClosingHoursTime(currentTimetable.closeHours || "");
    setClosingMinutesTime(currentTimetable.closeMinutes || "");
    setSelectedDay(currentTimetable.isHoliday ? "Day off" : "Working day");
    setTimeTableDescription(
      currentTimetable.description?.descriptionOriginal || ""
    );
    setHistoryChanges(currentTimetable.historyChanges || {});

    // Сохраняем исходные значения
    setInitialOpeningHours(currentTimetable.openHours || "");
    setInitialOpeningMinutes(currentTimetable.openMinutes || "");
    setInitialClosingHours(currentTimetable.closeHours || "");
    setInitialClosingMinutes(currentTimetable.closeMinutes || "");
    setInitialSelectedDay(
      currentTimetable.isHoliday ? "Day off" : "Working day"
    );
    setInitialDescription(
      currentTimetable.description?.descriptionOriginal || ""
    );
  }, [timetable, indexDay]);

  useEffect(() => {
    setNewData({
      weekday: { weekdayOriginal: getWeekDayName(indexDay) },
      openHours: openingHoursTime.toString(),
      openMinutes: openingMinutesTime.toString(),
      closeHours: closingHoursTime.toString(),
      closeMinutes: closingMinutesTime.toString(),
      description: {
        descriptionOriginal: timeTableDescription,
      },
      isHoliday: selectedDay === "Day off" ? true : false,
    });
  }, [
    indexDay,
    closingHoursTime,
    closingMinutesTime,
    openingHoursTime,
    openingMinutesTime,
    selectedDay,
    timeTableDescription,
  ]);

  // Логика активации кнопки Apply на основе изменений
  useEffect(() => {
    // Проверяем, изменились ли данные относительно исходных значений
    const hasChanges =
      openingHoursTime !== initialOpeningHours ||
      openingMinutesTime !== initialOpeningMinutes ||
      closingHoursTime !== initialClosingHours ||
      closingMinutesTime !== initialClosingMinutes ||
      selectedDay !== initialSelectedDay ||
      timeTableDescription !== initialDescription;

    // Проверяем, заполнены ли все необходимые поля для рабочего дня
    const isWorkingDayComplete =
      selectedDay === "Working day"
        ? openingHoursTime &&
          openingMinutesTime &&
          closingHoursTime &&
          closingMinutesTime
        : true;

    // Обновляем состояние кнопки Apply
    setApplyButtonActive(hasChanges && isWorkingDayComplete);
  }, [
    openingHoursTime,
    openingMinutesTime,
    closingHoursTime,
    closingMinutesTime,
    selectedDay,
    timeTableDescription,
    initialOpeningHours,
    initialOpeningMinutes,
    initialClosingHours,
    initialClosingMinutes,
    initialSelectedDay,
    initialDescription,
  ]);

  const handleApply = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    // Получаем актуальный timetable из Redux или из локального состояния
    let actualTimetable = [];

    if (isModeratorAddedMode) {
      actualTimetable =
        tempData?.tempData_rentalPoint_timetable ||
        rootNewData?.rootRentalPointData?.timetable ||
        [];
    } else if (isModerationMode) {
      actualTimetable =
        tempData?.tempData_rentalPoint_timetable ||
        tempRootData?.selectedBike_rentalPont?.timetable ||
        [];
    }

    // Преобразуем в массив, если вдруг не массив
    if (!Array.isArray(actualTimetable)) {
      actualTimetable = [];
    }

    // Создаем копию массива
    const updatedTimetable = [...actualTimetable];

    // Обновляем элемент с указанным индексом
    updatedTimetable[indexDay] = newData;

    console.log("Updating timetable:", {
      currentTimetable: timetable,
      actualTimetable: actualTimetable,
      updatedTimetable: updatedTimetable,
      newData: newData,
      indexDay: indexDay,
    });

    // Сохраняем весь обновленный массив в tempData
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_timetable",
        data: updatedTimetable,
      })
    );

    onClose();
  };

  const hours = Array.from({ length: 25 }, (_, index) => index);
  const minutes = Array.from({ length: 61 }, (_, index) =>
    index.toString().padStart(2, "0")
  );

  const hoursOptions = {
    label: "Hours",
    value: hours,
  };

  const minutesOptions = {
    label: "Minutes",
    value: minutes,
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div className="profile-widget__content-box">
            <SegmentedControls
              options={["Working day", "Day off"]}
              onSelect={selectedDay}
              selected={setSelectedDay}
              // label="SegmentedControls"
              description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. "
            />

            {selectedDay === "Working day" && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "16px",
                  }}
                >
                  <DoubleDropdownList
                    optionsFirst={hoursOptions}
                    optionsSecond={minutesOptions}
                    onSelectFirst={setOpeningHoursTime}
                    onSelectSecond={setOpeningMinutesTime}
                    label={
                      openingHoursTime && openingMinutesTime
                        ? `${openingHoursTime}:${openingMinutesTime}`
                        : "Open time"
                    }
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    selectedOptionFirst={openingHoursTime}
                    selectedOptionSecond={openingMinutesTime}
                  />

                  <DoubleDropdownList
                    optionsFirst={hoursOptions}
                    optionsSecond={minutesOptions}
                    onSelectFirst={setClosingHoursTime}
                    onSelectSecond={setClosingMinutesTime}
                    label={
                      closingHoursTime && closingMinutesTime
                        ? `${closingHoursTime}:${closingMinutesTime}`
                        : "Close time"
                    }
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                    selectedOptionFirst={closingHoursTime}
                    selectedOptionSecond={closingMinutesTime}
                  />
                </div>
                <Textarea
                  label="Description"
                  maxLength={500}
                  onChange={(e) => setTimeTableDescription(e.target.value)}
                  value={timeTableDescription}
                  description={`Please describe the details of your rental point's operation on this day, if any.`}
                />
              </>
            )}
          </div>
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={onClose}
            type="small"
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to leave the editing window?
              </h1>
            }
          />
          <Button
            type="small"
            label={applyButtonActive ? "Save schedule" : "Apply"}
            active={applyButtonActive}
            onClick={handleApply}
            color="--color-primary"
            allert={
              <div
                style={{
                  padding: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>
                  {applyButtonActive
                    ? "Are you sure you want to save the schedule changes?"
                    : "Are you sure you want to apply these settings?"}
                </h1>
              </div>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditTimetableModeration;
