import IconContainer from "../icons/IconContainer";

const PreviewItemCard = ({
  index,
  length,
  //
  title,
  description,
  //
  stateItem,
  //
  iconLeft = null,
  iconRight = null,
  iconLeftStyle,
  iconRightStyle,
  containerIconLeftStyle,
  containerIconRightStyle,
  //
  containerCardStyle,
  titleCardStyle,
  descriptionCardStyle,
  //
  onClick,
  isLastItem = false,
  children,
}) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        width: "100%",
        minHeight: "80px",
        // justifyContent: "flex-start",
        gap: "16px",
        backgroundColor: "var(--color-gray-100)",
        borderRadius:
          length === 1
            ? "16px"
            : index === 0
            ? "16px 16px 0 0"
            : index === length - 1
            ? "0px 0px 16px 16px"
            : "0px",
        padding: "16px",
        cursor: onClick ? "pointer" : "default",
        ...containerCardStyle,
      }}
    >
      {/* Определение иконки в зависимости от статуса */}
      {iconLeft && (
        <div>
          <IconContainer
            iconStyle={iconLeftStyle}
            icon={iconLeft}
            containerStyle={containerIconLeftStyle}
            onClick={onClick}
          />
        </div>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "4px",
          position: "relative",
        }}
      >
        <h6 style={{ ...titleCardStyle }}>{title}</h6>
        {description && (
          <span
            className="p-light"
            style={{
              textOverflow: "ellipsis",
              ...descriptionCardStyle,
            }}
          >
            {description}
          </span>
        )}
        {children}
      </div>

      {iconRight && (
        <div>
          <IconContainer
            iconStyle={iconRightStyle}
            icon={iconRight}
            containerStyle={containerIconRightStyle}
            onClick={onClick}
          />
        </div>
      )}
    </div>
  );
};

export default PreviewItemCard;
