import { createAsyncThunk } from "@reduxjs/toolkit";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { fetchDocumentsByIds } from "../../services/firebase/crudFirestore";
import handleThunkError from "../../functions/handleThunkError";
import { createDataThunkV4 } from "./createDataThunkV4";

export const googleSignUpThunkV4 = createAsyncThunk(
  "reducersToolkit/googleSignUpThunkV4",
  async (_, { dispatch, rejectWithValue }) => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    provider.setCustomParameters({
      prompt: "select_account",
    });

    try {
      const userCredential = await signInWithPopup(auth, provider);

      // Проверяем, существует ли пользователь в базе данных
      const existingUsers = await fetchDocumentsByIds(
        "users",
        userCredential.user.uid
      );

      // Извлекаем первый документ из массива (если он есть)
      const existingUser = existingUsers.length > 0 ? existingUsers[0] : null;

      // Если пользователь существует, возвращаем его данные
      if (existingUser) {
        return { currentUser: existingUser };
      }

      // Создаем объект с данными пользователя
      const rootUserData = {
        createdat: Date.now(),
        status: "moderation",
        role: {
          currentRole: "rental",
        },
        user: {
          ownerName: userCredential.user.displayName || "",
        },
        userContacts: {
          email: userCredential.user.email,
          phoneNumber: userCredential.user.phoneNumber || "",
        },
        providerData: userCredential.user.providerData || [],
        id: userCredential.user.uid,
      };

      const previewUserData = {
        createdat: Date.now(),
        fileUrl: userCredential.user.photoURL || "",
        ownerName: userCredential.user.displayName || "",
        email: userCredential.user.email,
        phoneNumber: userCredential.user.phoneNumber || "",
        id: userCredential.user.uid,
        status: "moderation",
        role: "rental",
      };

      await dispatch(
        createDataThunkV4({
          rootData: rootUserData,
          idRootDocument: userCredential.user.uid,
          previewData: previewUserData,
          rootCollectionPath: "users",
          previewCollectionPath: "manager_usersPreview",
          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: "manager_usersPreview.metadata",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          // Путь к общей коллекции предварительных документов (опционально)
          previewGeneralCollectionPath: "general_usersPreview",
          // Путь к общим метаданным (опционально)
          metadataGeneralDocumentPath: "general_usersPreview.metadata",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          // previewCurrentDocumenState: "user_auth_previewCurrentData",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_user_auth_rootCurrentData",
          errorStateName: "error_user_auth_rootCurrentData",
        })
      )
        .unwrap()
        .then((response) => {
          console.log("Пользователь успешно создан:", response);
        })
        .catch((error) => {
          console.error("Ошибка при создании пользователя:", error);
        });

      return {
        currentUser: rootUserData,
        rootData: rootUserData,
        previewData: previewUserData,
      };
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);
