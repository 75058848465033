export const formatCurrencyUSD = (number) => {
  const num = Number(number);
  if (isNaN(num) || num === 0) {
    return "$0.00";
  }
  const conversion = num.toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
  });
  return conversion;
};
