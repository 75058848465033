// WidgetRentalPointDialogV4.js
import React, { useMemo } from "react";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import UserData from "../../../../UIComponents/UserData/UserData";
import WidgetBikeItemDataV4 from "./WidgetBikeItemDataV4";
import WidgetBikesReplacmentListV4 from "./WidgetBikesReplacmentListV4";
import WidgetWoucherListV4 from "./WidgetWoucherListV4";

// Стили сообщений
const messageStyles = {
  borderRadius: "16px",
  padding: "16px",
  boxSizing: "border-box",
};

const leftMessageStyle = {
  ...messageStyles,
  borderRadius: "16px 0 16px 16px",
  backgroundColor: "var(--color-gray-100)",
};

const rightMessageStyle = {
  ...messageStyles,
  borderRadius: "0 16px 16px 16px",
  backgroundColor: "var(--color-gray-100)",
};

const canceledMessageStyle = {
  ...leftMessageStyle,
  backgroundColor: "var(--color-alarm)",
  color: "var(--color-white)",
};

const dialogContainerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "8px",
};

const itemsListStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "2px",
};

const voucherContainerStyle = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "8px",
  borderRadius: "16px",
};

const WidgetRentalPointDialogV4 = ({
  // user
  userName,
  userRole,
  userAvatarUrls,
  // rentalPoint
  rentalPointName,
  rentalPointLogoFile,
  rentalPointRootData,
  // order
  currentCart,
  totalOrders,
  replacementBox,
  waitUserBox,
  order_selectedCompletedRootOrder,
  // handleReplacementBike,
  // handleDeleteReplacementBike,
  currentStateOrder,
  rootOrderStatus,
  // Params
  isMobile,
}) => {
  // Оптимизированная функция поиска статусов
  const findStatus = useMemo(() => {
    return (statusToFind) => {
      const foundItem = rootOrderStatus?.find(
        (item) => item.status === statusToFind
      );
      return foundItem
        ? {
            status: true,
            userId: foundItem?.user?.userId,
            timestamp: foundItem?.timestamp,
            userName: foundItem?.user?.userName,
            userAvatarUrls: foundItem?.user?.userAvatarUrls,
          }
        : { status: false, userId: null, timestamp: 0 };
    };
  }, [rootOrderStatus]);

  // Получаем статусы
  const replacementStatus = findStatus("replacement");
  const confirmStatus = findStatus("confirm");
  const canceledStatus = findStatus("canceled");

  return (
    <ContentBox position="start">
      {/* Новый заказ от клиента */}
      <UserData
        type="left"
        date={canceledStatus.timestamp}
        userName={canceledStatus.userName}
        userAvatarUrls={canceledStatus.userAvatarUrls}
        isMobile={isMobile}
      >
        <div style={dialogContainerStyle}>
          <div style={leftMessageStyle}>
            <p className="p-light">Новый заказ от клиента</p>
          </div>

          <div style={itemsListStyle}>
            {currentCart?.map((item, index) => (
              <div key={`cart-item-${index}`} className="list-item">
                <WidgetBikeItemDataV4
                  item={item}
                  index={index}
                  groupItems={currentCart}
                  isMobile={isMobile}
                />
              </div>
            ))}
          </div>
        </div>
      </UserData>

      {/* Предложение замены */}
      {(replacementBox || replacementStatus.status) && (
        <UserData
          type="right"
          date={replacementStatus.timestamp}
          userName={rentalPointName}
          userAvatarUrls={rentalPointLogoFile}
          isMobile={isMobile}
        >
          <div style={dialogContainerStyle}>
            <div style={rightMessageStyle}>
              <p className="p-light">Выберите варианты замены</p>
            </div>

            {currentCart?.map((item, index) => (
              <div key={`replacement-${index}`} style={itemsListStyle}>
                <WidgetBikesReplacmentListV4
                  item={item}
                  indexBikeCurrentCart={index}
                  order_selectedCompletedRootOrder={
                    order_selectedCompletedRootOrder
                  }
                  // onClick={handleReplacementBike}
                  // onClickDelete={handleDeleteReplacementBike}
                  isMobile={isMobile}
                  currentStateOrder={currentStateOrder}
                />
              </div>
            ))}
          </div>
        </UserData>
      )}

      {/* Ожидание ответа клиента */}
      {(waitUserBox || replacementStatus.status) && (
        <UserData
          type="left"
          date={replacementStatus.timestamp}
          userName={userName}
          userAvatarUrls={userAvatarUrls}
          isMobile={isMobile}
        >
          <div style={leftMessageStyle}>
            <p className="p-light">Ожидаем ответа от клиента</p>
          </div>
        </UserData>
      )}

      {/* Подтверждение заказа */}
      {confirmStatus.status && (
        <UserData
          type="right"
          date={confirmStatus.timestamp}
          userName={rentalPointName}
          userAvatarUrls={rentalPointLogoFile}
          isMobile={isMobile}
        >
          <div style={dialogContainerStyle}>
            <div style={rightMessageStyle}>
              <p className="p-light">Клиент подтвердил заказ</p>
            </div>

            <div style={voucherContainerStyle}>
              <WidgetWoucherListV4
                isMobile={isMobile}
                userType={userRole}
                currentRentalPointOrders={currentCart}
                totalOrders={totalOrders}
                rentalPointRootData={rentalPointRootData}
              />
            </div>
          </div>
        </UserData>
      )}

      {/* Отмена заказа */}
      {canceledStatus.status && (
        <UserData
          type="left"
          date={canceledStatus.timestamp}
          userName={canceledStatus.userName}
          userAvatarUrls={canceledStatus.userAvatarUrls}
          isMobile={isMobile}
        >
          <div style={canceledMessageStyle}>
            <p className="p-light" style={{ color: "var(--color-white)" }}>
              Клиент отменил заказ
            </p>
          </div>
        </UserData>
      )}
    </ContentBox>
  );
};

export default WidgetRentalPointDialogV4;
