import React, { useEffect, useState } from "react";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowLocationDetailModeration from "./WindowLocationDetailModeration";
import AddData from "../../../../UIComponents/AddData/AddData";

const WidgetLocation = ({ location, mode, ancorId, isChangeState }) => {
  const [address, setAddress] = useState(null);
  const [cityTitle, setCityTitle] = useState(null);
  const [countryTitle, setCountryTitle] = useState(null);
  const [isOpenLocationModeration, setIsOpenLocationModeration] =
    useState(false);

  const handleToggle = () => {
    setIsOpenLocationModeration(true);
  };

  const handleClose = () => {
    setIsOpenLocationModeration(false);
  };

  useEffect(() => {
    setCityTitle(location?.city?.title || null);
    setCountryTitle(location?.country?.title || null);
    setAddress(location?.pointAddress || null);
  }, [location.city, location.country, location.pointAddress]);

  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={isOpenLocationModeration}
        onClose={handleClose}
        title="Rental point location moderation"
      >
        <WindowLocationDetailModeration
          onClose={handleClose}
          mode={mode}
          isChangeState={isChangeState}
        />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}

      <div className="profile-widget__content-box" id={ancorId}>
        <AddData
          onClick={handleToggle}
          icon={
            address ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={address || "No address"}
          description={
            <div>
              <p className="p-light">City: {countryTitle || "No city"}</p>
              <p className="p-light">Country: {cityTitle || "No country"}</p>
            </div>
          }
        />
        {/* <div
          style={{
            width: "100%",
            aspectRatio: "16/9",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            backgroundImage:
              "url(https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2F2024-05-11_11-05-16.png?alt=media&token=abba679b-3c9a-4dee-bd49-2f6872af8eb7)",
            backgroundSize: "auto 300%",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <img
            src="https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Fmap%2FselectedIconMapPointShadow.svg?alt=media&token=79f1fe50-77cf-4185-9d00-65e758d0f7fb"
            alt=""
            style={{
              width: "80px",
            }}
          />
        </div> */}
      </div>
    </>
  );
};

export default WidgetLocation;
