import React from "react";
import AvatarUser from "../../../../UIComponents/Avatars/AvatarUser";

const SkeletonUserPreview = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "16px",
        boxSizing: "border-box",
      }}
    >
      <AvatarUser size={80} isLoading={true} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "70%",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "var(--color-gray-100)",
            height: "8px",
            borderRadius: "100px",
          }}
        />
        <div
          style={{
            width: "50%",
            backgroundColor: "var(--color-gray-100)",
            height: "8px",
            borderRadius: "80px",
          }}
        />
        <div
          style={{
            width: "30%",
            backgroundColor: "var(--color-gray-100)",
            height: "8px",
            borderRadius: "80px",
          }}
        />
      </div>
    </div>
  );
};

export default SkeletonUserPreview;
