import { createAsyncThunk } from "@reduxjs/toolkit";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import handleThunkError from "../../functions/handleThunkError";

import { createDataThunkV4 } from "./createDataThunkV4";

export const emailSignUpThunkV4 = createAsyncThunk(
  "reducersToolkit/emailSignUpThunkV4",
  async (
    { email, password, userName },
    { dispatch, rejectWithValue, getState }
  ) => {
    const auth = getAuth();
    const state = getState();

    const catalogState = state.catalogSliceV4;

    // Достаем сохраненные данные из catalogSliceV4
    const rootUserState = catalogState?.rootNewData?.rootUserData || null;
    const previewUserState =
      catalogState?.previewNewData?.previewUserData || null;

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      if (!userCredential.user) {
        console.error("User is not authenticated.");
        throw new Error("User is not authenticated.");
      }
      // Формирование ROOT USER DATA
      const rootUserData = {
        ...rootUserState,
        user: {
          ownerName: userName || "",
        },
        userContacts: {
          email: email,
          phoneNumber: userCredential.user.phoneNumber || "",
        },
        id: userCredential.user.uid,
      };

      // Формирование PREVIEW USER DATA
      const previewUserData = {
        ...previewUserState,
        status: "moderation",
        avatarUrl: userCredential.user.photoURL || "",
        ownerName: userName || "",
        email: email,
        phoneNumber: userCredential.user.phoneNumber || "",
      };
      /**
      * createDataThunkV4({
          // Основные данные для корневого документа
          rootData: rootUserData,
          // ID корневого документа
          idRootDocument: userCredential.user.uid,
          // Данные для предварительного документа
          previewData: previewUserData,
          // Файлы для загрузки (пусто, так как при регистрации нет файлов)
          // files: {},
          // Поля для иконок (не используется при регистрации)
          iconFields: null,
          // Путь к коллекции для корневого документа
          rootCollectionPath: "users",
          // Путь к коллекции для предварительных документов
          previewCollectionPath: "manager_usersPreview",
          // Путь к общей коллекции предварительных документов (опционально)
          previewGeneralCollectionPath: "general_usersPreview",
          // Путь к документу метаданных
          metadataDocumentPath: "manager_usersPreview.metadata",
          // Путь к общим метаданным (опционально)
          metadataGeneralDocumentPath:
            "general_usersPreview.metadata",
          // Текущее состояние корневого документа
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          // Текущее состояние предварительного документа (опционально)
          previewCurrentDocumenState: "user_auth_previewCurrentData",
          // Текущее состояние общего предварительного документа (опционально)
          previewGeneralDocumenState: "general_usersPreview",
          // Название состояния загрузки
          loadingStateName: "loading_user_auth_rootCurrentData",
          // Название состояния ошибки
          errorStateName: "error_user_auth_rootCurrentData",
        })

 */
      await dispatch(
        createDataThunkV4({
          // Данные которые будут использоваться для создания коллекции в firestore в разделе root
          // data: rootCountryData,
          rootData: rootUserData,
          // id документа. Если id отсутствует, то он формируется автоматически в firestore
          idRootDocument: userCredential.user.uid,
          //  // Данные которые будут использоваться для создания коллекции в firestore в разделе preview
          previewData: previewUserData,
          // files: {
          //   // или другие массивы файлов, если есть
          //   iconFiles:,
          // },
          // Имя того массива файлов который будет использоваться для назначения иконки
          // iconFields: "iconFiles",
          // Определяем пути для коллекций и метаданных с точками в качестве разделителей
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "users",
          previewCollectionPath: "manager_usersPreview",
          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: "manager_usersPreview.metadata",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "user_auth_rootCurrentData",
          // Путь к общей коллекции предварительных документов (опционально)
          previewGeneralCollectionPath: "general_usersPreview",
          // Путь к общим метаданным (опционально)
          metadataGeneralDocumentPath: "general_usersPreview.metadata",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          // previewCurrentDocumenState: "user_auth_previewCurrentData",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_user_auth_rootCurrentData",
          errorStateName: "error_user_auth_rootCurrentData",
        })
      )
        .unwrap()
        .then((response) => {
          console.log("Пользователь успешно создан:", response);
        })
        .catch((error) => {
          console.error("Ошибка при создании пользователя:", error);
        });

      return {
        currentUser: rootUserData,
        rootData: rootUserData,
        previewData: previewUserData,
      };
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);
