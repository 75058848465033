import React, { memo, useEffect, useState } from "react";
import Preloader from "../Preloader/Preloader";
import SkeletonPhotosGallery from "./SkeletonPhotosGallery";

const PhotosGallery = ({ photos, onClick, isLoading, info, style }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);

  // Преобразуем строку в массив, если photos это строка
  const normalizedPhotos = Array.isArray(photos) ? photos : [photos];

  // Найти фото с флагом priority
  const priorityPhotoIndex = normalizedPhotos?.findIndex(
    (photo) => photo?.priority || 0
  );

  useEffect(() => {
    if (priorityPhotoIndex !== -1) {
      setCurrentPhotoIndex(priorityPhotoIndex);
    }
  }, [priorityPhotoIndex]);

  const handlePhotoChange = (index) => {
    setIsLoadingFiles(true);
    setCurrentPhotoIndex(index);
  };

  const handleImageLoad = () => {
    setIsLoadingFiles(false);
  };

  return (
    <div
      style={{
        width: "100%",
        aspectRatio: "16/9",
        backgroundColor: "var(--color-gray-100)",
        boxSizing: "border-box",
        borderRadius: "16px",
        position: "relative",
        backgroundImage: `url(${
          normalizedPhotos
            ? normalizedPhotos[currentPhotoIndex]?.url ||
              normalizedPhotos[currentPhotoIndex]
            : ""
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        ...style, // Применяем переданные стили
      }}
    >
      <div
        style={{
          width: "100%",
          height: "84%",
        }}
        onClick={onClick}
      />
      {(isLoading || isLoadingFiles) && (
        <div
          onClick={onClick}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <Preloader
            colorSecond="--color-white"
            colorPrimary="--color-primary"
          />
        </div>
      )}
      <img
        src={
          normalizedPhotos
            ? normalizedPhotos[currentPhotoIndex]?.url ||
              normalizedPhotos[currentPhotoIndex]
            : ""
        }
        alt={`Photo ${currentPhotoIndex}`}
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />

      {normalizedPhotos.length > 1 && (
        <div
          style={{
            position: "absolute",
            bottom: "8px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
            width: "100%",
            padding: "0 16%",
            boxSizing: "border-box",
            height: "100%",
          }}
        >
          {normalizedPhotos.map((photo, index) => (
            <div
              key={index}
              onClick={() => handlePhotoChange(index)}
              style={{
                width: `calc(100% / ${normalizedPhotos.length})`,
                height: "calc(100% - 8px)",
                cursor: "pointer",
                display: "flex",
                alignItems: "flex-end",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "6px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  key={index}
                  style={{
                    width: "100%",
                    backgroundColor: "var(--color-white)",
                    height: index === currentPhotoIndex ? "6px" : "4px",
                    borderRadius: "80px",
                    opacity: index === currentPhotoIndex ? "1" : "0.5",
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      {info}
    </div>
  );
};

export default memo(PhotosGallery);
