import React, { useEffect, useState } from "react";
import Button from "../../../../UIComponents/Buttons/Button.js";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox.js";
import WidgetBikeBasicInformationModeration from "../BikesV3/WidgetBikeBasicInformationModeration.js";
import WidgetBrandCategoryModeration from "../BikesV3/WidgetBrandCategoryModeration.js";
import WidgetQuntityBikeModeration from "../BikesV3/WidgetQuntityBikeModeration.js";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar.js";
import WidgetListFileV4 from "../../../../UIComponents/UploadFiles/WidgetListFileV4.js";
import WidgetUploadFilesV4 from "../../../../UIComponents/UploadFiles/WindgetUploadFilesV4.js";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4.js";
import { v4 as uuidv4 } from "uuid";
import { createDataThunkV4 } from "../../../../../reducersToolkit/thunk/createDataThunkV4.js";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/updateDataThunkV4.js";
import { deleteDataThunkV4 } from "../../../../../reducersToolkit/thunk/deleteDataThunkV4.js";
import WidgetBikeModerationV4 from "./WidgetBikeModerationV4.js";
import {
  subscribeRootDocumentsThunkV5,
  unsubscribeRootDocumentsThunkV5,
} from "../../../../../reducersToolkit/thunk/subscribeRootDocumentsThunkV5.js";
import WidgetBikeRateV4 from "../BikesV3/WidgetBikeRateV4.js";
import PageBox from "../../../../UIComponents/ContentBox/PageBox.js";
import { useNavigate, useParams } from "react-router-dom";
import SkeletonBikeRate from "./SkeletonBikeRate.js";
import SkeletonQuntityBike from "./SkeletonQuntityBike.js";
import SkeletonBrandCategory from "./SkeletonBrandCategory.js";
import WidgetAllertDelete from "./WidgetAllertDelete.js";
import SkeletonTitleDescription from "./SkeletonBikeModeration.js";

/**
 * Компонент страницы детализации велосипеда с поддержкой режимов модерации
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {Array} props.breadcrumbPath - Путь для хлебных крошек
 * @param {string} props.title - Заголовок страницы
 * @param {string} props.mode - Режим работы компонента: 'new', 'moderatorAdded', 'moderation', 'order', 'replacement', 'selected'
 * @returns {JSX.Element} Страница детализации велосипеда
 */
const PageBikeDetailModerationV4 = ({ breadcrumbPath, title, mode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bikeId, rentalPointId, userId } = useParams();

  /**
   * Определение режимов работы компонента
   */
  const isNewMode = mode === "new";
  const isModeratorAddedMode = mode === "moderatorAdded";
  const isModerationMode = mode === "moderation";
  const isOrderMode = mode === "order";
  const isReplacementMode = mode === "replacement";
  const isSelectedMode = mode === "selected";

  /**
   * Получение данных из Redux Store
   */
  const {
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    loading,
  } = useSelector((state) => state.catalogSliceV4);

  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState(false);

  // Основная информация о велосипеде
  const [basicInformation, setBasicInformation] = useState({});
  const [bikeRateInfo, setBikeRateInfo] = useState([]);
  const [bikeMetrics, setBikeMetrics] = useState({});
  const [bikeStatus, setBikeStatus] = useState({});
  const [bikeQuantity, setBikeQuantity] = useState(0);

  // Данные для предварительного просмотра и сохранения
  const [previewBikeData, setPreviewBikeData] = useState(null);
  const [rootNewBikeData, setRootNewBikeData] = useState(null);
  const [newBikeRootData, setNewBikeRootData] = useState(null);
  const [newBikePreviewData, setNewBikePreviewData] = useState(null);
  const [editBikePreviewData, setEditBikePreviewData] = useState(null);
  const [editBikeRootData, setEditBikeRootData] = useState(null);

  const [previewPhotoFiles, setPreviewPhotoFiles] = useState([]);
  const [newPhotoFiles, setNewPhotoFiles] = useState(null);

  // Состояние активности кнопки обновления и загрузки
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(true);
  const [isLoadingUpdateBike, setIsLoadingUpdateBike] = useState(false);
  const [isLoadingCreateBike, setIsLoadingCreateBike] = useState(false);
  const [isLoadingDeleteBike, setIsLoadingDeleteBike] = useState(false);
  const [loading_manager_bikes_update, setLoading_manager_bikes_update] =
    useState(false);
  const [loading_manager_bikes_create, setLoading_manager_bikes_create] =
    useState(false);
  const [isUploadingBikePhotos, setIsUploadingBikePhotos] = useState(false);

  const [availableBrands, setAvailableBrands] = useState([]);
  const [availableCategories, setAvailableCategories] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  // Данные для местоположения
  const [rentalPointDirectory, setRentalPointDirectory] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);

  const [selectedBikeRootData, setSelectedBikeRootData] = useState(null);

  const [bikeModerationPreviewData, setBikeModerationPreviewData] =
    useState(null);

  // Флаги изменений для активации кнопки обновления
  const [isChangeQuntityBike, setIsChangeQuntityBike] = useState(false);
  const [isChangeBrandCategory, setIsChangeBrandCategory] = useState(false);
  const [isChangeUploadFiles, setIsChangeUploadFiles] = useState(false);
  const [isChangeBikeModeration, setIsChangeBikeModeration] = useState(false);
  const [isChangeBasicInformation, setIsChangeBasicInformation] =
    useState(false);
  const [isChangeRate, setIsChangeRate] = useState(false);
  const [deleteState, setDeleteState] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const [isResetUploadFiles, setIsResetUploadFiles] = useState(false);
  const [isResetQuntityBike, setIsResetQuntityBike] = useState(false);

  /**
   * Инициализация стейтов
   */
  useEffect(() => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_BbasicInformation",
        data: null,
      })
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_bike_moderation",
        data: null,
      })
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_brand",
        data: null,
      })
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_category",
        data: null,
      })
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_bikeRate",
        data: null,
      })
    );

    setNewPhotoFiles(null);
    setIsReset(false);

    setIsChangeQuntityBike(false);
    setIsChangeBrandCategory(false);
    setIsChangeUploadFiles(false);
    setIsChangeBikeModeration(false);
    setIsChangeBasicInformation(false);
    setIsChangeRate(false);
  }, [dispatch, isReset]);

  /**
   * Инициализация данных ROOT в redux
   */
  // useEffect(() => {
  //   if (isModeratorAddedMode) {
  //     dispatch(
  //       setCurrentRootData({
  //         rootCurrentDocumentState: "newRootBike_create",
  //         data: rootNewData?.rootBikeData || {},
  //       })
  //     );
  //   }
  // }, [dispatch, rootNewData?.rootBikeData, isModeratorAddedMode]);

  /**
   * Отслеживание состояния загрузки
   */
  useEffect(() => {
    setIsUploadingBikePhotos(loading?.loading_upload_bikePhotoFiles || false);
    setLoading_manager_bikes_update(
      loading?.loading_manager_bikes_update || false
    );
    setLoading_manager_bikes_create(
      loading?.loading_manager_bikes_create || false
    );
  }, [
    loading?.loading_upload_bikePhotoFiles,
    loading?.loading_manager_bikes_update,
    loading?.loading_manager_bikes_create,
  ]);

  /**
   * Загрузка данных из rootNewData и previewNewData
   */
  useEffect(() => {
    setRootNewBikeData(rootNewData?.rootBikeData || null);
    setPreviewBikeData(previewNewData?.previewBikeData || null);
  }, [rootNewData?.rootBikeData, previewNewData?.previewBikeData]);

  /**
   * Загрузка данных из tempPreviewData: категории, бренды, идентификаторы
   */
  useEffect(() => {
    // Загрузка категорий и брендов
    setAvailableBrands(tempPreviewData?.brandsPreview || []);
    setAvailableCategories(tempPreviewData?.categoriesPreview || []);

    // Загрузка идентификаторов
    const bikeModeration =
      tempPreviewData?.choosingBikeForModeration_previewData;
    // setBikeRootDocumentId(bikeModeration?.idRootDocument || null);
    // setBikePreviewDocumentId(bikeModeration?.idPreviewDocument || null);
    setBikeModerationPreviewData(bikeModeration || null);
  }, [
    tempPreviewData?.brandsPreview,
    tempPreviewData?.categoriesPreview,
    tempPreviewData?.choosingBikeForModeration_previewData,
    tempPreviewData?.user_moderation_selectedPreviewCurrentData,
    isModeratorAddedMode,
    isModerationMode,
    isReplacementMode,
    isOrderMode,
    isSelectedMode,
  ]);

  /**
   * Загрузка данных из tempData
   */
  useEffect(() => {
    // Загрузка точки проката в режиме добавления модератором
    // if (isModeratorAddedMode) {
    //   setRentalPointRootDocumentId(
    //     tempData?.rentalPoint_moderation_selectedRentalPoint?.idRootDocument ||
    //       null
    //   );
    // }

    // Загрузка базовой информации и статуса
    setRentalPointDirectory(
      tempData?.rentalPoint_moderation_selectedRentalPoint || null
    );
    setSelectedCountry(
      tempData?.rentalPoint_moderation_selectedRentalPoint?.country || null
    );
    setSelectedCity(
      tempData?.rentalPoint_moderation_selectedRentalPoint?.city || null
    );
    // setSlotIdsData(tempData?.currentSlotIds || null);
  }, [
    tempData?.rentalPoint_moderation_selectedRentalPoint,
    tempData?.currentSlotIds,
    isModeratorAddedMode,
  ]);

  /**
   * Загрузка данных из tempRootData в зависимости от режима
   */
  useEffect(() => {
    if (isNewMode || isModeratorAddedMode) {
      // Новый велосипед или добавление модератором
      const newBikeData = rootNewData?.rootBikeData || {};

      setBasicInformation(
        tempData?.tempData_BbasicInformation ||
          newBikeData?.basicInformation ||
          {}
      );
      setBikeRateInfo(tempData?.tempData_bikeRate || newBikeData?.rate || []);
      setSelectedBrand(
        tempData.tempData_brand || newBikeData?.directory?.brand || null
      );
      setSelectedCategory(
        tempData.tempData_category || newBikeData?.directory?.category || null
      );
      setBikeMetrics(newBikeData?.metricsBike || {});
      setPreviewPhotoFiles(newBikeData?.files?.photoFiles || []);
      setBikeStatus(
        newBikeData?.statusHistory || {
          currentStatus: "moderation",
          comments: [],
        }
      );
    } else if (
      isModerationMode ||
      isReplacementMode ||
      isOrderMode ||
      isSelectedMode
    ) {
      setDeleteState(tempRootData?.selectedBike_rootData?.remove || null);
      // Редактирование существующего велосипеда
      // setOrderRootData(tempRootData?.order_selectedCompletedRootOrder || null);
      // setCartData(
      //   tempRootData?.order_selectedCompletedRootOrder?.currentCart || null
      // );
      setSelectedBikeRootData(tempRootData?.selectedBike_rootData || {});

      // Загрузка основных данных с учетом временных изменений
      setBasicInformation(
        tempData?.tempData_BbasicInformation ||
          tempRootData?.selectedBike_rootData?.basicInformation ||
          {}
      );
      setBikeRateInfo(
        tempData?.tempData_bikeRate ||
          tempRootData?.selectedBike_rootData?.rate ||
          []
      );
      setBikeStatus(
        tempData?.tempData_bike_moderation ||
          tempRootData?.selectedBike_rootData?.statusHistory || {
            currentStatus: "moderation",
            comments: [],
          }
      );

      setSelectedBrand(
        tempData.tempData_brand ||
          tempRootData?.selectedBike_rootData?.directory?.brand ||
          null
      );
      setSelectedCategory(
        tempData.tempData_category ||
          tempRootData?.selectedBike_rootData?.directory?.category ||
          null
      );
      setBikeMetrics(tempRootData?.selectedBike_rootData?.metricsBike || {});
      setPreviewPhotoFiles(
        tempRootData?.selectedBike_rootData?.files?.photoFiles || []
      );
      // setIsDeleteState(!!tempRootData?.selectedBike_rootData?.remove || null);
      // setDeleteState(tempRootData?.selectedBike_rootData?.remove || null);
    }
  }, [
    tempData.tempData_brand,
    tempData.tempData_category,
    tempData?.tempData_BbasicInformation,
    tempData?.tempData_bike_moderation,
    tempData?.tempData_bikeRate,
    rootNewData?.rootBikeData,
    tempRootData?.selectedBike_rootData,
    tempRootData?.order_selectedCompletedRootOrder,
    isNewMode,
    isModeratorAddedMode,
    isModerationMode,
    isReplacementMode,
    isOrderMode,
    isSelectedMode,
  ]);

  /**
   * Формирование объекта ROOT
   */
  useEffect(() => {
    const basicData = {
      basicInformation: basicInformation,
      bikeMetrics: bikeMetrics,
      directory: {
        category: selectedCategory,
        brand: selectedBrand,
        country: selectedCountry,
        city: selectedCity,
        rentalPoint: rentalPointDirectory,
      },
      metricsBike: {
        quantityBikes: bikeQuantity,
      },
      rate: bikeRateInfo,
    };
    // Новый байк
    setNewBikeRootData({
      ...rootNewBikeData,
      ...basicData,
      statusHistory: {
        currentStatus: "moderation",
        comments: [],
      },
      created: Date.now(),
    });

    // Редактирование байка
    setEditBikeRootData({
      ...selectedBikeRootData,
      ...basicData,
      // statusHistory: bikeStatus,
      updated: Date.now(),
    });
  }, [
    userId,
    rentalPointId,
    rootNewBikeData,
    bikeStatus,
    basicInformation,
    bikeRateInfo,
    bikeMetrics,
    selectedBrand,
    selectedCategory,
    bikeQuantity,
    selectedCountry,
    selectedCity,
    rentalPointDirectory,
    selectedBikeRootData,
  ]);

  /**
   * Формирование объекта PREVIEW
   */

  useEffect(() => {
    // Подготовка сокращенных описаний для рейтинга
    const ratePreview = bikeRateInfo.map((item) => ({
      ...item,
      description: {
        ...item.description,
        descriptionOriginal: item.description.descriptionOriginal.slice(0, 250),
      },
    }));

    // Базовые данные для всех режимов
    const basePreviewData = {
      status: bikeStatus?.currentStatus || "moderation",
      created: Date.now(),
      title: basicInformation?.title?.titleOriginal || "",
      description: (
        basicInformation?.description?.descriptionOriginal || ""
      ).slice(0, 250),
      rate: ratePreview,
      category: selectedCategory,
      brand: selectedBrand,
      country: {
        title: selectedCountry?.title || null,
        id: selectedCountry?.idRootDocument || null,
      },
      city: {
        title: selectedCity?.title || null,
        id: selectedCity?.idRootDocument || null,
      },
      quantity: bikeQuantity,
      remove: deleteState,
    };

    setNewBikePreviewData({
      ...previewBikeData,
      ...basePreviewData,
      idRootDocumentUser: userId,
      idRootDocumentRentalPoint: rentalPointId,
    });

    setEditBikePreviewData({
      ...basePreviewData,
      idRootDocumentUser: userId,
      idRootDocumentRentalPoint: rentalPointId,
    });
  }, [
    previewBikeData,
    bikeModerationPreviewData,
    bikeStatus,
    basicInformation?.title?.titleOriginal,
    basicInformation?.description?.descriptionOriginal,
    selectedCategory,
    selectedBrand,
    bikeRateInfo,
    rentalPointId,
    userId,
    selectedCountry,
    selectedCity,
    bikeQuantity,
    isModeratorAddedMode,
    isModerationMode,
    isOrderMode,
    deleteState,
  ]);

  /**
   * Проверка изменений для активации кнопки "Обновить"
   */
  useEffect(() => {
    if (isModerationMode) {
      setIsButtonActive(
        isChangeBrandCategory ||
          isChangeQuntityBike ||
          isChangeUploadFiles ||
          isChangeBikeModeration ||
          isChangeBasicInformation ||
          isChangeRate
      );
    } else if (isNewMode || isModeratorAddedMode) {
      // В режиме создания нового велосипеда кнопки всегда активны
      setIsButtonActive(
        isChangeBrandCategory &&
          isChangeQuntityBike &&
          isChangeUploadFiles &&
          isChangeBasicInformation &&
          isChangeRate
      );
    }
  }, [
    isModerationMode,
    isNewMode,
    isModeratorAddedMode,
    isChangeBrandCategory,
    isChangeQuntityBike,
    isChangeUploadFiles,
    isChangeBikeModeration,
    isChangeBasicInformation,
    isChangeRate,
  ]);

  /**
   * Подписка на документ велосипеда для режимов редактирования
   */
  useEffect(() => {
    if (
      (isModerationMode ||
        isReplacementMode ||
        isOrderMode ||
        isSelectedMode) &&
      bikeId
    ) {
      console.log(`Выполняем подписку на документ: ${bikeId}`);

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV5({
              collectionPath: "bikes",
              rootCurrentDocumentState: "selectedBike_rootData",
              idRootDocument: bikeId,
              loadingStateName: "loading_bikes",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      // Отписка при размонтировании компонента
      return () => {
        dispatch(
          unsubscribeRootDocumentsThunkV5({
            collectionPath: "bikes",
          })
        );
      };
    }
  }, [
    dispatch,
    isModerationMode,
    isReplacementMode,
    isOrderMode,
    isSelectedMode,
    bikeId,
  ]);

  /**
   * Обновление данных велосипеда
   * @async
   */
  const updateData = async () => {
    if (!bikeId) return;

    try {
      setIsLoadingUpdateBike(true);
      setIsDeleteButtonActive(false);
      await dispatch(
        updateDataThunkV4({
          rootData: editBikeRootData,
          previewData: editBikePreviewData,
          newFiles: {
            photoFiles: newPhotoFiles,
          },
          iconFields: "photoFiles",
          idPreviewDocument: null,
          idRootDocument: bikeId,
          rootCollectionPath: "bikes",
          previewCollectionPath: `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}`,
          metadataDocumentPath: `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}.metadata`,
          metadataGeneralDocumentPath: "general_bikesPreview.metadata",
          rootCurrentDocumentState: "selectedBike_rootData",
          previewCurrentDocumenState: null,
          previewGeneralDocumenState: null,
          previewGeneralCollectionPath: "general_bikesPreview",
          previewGeneralDocumentState: "general_bikesPreview",
          loadingStateName: "loading_manager_bikes_update",
          errorStateName: "currentError",
          isChangeStat: null,
        })
      ).unwrap();

      console.log("Велосипед успешно обновлен");

      // Reset all change flags after successful update
      setIsChangeQuntityBike(false);
      setIsChangeBrandCategory(false);
      setIsChangeUploadFiles(false);
      setIsChangeBikeModeration(false);
      setIsChangeBasicInformation(false);
      setIsChangeRate(false);

      // Кнопка удаления всегда должна быть активна после завершения обновления
      setIsDeleteButtonActive(true);
    } catch (error) {
      console.error("Ошибка при обновлении велосипеда:", error);
      // В случае ошибки восстанавливаем активность кнопки обновления
      setIsButtonActive(true);
    } finally {
      // Очищаем состояния файлов независимо от результата
      setNewPhotoFiles(null);
      // Сбрасываем флаг загрузки
      setIsLoadingUpdateBike(false);
    }
  };

  /**
   * Создание нового велосипеда
   * @async
   */
  const createData = async () => {
    try {
      // Устанавливаем состояние загрузки
      setIsLoadingCreateBike(true);
      // Не деактивируем кнопки, они должны оставаться активными
      // setIsButtonActive(false);

      // Вызываем thunk для создания данных велосипеда
      const result = await dispatch(
        createDataThunkV4({
          rootData: newBikeRootData,
          previewData: newBikePreviewData,
          files: {
            photoFiles: newPhotoFiles,
          },
          iconFields: "photoFiles",
          rootCollectionPath: "bikes",
          previewCollectionPath: `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}`,
          previewGeneralCollectionPath: "general_bikesPreview",
          metadataDocumentPath: `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}.metadata`,
          metadataGeneralDocumentPath: "general_bikesPreview.metadata",
          rootCurrentDocumentState: "selectedBike_rootData",
          previewGeneralDocumentState: "general_bikesPreview",
          loadingStateName: "loading_manager_bikes_create",
          errorStateName: "currentError",
        })
      ).unwrap();

      console.log("Велосипед успешно создан", result);

      // После успешного создания выполняем переадресацию
      navigate(`/ModerationRentalPoint/${rentalPointId}/${userId}`);
    } catch (error) {
      console.error("Ошибка при создании велосипеда:", error);
    } finally {
      // В любом случае сбрасываем состояние загрузки
      setIsLoadingCreateBike(false);
    }
  };

  /**
   * Удаление байка
   */
  const deleteData = async () => {
    try {
      setIsLoadingDeleteBike(true);
      setIsDeleteButtonActive(false);
      await dispatch(
        deleteDataThunkV4({
          idPreviewDocument: null,
          idRootDocument: bikeId,
          rootCollectionPath: "bikes",
          previewCollectionPath: `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}`,
          previewGeneralCollectionPath: "general_bikesPreview",
          metadataDocumentPath: `manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}.metadata`,
          metadataGeneralDocumentPath: "general_bikesPreview.metadata",

          rootCurrentDocumentState: "selectedBike_rootData",
          previewCurrentDocumentState: "manager_bikesPreview",
          previewGeneralDocumentState: "general_bikesPreview",

          loadingStateName: "loading_deleteBike",
          errorStateName: "currentError",
        })
      ).unwrap();

      console.log("Байк успешно удален");
    } catch (error) {
      console.error("Ошибка при удалении байка:", error);
    } finally {
      setIsLoadingDeleteBike(false);
      setIsDeleteButtonActive(true);
      // Активируем кнопку обновления после завершения операции удаления
      setIsButtonActive(true);
    }
  };

  /**
   * Обработчик добавления нового велосипеда
   */
  const handleAddBike = () => {
    // Не деактивируем кнопки, они должны оставаться активными
    // setIsButtonActive(false);
    createData();
  };

  /**
   * Обработчик отмены действия
   */
  const handleCancel = () => {
    // Сбрасываем временные данные модерации
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_bike_moderation",
        data: null,
      })
    );

    setIsResetUploadFiles(true);
    setIsResetQuntityBike(true);
    setIsReset(true);
  };

  /**
   * Обработчик обновления существующего велосипеда
   */

  const handleUpdateBike = () => {
    // Устанавливаем флаг загрузки для индикации процесса
    setIsLoadingUpdateBike(true);

    // Деактивируем кнопки во избежание повторных кликов
    setIsButtonActive(false);

    // Вызываем функцию обновления данных
    updateData();
  };

  /**
   * Обработчик удаления велосипеда
   */
  const handleDeleteBike = () => {
    setIsLoadingDeleteBike(true);
    setIsDeleteButtonActive(false);
    deleteData();
  };

  /**
   * Обработчик закрытия модального окна заказа
   */
  const handleCloseOrderModal = () => {
    setIsOrderDetailModalOpen(false);
  };

  /**
   * Рендеринг кнопок в зависимости от режима
   * @returns {JSX.Element} Панель кнопок
   */

  return (
    <>
      {/* Модальное окно детализации заказа */}
      {/* <PopupDirrectory
        isOpen={isOrderDetailModalOpen}
        onClose={handleCloseOrderModal}
        title="Детализация заказа"
      >
        <WindowOrderDetailModeration
          mode="new"
          onClose={handleCloseOrderModal}
        />
      </PopupDirrectory> */}

      <PageBox path={breadcrumbPath} title={title}>
        {/* Состояние байка */}
        {deleteState && (
          <ContentBox
            style={{
              backgroundColor: "var(--color-alarm)",
              padding: "8px 0",
            }}
          >
            <WidgetAllertDelete
              title="Удаляем байк"
              onClick={handleDeleteBike}
              deleteState={deleteState}
            />
          </ContentBox>
        )}
        {/* Секция основной информации */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          {/* Базовая информация о велосипеде */}
          <ContentBox
            position={isModerationMode ? "end" : ""}
            isLoading={
              isLoadingUpdateBike ||
              isLoadingCreateBike ||
              isLoadingDeleteBike ||
              loading_manager_bikes_update ||
              loading_manager_bikes_create ||
              isUploadingBikePhotos
            }
            // isLoading={true}
            skeleton={<SkeletonTitleDescription />}
          >
            <WidgetBikeBasicInformationModeration
              isChangeState={setIsChangeBasicInformation}
              mode={mode}
              basicInformation={basicInformation}
            />
          </ContentBox>

          {/* Секция модерации (только в режиме модерации) */}
          {isModerationMode && (
            <ContentBox
              position="start"
              isLoading={
                isLoadingUpdateBike ||
                isLoadingCreateBike ||
                isLoadingDeleteBike ||
                loading_manager_bikes_update ||
                loading_manager_bikes_create ||
                isUploadingBikePhotos
              }
              // isLoading={true}
              skeleton={<SkeletonTitleDescription />}
            >
              <WidgetBikeModerationV4
                isChangeState={setIsChangeBikeModeration}
                mode={mode}
                bikeStatus={bikeStatus}
              />
            </ContentBox>
          )}
        </div>

        {/* Секция фотографий */}
        <ContentBox>
          <WidgetListFileV4
            // isLoading={true}
            isLoading={
              isLoadingUpdateBike ||
              isLoadingCreateBike ||
              isLoadingDeleteBike ||
              loading_manager_bikes_update ||
              loading_manager_bikes_create ||
              isUploadingBikePhotos
            }
            // isChangeState={setIsChangeUploadFiles}
            viewFiles={previewPhotoFiles}
            fieldName="files.photoFiles"
            rootCollectionPath="bikes"
            previewCollectionPath={`manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}`}
            previewGeneralCollectionPath="general_bikesPreview"
            metadataDocumentPath={`manager_bikesPreview.userIds.${userId}.reintalPointIds.${rentalPointId}.metadata`}
            metadataGeneralDocumentPath="general_bikesPreview.metadata"
            idRootDocument={bikeId}
            loadingStateName="loading_upload_bikePhotoFiles"
            errorStateName="currentError"
            rootCurrentDocumentState="selectedBike_rootData"
            previewCurrentDocumentState="manager_bikesPreview"
            previewGeneralDocumentState="general_bikesPreview"
            mode="edit"
          />

          <WidgetUploadFilesV4
            isChangeState={setIsChangeUploadFiles}
            // isLoading={true}
            isLoading={
              isLoadingUpdateBike ||
              isLoadingCreateBike ||
              isLoadingDeleteBike ||
              loading_manager_bikes_update ||
              loading_manager_bikes_create ||
              isUploadingBikePhotos
            }
            id={uuidv4()}
            title="Добавить фотографии"
            description="Добавьте фотографии велосипеда для лучшего представления."
            files={setNewPhotoFiles}
            isReset={isResetUploadFiles}
            setIsReset={setIsResetUploadFiles}
          />
        </ContentBox>

        {/* Секция категорий и брендов */}
        <ContentBox
          isLoading={
            isLoadingUpdateBike ||
            isLoadingCreateBike ||
            isLoadingDeleteBike ||
            loading_manager_bikes_update ||
            loading_manager_bikes_create ||
            isUploadingBikePhotos
          }
          // isLoading={true}
          skeleton={<SkeletonBrandCategory />}
        >
          <WidgetBrandCategoryModeration
            isChangeState={setIsChangeBrandCategory}
            mode={mode}
            brands={availableBrands}
            categories={availableCategories}
            initialSelectedBrand={selectedBrand}
            initialSelectedCategory={selectedCategory}
          />
        </ContentBox>

        {/* Секция метрик велосипеда (количество) */}
        <ContentBox
          isLoading={
            isLoadingUpdateBike ||
            isLoadingCreateBike ||
            isLoadingDeleteBike ||
            loading_manager_bikes_update ||
            loading_manager_bikes_create ||
            isUploadingBikePhotos
          }
          // isLoading={true}
          skeleton={<SkeletonQuntityBike />}
        >
          <WidgetQuntityBikeModeration
            mode={mode}
            metricsBike={bikeMetrics}
            setQuantityBikes={setBikeQuantity}
            isChangeState={setIsChangeQuntityBike}
            isReset={isResetQuntityBike}
            setIsReset={setIsResetQuntityBike}
          />
        </ContentBox>

        {/* Секция рейтинга */}
        <ContentBox
          isLoading={
            isLoadingUpdateBike ||
            isLoadingCreateBike ||
            isLoadingDeleteBike ||
            loading_manager_bikes_update ||
            loading_manager_bikes_create ||
            isUploadingBikePhotos
          }
          // isLoading={true}
          skeleton={<SkeletonBikeRate />}
        >
          <WidgetBikeRateV4
            mode={mode}
            rate={bikeRateInfo}
            isChangeState={setIsChangeRate}
          />
        </ContentBox>

        {/* Панель кнопок */}
        <ButtonsBar>
          {isModerationMode && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "16px",
                width: "100%",
              }}
            >
              {deleteState ? (
                <Button
                  type="small"
                  color="--color-success"
                  label="Restore"
                  active={isDeleteButtonActive && !isButtonActive}
                  onClick={handleDeleteBike}
                  allert={
                    <div
                      style={{
                        padding: "48px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "16px",
                        textAlign: "center",
                      }}
                    >
                      <h1>Восстанавливаем?</h1>
                    </div>
                  }
                />
              ) : (
                <Button
                  type="small"
                  color="--color-alarm"
                  label="Delete"
                  active={isDeleteButtonActive && !isButtonActive}
                  onClick={handleDeleteBike}
                  allert={
                    <div
                      style={{
                        padding: "48px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "16px",
                        textAlign: "center",
                      }}
                    >
                      <h1>Удаляем?</h1>
                    </div>
                  }
                />
              )}

              <Button
                type="small"
                color="--color-black"
                label="Отмена"
                active={isButtonActive}
                onClick={handleCancel}
              />
              <Button
                type="small"
                label="Обновить"
                active={isButtonActive}
                onClick={handleUpdateBike}
              />
            </div>
          )}

          {(isModeratorAddedMode || isNewMode) && (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "16px",
                width: "100%",
              }}
            >
              <Button
                type="small"
                label="Добавить велосипед"
                active={!isLoadingCreateBike && isButtonActive}
                onClick={handleAddBike}
              />
              <Button
                type="small"
                color="--color-black"
                label="Отмена"
                active={!isLoadingCreateBike}
                onClick={handleCancel}
              />
            </div>
          )}
        </ButtonsBar>
      </PageBox>
    </>
  );
};

export default PageBikeDetailModerationV4;
