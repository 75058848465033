import React, { useEffect, useState } from "react";
import SegmentedControls from "../../../../UIComponents/SegmentedControls/SegmentedControls";
import Button from "../../../../UIComponents/Buttons/Button";
import Textarea from "../../../../UIComponents/Textarea/Textarea";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import { v4 as uuidv4 } from "uuid";
import WidgetDialogModeratorToClient from "./WidgetDialogModeratorToClient";

const WindowRentalPointModeration = ({ onClose, mode, isChangeState }) => {
  // Диспатч
  const dispatch = useDispatch();

  // Хранилище
  const { tempRootData, tempData, tempPreviewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // URL аватара пользователя
  const [userAvatarFiles, setUserAvatarFiles] = useState(null);

  // Имя пользователя
  const [userName, setUserName] = useState(null);

  // Роль пользователя
  const [userRole, setUserRole] = useState(null);

  // Состояние для статуса модерации
  const [rentalPointStateModeration, setRentalPointStateModeration] =
    useState(null);
  console.log("rentalPointStateModeration", rentalPointStateModeration);
  // Состояние для комментариев
  const [comments, setComments] = useState([]);

  // Отфильтрованные комментарии (без черновика)
  const [displayComments, setDisplayComments] = useState([]);

  // Состояние для черновика
  const [hasDraftComment, setHasDraftComment] = useState(false);
  const [draftCommentId, setDraftCommentId] = useState(null);

  // Состояние для комментария модератора
  const [moderatorComment, setModeratorComment] = useState("");

  // Состояние для id пользователя
  const [userId, setUserId] = useState(null);

  // Состояние для нового комментария
  const [newData, setNewData] = useState(null);

  // Состояние для активации кнопки Apply
  const [isCommentActive, setIsCommentActive] = useState(false);

  /**
   * Наполнение useState из tempData
   */
  useEffect(() => {
    // Устанавливаем комментарии из statusHistory
    setComments(
      tempData?.tempData_rentalPoint_moderation?.comments ||
        tempRootData?.selectedBike_rentalPont?.statusHistory?.comments ||
        []
    );

    // Инициализация статуса модерации только из временного хранилища
    // Не используем tempRootData?.selectedBike_rentalPont?.statusHistory?.currentStatus
    // так как это значение должно выбираться пользователем вручную
    if (tempData?.tempData_rentalPoint_moderation?.currentStatus) {
      setRentalPointStateModeration(
        tempData.tempData_rentalPoint_moderation.currentStatus
      );
    } else {
      // Если нет данных в tempData, оставляем rentalPointStateModeration пустым (null)
      setRentalPointStateModeration(null);
    }

    // Параметр user_auth_rootCurrentData инициализирован в App.js
    setUserId(tempRootData?.user_auth_rootCurrentData?.id || null);

    // Получение аватара из ROOT_DATA с приоритетом
    const avatarFiles =
      tempRootData?.user_auth_rootCurrentData?.files?.userAvatarFiles || [];

    // Ищем аватар с priority = true или берем первый, если такого нет
    let priorityAvatar = null;
    if (Array.isArray(avatarFiles) && avatarFiles.length > 0) {
      priorityAvatar =
        avatarFiles.find((avatar) => avatar.priority === true) ||
        avatarFiles[0];
    }
    // Устанавливаем аватар пользователя
    setUserAvatarFiles(priorityAvatar);
    // Устанавливаем имя пользователя
    setUserName(tempRootData?.user_auth_rootCurrentData?.user?.ownerName);
    // Устанавливаем роль пользователя (по умолчанию admin для модераторов)
    setUserRole(tempRootData?.user_auth_rootCurrentData?.user?.role || "admin");
  }, [
    tempRootData?.selectedBike_rentalPont?.statusHistory?.comments,
    tempData?.tempData_rentalPoint_moderation?.comments,
    tempData?.tempData_rentalPoint_moderation?.currentStatus,
    tempRootData?.user_auth_rootCurrentData,
  ]);

  /**
   * Загрузка последнего комментария для Textarea
   */
  useEffect(() => {
    // Если есть комментарии в tempData
    if (tempData?.tempData_rentalPoint_moderation?.comments?.length > 0) {
      const tempComments = tempData.tempData_rentalPoint_moderation.comments;
      // Получаем самый свежий комментарий через сортировку
      const sortedComments = [...tempComments].sort((a, b) => {
        const timeA = a.created || a.timestamp || 0;
        const timeB = b.created || b.timestamp || 0;
        return timeB - timeA; // Сортировка от новых к старым
      });

      const lastComment = sortedComments[0]; // Самый свежий комментарий

      // Проверяем, является ли последний комментарий черновиком текущего пользователя
      const isRecentComment =
        lastComment.created &&
        Date.now() - lastComment.created < 24 * 60 * 60 * 1000;
      const isCurrentUser =
        lastComment.user && lastComment.user.userId === userId;

      if (isRecentComment && isCurrentUser) {
        // Если это недавний комментарий текущего пользователя, считаем его черновиком
        setModeratorComment(lastComment.content || "");
        setHasDraftComment(true);
        setDraftCommentId(lastComment.id);
        // Активируем кнопку, если есть текст
        setIsCommentActive(!!lastComment.content);
      } else {
        // Если это не черновик, просто очищаем поле
        setModeratorComment("");
        setHasDraftComment(false);
        setDraftCommentId(null);
        setIsCommentActive(false);
      }
    }
  }, [tempData?.tempData_rentalPoint_moderation?.comments, userId]);

  /**
   * Фильтрация комментариев для отображения (исключаем черновик)
   */
  useEffect(() => {
    if (hasDraftComment && draftCommentId) {
      // Фильтруем комментарии, исключая черновик
      const filteredComments = comments.filter(
        (comment) => comment.id !== draftCommentId
      );
      setDisplayComments(filteredComments);
    } else {
      // Если нет черновика, отображаем все комментарии
      setDisplayComments(comments);
    }
  }, [comments, hasDraftComment, draftCommentId]);

  /**
   * Формирование объектов newData
   */
  useEffect(() => {
    if (moderatorComment && rentalPointStateModeration) {
      // Формируем новый комментарий с правильной структурой
      const newComment = {
        content: moderatorComment,
        status: rentalPointStateModeration,
        user: {
          userId: userId,
          avatarUrl: userAvatarFiles?.url || "",
          name: userName || "",
          role: userRole || "admin",
        },
        // Используем единый формат времени
        created: Date.now(),
        id: hasDraftComment && draftCommentId ? draftCommentId : uuidv4(),
      };

      // Если у нас есть черновик, заменяем его
      // Иначе добавляем новый комментарий
      let updatedComments;
      if (hasDraftComment && draftCommentId) {
        updatedComments = comments.map((comment) =>
          comment.id === draftCommentId ? newComment : comment
        );
      } else {
        updatedComments = [...comments, newComment];
      }

      // Обновляем данные statusHistory
      setNewData({
        currentStatus: rentalPointStateModeration,
        comments: updatedComments,
      });
    }
  }, [
    userAvatarFiles,
    userName,
    userRole,
    moderatorComment,
    rentalPointStateModeration,
    userId,
    comments,
    hasDraftComment,
    draftCommentId,
  ]);

  /**
   * CRUD функции
   */
  const updateTempData = () => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_moderation",
        data: newData,
      })
    );
  };

  /**
   * Логика компонента
   */

  // Обработчик изменения статуса модерации
  const handleRentalPointStateModerationChange = (value) => {
    setRentalPointStateModeration(value);

    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
  };

  // Обработчик изменения комментария модератора
  const handleModeratorCommentChange = (event) => {
    setModeratorComment(event.target.value);
    setIsCommentActive(!!event.target.value);

    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
  };

  // Кнопка Apply
  const applyButton = () => {
    updateTempData();

    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    // Сбрасываем состояние комментария и закрываем окно
    setModeratorComment("");
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          {mode === "moderation" && (
            <>
              <WidgetDialogModeratorToClient
                comments={displayComments}
                modKey={mode}
              />

              <SegmentedControls
                options={[
                  {
                    title: "approved",
                    description: (
                      <span>
                        By confirming the moderation of the rental point, you
                        make it available to all users.
                      </span>
                    ),
                  },
                  {
                    title: "moderation",
                    description:
                      "By sending to moderation, the rental point will not be available to users until it's approved.",
                  },
                  {
                    title: "banned",
                    description:
                      "By banning the rental point, it will not be available to users.",
                  },
                ]}
                selected={handleRentalPointStateModerationChange}
                onSelect={rentalPointStateModeration}
              />

              {/* Поля для комментариев в зависимости от состояния */}
              {rentalPointStateModeration && (
                <Textarea
                  label="Moderation reason"
                  maxLength={5000}
                  description="Please provide your reason for this moderation decision."
                  onChange={handleModeratorCommentChange}
                  value={moderatorComment}
                  isChangeState={setIsCommentActive}
                />
              )}
            </>
          )}
        </ContentBox>
      </div>

      <ButtonsBar position="end">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to cancel the moderation process?
              </h1>
            }
          />

          <Button
            type="small"
            active={isCommentActive && rentalPointStateModeration !== null}
            label="Apply"
            onClick={applyButton}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to apply these changes?
              </h1>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowRentalPointModeration;
