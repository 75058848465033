import React from "react";
import useIsMobile from "../../../../../functions/isMobile";
import SkeletonAddData from "../../../../UIComponents/AddData/SkeletonAddData";
import styled from "styled-components";

const SkeletonItemWrapper = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
  border-radius: 16px;
  background-color: var(--color-gray-100);
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  box-sizing: border-box;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--color-white);
  box-sizing: border-box;
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 100%;
`;

const BottomIndicator = styled.div`
  width: 100%;
  height: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

const IndicatorBar = styled.div`
  width: 30%;
  height: 8px;
  border-radius: 4px;
  background-color: var(--color-gray-100);
`;

const SkeletonItem = () => {
  return <SkeletonItemWrapper />;
};

const SkeletonRentalPointTimeTable = () => {
  const isMobile = useIsMobile();

  return (
    <Container>
      <SkeletonAddData />

      <ContentWrapper>
        <ItemsContainer>
          {Array.from({ length: 7 }, (_, i) => (
            <SkeletonItem key={i} />
          ))}
        </ItemsContainer>
      </ContentWrapper>

      <BottomIndicator>
        <IndicatorBar />
      </BottomIndicator>
    </Container>
  );
};

export default SkeletonRentalPointTimeTable;
