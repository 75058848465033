import React, { useEffect, useState } from "react";
import WidgetListFileV4 from "../../../../UIComponents/UploadFiles/WidgetListFileV4";
import CollapsableText from "../../../../UIComponents/Collapsable/CollapsableText";
import WidgetRate from "../../../../Public/ProductCardV2/WidgetRate.js";

const WidgetViewBikeDetail = ({
  tempRootData_currentBike,
  // photoFiles,
  // basicInformation,
  // directory,
  // rate,
  startBookingDates,
  endBookingDates,
}) => {
  /**
   * Данные из Slice tempRootData_currentBike
   */
  // rate

  const [titleBike, setTitleBike] = useState(null);
  const [descriptionBike, setDescriptionBike] = useState(null);
  const [brandBike, setBrandBike] = useState(null);
  const [categoryBike, setCategoryBike] = useState(null);
  const [rateBike, setRateBike] = useState([]);
  const [photoFiles, setPhotoFiles] = useState([]);

  useEffect(() => {
    setDescriptionBike(
      tempRootData_currentBike?.basicInformation?.description
        ?.descriptionOriginal || null
    );
    setTitleBike(
      tempRootData_currentBike?.basicInformation?.title?.titleOriginal || null
    );
    setBrandBike(tempRootData_currentBike?.directory?.brand?.title || null);
    setCategoryBike(
      tempRootData_currentBike?.directory?.category?.title || null
    );
    setRateBike(tempRootData_currentBike?.rate || []);
    setPhotoFiles(tempRootData_currentBike?.files?.photoFiles || []);
  }, [tempRootData_currentBike]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <h5
        style={{
          ...(titleBike
            ? {}
            : {
                backgroundColor: "var(--color-gray-100)",
                height: "24px",
                width: "70%",
                borderRadius: "80px",
              }),
        }}
      >
        {titleBike || ""}
      </h5>
      <div
        style={{
          width: "100%",
          position: "relative",
          ...(!photoFiles && { aspectRatio: "16/9" }),
          borderRadius: "16px",
        }}
      >
        <WidgetListFileV4 isLoading={false} viewFiles={photoFiles} />
        <div
          style={{
            position: "absolute",
            left: "8px",
            top: "8px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <div
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              padding: "2px 8px",
              borderRadius: "4px",
              // height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              className="p-light"
              style={{
                color: "var(--color-white)",
              }}
            >
              {categoryBike || ""}
            </p>
          </div>

          <div
            style={{
              backgroundColor: "rgb(255, 255, 255, 0.5)",
              backdropFilter: "blur(10px)",
              padding: "2px 8px",
              borderRadius: "4px",
              // height: "24px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p
              className="p-light"
              style={{
                color: "var(--color-white)",
              }}
            >
              {brandBike || ""}
            </p>
          </div>
        </div>
      </div>

      <WidgetRate
        startBookingDates={startBookingDates}
        endBookingDates={endBookingDates}
        rate={rateBike}
      />

      {descriptionBike && descriptionBike.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <h6>{`${titleBike}`}</h6>
          <CollapsableText text={descriptionBike} />
        </div>
      )}
    </div>
  );
};

export default WidgetViewBikeDetail;
