import React, { useState, useRef, useEffect } from "react";

const CollapsableText = ({ text }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    // Check if text is overflowing the container
    if (textRef.current && containerRef.current) {
      const textHeight = textRef.current.scrollHeight;
      const containerHeight = containerRef.current.clientHeight;
      setIsOverflowing(textHeight > containerHeight);
    }
  }, [text]);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // Проверяем, что строка не пустая
  if (!text || text.length === 0) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
      }}
    >
      <div
        ref={containerRef}
        style={{
          maxHeight: isExpanded ? "1000px" : "calc(1.3em * 3)",
          overflow: "hidden",
          transition: "max-height 0.5s ease-out",
        }}
      >
        <p
          ref={textRef}
          style={{
            fontSize: "0.8rem",
            lineHeight: "1.3",
            margin: "0",
          }}
        >
          {text}
        </p>
      </div>

      {isOverflowing && (
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "var(--color-gray-200-alpha-50)",
            gap: "8px",
            padding: "4px 10px",
            borderRadius: "80px",
            width: "70px",
          }}
          onClick={toggleExpanded}
        >
          <p className="p-light">{isExpanded ? "Collapse" : "Expand"}</p>
          <span className="material-symbols-outlined p-light">
            {isExpanded ? "unfold_less" : "unfold_more"}
          </span>
        </div>
      )}
    </div>
  );
};

export default CollapsableText;
