import React, { useState, useEffect, useRef } from "react";
import "./swither.css";

const Switcher = ({
  id,
  isChecked,
  onChange,
  label,
  description,
  isActive,
}) => {
  const [internalChecked, setInternalChecked] = useState(isChecked);
  const switcherRef = useRef(null);

  // Обновляем internalChecked на основе isChecked и isActive
  useEffect(() => {
    if (isActive === true) {
      setInternalChecked(true); // Если isActive === true, включаем переключатель
    } else if (isActive === false) {
      setInternalChecked(false); // Если isActive === false, выключаем переключатель
    } else {
      setInternalChecked(isChecked); // В противном случае используем isChecked
    }
  }, [isChecked, isActive]);

  const handleChange = () => {
    // Разрешаем изменение только если isActive не установлено явно
    if (isActive === undefined) {
      const newValue = !internalChecked;
      setInternalChecked(newValue);
      onChange(newValue);
    }
  };

  // Анимация переключения
  useEffect(() => {
    if (switcherRef.current) {
      switcherRef.current.classList.remove("no-animation");
      void switcherRef.current.offsetWidth; // Force reflow для перезапуска анимации
      switcherRef.current.classList.add("no-animation");
    }
  }, [internalChecked]);

  return (
    <div className="swither__content-box">
      <div
        style={{
          width: "70%",
        }}
      >
        {label && <h6>{label}</h6>}
        {description && <p className="p-light">{description}</p>}
      </div>

      <div>
        <div
          ref={switcherRef}
          id={`switcher-control-${id}`}
          className={`swither__control no-animation ${
            internalChecked ? "swither__control--on" : "swither__control--off"
          } ${isActive === false ? "swither__control--disabled" : ""}`}
          onClick={handleChange}
        >
          <div
            className={`swither__handle--off ${
              internalChecked && "swither__handle--on"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default Switcher;
