import React, { useEffect, useState } from "react";
import InputText from "../../../../UIComponents/Inputs/InputText";
import Textarea from "../../../../UIComponents/Textarea/Textarea";
import Button from "../../../../UIComponents/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4";

const WindowEditBasicInformationModeration = ({
  isChangeState,
  mode,
  onClose,
}) => {
  const dispatch = useDispatch();

  // Константы для упрощения проверки режима
  const isModerationMode = mode === "moderation";
  const isModeratorAddedMode = mode === "moderatorAdded";

  const { tempRootData, tempData, rootNewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [newData, setNewData] = useState(null);
  const [applyButtonActive, setApplyButtonActive] = useState(false);

  // Добавляем переменные для хранения исходных значений
  const [initialTitle, setInitialTitle] = useState("");
  const [initialDescription, setInitialDescription] = useState("");

  /**
   *
   * Формирование newData объекта
   *
   */
  useEffect(() => {
    setNewData({
      title: {
        titleOriginal: title,
      },
      description: {
        descriptionOriginal: description,
      },
    });
  }, [title, description]);

  /**
   *
   * Наполнение useState
   *
   */
  useEffect(() => {
    let currentTitle = "";
    let currentDescription = "";

    if (isModerationMode) {
      currentTitle =
        tempData.tempData_rentalPoint_basicInformation?.title?.titleOriginal ||
        tempRootData?.selectedBike_rentalPont?.basicInformation?.title
          ?.titleOriginal ||
        "";
      currentDescription =
        tempData.tempData_rentalPoint_basicInformation?.description
          ?.descriptionOriginal ||
        tempRootData?.selectedBike_rentalPont?.basicInformation?.description
          ?.descriptionOriginal ||
        "";
    } else if (isModeratorAddedMode) {
      currentTitle =
        tempData.tempData_rentalPoint_basicInformation?.title?.titleOriginal ||
        rootNewData?.rootRentalPointData?.basicInformation?.title
          ?.titleOriginal ||
        "";
      currentDescription =
        tempData.tempData_rentalPoint_basicInformation?.description
          ?.descriptionOriginal ||
        rootNewData?.rootRentalPointData?.basicInformation?.description
          ?.descriptionOriginal ||
        "";
    }

    // Устанавливаем текущие и исходные значения
    setTitle(currentTitle);
    setDescription(currentDescription);
    setInitialTitle(currentTitle);
    setInitialDescription(currentDescription);
  }, [
    mode,
    isModerationMode,
    isModeratorAddedMode,
    tempRootData?.selectedBike_rentalPont?.basicInformation,
    rootNewData?.rootRentalPointData?.basicInformation,
    tempData.tempData_rentalPoint_basicInformation,
  ]);

  /**
   *
   * CRUD функции
   *
   */
  const applyChanges = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_basicInformation",
        data: newData,
      })
    );

    onClose();
  };

  /**
   *
   * Логика компонента
   *
   */

  // Функция для обработки изменения заголовка
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  // Функция для обработки изменения описания
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  // Активация кнопки apply
  useEffect(() => {
    // Проверяем, изменились ли данные относительно исходных значений
    const hasChanges =
      title !== initialTitle || description !== initialDescription;

    // Обновляем состояние кнопки Save
    setApplyButtonActive(hasChanges);
  }, [title, description, initialTitle, initialDescription]);

  // Кнопки
  const applyButton = () => {
    applyChanges();
  };

  const cancelButton = () => {
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Rental point name"
            description="Enter the name of your rental point."
            value={title}
            onChange={handleTitleChange}
          />
          <Textarea
            label="Description rental point"
            maxLength={500}
            description="Provide a detailed description of your rental point."
            value={description}
            onChange={handleDescriptionChange}
          />
        </ContentBox>
      </div>
      <ButtonsBar position="end">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={cancelButton}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            active={applyButtonActive}
            label="Save"
            onClick={applyButton}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Please confirm that you want to update the rental point
                information.
              </h1>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditBasicInformationModeration;
