import React, { useEffect, useState } from "react";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import TitleBlockSection from "../../../../UIComponents/Titles/TitleBlockSection";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowOrderAddToCartAdditionalService from "./WindowOrderAddToCartAdditionalService";

const WidgetOrderAditionalServicesTotal = ({
  mode,
  orderedAditionalServices,
}) => {
  const [total, setTotal] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [additionalServiceId, setAdditionalServiceId] = useState(null);
  const [additionalServiceTitle, setAdditionalServiceTitle] = useState(null);

  const handleOpen = (additionalServiceId, additionalServiceTitle) => {
    setAdditionalServiceTitle(additionalServiceTitle);
    setAdditionalServiceId(additionalServiceId);
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const newTotal = orderedAditionalServices.reduce((acc, service) => {
      if (service.ordered && service.price) {
        return acc + service.price * service.ordered;
      }
      return acc;
    }, 0);
    setTotal(newTotal);
  }, [orderedAditionalServices]);

  return (
    total > 0 && (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            boxSizing: "border-box",
          }}
        >
          {/* <TitleBlockSection
          title="Total additional services ordered"
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
        /> */}
          {orderedAditionalServices &&
            orderedAditionalServices.map(
              (service, index) =>
                service.ordered > 0 && (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      padding: "16px 0",
                      borderBottom: "1px solid var(--color-gray-200)",
                      boxSizing: "border-box",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      handleOpen(service.id, service.title.titleOriginal)
                    }
                  >
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                          gap: "8px",
                          width: "72%",
                        }}
                      >
                        <p
                          className="p-light"
                          style={{
                            backgroundColor: "var(--color-gray-100)",
                            padding: "4px 10px",
                            borderRadius: "80px",
                          }}
                        >
                          {service.ordered}
                          &nbsp;service{service.ordered > 1 && "s"}
                        </p>

                        <p>{service.title.titleOriginal}</p>

                        <p className="p-light">
                          {formatCurrencyUSD(service.price)}
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            fontWeight: "600",
                          }}
                        >
                          {formatCurrencyUSD(service.price * service.ordered)}
                        </p>
                        <span className="material-symbols-outlined">
                          chevron_right
                        </span>
                      </div>
                    </div>
                  </div>
                )
            )}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "16px 0",
              boxSizing: "border-box",
            }}
          >
            <p>Total:</p>
            <p
              style={{
                fontWeight: "600",
              }}
            >
              {formatCurrencyUSD(total)}
            </p>
          </div>
        </div>
        <PopupDirrectory
          isOpen={isOpen}
          onClose={handleClose}
          title="Edit order additional services"
        >
          <WindowOrderAddToCartAdditionalService
            mode={mode}
            additionalServiceId={additionalServiceId}
            onClose={handleClose}
          />
        </PopupDirrectory>
      </>
    )
  );
};

export default WidgetOrderAditionalServicesTotal;
