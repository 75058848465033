// import { useEffect, useState } from "react";
import React from "react";
import useIsMobile from "../../../../../functions/isMobile";
import AddData from "../../../../UIComponents/AddData/AddData";
// import Divide from "../../../../UIComponents/Divide/Divide";
import SkeletonSegmentControl from "../../../../UIComponents/SegmentedControls/SkeletonSegmentControl";

const SkeletonItemBikePreview = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "8px",
      }}
    >
      {/* Изображение */}
      <div
        style={{
          width: "100%",
          aspectRatio: "16/9",
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "16px",
        }}
      />
      {/* Категория и бренд */}
      <div
        style={{
          width: "80%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "24px",
            borderRadius: "4px",
            backgroundColor: "var(--color-gray-100)",
          }}
        />

        <div
          style={{
            width: "100%",
            height: "24px",
            borderRadius: "4px",
            backgroundColor: "var(--color-gray-100)",
          }}
        />
      </div>
      {/* Заголовок */}
      <div
        style={{
          height: "16px",
          width: "70px",
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "8px",
        }}
      />
      {/* Столбики тарифов */}
      <div
        style={{
          width: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(12, 1fr)",
          gap: "2px",
          height: "60px",
          alignItems: "flex-end",
          paddingBottom: "16px",
        }}
      >
        {Array.from({ length: 12 }, (_, i) => (
          <div
            key={i}
            style={{
              height: `${Math.floor(Math.random() * (100 - 8 + 1)) + 8}%`, // От 8% до 100%
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px 4px 0 0",
            }}
          />
        ))}
      </div>
    </div>
  );
};

const SkeletonRentalPointBikeList = ({ bikesList }) => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        boxSizing: "border-box",
      }}
    >
      <AddData isLoading={true} />
      <SkeletonSegmentControl />
      {bikesList?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "grid",
              ...(isMobile
                ? { gap: "16px", gridTemplateColumns: "repeat(1, 1fr)" }
                : { gap: "8px", gridTemplateColumns: "repeat(2, 1fr)" }),
              width: "100%",
            }}
          >
            {Array.from({ length: 6 }, (_, i) => (
              <SkeletonItemBikePreview key={i} />
            ))}
          </div>
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: "8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <div
          style={{
            width: "30%",
            height: "8px",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "4px",
          }}
        />
      </div>
    </div>
  );
};

export default SkeletonRentalPointBikeList;
