import { useEffect, useState } from "react";

/**
 * @typedef {Object} TimeRemainingObject
 * @property {number} days - Количество оставшихся дней
 * @property {number} hours - Количество оставшихся часов
 * @property {number} minutes - Количество оставшихся минут
 * @property {number} seconds - Количество оставшихся секунд
 */

/**
 * @typedef {Object} TimestampObject
 * @property {number} timestamp - Метка времени в миллисекундах
 */

/**
 * Компонент обратного отсчета, показывающий время до удаления элемента
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {(number|TimestampObject)} props.deleteState - Метка времени удаления (timestamp) или объект с полем timestamp
 * @param {number} [props.interval=604800000] - Интервал в миллисекундах до удаления (по умолчанию 7 дней)
 * @returns {React.ReactElement|null} Элемент React с таймером или null, если время не определено
 *
 * @example
 * // Использование с timestamp напрямую
 * <CountdownTimer deleteState={1647852039000} />
 *
 * @example
 * // Использование с объектом, содержащим timestamp
 * <CountdownTimer deleteState={{ timestamp: 1647852039000 }} />
 *
 * @example
 * // Использование с пользовательским интервалом (3 дня)
 * <CountdownTimer deleteState={1647852039000} interval={259200000} />
 */
const CountdownTimer = ({ deleteState, interval = 604800000 }) => {
  /** @type {[TimeRemainingObject|null, Function]} */
  const [timeRemaining, setTimeRemaining] = useState(null);

  /** @type {[boolean, Function]} */
  const [isExpired, setIsExpired] = useState(false);

  /**
   * Извлекает timestamp из различных форматов входного параметра deleteState
   *
   * @returns {number|null} Timestamp в миллисекундах или null, если невозможно получить
   */
  const getTimestamp = () => {
    // Если deleteState - это объект с полем timestamp
    if (
      deleteState &&
      typeof deleteState === "object" &&
      deleteState.timestamp
    ) {
      return deleteState.timestamp;
    }

    // Если deleteState - это число (timestamp напрямую)
    if (deleteState && typeof deleteState === "number") {
      return deleteState;
    }

    return null;
  };

  /**
   * Вычисляет и форматирует оставшееся время до удаления
   *
   * @returns {TimeRemainingObject|null} Объект с оставшимся временем или null, если timestamp не определен
   */
  const calculateTimeRemaining = () => {
    const timestamp = getTimestamp();

    if (!timestamp) {
      return null;
    }

    // Set deletion date based on the provided interval (default: 7 days = 604800000 ms)
    const deletionDate = new Date(timestamp + interval);
    const now = new Date();

    // Calculate difference in milliseconds
    const diff = deletionDate - now;

    if (diff <= 0) {
      setIsExpired(true);
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    // Convert milliseconds to days, hours, minutes, seconds
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  /**
   * Эффект для инициализации и обновления таймера
   * Запускается при изменении deleteState или interval
   */
  useEffect(() => {
    // Initial calculation
    const remaining = calculateTimeRemaining();
    setTimeRemaining(remaining);

    // Установим isExpired на основе первоначального расчета
    if (
      remaining &&
      remaining.days === 0 &&
      remaining.hours === 0 &&
      remaining.minutes === 0 &&
      remaining.seconds === 0
    ) {
      setIsExpired(true);
    }

    // Update every second
    const timer = setInterval(() => {
      const remaining = calculateTimeRemaining();
      setTimeRemaining(remaining);
    }, 1000);

    // Cleanup on unmount
    return () => clearInterval(timer);
  }, [deleteState, interval]);

  /**
   * Форматирует число с ведущими нулями
   *
   * @param {number} num - Число для форматирования
   * @returns {string} Отформатированное число с ведущими нулями
   */
  const formatNumber = (num) => {
    return num.toString().padStart(2, "0");
  };

  // If no timeRemaining, return nothing
  if (!timeRemaining) {
    return null;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "4px",
        whiteSpace: "nowrap",
      }}
    >
      <span
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "4px",
          whiteSpace: "nowrap",
        }}
      >
        {timeRemaining.days > 0 && (
          <span>
            <span>{timeRemaining.days}</span>
            <span> дней </span>
          </span>
        )}
        <span
          style={{
            // fontWeight: "600",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            // gap: "2px",
          }}
        >
          <span
            style={{
              // backgroundColor: "var(--color-white)",
              padding: "2px 2px",
              borderRadius: "2px",
              // color: "var(--color-alarm)",
              // fontSize: "0.7rem",
            }}
          >
            {formatNumber(timeRemaining.hours)}
          </span>
          :
          <span
            style={{
              // backgroundColor: "var(--color-white)",
              padding: "2px 2px",
              borderRadius: "2px",
              // color: "var(--color-alarm)",
              // fontSize: "0.7rem",
            }}
          >
            {formatNumber(timeRemaining.minutes)}
          </span>
          :
          <span
            style={{
              // backgroundColor: "var(--color-white)",
              padding: "2px 2px",
              borderRadius: "2px",
              // color: "var(--color-alarm)",
              // fontSize: "0.7rem",
            }}
          >
            {formatNumber(timeRemaining.seconds)}
          </span>
        </span>
      </span>

      <span>{isExpired && <span>Срок истек</span>}</span>
    </div>
  );
};

export default CountdownTimer;
