import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../UIComponents/Buttons/Button.js";
// import WidgetBikeRateV4 from "../BikesV3/WidgetBikeRateV4.js";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox.js";
import WidgetBikeBasicInformationModeration from "../BikesV3/WidgetBikeBasicInformationModeration.js";
import WidgetBrandCategoryModeration from "../BikesV3/WidgetBrandCategoryModeration.js";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory.js";
import WindowOrderDetailModeration from "../OrdersV3/WindowOrderDetailModeration.js";
import WidgetQuntityBikeModeration from "../BikesV3/WidgetQuntityBikeModeration.js";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar.js";
import WidgetListFileV4 from "../../../../UIComponents/UploadFiles/WidgetListFileV4.js";
import WidgetUploadFilesV4 from "../../../../UIComponents/UploadFiles/WindgetUploadFilesV4.js";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4.js";
import { v4 as uuidv4 } from "uuid";
import { createDataThunkV4 } from "../../../../../reducersToolkit/thunk/createDataThunkV4.js";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/updateDataThunkV4.js";

import { deleteDataThunkV4 } from "../../../../../reducersToolkit/thunk/deleteDataThunkV4.js";
import WidgetBikeModerationV4 from "./WidgetBikeModerationV4.js";
import {
  subscribeRootDocumentsThunkV4,
  unsubscribeRootDocument,
} from "../../../../../reducersToolkit/thunk/subscribeRootDocumentsThunkV4.js";
import WidgetBikeRateV4 from "../BikesV3/WidgetBikeRateV4.js";

const WindowBikeDetailModerationV4 = ({ mode, onClose, closeParent }) => {
  const dispatch = useDispatch();

  const {
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    loading,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  /**
   *
   * LocalStats
   *
   */

  // ------- Windows stats
  const [windowOrderDetailModeration, setWindowOrderDetailModeration] =
    useState(false);

  // ------- Buttons stats

  // ------- Widgets stats
  const [quantityBikes, setQuantityBikes] = useState(0);

  // ------- Files

  // ------- previewNewData
  const [previewBikeData, setPreviewBikeData] = useState(null);

  // ------- rootNewData
  const [currentBikeRootData, setCurrentBikeRootData] = useState(null);

  // ------- tempPreviewData
  const [brandsPreview, setBrandsPreview] = useState([]);
  const [categoriesPreview, setCategoriesPreview] = useState([]);
  // const [idRootDocumentSelectedUser, setIdRootDocumentSelectedUser] =
  //   useState(null);
  const [idPreviewDocumentBike, setIdPreviewDocumentBike] = useState(null);
  const [idRootDocumentBike, setidRootDocumentBike] = useState(null);
  const [
    choosingBikeForModeration_previewData,
    setChoosingBikeForModeration_previewData,
  ] = useState(null);

  // ------- tempRootData
  const [currentCart, setCurrentCart] = useState(null);
  const [basicInformation, setBasicInformation] = useState({});
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [metricsBike, setMetricsBike] = useState({});
  const [rate, setRate] = useState([]);
  const [bikeStatus, setBikeStatus] = useState(null); //*fetchRootDataThunkV4 <WindowBikeDetailModerationV4/>
  const [newComments, setNewComments] = useState(null); //*fetchRootDataThunkV4 <WindowBikeDetailModerationV4/>
  const [selectedBike_rootData, setSelectedBike_rootData] = useState(null);
  const [idRootDocumentRentalPoint, setIdRootDocumentRentalPoint] =
    useState(null);
  const [directoryRentalPoint, setDirectoryRentalPoint] = useState(null);
  const [idRootDocumentUser, setIdRootDocumentUser] = useState(null);
  const [
    order_selectedCompletedRootOrder,
    setOrder_selectedCompletedRootOrder,
  ] = useState(null);

  // ------- tempData
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [currentSlotIds, setCurrentSlotIds] = useState(null);
  const [keySlotReplacementBike, setKeySlotReplacementBike] = useState(null);

  // ------- loading
  const [loading_upload_bikePhotoFiles, setLoading_upload_bikePhotoFiles] =
    useState(false);

  // ------- error

  // ------- localStates
  const [newPreviewBikeData, setNewPreviewBikeData] = useState(null);
  const [newRootBikeData, setNewRootBikeData] = useState(null);
  const [newPhotoFiles, setNewPhotoFiles] = useState(null);
  const [previewPhotoFiles, setpreviewPhotoFiles] = useState([]);

  /**
   *
   * Наполнение useState
   *
   * */

  // rootNewData
  useEffect(() => {
    setCurrentBikeRootData(rootNewData?.rootBikeData || null);
    setPreviewBikeData(previewNewData?.previewBikeData || null);
  }, [rootNewData?.rootBikeData, previewNewData?.previewBikeData]);

  // loading
  useEffect(() => {
    setLoading_upload_bikePhotoFiles(
      loading?.loading_upload_bikePhotoFiles || false
    );
  }, [loading?.loading_upload_bikePhotoFiles]);

  // tempPreviewData
  useEffect(() => {
    setBrandsPreview(tempPreviewData?.brandsPreview || []);

    setCategoriesPreview(tempPreviewData?.categoriesPreview || []);

    setidRootDocumentBike(
      tempPreviewData?.choosingBikeForModeration_previewData?.idRootDocument ||
        null
    );

    setIdPreviewDocumentBike(
      tempPreviewData?.choosingBikeForModeration_previewData
        ?.idPreviewDocument || null
    );

    if (mode === "moderatorAdded") {
      setIdRootDocumentUser(
        tempPreviewData?.user_moderation_selectedPreviewCurrentData
          ?.idRootDocument || null
      );
    }

    if (
      mode === "moderation" ||
      mode === "replacement" ||
      mode === "order" ||
      mode === "selected"
    ) {
      setIdRootDocumentRentalPoint(
        tempPreviewData?.choosingBikeForModeration_previewData
          ?.idRootDocumentRentalPoint || null
      );
      setIdRootDocumentUser(
        tempPreviewData?.choosingBikeForModeration_previewData
          ?.idRootDocumentUser || null
      );
    }
    setChoosingBikeForModeration_previewData(
      tempPreviewData?.choosingBikeForModeration_previewData || null
    );
  }, [
    tempPreviewData?.rentalPoints_subscribe_previewData,
    tempPreviewData?.general_usersPreviewNotifications,
    tempPreviewData?.subscribe_bikesPreview,
    tempPreviewData?.user_moderation_selectedPreviewCurrentData,
    tempPreviewData?.brandsPreview,
    tempPreviewData?.categoriesPreview,
    tempPreviewData?.choosingBikeForModeration_previewData,
    mode,
  ]);

  // tempData
  useEffect(() => {
    if (mode === "moderatorAdded") {
      setIdRootDocumentRentalPoint(
        tempData?.rentalPoint_moderation_selectedRentalPoint?.idRootDocument ||
          null
      );
    }
    setDirectoryRentalPoint(
      tempData?.rentalPoint_moderation_selectedRentalPoint || null
    );
    setSelectedCountry(
      tempData?.rentalPoint_moderation_selectedRentalPoint?.country || null
    );
    setSelectedCity(
      tempData?.rentalPoint_moderation_selectedRentalPoint?.city || null
    );
    setCurrentSlotIds(tempData?.currentSlotIds || null);
    setKeySlotReplacementBike(
      tempData?.currentSlotIds?.keySlotReplacementBike || null
    );
  }, [
    tempData?.rentalPoint_moderation_selectedRentalPoint,
    mode,
    tempData?.currentSlotIds,
  ]);

  // tempRootData
  useEffect(() => {
    if (mode === "new" || mode === "moderatorAdded") {
      setBasicInformation(
        tempRootData?.newRootBike_create?.basicInformation || {}
      );
      setRate(tempRootData?.newRootBike_create?.rate || []);
      setSelectedBrand(
        tempRootData?.newRootBike_create?.directory?.brand || null
      );
      setSelectedCategory(
        tempRootData?.newRootBike_create?.directory?.category || null
      );
      setMetricsBike(tempRootData?.newRootBike_create?.metricsBike || {});
      setpreviewPhotoFiles(
        tempRootData?.newRootBike_create?.files?.photoFiles || []
      );
      setBikeStatus(tempRootData?.newRootBike_create?.bikeStatus || null);
    } else if (
      mode === "moderation" ||
      mode === "replacement" ||
      mode === "order" ||
      mode === "selected"
    ) {
      setOrder_selectedCompletedRootOrder(
        tempRootData?.order_selectedCompletedRootOrder || null
      );
      setCurrentCart(
        tempRootData?.order_selectedCompletedRootOrder?.currentCart || null
      );
      setSelectedBike_rootData(tempRootData?.selectedBike_rootData || {});
      setBasicInformation(
        tempRootData?.selectedBike_rootData?.basicInformation || {}
      );
      setRate(tempRootData?.selectedBike_rootData?.rate || []);
      setBikeStatus(tempRootData?.selectedBike_rootData?.bikeStatus || null);
      setNewComments(
        tempRootData?.selectedBike_rootData?.bikeStatus?.comments || null
      );
      setSelectedBrand(
        tempRootData?.selectedBike_rootData?.directory?.brand || null
      );
      setSelectedCategory(
        tempRootData?.selectedBike_rootData?.directory?.category || null
      );
      setMetricsBike(tempRootData?.selectedBike_rootData?.metricsBike || {});
      setpreviewPhotoFiles(
        tempRootData?.selectedBike_rootData?.files?.photoFiles || []
      );
    }
  }, [
    tempRootData?.newRootBike_create,
    tempRootData?.selectedBike_rootData,
    tempRootData?.order_selectedCompletedRootOrder,
    mode,
    dispatch,
  ]);

  /**
   *
   * Формирование объектов Root и Preview
   *
   * */

  // setNewRootBikeData
  useEffect(() => {
    setNewRootBikeData({
      ...currentBikeRootData,
      bikeStatus: { ...bikeStatus },
      created: Date.now(),
      basicInformation: basicInformation,
      directory: {
        category: selectedCategory,
        brand: selectedBrand,
        country: selectedCountry,
        city: selectedCity,
        rentalPoint: directoryRentalPoint,
      },
      // characteristics: [],
      metricsBike: {
        quantityBikes: quantityBikes,
      },
      // Файлы передаются отдельно в функции createDataThunkV4
      // files: {
      //   bikePhotoFiles: newPhotoFiles,
      // },
      rate: rate,
    });
  }, [
    bikeStatus,
    currentBikeRootData,
    basicInformation,
    newPhotoFiles,
    rate,
    metricsBike,
    selectedBrand,
    selectedCategory,
    quantityBikes,
    selectedCountry,
    selectedCity,
    directoryRentalPoint,
  ]);

  // setNewPreviewBikeData
  useEffect(() => {
    const ratePreview = rate.map((item) => ({
      ...item,
      description: {
        ...item.description,
        descriptionOriginal: item.description.descriptionOriginal.slice(0, 250),
      },
    }));

    setNewPreviewBikeData({
      ...(mode === "moderatorAdded" && {
        ...previewBikeData,
        idRootDocumentUser: idRootDocumentUser,
        idRootDocumentRentalPoint: idRootDocumentRentalPoint,
        country: {
          title: selectedCountry?.title || null,
          id: selectedCountry?.idRootDocument || null,
        },
        city: {
          title: selectedCity?.title || null,
          id: selectedCity?.idRootDocument || null,
        },
      }),
      ...((mode === "moderation" || mode === "order") && {
        ...choosingBikeForModeration_previewData,
      }),

      bikeStatus: bikeStatus?.currentStatus,

      created: Date.now(),

      title: basicInformation?.title?.titleOriginal || "",

      description: (
        basicInformation?.description?.descriptionOriginal || ""
      ).slice(0, 250),

      rate: ratePreview,

      category: selectedCategory,
      brand: selectedBrand,
      country: {
        title: selectedCountry?.title || null,
        id: selectedCountry?.idRootDocument || null,
      },
      city: {
        title: selectedCity?.title || null,
        id: selectedCity?.idRootDocument || null,
      },
      quantity: quantityBikes,
      // Файлы передаются отдельно в функции createDataThunkV4
    });
  }, [
    previewBikeData,
    choosingBikeForModeration_previewData,
    bikeStatus?.currentStatus,
    basicInformation?.title?.titleOriginal,
    basicInformation?.description?.descriptionOriginal,
    selectedCategory,
    selectedBrand,
    rate,
    idRootDocumentRentalPoint,
    idRootDocumentUser,
    selectedCountry,
    selectedCity,
    quantityBikes,
    mode,
  ]);

  /**
   *
   * Fetch функции
   *
   */

  // bikes
  useEffect(() => {
    if (
      (mode === "moderation" || mode === "replacement" || mode === "order") &&
      idRootDocumentBike
    ) {
      console.log(`Выполняем подписку на документ: ${idRootDocumentBike}`);

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV4({
              collectionPath: "bikes",
              rootCurrentDocumenState: "selectedBike_rootData",
              idRootDocument: idRootDocumentBike,
              loadingStateName: "loading_bikes",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      // Отписываемся при размонтировании компонента или изменении `idRootDocumentBike`
      return () => {
        unsubscribeRootDocument(idRootDocumentBike);
      };
    }
  }, [dispatch, mode, idRootDocumentBike]);

  /**
   *
   * CRUD функции
   *
   */

  // setCurrentRootData --selectedBike_rootData-- --newRootBike_create--
  useEffect(() => {
    if (mode === "moderation" || mode === "replacement" || mode === "order") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "selectedBike_rootData",
          data: selectedBike_rootData || {},
        })
      );
    } else if (mode === "moderatorAdded") {
      // !!! Создаём стейт в который сохраняем исходное состояние root объекта currentBikeRootData. Далее этот стейт используется для его редактирования в других widgets и windows
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "newRootBike_create",
          data: rootNewData?.rootBikeData || {},
        })
      );
    }
  }, [
    dispatch,
    rootNewData.rootBikeData,
    selectedBike_rootData,
    mode,
    idRootDocumentBike,
  ]);

  const clearCurrentBike = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: "selectedBike_rootData",
        data: null,
      })
    );
  };

  const updateData = async () => {
    try {
      if (idRootDocumentBike) {
        dispatch(
          updateDataThunkV4({
            // Указываем id preview документа который будем менять
            // idPreviewDocument: idPreviewDocumentBike,

            // Указываем id root Элемента
            idRootDocument: idRootDocumentBike,

            // Данные для обновления, как правило это объект схожий по структуре с документом в firestore
            rootData: newRootBikeData,
            previewData: newPreviewBikeData,

            // Массивы файлов для обновления. Файлы будут добавлены к уже существующим файлам в массиве
            newFiles: {
              photoFiles: newPhotoFiles,
              /* Другие массивы файлов. Массивов файлов может быть несколько*/
            },
            // Имя того массива файлов который будет использоваться для назначения иконки
            iconFields: "photoFiles",

            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath: "bikes",
            previewCollectionPath: `manager_bikesPreview.userIds.${idRootDocumentUser}.reintalPointIds.${idRootDocumentRentalPoint}`,
            previewGeneralCollectionPath: "general_bikesPreview",

            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath: `manager_bikesPreview.userIds.${idRootDocumentUser}.reintalPointIds.${idRootDocumentRentalPoint}.metadata`,
            metadataGeneralDocumentPath: "general_bikesPreview.metadata",
            /*************/

            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState: "selectedBike_rootData",
            // Сохраняет в state МАССИВ объектов как результат выполнения thunk
            // previewCurrentDocumenState: `subscribe_bikesPreview`,
            // Эти состояния будут отображать состояния загрузки и ошибки
            previewGeneralDocumenState: "general_bikesPreview",
            /*************/

            loadingStateName: "loading_manager_bikes_update",
            errorStateName: "currentError",
          })
        );
      }
    } catch (error) {
      console.error("Ошибка при создании или обновлении bikes:", error);
    }
  };

  const createData = async () => {
    try {
      dispatch(
        createDataThunkV4({
          // Данные которые будут использоваться для создания коллекции в firestore
          rootData: newRootBikeData,
          previewData: newPreviewBikeData,

          files: {
            photoFiles: newPhotoFiles,
            //  Ниже можно использовать другие массивы файлов, если требуется
          },
          // Имя того массива файлов который будет использоваться для назначения иконки
          iconFields: "photoFiles",
          // Определяем пути для коллекций и метаданных с точками в качестве разделителей
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "bikes",
          previewCollectionPath: `manager_bikesPreview.userIds.${idRootDocumentUser}.reintalPointIds.${idRootDocumentRentalPoint}`,
          previewGeneralCollectionPath: "general_bikesPreview",
          // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
          metadataDocumentPath: `manager_bikesPreview.userIds.${idRootDocumentUser}.reintalPointIds.${idRootDocumentRentalPoint}.metadata`,
          metadataGeneralDocumentPath: "general_bikesPreview.metadata",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "selectedBike_rootData",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          // previewCurrentDocumenState: "subscribe_bikesPreview",
          // Сохраняет в общий список массивов объектов
          previewGeneralDocumenState: "general_bikesPreview",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_manager_bikes_create",
          errorStateName: "currentError",
        })
      )
        .unwrap()
        .then((response) => {
          console.log("bike успешно создан:", response);
          onClose();
        })
        .catch((error) => {
          console.error("Ошибка при создании bikes:", error);
        });
    } catch (error) {
      console.error("Ошибка при создании или обновлении bikes:", error);
    }
  };

  const deleteBike = () => {
    dispatch(
      deleteDataThunkV4({
        // Id документов у которых поле remove будет обновлено
        idPreviewDocument: idPreviewDocumentBike,
        idRootDocument: idRootDocumentBike,
        // Определение путей коллекций
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        rootCollectionPath: "bikes",
        previewCollectionPath: `manager_bikesPreview.userIds.${idRootDocumentUser}`,
        previewGeneralCollectionPath: "general_bikesPreview",
        // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
        metadataDocumentPath: `manager_bikesPreview.userIds.${idRootDocumentUser}.metadata`,
        metadataGeneralDocumentPath: "general_bikesPreview.metadata",
        // Название state который будет содержать массив как результат работы thunk
        // previewCurrentDocumenState: "brandsPreview_deleteBrand",
        // Название state который будет помогать отслежывать результаты работы thunk
        previewGeneralDocumenState: "general_bikesPreview",
        loadingStateName: "loading_deleteBike",
        errorStateName: "currentError",
      })
    );
  };
  /**
   *
   * Внутренняя логика компонента
   *
   */

  const addData = async () => {
    createData();
    onClose();
  };

  const cancel = () => {
    onClose();
  };

  const updeteData = async () => {
    updateData();
    clearCurrentBike();
    onClose();
  };

  const deleteData = async () => {
    deleteBike();
    onClose();
    closeParent();
  };

  const addCart = () => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "orderedBike",
        data: selectedBike_rootData || null,
      })
    );
    setWindowOrderDetailModeration(true);
  };

  // const handleReplacement = () => {
  //   dispatch(
  //     setCurrentRootData({
  //       rootCurrentDocumentState: "order_selectedCompletedRootOrder",
  //       data: updateBikeSlot({
  //         currentSlotIds: currentSlotIds,
  //         selectedBike_rootData: selectedBike_rootData,
  //         order_selectedCompletedRootOrder: order_selectedCompletedRootOrder,
  //         keySlotReplacementBike: keySlotReplacementBike,
  //       }),
  //     })
  //   );

  //   onClose();
  //   closeParent();
  // };

  // const handleSelected = () => {
  //   // dispatch();
  //   // setCurrentRootData({
  //   //   rootCurrentDocumentState: "order_selectedCompletedRootOrder",
  //   //   data: selectedBikeSlot(
  //   //     indexReplacementBike,
  //   //     selectedBike_rootData,
  //   //     order_selectedCompletedRootOrder
  //   //   ),
  //   // })
  //   onClose();
  //   closeParent();
  // };

  const handleClose = () => {
    setWindowOrderDetailModeration(false);
  };

  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={windowOrderDetailModeration}
        onClose={handleClose}
        title="WindowOrderDetailModeration"
      >
        <WindowOrderDetailModeration
          mode="new"
          onClose={handleClose}
          closeParent={onClose}
        />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          {/* ------------- Basic information ------------- */}
          <ContentBox position={mode === "moderation" ? "one" : "start"}>
            <WidgetBikeBasicInformationModeration
              mode={mode}
              basicInformation={basicInformation}
            />
          </ContentBox>

          {/* ------------- Moderation ------------- */}
          {mode === "moderation" && (
            <ContentBox position="start">
              <WidgetBikeModerationV4
                mode={mode}
                currentBike={tempRootData?.selectedBike_rootData}
                comments={newComments}
              />
            </ContentBox>
          )}
        </div>

        {/* ------------- Files photo ------------- */}

        <ContentBox>
          <WidgetListFileV4
            isLoading={loading_upload_bikePhotoFiles}
            // Передаём данные для отображения
            viewFiles={previewPhotoFiles}
            // Поле в котором находится массив с файлами
            fieldName="files.photoFiles"
            // Путь до коллекции указывается из НЕ четного колличества вложенностей
            rootCollectionPath="bikes"
            previewCollectionPath={`manager_bikesPreview.userIds.${idRootDocumentUser}.reintalPointIds.${idRootDocumentRentalPoint}`}
            previewGeneralCollectionPath="general_bikesPreview"
            // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
            metadataDocumentPath={`manager_bikesPreview.userIds.${idRootDocumentUser}.reintalPointIds.${idRootDocumentRentalPoint}.metadata`}
            metadataGeneralDocumentPath="general_bikesPreview.metadata"
            // Редактируемые документы. Если создётся новый документ значения могуть быть null
            idPreviewDocument={idPreviewDocumentBike}
            idRootDocument={idRootDocumentBike}
            // Динамические состояния загрузки
            loadingStateName="loading_upload_bikePhotoFiles"
            errorStateName="currentError"
            // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
            rootCurrentDocumentState="selectedBike_rootData"
            // Сохраняет в state МАССИВ объектов как результат выполнения thunk
            previewCurrentDocumenState="manager_bikesPreview"
            previewGeneralDocumenState="general_bikesPreview"
            // Режим
            mode="edit"
          />

          <WidgetUploadFilesV4
            isLoading={loading_upload_bikePhotoFiles}
            id={uuidv4()}
            title="Add photo files"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            files={setNewPhotoFiles}
          />
        </ContentBox>

        {/* ------------- Directory ------------- */}

        <ContentBox>
          <WidgetBrandCategoryModeration
            mode={mode}
            brands={brandsPreview}
            categories={categoriesPreview}
            initialSelectedBrand={selectedBrand}
            initialSelectedCategory={selectedCategory}
          />
        </ContentBox>

        {/* ------------- Metrics Bike ------------- */}

        <ContentBox>
          <WidgetQuntityBikeModeration
            mode={mode}
            metricsBike={metricsBike}
            setQuantityBikes={setQuantityBikes}
          />
        </ContentBox>

        {/* ------------- Rate ------------- */}

        <ContentBox>
          <WidgetBikeRateV4
            mode={mode}
            rate={rate}
            // setRate={setRate}
          />
        </ContentBox>

        <ButtonsBar>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "16px",
              width: "100%",
            }}
          >
            {mode === "order" && (
              <>
                <Button
                  type="small"
                  color="--color-black"
                  label="Cancel"
                  active={true}
                  onClick={cancel}
                />
                <Button
                  type="small"
                  label="Add cart"
                  active={true}
                  onClick={addCart}
                />
              </>
            )}
            {mode === "moderation" && (
              <>
                <Button
                  type="small"
                  color="--color-black"
                  label="Cancel"
                  active={true}
                  onClick={cancel}
                />
                <Button
                  type="small"
                  label="Update"
                  active={true}
                  onClick={updeteData}
                />
              </>
            )}
            {/* {mode === "replacement" && (
              <>
                <Button
                  type="small"
                  color="--color-black"
                  label="Cancel"
                  active={true}
                  onClick={cancel}
                />
                <Button
                  type="small"
                  label="Replacement"
                  active={true}
                  onClick={handleReplacement}
                />
              </>
            )} */}
            {/* {mode === "selected" && (
              <>
                <Button
                  type="small"
                  color="--color-black"
                  label="Cancel"
                  active={true}
                  onClick={cancel}
                />
                <Button
                  type="small"
                  label="Selected"
                  active={true}
                  onClick={handleSelected}
                />
              </>
            )} */}
            {mode === "moderatorAdded" && (
              <>
                <Button
                  type="small"
                  label="Add bike"
                  active={true}
                  onClick={addData}
                />
                <Button
                  type="small"
                  color="--color-black"
                  label="Cancel"
                  active={true}
                  onClick={cancel}
                />
              </>
            )}
            {mode === "new" && (
              <>
                <Button
                  type="small"
                  label="Add bike"
                  active={true}
                  onClick={addData}
                />

                <Button
                  type="small"
                  color="--color-black"
                  label="Cancel"
                  active={true}
                  onClick={cancel}
                />
              </>
            )}
          </div>
        </ButtonsBar>
      </div>
    </>
  );
};

export default WindowBikeDetailModerationV4;
