import React, { useState, useEffect } from "react";
import Preloader from "../Preloader/Preloader";
import Checkbox from "../Switcher/Checkbox";
import { useDispatch } from "react-redux";
import { deleteFilesThunkV4 } from "../../../reducersToolkit/thunk/deleteFilesThunkV4";
import Button from "../Buttons/Button";
import WindowFileDetailV4 from "./WindowFileDetailV4";
import { updateFilePriorityThunkV4 } from "../../../reducersToolkit/thunk/updateFilePriorityThunkV4";
import WidgetAlert from "../Alerts/WidgetAlert";
import AnimatedDiv from "../Animation/AnimatedDiv";
import ContextMenu from "../ContentBox/ContextMenu";
import PopupWindow from "../Popups/PopupWindow";

const WidgetListFileV4 = ({
  isLoading,
  isChangeState,

  viewFiles,
  fieldName,

  rootCollectionPath,
  previewCollectionPath,
  previewGeneralCollectionPath,
  metadataDocumentPath,
  metadataGeneralDocumentPath,

  idPreviewDocument,
  idRootDocument,

  loadingStateName,
  errorStateName,

  rootCurrentDocumentState,
  previewCurrentDocumenState,
  previewGeneralDocumenState,

  mode,
}) => {
  const dispatch = useDispatch();

  // Базовые состояния
  const [isOpenDetailPhoto, setIsOpenDetailPhoto] = useState(false);
  const [loadedImages, setLoadedImages] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [deletingFiles, setDeletingFiles] = useState([]);
  const [contextMenu, setContextMenu] = useState({
    show: false,
    x: 0,
    y: 0,
    file: null,
  });

  // Состояния отслеживания операций удаления файлов
  const [isDeleteFilesOperationActive, setIsDeleteFilesOperationActive] =
    useState(false);
  const [isDeleteOneFileOperationActive, setIsDeleteOneFileOperationActive] =
    useState(false);

  // Состояние отслеживания операции обновления приоритета (дополнительно)
  const [isUpdatePriorityOperationActive, setIsUpdatePriorityOperationActive] =
    useState(false);

  // Вычисляемое состояние для блокировки кнопок - основной фокус на deleteFilesThunkV4
  const areButtonsBlocked =
    isLoading || isDeleteFilesOperationActive || isDeleteOneFileOperationActive;

  // Эффект для сброса выбранных файлов при изменении состояния загрузки
  useEffect(() => {
    if (isLoading) {
      setDeletingFiles([]);
      setSelectedFiles([]);
    }
  }, [isLoading]);

  const handleClose = () => {
    setIsOpenDetailPhoto(false);
  };

  const handleToggleDetailPhoto = (fileItem) => {
    // Не открываем детализацию, если идет процесс удаления
    if (areButtonsBlocked) return;

    setSelectedFile(fileItem);
    setIsOpenDetailPhoto(true);
  };

  const handleImageLoad = (index) => {
    setLoadedImages((prev) => ({ ...prev, [index]: true }));
  };

  const handleCheckboxChange = (event, fileItem) => {
    // Запрещаем выбор, если идет процесс удаления
    if (areButtonsBlocked) return;

    event.stopPropagation();
    setSelectedFiles((prevSelectedFiles) => {
      if (prevSelectedFiles.includes(fileItem)) {
        return prevSelectedFiles.filter((item) => item !== fileItem);
      } else {
        return [...prevSelectedFiles, fileItem];
      }
    });
  };

  const handleDeleteFiles = () => {
    // Если уже идет процесс удаления, не запускаем новый
    if (areButtonsBlocked) return;

    const fileIds = selectedFiles.map((file) => file.id);
    setDeletingFiles(fileIds);
    setIsDeleteFilesOperationActive(true); // Помечаем, что операция удаления файлов активна

    dispatch(
      deleteFilesThunkV4({
        fieldName,
        fileIds,
        rootCollectionPath,
        previewCollectionPath,
        previewGeneralCollectionPath,
        idPreviewDocument,
        idRootDocument,
        metadataDocumentPath,
        metadataGeneralDocumentPath,
        rootCurrentDocumentState,
        previewCurrentDocumenState,
        previewGeneralDocumenState,
        loadingStateName,
        errorStateName,
      })
    )
      .then(() => {
        console.log("Файлы успешно удалены");
        setDeletingFiles([]);
        setSelectedFiles([]);
        if (typeof isChangeState === "function") {
          isChangeState(true);
        }
      })
      .catch((error) => {
        console.error("Ошибка при удалении файлов:", error);
      })
      .finally(() => {
        // В любом случае сбрасываем флаг активности операции
        setIsDeleteFilesOperationActive(false);
      });
  };

  const handleDeleteOneFile = ({ fieldName, fileIds }) => {
    setDeletingFiles(fileIds);
    setIsDeleteOneFileOperationActive(true); // Помечаем, что операция удаления одного файла активна

    dispatch(
      deleteFilesThunkV4({
        fieldName,
        fileIds,
        rootCollectionPath,
        previewCollectionPath,
        previewGeneralCollectionPath,
        idPreviewDocument,
        idRootDocument,
        metadataDocumentPath,
        metadataGeneralDocumentPath,
        rootCurrentDocumentState,
        previewCurrentDocumenState,
        previewGeneralDocumenState,
        loadingStateName,
        errorStateName,
      })
    )
      .then(() => {
        console.log("Файл успешно удален");
        setDeletingFiles([]);
        setSelectedFiles([]);
        if (typeof isChangeState === "function") {
          isChangeState(true);
        }
      })
      .catch((error) => {
        console.error("Ошибка при удалении файла:", error);
      })
      .finally(() => {
        // В любом случае сбрасываем флаг активности операции
        setIsDeleteOneFileOperationActive(false);
      });
  };

  const handleReset = () => {
    // Если идет процесс удаления, не позволяем сбрасывать выбор
    if (areButtonsBlocked) return;

    setDeletingFiles([]);
    setSelectedFiles([]);
  };

  const handlePriorityUpdate = async ({
    fieldName,
    filesList,
    currentFile,
  }) => {
    setIsUpdatePriorityOperationActive(true);

    try {
      await dispatch(
        updateFilePriorityThunkV4({
          rootCollectionPath,
          previewCollectionPath,
          previewGeneralCollectionPath,
          metadataDocumentPath,
          metadataGeneralDocumentPath,
          idRootDocument,
          idPreviewDocument,
          fieldName,
          filesList,
          currentFile,
          rootCurrentDocumentState,
          previewCurrentDocumenState,
          previewGeneralDocumenState,
          loadingStateName,
          errorStateName,
        })
      ).unwrap();
      console.log("Приоритет файла успешно обновлен");
      if (typeof isChangeState === "function") {
        isChangeState(true);
      }
    } catch (error) {
      console.error("Ошибка при обновлении приоритета файла:", error);
    } finally {
      setIsUpdatePriorityOperationActive(false);
    }
  };

  const handleContextMenu = (e, fileItem) => {
    e.stopPropagation();
    setContextMenu({
      show: true,
      x: e.clientX,
      y: e.clientY,
      file: fileItem,
    });
  };

  const handleContextMenuClose = () => {
    setContextMenu({
      show: false,
      x: 0,
      y: 0,
      file: null,
    });
  };

  const handleContextMenuSelect = (option) => {
    if (!contextMenu.file) return;

    if (option === "Delete file") {
      handleDeleteOneFile({
        fieldName,
        fileIds: [contextMenu.file.id],
      });
    } else if (option === "Make main") {
      handlePriorityUpdate({
        fieldName,
        filesList: viewFiles,
        currentFile: contextMenu.file,
      });
    } else if (option === "Select file" || option === "Cancel select file") {
      handleCheckboxChange({ stopPropagation: () => {} }, contextMenu.file);
    }
    handleContextMenuClose();
  };

  return (
    viewFiles?.length > 0 && (
      <>
        <ContextMenu
          show={contextMenu.show}
          options={[
            contextMenu.file && selectedFiles.includes(contextMenu.file)
              ? "Cancel select file"
              : "Select file",
            "Delete file",
            ...(!contextMenu.file?.priority ? ["Make main"] : []),
          ]}
          onSelect={handleContextMenuSelect}
          onClose={handleContextMenuClose}
        />

        <PopupWindow
          isOpen={isOpenDetailPhoto}
          onClose={handleClose}
          title="Photo detail"
        >
          <WindowFileDetailV4
            onPriorityUpdate={handlePriorityUpdate}
            onDeleteFile={handleDeleteOneFile}
            currentFile={selectedFile}
            filesList={viewFiles}
            collectionPath={rootCollectionPath}
            documentId={idRootDocument}
            fieldName={fieldName}
            onClose={handleClose}
            mode={mode}
            isDeleteOperationActive={isDeleteOneFileOperationActive}
            isUpdatePriorityOperationActive={isUpdatePriorityOperationActive}
          />
        </PopupWindow>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: `repeat(${
              viewFiles && viewFiles.length <= 3 ? viewFiles.length : 3
            }, 1fr)`,
            flex: "1",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          {viewFiles?.map((fileItem, index) => (
            <div
              key={index}
              style={{
                overflow: "hidden",
                backgroundImage: loadedImages[index]
                  ? isLoading
                    ? "none"
                    : `url(${fileItem.url})`
                  : "none",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundColor: "var(--color-gray-100)",
                borderRadius: "8px",
                cursor: areButtonsBlocked ? "default" : "pointer",
                position: "relative",
                flex: "1",
                aspectRatio: "16/9",
                ...(fileItem.priority && {
                  gridColumn: `1 / span ${viewFiles.length < 3 ? 1 : 2}`,
                  gridRow: `1 / span ${viewFiles.length < 3 ? 1 : 2}`,
                }),
              }}
            >
              {!loadedImages[index] && (
                <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
              )}
              {isLoading && (
                <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
              )}
              {deletingFiles.includes(fileItem.id) && (
                <div
                  style={{
                    position: "absolute",
                    zIndex: 1,
                    backgroundColor: "var(--color-gray-100-alpha-50)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Preloader color="--color-white" />
                </div>
              )}
              <img
                src={fileItem.url}
                alt=""
                onLoad={() => handleImageLoad(index)}
                style={{ display: "none" }}
              />
              {mode === "edit" && !areButtonsBlocked && (
                <>
                  <div
                    style={{
                      position: "absolute",
                      bottom: "8px",
                      right: "8px",
                      // zIndex: 2,
                      cursor: "pointer",
                      backgroundColor: "var(--color-white)",
                      borderRadius: "4px",
                      // width: "32px",
                      // height: "32px",
                      padding: "2px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                  >
                    <Checkbox
                      checked={selectedFiles.includes(fileItem)}
                      onChange={(e) => handleCheckboxChange(e, fileItem)}
                      disabled={areButtonsBlocked}
                    />
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "4px",
                      right: "4px",
                      zIndex: 2,
                      cursor: "pointer",
                      backgroundColor: "var(--color-white)",
                      borderRadius: "50%",
                      width: "32px",
                      height: "32px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      // boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                    }}
                    onClick={(e) => handleContextMenu(e, fileItem)}
                  >
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "20px" }}
                    >
                      more_vert
                    </span>
                  </div>
                </>
              )}
              {mode === "edit" && fileItem.priority && !isLoading && (
                <div
                  style={{
                    position: "absolute",
                    left: "0",
                    bottom: "0",
                    width: "auto",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    boxSizing: "border-box",
                    gap: "4px",
                    padding: "4px",
                    backgroundColor: "var(--color-success)",
                    borderRadius: "80px",
                    margin: "8px",
                    color: "var(--color-white)",
                  }}
                >
                  <span
                    className="material-symbols-outlined"
                    style={{
                      fontSize: "1rem",
                    }}
                  >
                    task_alt
                  </span>
                </div>
              )}
              <div
                onClick={() => handleToggleDetailPhoto(fileItem)}
                className="widgetListFile__cleek-box"
              ></div>
            </div>
          ))}
        </div>
        {selectedFiles.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <>
              <Button
                type="small"
                color="--color-black"
                label="Cancel selections"
                onClick={handleReset}
                active={!areButtonsBlocked} // Блокируем кнопку во время удаления
              />

              <Button
                type="small"
                color="--color-alarm"
                label={`Remove ${
                  selectedFiles.length > 1
                    ? `${selectedFiles.length} viewFiles`
                    : "file"
                }`}
                onClick={handleDeleteFiles}
                active={!areButtonsBlocked} // Блокируем кнопку во время удаления
                allert={
                  <WidgetAlert
                    title={`Please confirm that you really want to delete
                      ${
                        selectedFiles.length === 1
                          ? "this file"
                          : `${selectedFiles.length} viewFiles.`
                      }`}
                  />
                }
              />
            </>
          </div>
        )}
      </>
    )
  );
};

export default WidgetListFileV4;
