import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "config",

  initialState: {
    loadingStateGoogleMapApi: false,
  },

  reducers: {
    setLoadingStateGoogleMapApi: (state, action) => {
      state.loadingStateGoogleMapApi = action.payload;
    },
  },
});

export const { setLoadingStateGoogleMapApi } = configSlice.actions;

export default configSlice.reducer;
