import React from "react";
// import "../../profile.css";

const TitleBlockSection = ({
  title,
  titleStyle,
  description,
  descriptionStyle,
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        // gap: "4px",
        width: "100%",
        minHeight: "64px",
        padding: "8px 0",
        boxSizing: "border-box",
      }}
    >
      <h5 style={{ ...titleStyle }}>{title ? title : "No title"}</h5>
      {description && (
        <div
          className="p-light"
          style={{
            width: "80%",
            ...descriptionStyle,
          }}
        >
          {description}
        </div>
      )}
    </div>
  );
};

export default TitleBlockSection;
