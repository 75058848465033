import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentBoxV4 from "../../UIComponents/ContentBox/ContentBoxV4";

const WidgetNavigateMenu = ({ onClose }) => {
  const [language, setLanguage] = useState("en");

  const { currentUser } = useSelector((state) => state.catalogSliceV4);

  useEffect(() => {
    setLanguage(currentUser?.settings?.language || "en");
  }, [currentUser]);

  // Рендер иконки для элемента меню
  const renderIcon = (iconName) => {
    return <span className="material-symbols-outlined">{iconName}</span>;
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "16px",
      }}
    >
      <ContentBoxV4
        key="/Catalog"
        label="Catalog"
        icon={
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "3rem",
            }}
          >
            inventory_2
          </span>
        }
        execute={`/${language}/Catalog`}
        style={{
          padding: "16px",
          boxSizing: "border-box",
          borderRadius: "16px",
          backgroundColor: "var(--color-gray-100)",
          aspectRatio: "1/1",
        }}
        onClickAfter={onClose}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <h1 style={{ fontWeight: "100" }}>800</h1>
          <p>bikes</p>
        </div>
      </ContentBoxV4>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <ContentBoxV4
          key="/"
          label="Home"
          icon={
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "3rem",
              }}
            >
              home
            </span>
          }
          execute={`/${language}`}
          style={{
            padding: "16px",
            boxSizing: "border-box",
            borderRadius: "16px",
            backgroundColor: "var(--color-gray-100)",
            // aspectRatio: "1/1",
          }}
          onClickAfter={onClose}
        />
        <ContentBoxV4
          key="/support"
          label="Support"
          icon={
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "3rem",
              }}
            >
              support_agent
            </span>
          }
          execute={`/${language}/support`}
          style={{
            padding: "16px",
            boxSizing: "border-box",
            borderRadius: "16px",
            backgroundColor: "var(--color-gray-100)",
          }}
          onClickAfter={onClose}
        />
        <ContentBoxV4
          key="/offer"
          label="Offer"
          icon={
            <span
              className="material-symbols-outlined"
              style={{
                fontSize: "3rem",
              }}
            >
              local_offer
            </span>
          }
          execute={`/${language}/offer`}
          style={{
            padding: "16px",
            boxSizing: "border-box",
            borderRadius: "16px",
            backgroundColor: "var(--color-gray-100)",
          }}
          onClickAfter={onClose}
        />
      </div>
      <ContentBoxV4
        key="/about"
        label="About"
        icon={
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "3rem",
            }}
          >
            info
          </span>
        }
        execute={`/${language}/about`}
        style={{
          padding: "16px",
          boxSizing: "border-box",
          borderRadius: "16px",
          backgroundColor: "var(--color-gray-100)",
          // aspectRatio: "1/1",
        }}
        onClickAfter={onClose}
      />
      <ContentBoxV4
        key="/inform"
        label="Inform"
        icon={
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "3rem",
            }}
          >
            notifications
          </span>
        }
        execute={`/${language}/inform`}
        style={{
          padding: "16px",
          boxSizing: "border-box",
          borderRadius: "16px",
          backgroundColor: "var(--color-gray-100)",
        }}
        onClickAfter={onClose}
      />
    </div>
  );
};

export default WidgetNavigateMenu;
