import React from "react";
import Preloader from "../Preloader/Preloader";

const ContentBox = React.memo(
  ({
    children, // Содержимое блока
    position = "medium", // Позиция блока (start, end, one, medium), по умолчанию "medium"
    isLoading = false, // Флаг состояния загрузки, по умолчанию false
    label, // Текст подписи при загрузке (только для Preloader)
    header, // Заголовок блока (опционально)
    onClick, // Обработчик клика (опционально)
    skeleton, // Скелетон для отображения во время загрузки (опционально)
    style,
  }) => {
    // --- Вычисление стилей в зависимости от позиции блока ---
    const getPositionStyles = () => {
      switch (position) {
        case "start":
          return {
            gridColumn: "1 / span 2",
            gridRow: "1 / span 1",
          };
        case "end":
          return {
            gridColumn: "3 / span 2",
            gridRow: "1 / span 1",
          };
        case "one":
          return {
            gridColumn: "1 / span 4",
            gridRow: "1 / span 1",
          };
        default: // medium
          return {
            gridColumn: "auto / span 2",
            gridRow: "auto / span 1",
          };
      }
    };

    // --- Обработчик клика с учетом состояния загрузки ---
    const handleClick = !isLoading && onClick ? onClick : undefined;

    // --- Рендеринг компонента ---
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          boxSizing: "border-box",
          padding: header ? "0" : "16px",
          borderRadius: "24px",
          backgroundColor: "var(--color-white)",
          width: "100%",
          height: "100%",
          ...getPositionStyles(),
          ...style,
        }}
      >
        {/* Секция заголовка (header), если передан */}
        {header && (
          <div
            style={{
              width: "100%",
              position: "relative",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                position: "absolute",
                bottom: "0",
                left: "0",
                width: "100%",
                height: "24px",
                borderRadius: "24px 24px 0 0",
                zIndex: 1,
                boxSizing: "border-box",
              }}
            />
            {header}
          </div>
        )}

        {/* Контент блока с обработчиком клика */}
        <div
          onClick={handleClick}
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "16px",
            // padding: "16px 0px",
            boxSizing: "border-box",
            cursor: !isLoading && onClick ? "pointer" : "default",
          }}
        >
          {/* Условное отображение: загрузка или контент */}
          {isLoading
            ? skeleton || (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "8px",
                    width: "100%",
                    height: "100%",
                  }}
                >
                  <Preloader />
                  {label && (
                    <p
                      style={{
                        color: "var(--color-gray-500)",
                        fontSize: "14px",
                        fontWeight: "300",
                        margin: 0,
                      }}
                    >
                      {label}
                    </p>
                  )}
                </div>
              )
            : children}
        </div>
      </div>
    );
  }
);

ContentBox.displayName = "ContentBox";

export default ContentBox;
