import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";

/**
 * Универсальный компонент контекстного меню в стиле iOS
 *
 * @param {Array} options - Массив опций меню (строки или объекты с полями title и additions)
 * @param {Function} onSelect - Функция обработки выбора опции
 * @param {Function} onCancel - Функция обработки нажатия на кнопку Cancel
 * @param {string} selectedOption - Текущая выбранная опция (для подсветки)
 * @param {boolean} show - Флаг отображения меню (если управляется извне)
 * @param {Function} onClose - Функция закрытия меню
 * @param {Object} customStyles - Объект с кастомными стилями для переопределения
 * @param {React.ReactNode} icon - Иконка для кнопки меню
 * @param {boolean} useInternalToggle - Флаг для использования внутреннего управления видимостью
 * @param {Object} buttonStyle - Объект с кастомными стилями для кнопки меню
 * @returns {React.Component}
 */
const ContextMenu = ({
  options,
  onSelect,
  onCancel,
  selectedOption,
  show: externalShow,
  onClose,
  customStyles = {},
  title = "Выберите опцию",
  icon = <span className="material-symbols-outlined">sort</span>,
  useInternalToggle = false,
  buttonStyle = {},
}) => {
  const [internalShow, setInternalShow] = useState(false);
  const [menuTransform, setMenuTransform] = useState("translateY(100%)");
  const [overlayOpacity, setOverlayOpacity] = useState(0);
  const [contentHeight, setContentHeight] = useState("auto");

  // Определяем, что управляет видимостью - внутреннее состояние или внешний параметр
  const show = useInternalToggle ? internalShow : externalShow;

  const popupIdRef = useRef(
    `context-menu-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`
  );

  const menuRef = useRef(null);
  const contentRef = useRef(null);
  const resizeObserverRef = useRef(null);
  const mutationObserverRef = useRef(null);
  const startY = useRef(0);
  const currentY = useRef(0);
  const isDragging = useRef(false);
  const resizeTimerRef = useRef(null);
  const isUpdatingRef = useRef(false);
  const lastContentHeightRef = useRef(0);
  const forceUpdateRef = useRef(0);

  const closePopup = () => {
    setMenuTransform("translateY(100%)");
    setOverlayOpacity(0);

    setTimeout(() => {
      if (useInternalToggle) {
        setInternalShow(false);
      } else if (onClose) {
        onClose();
      }
    }, 300);
  };

  const toggleMenu = () => {
    if (useInternalToggle) {
      setInternalShow(!internalShow);
    }
  };

  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
    isDragging.current = true;
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;

    currentY.current = e.touches[0].clientY;
    const deltaY = currentY.current - startY.current;

    if (deltaY > 0) {
      setMenuTransform(`translateY(${deltaY}px)`);
      setOverlayOpacity(Math.max(0, 0.5 - deltaY / 1000));
    }
  };

  const handleTouchEnd = () => {
    if (!isDragging.current) return;

    isDragging.current = false;
    const deltaY = currentY.current - startY.current;

    if (deltaY > 100) {
      closePopup();
    } else {
      setMenuTransform("translateY(0)");
      setOverlayOpacity(0.5);
    }
  };

  const forceUpdate = () => {
    forceUpdateRef.current = forceUpdateRef.current + 1;
    updateContentHeight(true);
  };

  const updateContentHeight = (force = false) => {
    if ((!contentRef.current || !show) && !force) return;
    if (isUpdatingRef.current && !force) return;

    try {
      isUpdatingRef.current = true;

      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }

      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }

      if (contentRef.current) {
        contentRef.current.style.height = "auto";

        const originalDisplay = contentRef.current.style.display;
        contentRef.current.style.display = "none";
        void contentRef.current.offsetHeight;
        contentRef.current.style.display = originalDisplay;
      }

      requestAnimationFrame(() => {
        if (!contentRef.current || !show) {
          isUpdatingRef.current = false;
          return;
        }

        const contentScrollHeight = contentRef.current.scrollHeight;
        const headerHeight = 56;
        const indicatorHeight = 20;
        const padding = 8;
        const maxAvailableHeight = window.innerHeight * 0.95;

        const currentHeight = contentScrollHeight;
        const heightChanged =
          Math.abs(currentHeight - lastContentHeightRef.current) > 5;
        lastContentHeightRef.current = currentHeight;

        const totalContentHeight =
          contentScrollHeight + headerHeight + indicatorHeight + padding;

        if (totalContentHeight > maxAvailableHeight) {
          const newHeight = `calc(${maxAvailableHeight}px - ${
            headerHeight + indicatorHeight + padding
          }px)`;
          setContentHeight(newHeight);
        } else {
          setContentHeight(`${contentScrollHeight}px`);
        }

        if (heightChanged || force) {
          setTimeout(() => {
            if (show && contentRef.current) {
              updateContentHeight();
            }
          }, 30);
        } else {
          setTimeout(() => {
            setupObservers();
            isUpdatingRef.current = false;
          }, 20);
        }
      });
    } catch (error) {
      console.warn("Error updating height:", error);
      isUpdatingRef.current = false;

      setTimeout(() => {
        setupObservers();
      }, 100);
    }
  };

  const setupObservers = () => {
    if (!contentRef.current || !show) return;

    try {
      if (typeof ResizeObserver !== "undefined") {
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }

        resizeObserverRef.current = new ResizeObserver(() => {
          if (!isUpdatingRef.current) {
            requestAnimationFrame(() => updateContentHeight());
          }
        });

        resizeObserverRef.current.observe(contentRef.current);

        const observeAllChildren = (element) => {
          if (!element) return;

          Array.from(element.children).forEach((child) => {
            try {
              resizeObserverRef.current.observe(child);
            } catch (e) {
              console.warn("Failed to observe:", e);
            }
            observeAllChildren(child);
          });
        };

        observeAllChildren(contentRef.current);
      }

      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }

      mutationObserverRef.current = new MutationObserver((mutations) => {
        const hasRelevantChanges = mutations.some(
          (mutation) =>
            mutation.type === "childList" ||
            (mutation.type === "attributes" &&
              mutation.target.style &&
              (mutation.attributeName === "style" ||
                mutation.attributeName === "class" ||
                mutation.attributeName === "hidden"))
        );

        if (hasRelevantChanges && !isUpdatingRef.current) {
          setTimeout(() => {
            forceUpdate();
          }, 10);
        }
      });

      mutationObserverRef.current.observe(contentRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true,
        attributeFilter: ["style", "class", "hidden", "display"],
      });
    } catch (error) {
      console.warn("Error setting up observers:", error);
    }
  };

  useEffect(() => {
    if (show) {
      setContentHeight("auto");
      lastContentHeightRef.current = 0;
      isUpdatingRef.current = false;

      const setupWithDelays = () => {
        setupObservers();
        updateContentHeight(true);

        setTimeout(() => updateContentHeight(true), 50);
        setTimeout(() => updateContentHeight(true), 300);
        setTimeout(() => updateContentHeight(true), 1000);
      };

      setTimeout(setupWithDelays, 30);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }
    };
  }, [show, options, forceUpdateRef.current]);

  useEffect(() => {
    if (show) {
      document.body.classList.add("no-scroll");
      isUpdatingRef.current = false;
      lastContentHeightRef.current = 0;
      setContentHeight("auto");

      setTimeout(() => {
        setMenuTransform("translateY(0)");
        setOverlayOpacity(0.5);

        setTimeout(() => {
          updateContentHeight(true);
        }, 50);
      }, 10);

      resizeTimerRef.current = setInterval(() => {
        if (!isUpdatingRef.current) {
          updateContentHeight();
        }
      }, 300);
    } else {
      document.body.classList.remove("no-scroll");
      setMenuTransform("translateY(100%)");
      setOverlayOpacity(0);

      if (resizeTimerRef.current) {
        clearInterval(resizeTimerRef.current);
      }
    }

    return () => {
      document.body.classList.remove("no-scroll");
      if (resizeTimerRef.current) {
        clearInterval(resizeTimerRef.current);
      }
    };
  }, [show]);

  useEffect(() => {
    const handleResize = () => {
      if (show) {
        updateContentHeight(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [show]);

  // Кнопка меню
  const MenuButton = () => (
    <button
      onClick={toggleMenu}
      style={{
        height: "48px",
        aspectRatio: "1/1",
        backgroundColor: selectedOption
          ? "var(--color-primary)"
          : "var(--color-gray-100)",
        color: selectedOption ? "var(--color-white)" : "var(--color-black)",
        borderRadius: "16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "none",
        cursor: "pointer",
        ...buttonStyle,
      }}
    >
      {icon}
    </button>
  );

  // Если используется внутреннее управление, всегда отображаем кнопку
  if (useInternalToggle) {
    return (
      <>
        <MenuButton />
        {internalShow && (
          <Portal
            options={options}
            onSelect={onSelect}
            onCancel={onCancel}
            selectedOption={selectedOption}
            title={title}
            customStyles={customStyles}
            closePopup={closePopup}
            menuTransform={menuTransform}
            overlayOpacity={overlayOpacity}
            contentHeight={contentHeight}
            popupIdRef={popupIdRef}
            menuRef={menuRef}
            contentRef={contentRef}
            handleTouchStart={handleTouchStart}
            handleTouchMove={handleTouchMove}
            handleTouchEnd={handleTouchEnd}
          />
        )}
      </>
    );
  }

  // Если используется внешнее управление и меню не показывается, возвращаем null
  if (!show) {
    return null;
  }

  // Если используется внешнее управление и меню показывается, отображаем только меню
  return (
    <Portal
      options={options}
      onSelect={onSelect}
      onCancel={onCancel}
      selectedOption={selectedOption}
      title={title}
      customStyles={customStyles}
      closePopup={closePopup}
      menuTransform={menuTransform}
      overlayOpacity={overlayOpacity}
      contentHeight={contentHeight}
      popupIdRef={popupIdRef}
      menuRef={menuRef}
      contentRef={contentRef}
      handleTouchStart={handleTouchStart}
      handleTouchMove={handleTouchMove}
      handleTouchEnd={handleTouchEnd}
    />
  );
};

// Компонент порталa для отображения меню
const Portal = ({
  options,
  onSelect,
  onCancel,
  selectedOption,
  title,
  customStyles,
  closePopup,
  menuTransform,
  overlayOpacity,
  contentHeight,
  popupIdRef,
  menuRef,
  contentRef,
  handleTouchStart,
  handleTouchMove,
  handleTouchEnd,
}) => {
  return ReactDOM.createPortal(
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1000,
        boxSizing: "border-box",
        backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`,
        backdropFilter: "blur(8px)",
        transition: "background-color 0.3s ease",
        ...customStyles.overlay,
      }}
      onClick={closePopup}
      data-popup-id={popupIdRef.current}
    >
      <div
        ref={menuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          borderRadius: "16px 16px 0 0",
          maxWidth: "var(--width-mobile-content)",
          height: "auto",
          overflow: "hidden",
          backgroundColor: "white",
          transform: menuTransform,
          transition: "transform 0.3s ease, height 0.3s ease",
          touchAction: "none",
          maxHeight: "calc(100vh - 80px)",
          boxSizing: "border-box",
          position: "relative",
          ...customStyles.container,
        }}
        onClick={(e) => e.stopPropagation()}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <div
          style={{
            position: "absolute",
            top: "4px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "48px",
            height: "4px",
            backgroundColor: "var(--color-gray-300)",
            borderRadius: "2px",
            margin: "8px auto",
            boxSizing: "border-box",
            ...customStyles.indicator,
          }}
        />

        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px",
            width: "100%",
            padding: "24px 8px 8px 16px",
            boxSizing: "border-box",
            borderBottom: "1px solid var(--color-gray-200)",
            ...customStyles.header,
          }}
        >
          <h5
            style={{
              textTransform: "capitalize",
              margin: 0,
              color: "var(--color-black)",
              ...customStyles.title,
            }}
          >
            {title}
          </h5>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                height: "32px",
                aspectRatio: "1/1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                ...customStyles.closeButton,
              }}
              onClick={closePopup}
            >
              <span className="material-symbols-outlined">close</span>
            </div>
          </div>
        </div>

        <div
          ref={contentRef}
          style={{
            overflowY: contentHeight === "auto" ? "visible" : "auto",
            height: contentHeight,
            boxSizing: "border-box",
            width: "100%",
            backgroundPosition: "center 16px",
            backgroundRepeat: "no-repeat",
            backgroundSize: "auto 80px",
            transition: "height 0.2s ease",
            ...customStyles.content,
          }}
        >
          <div style={{ padding: "8px 0" }}>
            {options?.map((option, index) => {
              const optionTitle =
                typeof option === "string" ? option : option.title;
              const optionComponent =
                typeof option === "string" ? null : option.additions;
              const isSelected = optionTitle === selectedOption;

              return (
                <div
                  key={index}
                  onClick={() => {
                    onSelect(option);
                    closePopup();
                  }}
                  style={{
                    padding: "16px 24px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "12px",
                    backgroundColor: isSelected
                      ? "var(--color-gray-100)"
                      : "transparent",
                    textTransform: "capitalize",
                    color: isSelected ? "var(--color-black)" : "inherit",
                    fontWeight: isSelected ? "500" : "normal",
                    borderBottom:
                      index < options.length - 1
                        ? "1px solid var(--color-gray-200)"
                        : "none",
                    ...(customStyles.option
                      ? customStyles.option(isSelected)
                      : {}),
                  }}
                >
                  <span>{optionTitle}</span>
                  {optionComponent && optionComponent}
                  {isSelected && (
                    <span
                      className="material-symbols-outlined"
                      style={{ fontSize: "20px" }}
                    >
                      check
                    </span>
                  )}
                </div>
              );
            })}

            {/* Кнопка Cancel */}
            <div
              style={{
                marginTop: "8px",
                borderTop: "1px solid var(--color-gray-200)",
                padding: "16px 24px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                color: "var(--color-black)",
                fontWeight: "600",
                fontSize: "16px",
                ...customStyles.cancelButton,
              }}
              onClick={() => {
                if (onCancel) {
                  onCancel();
                }
                closePopup();
              }}
            >
              Cancel
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default ContextMenu;
