import React, { useEffect, useState } from "react";
import SegmentedControls from "../../../../UIComponents/SegmentedControls/SegmentedControls";
import WidgetUsersListModerationV4 from "./WidgetUsersListModerationV4";
import { useNavigate } from "react-router-dom";
import PreviewItemCard from "../../../../UIComponents/Preview/PreviewItemCard";
import { v4 as uuidv4 } from "uuid";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
// Компонент для отображения списка пользователей в зависимости от выбранного фильтра
const UsersListStatus = ({
  usersModeration,
  state = null,
  contextSortFilter,
}) => {
  const navigate = useNavigate();
  const [filteredUsersList, setFilteredUsersList] = useState([]);

  useEffect(() => {
    if (usersModeration?.length > 0) {
      let filteredList = [...usersModeration];

      // Фильтрация по статусу, если указан
      if (state) {
        filteredList = filteredList.filter(
          (user) =>
            user.statusHistory?.currentStatus === state || user.status === state
        );
      }

      // Применяем дополнительные фильтры из контекстного меню
      if (contextSortFilter === "Deleted") {
        // Фильтруем только удаленных пользователей
        filteredList = filteredList.filter((user) => user.remove === true);
      }

      // Сортировка по времени
      if (contextSortFilter === "Newest First") {
        // Сначала новые (по убыванию created/updated)
        filteredList.sort((a, b) => {
          const aLatestTimestamp = Math.max(
            a.created || 0,
            a.updated || 0,
            new Date(a.lastUpdated || 0).getTime()
          );
          const bLatestTimestamp = Math.max(
            b.created || 0,
            b.updated || 0,
            new Date(b.lastUpdated || 0).getTime()
          );
          return bLatestTimestamp - aLatestTimestamp;
        });
      } else if (contextSortFilter === "Oldest First") {
        // Сначала старые (по возрастанию created/updated)
        filteredList.sort((a, b) => {
          const aLatestTimestamp = Math.max(
            a.created || 0,
            a.updated || 0,
            new Date(a.lastUpdated || 0).getTime()
          );
          const bLatestTimestamp = Math.max(
            b.created || 0,
            b.updated || 0,
            new Date(b.lastUpdated || 0).getTime()
          );
          return aLatestTimestamp - bLatestTimestamp;
        });
      } else {
        // По умолчанию - сортируем по убыванию времени создания/обновления
        filteredList.sort((a, b) => {
          const aLatestTimestamp = Math.max(
            a.created || 0,
            a.updated || 0,
            new Date(a.lastUpdated || 0).getTime()
          );
          const bLatestTimestamp = Math.max(
            b.created || 0,
            b.updated || 0,
            new Date(b.lastUpdated || 0).getTime()
          );
          return bLatestTimestamp - aLatestTimestamp;
        });
      }

      setFilteredUsersList(filteredList);
    } else {
      setFilteredUsersList([]);
    }
  }, [usersModeration, state, contextSortFilter]);

  // Функция для проверки, был ли пользователь создан не более 60 секунд назад
  const isRecentlyCreated = (created) => {
    if (!created) return false;
    const now = Date.now();
    const sixtySecondsAgo = now - 60 * 1000; // 60 секунд в миллисекундах
    return created > sixtySecondsAgo;
  };

  // Обработчик перехода на страницу модерации пользователя
  const handleNavigateToUserModeration = (userId) => {
    if (userId) {
      navigate(`/ClientModeration/${userId}`);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "2px",
      }}
    >
      {filteredUsersList.length > 0 &&
        filteredUsersList?.map((userItem, index) => (
          <PreviewItemCard
            key={userItem.idRootDocument || uuidv4()}
            index={index}
            length={filteredUsersList.length}
            title={userItem.ownerName || "No name"}
            // icon left
            iconLeft={<span className="material-symbols-outlined">person</span>}
            iconLeftStyle={{
              color: "var(--color-black)",
            }}
            containerIconLeftStyle={{
              backgroundColor: isRecentlyCreated(userItem.created)
                ? "var(--color-success-alpha-30)"
                : userItem.remove
                ? "var(--color-alarm-alpha-30)"
                : "var(--color-gray-900-alpha-30)",
            }}
            // icon right
            iconRight={<span className="material-symbols-outlined">link</span>}
            iconRightStyle={{
              color: "var(--color-black)",
            }}
            containerIconRightStyle={{}}
            // container card
            containerCardStyle={{
              backgroundColor: isRecentlyCreated(userItem.created)
                ? "var(--color-success-alpha-30)"
                : userItem.remove
                ? "var(--color-alarm-alpha-30)"
                : "var(--color-gray-900-alpha-30)",
            }}
            onClick={() =>
              handleNavigateToUserModeration(userItem.idRootDocument)
            }
            itemKey={userItem.idRootDocument}
          />
        ))}
    </div>

    // <WidgetUsersListModerationV4 usersModerationList={filteredUsersList} />
  );
};

const WidgetModeration = ({ usersModeration, isLoading }) => {
  const [userFilterState, setUserFilterState] = useState(null);
  const [contextFilterOption, setContextFilterOption] = useState(null);

  // Обработчик выбора опции из контекстного меню
  const handleContextMenuSelect = (option) => {
    setContextFilterOption(option);
  };

  // Опции контекстного меню
  const contextMenuOptions = ["Deleted", "Newest First", "Oldest First"];

  return (
    <>
      {usersModeration?.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ContentBox position="one">
            <SegmentedControls
              options={["moderation", "approved", "banned"]}
              onSelect={userFilterState}
              selected={setUserFilterState}
              contextMenuOptions={contextMenuOptions}
              onSelectContextMenu={handleContextMenuSelect}
            />

            {userFilterState ? (
              <>
                {userFilterState === "moderation" && (
                  <UsersListStatus
                    usersModeration={usersModeration}
                    state="moderation"
                    contextSortFilter={contextFilterOption}
                  />
                )}
                {userFilterState === "approved" && (
                  <UsersListStatus
                    usersModeration={usersModeration}
                    state="approved"
                    contextSortFilter={contextFilterOption}
                  />
                )}
                {userFilterState === "banned" && (
                  <UsersListStatus
                    usersModeration={usersModeration}
                    state="banned"
                    contextSortFilter={contextFilterOption}
                  />
                )}
              </>
            ) : (
              <UsersListStatus
                usersModeration={usersModeration}
                contextSortFilter={contextFilterOption}
              />
            )}
          </ContentBox>
        </div>
      )}
    </>
  );
};

export default WidgetModeration;
