import React, { useEffect, useState } from "react";
import "../profile.css"; // Общие стили для профиля
import AvatarUser from "../../../../UIComponents/Avatars/AvatarUser"; // Компонент аватара пользователя
import priorityFile from "../../../../../functions/priorityFile"; // Утилита для выбора файла с приоритетом
import { useNavigate } from "react-router-dom";

// Компонент предварительного просмотра профиля пользователя
const WidgetUserPreview = ({ user }) => {
  const navigate = useNavigate();
  // --- Состояния компонента ---
  // Флаг открытия окна профиля пользователя
  const [isUserPreviewOpen, setIsUserPreviewOpen] = useState(false);
  // Email пользователя
  const [userEmail, setUserEmail] = useState("");
  // Имя пользователя
  const [userName, setUserName] = useState("");
  // Отформатированная дата создания профиля
  const [formattedCreationDate, setFormattedCreationDate] = useState("");
  // URL аватара пользователя
  const [avatarUrl, setAvatarUrl] = useState(null);
  // ID пользователя
  const [userId, setUserId] = useState(null);

  // --- Эффект для синхронизации данных из пропса user ---

  // Обновляет локальные состояния на основе данных пользователя
  useEffect(() => {
    // Парсим timestamp создания профиля и форматируем в локальную дату
    const date = new Date(parseInt(user?.created, 10));
    const formattedDate = date.toLocaleDateString("en-US");

    // Выбираем файл аватара с наивысшим приоритетом из списка
    const selectedPriorityFile = priorityFile(
      user?.files?.userAvatarFiles || []
    );

    // Устанавливаем значения состояний с дефолтными значениями при отсутствии данных
    setUserEmail(user?.userContacts?.email || "No email"); // Email пользователя или заглушка
    setUserName(user?.user?.ownerName || "No name"); // Имя пользователя или заглушка
    setFormattedCreationDate(formattedDate); // Отформатированная дата создания
    setAvatarUrl(selectedPriorityFile?.url || null); // URL аватара или null
    setUserId(user?.id || null); // ID пользователя
  }, [user]); // Зависимость от пропса user

  // --- Обработчик клика для открытия профиля ---
  // Переключает состояние окна профиля
  const handleProfileToggle = () => {
    setIsUserPreviewOpen(true);
  };

  // --- Эффект для навигации при открытии профиля ---
  // Перенаправляет пользователя на страницу профиля при открытии окна
  useEffect(() => {
    if (isUserPreviewOpen) {
      navigate(`/userProfile/${userId}`);
    }
  }, [navigate, isUserPreviewOpen, userId]);

  // --- Рендеринг компонента ---

  return (
    <>
      {/* Контейнер предварительного просмотра пользователя с обработчиком клика */}

      <div
        onClick={handleProfileToggle}
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        {/* Блок с аватаром и информацией */}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {/* Компонент аватара пользователя */}

          <AvatarUser size={80} url={avatarUrl} />

          {/* Контейнер с именем и контактной информацией */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
            }}
          >
            {/* Имя пользователя */}

            <h6>{userName}</h6>

            {/* Блок с email и датой создания */}

            <div>
              <p className="p-light">Email: {userEmail}</p>
              <p className="p-light">{formattedCreationDate}</p>
            </div>
          </div>
        </div>

        {/* Иконка редактирования */}

        <div>
          <span className="material-symbols-outlined">edit</span>
        </div>
      </div>
    </>
  );
};

export default WidgetUserPreview;
