import React, { useState, useMemo } from "react";
import AddData from "../../../../UIComponents/AddData/AddData";
import { useNavigate } from "react-router-dom";
import SegmentedControls from "../../../../UIComponents/SegmentedControls/SegmentedControls";
import WidgetBikesList from "../BikesV3/WidgetBikesList";
import ContextMenu from "../../../../UIComponents/ContentBox/ContextMenu";

const WidgetBikeListModerationV4 = ({ bikesList, mode, isLoading }) => {
  const navigate = useNavigate();
  console.log("WidgetBikeListModerationV4 bikesList", bikesList);
  const [bikeState, setBikeState] = useState(null);
  console.log("bikeState", bikeState);
  const [contextMenuSelected, setContextMenuSelected] = useState(null);
  console.log("contextMenuSelected", contextMenuSelected);

  // Базовая сортировка и фильтрация списка велосипедов
  const sortedBikesList = useMemo(() => {
    if (!bikesList || bikesList.length === 0) {
      return [];
    }

    let filtered = [...bikesList];

    // Применяем фильтрацию/сортировку из контекстного меню
    if (contextMenuSelected) {
      switch (contextMenuSelected) {
        case "deleted":
          filtered = filtered.filter((bike) => bike.remove !== null);
          break;
        case "recently added first":
          filtered = filtered.sort((a, b) => b.created - a.created);
          break;
        case "long ago added first":
          filtered = filtered.sort((a, b) => a.created - b.created);
          break;
        case "recently edited":
          filtered = filtered.sort((a, b) => b.lastUpdated - a.lastUpdated);
          break;
        case "long ago edited":
          filtered = filtered.sort((a, b) => a.lastUpdated - b.lastUpdated);
          break;
        default:
          break;
      }
    }

    return filtered;
  }, [bikesList, contextMenuSelected]);

  const handleCreateBike = () => {
    // Получаем необходимые параметры из props или контекста
    const temporaryBikeId = "new"; // Используем 'new' в качестве плейсхолдера для нового велосипеда
    console.log("handleCreateBike, передаваемые параметры:", {
      bikesList,
      mode,
    });

    // Ищем rentalPointId и userId из первого велосипеда в списке (если есть)
    let rentalPointId, userId;

    if (bikesList && bikesList.length > 0) {
      // Пытаемся получить идентификаторы из данных велосипеда
      rentalPointId = bikesList[0].idRootDocumentRentalPoint;
      userId = bikesList[0].idRootDocumentUser;
    }

    // Если не нашли в данных велосипедов, извлекаем из URL
    if (!rentalPointId || !userId) {
      const pathParts = window.location.pathname.split("/");
      // Формат URL обычно такой: /ModerationRentalPoint/:rentalPointId/:userId
      if (pathParts.length >= 4 && pathParts[1] === "ModerationRentalPoint") {
        rentalPointId = pathParts[2];
        userId = pathParts[3];
      }
    }

    // Проверяем, что у нас есть все необходимые параметры
    if (!rentalPointId || !userId) {
      console.error(
        "Не удалось получить rentalPointId или userId для создания велосипеда"
      );
      return;
    }

    // Формируем URL в нужном формате: /ModerationAddBikeToRentalPoint/:bikeId/:RentalPointId/:usrId
    navigate(
      `/ModerationAddBikeToRentalPoint/${temporaryBikeId}/${rentalPointId}/${userId}`
    );
    console.log(
      `Переход на URL: /ModerationAddBikeToRentalPoint/${temporaryBikeId}/${rentalPointId}/${userId}`
    );
  };

  // Функция для сброса фильтров контекстного меню
  const handleContextMenuCancel = () => {
    setContextMenuSelected(null);
    setBikeState(null);
  };

  return (
    <>
      <AddData
        icon={<span className="material-symbols-outlined">add</span>}
        title="Add bike"
        description="Add a new bike to this rental point."
        onClick={handleCreateBike}
        isLoading={isLoading}
      />
      {bikesList && bikesList.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            <SegmentedControls
              options={["moderation", "approved", "banned"]}
              selected={setBikeState}
              onSelect={bikeState}
            />

            {/* Контекстное меню с встроенной кнопкой сортировки */}
            <ContextMenu
              options={[
                "deleted",
                "recently added first",
                "long ago added first",
                "recently edited",
                "long ago edited",
              ]}
              title="Сортировка"
              icon={<span className="material-symbols-outlined">sort</span>}
              useInternalToggle={true}
              onSelect={setContextMenuSelected}
              onCancel={handleContextMenuCancel}
              selectedOption={contextMenuSelected}
            />
          </div>
          <WidgetBikesList
            bikesList={sortedBikesList}
            mode={mode}
            bikeState={bikeState}
            bikeFilter={contextMenuSelected}
          />
        </div>
      )}
    </>
  );
};

export default WidgetBikeListModerationV4;
