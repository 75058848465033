import React, { useEffect, useRef, useState } from "react";
import InputText from "../../../../UIComponents/Inputs/InputText";
import Textarea from "../../../../UIComponents/Textarea/Textarea";
import Button from "../../../../UIComponents/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import Counter from "../../../../UIComponents/Counter/Counter";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import Switcher from "../../../../UIComponents/Switcher/Switcher";

const WindowAditionalServiceDetailModeration = ({
  onClose,
  isChangeState,
  mode,
}) => {
  const dispatch = useDispatch();

  const { tempRootData, tempData, rootNewData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [description, setDescription] = useState("");
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [counterValues, setCounterValues] = useState(0);
  const [currentServices, setCurrentServices] = useState([]);
  const [isFreePrice, setIsFreePrice] = useState(false);
  const [isDoNotShow, setIsDoNotShow] = useState(false);

  // Добавляем состояния для хранения исходных значений
  const [initialTitle, setInitialTitle] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [initialPrice, setInitialPrice] = useState("");
  const [initialCounterValues, setInitialCounterValues] = useState(0);
  const [initialIsFreePrice, setInitialIsFreePrice] = useState(false);
  const [initialIsDoNotShow, setInitialIsDoNotShow] = useState(false);

  // Состояния для активации кнопок
  const [addButtonActive, setAddButtonActive] = useState(false);
  const [updateButtonActive, setUpdateButtonActive] = useState(false);
  const [removeButtonActive, setRemoveButtonActive] = useState(false);

  const prevCounterValues = useRef(counterValues);

  // Определяем, находимся ли мы в режиме модерации или создания
  const isModeratorAddedMode = mode === "moderatorAdded";
  const isModerationMode = mode === "moderation";

  // Получаем данные о выбранной услуге из Redux
  const selectedAdditionalService = tempData?.previewSeletedRentalPoint;

  let additionalService = {
    title: {
      titleOriginal: title,
    },
    description: {
      descriptionOriginal: description,
    },
    price: price, // Сохраняем оригинальную цену независимо от isFreePrice
    isFreePrice: isFreePrice,
    isActive: !isDoNotShow,
    id: selectedServiceId || Date.now().toString(),
    counter: counterValues,
    updateat: Date.now(),
  };

  /**
   *
   * Наполнение useState
   *
   */

  useEffect(() => {
    // Получаем данные о выбранной услуге из Redux

    const selectedData = tempData?.previewSeletedRentalPoint;

    if (selectedData) {
      const titleValue = selectedData?.title?.titleOriginal || "";
      const descriptionValue =
        selectedData?.description?.descriptionOriginal || "";
      const priceValue = selectedData?.price || "";
      const counterValue = selectedData?.counter || 0;
      const isFree = selectedData?.isFreePrice || false;
      const doNotShow = selectedData?.isActive === false;

      setTitle(titleValue);
      setDescription(descriptionValue);
      setPrice(priceValue);
      setSelectedServiceId(selectedData?.id);
      setCounterValues(counterValue);
      setIsFreePrice(isFree);
      setIsDoNotShow(doNotShow);

      // Сохраняем исходные значения
      setInitialTitle(titleValue);
      setInitialDescription(descriptionValue);
      setInitialPrice(priceValue);
      setInitialCounterValues(counterValue);
      setInitialIsFreePrice(isFree);
      setInitialIsDoNotShow(doNotShow);
      // }
    }
  }, [tempData?.previewSeletedRentalPoint, isModerationMode]);

  useEffect(() => {
    if (isModeratorAddedMode) {
      setCurrentServices(
        tempData.tempData_rentalPoint_aditionalServices ||
          rootNewData?.rootRentalPointData?.additionalServices ||
          []
      );
    } else if (isModerationMode) {
      setCurrentServices(
        tempData.tempData_rentalPoint_aditionalServices ||
          tempRootData?.selectedBike_rentalPont?.additionalServices ||
          []
      );
    }
  }, [
    tempData.tempData_rentalPoint_aditionalServices,
    tempRootData?.selectedBike_rentalPont?.additionalServices,
    isModeratorAddedMode,
    isModerationMode,
    rootNewData?.rootRentalPointData?.additionalServices,
  ]);

  // Логика активации кнопок на основе изменений и валидности данных
  useEffect(() => {
    // Проверяем, изменились ли данные относительно исходных значений
    const hasChanges =
      title !== initialTitle ||
      description !== initialDescription ||
      (price !== initialPrice && !isFreePrice) ||
      counterValues !== initialCounterValues ||
      isFreePrice !== initialIsFreePrice ||
      isDoNotShow !== initialIsDoNotShow;

    // Проверяем, заполнены ли все необходимые поля
    const isComplete =
      title &&
      title.trim().length > 0 &&
      (isFreePrice || (price && price > 0)) &&
      counterValues > 0;

    // Проверяем наличие выбранной услуги
    const hasSelectedService = !!selectedAdditionalService;

    // Кнопка Add активна, если все поля заполнены и нет выбранного сервиса
    setAddButtonActive(!hasSelectedService && isComplete);

    // Кнопка Update активна, если есть выбранный сервис и есть изменения
    setUpdateButtonActive(hasSelectedService && hasChanges && isComplete);

    // Кнопка Remove активна, если есть выбранный сервис
    setRemoveButtonActive(hasSelectedService);
  }, [
    title,
    description,
    price,
    counterValues,
    isFreePrice,
    isDoNotShow,
    initialTitle,
    initialDescription,
    initialPrice,
    initialCounterValues,
    initialIsFreePrice,
    initialIsDoNotShow,
    selectedAdditionalService,
  ]);

  /**
   *
   * CRUD функции
   *
   */
  const addAdditionalService = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    const updatedServices = [...currentServices, additionalService];
    console.log(
      "tempData_rentalPoint_aditionalServices updatedServices",
      updatedServices
    );
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_aditionalServices",
        data: updatedServices,
      })
    );
  };

  const updateAdditionalService = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    // Ищем и обновляем нужную услугу в массиве
    const updatedServices = currentServices.map((service) =>
      service.id === selectedServiceId ? additionalService : service
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_aditionalServices",
        data: updatedServices,
      })
    );
  };

  const deleteService = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    // Полностью удаляем сервис из массива вместо пометки на удаление
    const updatedServices = currentServices.filter(
      (service) => service.id !== selectedServiceId
    );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_aditionalServices",
        data: updatedServices,
      })
    );
  };

  /**
   *
   * Логика компонента
   *
   */
  const buttonAddAdditionalService = () => {
    addAdditionalService();
    onClose();
  };

  const buttonUpdateAdditionalService = () => {
    updateAdditionalService();
    onClose();
  };

  const handleDeleteService = () => {
    deleteService();
    onClose();
  };

  // Определяем есть ли выбранная услуга
  const hasSelectedService = !!selectedAdditionalService;

  // Обработчик изменения переключателя Free price
  const handleFreePriceChange = (value) => {
    setIsFreePrice(value);
    // Не изменяем значение price при изменении флага Free price
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
  };

  // Обработчик изменения переключателя Do not show to the client
  const handleDoNotShowChange = (value) => {
    setIsDoNotShow(value);
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
  };

  // Обработчик изменения цены с маской
  const handlePriceChange = (e) => {
    // Извлекаем числовое значение из маскированного ввода
    // Сохраняем точку и до двух цифр после неё
    const value = e.target.value;
    const numericValue = value.replace(/[^\d.]/g, "");

    // Обрабатываем случай, когда вводится точка
    if (numericValue.includes(".")) {
      const parts = numericValue.split(".");
      // Оставляем только первую точку и до двух цифр после неё
      if (parts.length > 1) {
        const decimalPart = parts[1].slice(0, 2); // Берем только до двух цифр после точки
        setPrice(`${parts[0]}.${decimalPart}`);
      } else {
        setPrice(numericValue);
      }
    } else {
      setPrice(numericValue);
    }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          // height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <InputText
            label="Service name"
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
            onChange={(e) => setTitle(e.target.value)}
            value={title ?? ""}
          />

          <Textarea
            label="Description aditional service"
            maxLength={500}
            onChange={(e) => setDescription(e.target.value)}
            value={description ?? ""}
            // unit="chars"
          />
          <Counter
            unit="PC"
            maxValue={100}
            minValue={1}
            value={counterValues}
            onChange={(newCount) => {
              if (prevCounterValues.current !== newCount) {
                setCounterValues(newCount);
                prevCounterValues.current = newCount;
              }
            }}
            label="Number of additional services"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          />
          <InputText
            label="Price"
            type="text"
            description="Enter the price for the additional service (e.g. 9.99 or 9999.99)."
            onChange={handlePriceChange}
            value={price ?? ""}
            unit="$"
            // disabled={isFreePrice}
            isActive={!isFreePrice}
          />

          <Switcher
            label="Free price"
            isChecked={isFreePrice}
            onChange={handleFreePriceChange}
          />
        </ContentBox>
        <ContentBox>
          <Switcher
            label="Do not show to the client"
            isChecked={isDoNotShow}
            onChange={handleDoNotShowChange}
          />
        </ContentBox>
      </div>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          {!hasSelectedService ? (
            <>
              <Button
                type="small"
                active={true}
                label="Cancel"
                color="--color-black"
                onClick={onClose}
                allert={
                  <h1
                    style={{
                      padding: "80px",
                      textAlign: "center",
                    }}
                  >
                    Are you sure you want to leave the editing window?
                  </h1>
                }
              />
              <Button
                label="Add"
                type="small"
                onClick={buttonAddAdditionalService}
                active={addButtonActive}
                color="--color-primary"
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>Are you sure you want to save this information?</h1>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <p className="p-light">Service name: {title}</p>
                      <p className="p-light">
                        Description aditional service: {description}
                      </p>
                      <p className="p-light">
                        Price: {isFreePrice ? "Free" : formatCurrencyUSD(price)}
                      </p>
                      <p className="p-light">
                        {isDoNotShow
                          ? "Hidden from clients"
                          : "Visible to clients"}
                      </p>
                    </div>
                  </div>
                }
              />
            </>
          ) : (
            <>
              <Button
                label="Delete"
                type="small"
                onClick={handleDeleteService}
                active={removeButtonActive}
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>
                      Are you sure you want to delete this additional service?
                    </h1>
                    <p className="p-light">
                      This action is irreversible, and you will lose all data
                      associated with this service.
                    </p>
                    <p className="p-light">Service name: {title}</p>
                    <p className="p-light">
                      Price: {isFreePrice ? "Free" : formatCurrencyUSD(price)}
                    </p>
                  </div>
                }
                color="--color-alarm"
              />

              <Button
                label="Update"
                type="small"
                onClick={buttonUpdateAdditionalService}
                active={updateButtonActive}
                color="--color-primary"
                allert={
                  <div
                    style={{
                      padding: "48px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "16px",
                      textAlign: "center",
                    }}
                  >
                    <h1>Are you sure you want to save this information?</h1>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "2px",
                      }}
                    >
                      <p className="p-light">Service name: {title}</p>
                      <p className="p-light">
                        Description aditional service: {description}
                      </p>
                      <p className="p-light">
                        Price: {isFreePrice ? "Free" : formatCurrencyUSD(price)}
                      </p>
                      <p className="p-light">
                        {isDoNotShow
                          ? "Hidden from clients"
                          : "Visible to clients"}
                      </p>
                    </div>
                  </div>
                }
              />
            </>
          )}
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowAditionalServiceDetailModeration;
