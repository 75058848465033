import React, { useEffect, useState } from "react";
import { Routes, Route, useParams } from "react-router-dom";
// import UILogin from "../../components/Private/Login/UILogin";
import PageProfileV3 from "../../components/Private/Login/Profile/PageProfile";
import PageCatalogV2 from "../../components/Public/CatalogV2/PageCatalogV2";
// import PageProductCardV2 from "../../components/Public/ProductCardV2/PageProductCardV2";
import PageOffer from "../../components/Public/OfferV2/PageOffer";
import PageInform from "../../components/Public/InformV2/PageInform";
import PageAbout from "../../components/Public/AboutV2/PageAbout";
// import PageSupport from "../../components/Public/SupportV2/PageSupport";
import UINotificationsV3 from "../../components/Private/Login/Profile/UINotificationsV3";
import UIBasketV3 from "../../components/Private/Login/Profile/UIBasketV3";
import PageUserProfile from "../../components/Private/Login/Profile/UserProfile/PageUserProfile";
import PageUserDetailModerationV4 from "../../components/Private/Login/Profile/Moderation/PageUserDetailModerationV4";
import PageRentalPointDetailModerationV4 from "../../components/Private/Login/Profile/Moderation/PageRentalPointDetailModerationV4";
import PageBikeDetailModerationV4 from "../../components/Private/Login/Profile/Moderation/PageBikeDetailModerationV4";
import PageProductCard from "../../components/Public/CatalogV2/PageProductCard";
import { useSelector } from "react-redux";
import PageLogin from "../../components/Public/Menu/PageLogin";

// Компоненты для маршрутов с динамическими breadcrumbs
const UserDetailRoute = () => {
  // Извлекаем userId из URL
  const { userId } = useParams();

  return (
    <PageUserDetailModerationV4
      breadcrumbPath={[
        { item: "Profile", link: "/profile" },
        { item: `User ${userId || ""}` },
      ]}
      title="User Management"
    />
  );
};

const RentalPointDetailRoute = ({ mode }) => {
  // Извлекаем параметры из URL
  const { rentalPointId, userId } = useParams();
  const isAddMode = mode === "moderatorAdded";

  return (
    <PageRentalPointDetailModerationV4
      mode={mode}
      id={0}
      breadcrumbPath={[
        { item: "Profile", link: "/profile" },
        { item: "User", link: `/ClientModeration/${userId}` },
        {
          item: isAddMode
            ? "Add Rental Point"
            : `Rental Point ${rentalPointId || ""}`,
        },
      ]}
      title={isAddMode ? "Add Rental Point" : "Edit Rental Point"}
    />
  );
};

const BikeDetailRoute = ({ mode }) => {
  // Извлекаем параметры из URL
  const { bikeId, rentalPointId, userId } = useParams();
  const isAddMode = mode === "moderatorAdded";

  return (
    <PageBikeDetailModerationV4
      mode={mode}
      id={0}
      breadcrumbPath={[
        { item: "Profile", link: "/profile" },
        { item: "User", link: `/ClientModeration/${userId}` },
        {
          item: "Rental Point",
          link: `/ModerationRentalPoint/${rentalPointId}/${userId}`,
        },
        {
          item: isAddMode ? "Add Bike" : `Bike ${bikeId || ""}`,
        },
      ]}
      title={isAddMode ? "Add Bike" : "Edit Bike"}
    />
  );
};

// Компонент маршрута каталога с динамическими хлебными крошками
const CatalogRoute = () => {
  const { language, countryId, cityId, brandId, categoryId, month } =
    useParams();

  // Формируем динамические хлебные крошки в зависимости от параметров URL
  const getBreadcrumbPath = () => {
    const path = [
      { item: "Home", link: `/${language || "en"}` },
      { item: "Catalog", link: `/${language || "en"}/Catalog` },
    ];

    if (countryId) {
      path.push({
        item: `Country: ${countryId}`,
        link: `/${language || "en"}/Catalog/${countryId}`,
      });
    }

    if (cityId) {
      path.push({
        item: `City: ${cityId}`,
        link: `/${language || "en"}/Catalog/${countryId}/${cityId}`,
      });
    }

    if (brandId) {
      path.push({
        item: `Brand: ${brandId}`,
        link: `/${language || "en"}/Catalog/${countryId}/${cityId}/${brandId}`,
      });
    }

    if (categoryId) {
      path.push({
        item: `Category: ${categoryId}`,
        link: `/${
          language || "en"
        }/Catalog/${countryId}/${cityId}/${brandId}/${categoryId}`,
      });
    }

    return path;
  };

  return (
    <PageCatalogV2
      breadcrumbPath={getBreadcrumbPath()}
      title="Bike Catalog"
      mode="view"
    />
  );
};

// Компонент Home маршрута
const HomeRoute = () => {
  const { language } = useParams();

  return (
    <PageCatalogV2
      breadcrumbPath={[{ item: "Home", link: `/${language || "en"}` }]}
      title="Home Page"
      mode="view"
    />
  );
};

// Компонент маршрута ProductCard с динамическими хлебными крошками
const ProductCardRoute = () => {
  const { bikeTitle, bikeId, language } = useParams();
  const { tempData, tempRootData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // Формируем динамические хлебные крошки в зависимости от параметров URL
  const getBreadcrumbPath = () => {
    // Формируем URL для каталога на основе текущих фильтров
    const catalogUrl = `/${
      tempRootData?.user_auth_rootCurrentData?.settings?.language ||
      language ||
      "en"
    }/Catalog/${
      tempData?.tempData_filtersParams?.country?.idRootDocument || "null"
    }/${tempData?.tempData_filtersParams?.city?.idRootDocument || "null"}/${
      tempData?.tempData_filtersParams?.brand?.idRootDocument || "null"
    }/${
      tempData?.tempData_filtersParams?.category?.idRootDocument || "null"
    }/${new Date(
      tempData?.tempData_filtersParams?.dateInterval?.[0] || Date.now()
    ).getMonth()}`;

    const path = [
      { item: "Home", link: "/" },
      { item: "Catalog", link: catalogUrl },
    ];

    // Если есть название велосипеда, добавляем его в хлебные крошки
    if (bikeTitle) {
      path.push({
        item: bikeTitle.replace(/-/g, " "),
      });
    }

    return path;
  };

  return (
    <PageProductCard
      breadcrumbPath={getBreadcrumbPath()}
      title={bikeTitle ? bikeTitle.replace(/-/g, " ") : "Bike Details"}
      mode="view"
    />
  );
};

const ProtectedRoutes = ({ currentUser, userId, rentalPointId }) => {
  const [role, setRole] = useState(null);

  useEffect(() => {
    setRole(currentUser?.role || null);
  }, [currentUser]);

  return (
    <Routes>
      {/* Главная страница */}
      <Route path="/:language?" element={<HomeRoute />} />
      <Route path="/:language?/login" element={<PageLogin />} />
      {/* Маршруты для каталога */}
      <Route path="/:language/Catalog" element={<CatalogRoute />} />
      <Route path="/:language/Catalog/:countryId" element={<CatalogRoute />} />
      <Route
        path="/:language/Catalog/:countryId/:cityId"
        element={<CatalogRoute />}
      />
      <Route
        path="/:language/Catalog/:countryId/:cityId/:brandId"
        element={<CatalogRoute />}
      />
      <Route
        path="/:language/Catalog/:countryId/:cityId/:brandId/:categoryId"
        element={<CatalogRoute />}
      />
      <Route
        path="/:language/Catalog/:countryId/:cityId/:brandId/:categoryId/:month"
        element={<CatalogRoute />}
      />

      {/* Маршруты для остальных страниц */}
      <Route path="/:language/offer" element={<PageOffer />} />
      <Route path="/:language/about" element={<PageAbout />} />
      <Route path="/:language/inform" element={<PageInform />} />
      <Route
        path="/:language?/catalog/:typeBike?/:id?"
        element={<PageCatalogV2 />}
      />
      <Route
        path="/productCard/:bikeTitle?/:bikeId?"
        element={<ProductCardRoute />}
      />
      <Route
        path="/:language?/productCard/:bikeTitle?/:bikeId?"
        element={<ProductCardRoute />}
      />

      {/* Общие маршруты, требующие авторизации */}
      {(role === "rental" || role === "client" || role === "admin") && (
        <>
          <Route
            path="/:language/profile"
            element={
              <PageProfileV3
                breadcrumbPath={[
                  { item: "Home", link: "/" },
                  { item: "Profile" },
                ]}
                title="User Dashboard"
              />
            }
          />
          <Route
            path="/profile"
            element={
              <PageProfileV3
                breadcrumbPath={[
                  { item: "Home", link: "/" },
                  { item: "Profile" },
                ]}
                title="User Dashboard"
              />
            }
          />
        </>
      )}

      {/* Маршруты для администратора */}
      {role === "admin" && (
        <>
          <Route
            path="/:language/notifications"
            element={<UINotificationsV3 />}
          />
          <Route path="/notifications" element={<UINotificationsV3 />} />

          <Route path="/:language/basket" element={<UIBasketV3 />} />
          <Route path="/basket" element={<UIBasketV3 />} />

          <Route
            path="/:language/userProfile"
            element={
              <PageUserProfile
                breadcrumbPath={[
                  { item: "Profile", link: "/profile" },
                  { item: "User Profile" },
                ]}
                title="User Profile"
              />
            }
          />
          <Route
            path="/userProfile/:userId?"
            element={
              <PageUserProfile
                breadcrumbPath={[
                  { item: "Profile", link: "/profile" },
                  { item: "User Profile" },
                ]}
                title="User Profile"
              />
            }
          />

          <Route
            path="/ClientModeration/:userId"
            element={<UserDetailRoute />}
          />

          <Route
            path="/ModerationRentalPoint/:rentalPointId/:userId"
            element={<RentalPointDetailRoute mode="moderation" />}
          />

          <Route
            path="/ModerationAddRentalPoint/:userId"
            element={<RentalPointDetailRoute mode="moderatorAdded" />}
          />

          <Route
            path="/ModerationEditBikeToRentalPoint/:bikeId/:rentalPointId/:userId"
            element={<BikeDetailRoute mode="moderation" />}
          />

          <Route
            path="/ModerationAddBikeToRentalPoint/:bikeId?/:rentalPointId?/:userId?"
            element={<BikeDetailRoute mode="moderatorAdded" />}
          />
        </>
      )}
    </Routes>
  );
};

export default ProtectedRoutes;
