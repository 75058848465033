import React, { useEffect, useState } from "react";
import AddData from "../../../../UIComponents/AddData/AddData";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowBikeModerationV4 from "./WindowBikeModerationV4";
import CollapsableText from "../../../../UIComponents/Collapsable/CollapsableText";

const WidgetBikeModerationV4 = ({ mode, bikeStatus, isChangeState }) => {
  const [windowBikeModeration, setWindowBikeModeration] = useState(false);
  const [latestComment, setLatestComment] = useState(null);
  const [displayStatus, setDisplayStatus] = useState("moderation");

  // Получение статуса из statusHistory
  useEffect(() => {
    if (bikeStatus?.currentStatus) {
      setDisplayStatus(bikeStatus.currentStatus);
    } else {
      setDisplayStatus("moderation");
    }
  }, [bikeStatus]);

  // Получение последнего комментария
  useEffect(() => {
    if (bikeStatus?.comments && Array.isArray(bikeStatus.comments)) {
      const sortedComments = [...bikeStatus.comments].sort((a, b) => {
        const timeA = a.created || a.timestamp || 0;
        const timeB = b.created || b.timestamp || 0;
        return timeB - timeA;
      });

      setLatestComment(sortedComments.length > 0 ? sortedComments[0] : null);
    }
  }, [bikeStatus]);

  // Обработчик открытия окна модерации
  const handleOpenWindowBikeModeration = () => {
    setWindowBikeModeration(true);
  };

  // Обработчик закрытия окна модерации
  const handleClose = () => {
    setWindowBikeModeration(false);
  };

  // Обработчик завершения модерации
  const handleModerationComplete = () => {
    handleClose();
    if (isChangeState) {
      isChangeState(true);
    }
  };

  // Получение заголовка для статуса
  const getStatusTitle = (status) => {
    switch (status) {
      case "approved":
        return "Approved";
      case "banned":
        return "Banned";
      case "moderation":
      default:
        return "Moderation";
    }
  };

  // Получение описания для статуса
  const getStatusDescription = (status, comment) => {
    // Если есть комментарий, используем его содержимое
    if (comment?.content) {
      return comment.content;
    }

    // Иначе используем стандартное описание для статуса
    switch (status) {
      case "approved":
        return "Bike has been approved and is available for rental.";
      case "banned":
        return "Bike has been banned and cannot be rented.";
      case "moderation":
      default:
        return "Bike is under moderation and waiting for approval.";
    }
  };

  return (
    mode === "moderation" && (
      <>
        {/* POPUP WINDOW */}
        <PopupDirrectory
          isOpen={windowBikeModeration}
          onClose={handleClose}
          title="Bike moderation"
        >
          <WindowBikeModerationV4
            mode="moderation"
            onClose={handleModerationComplete}
            isChangeState={isChangeState}
            initialStatus={displayStatus}
          />
        </PopupDirrectory>
        {/* POPUP WINDOW */}

        <AddData
          icon={<span className="material-symbols-outlined">add</span>}
          title={getStatusTitle(displayStatus)}
          description={
            <CollapsableText
              text={getStatusDescription(displayStatus, latestComment)}
            />
          }
          onClick={handleOpenWindowBikeModeration}
        />
      </>
    )
  );
};

export default WidgetBikeModerationV4;
