import React, { memo, useEffect, useState } from "react";
import { QRCodeCanvas } from "qrcode.react";
import PriorityFile from "../../../../UIComponents/UploadFiles/PriorityFile";
import Divide from "../../../../UIComponents/Divide/Divide";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";

// -------------------- Стили и вспомогательные функции --------------------

const commonStyles = {
  voucherContainer: {
    width: "100%",
    aspectRatio: "9/16",
    backgroundColor: "var(--color-primary)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "16px",
    padding: "16px",
    boxSizing: "border-box",
    position: "relative",
  },
  voucherHeader: {
    position: "absolute",
    left: "16px",
    right: "16px",
    top: "16px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  whiteText: {
    color: "var(--color-white)",
  },
  orderItemContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    backgroundColor: "var(--color-gray-100)",
    padding: "16px",
    boxSizing: "border-box",
    borderRadius: "16px",
  },
  timetableItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px",
  },
  contactContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "16px",
    backgroundColor: "var(--color-gray-100)",
    padding: "16px",
    borderRadius: "16px",
  },
};

const holidayStyle = {
  backgroundColor: "var(--color-black)",
  color: "var(--color-white)",
  padding: "2px 8px",
  borderRadius: "4px",
};

const workingHoursStyle = {
  backgroundColor: "var(--color-success)",
  color: "var(--color-white)",
  padding: "2px 8px",
  borderRadius: "4px",
  display: "flex",
  gap: "4px",
};

const checkMarkStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  position: "absolute",
  width: "24px",
  aspectRatio: "1/1",
  borderRadius: "50%",
  backgroundColor: "var(--color-success)",
  color: "var(--color-white)",
  right: "8px",
  top: "8px",
};

const truncateToThreeChars = (value) =>
  typeof value === "string" ? value.slice(0, 3) : value;

const CheckMark = () => (
  <span className="material-symbols-outlined" style={checkMarkStyle}>
    check_small
  </span>
);

// -------------------- Основной компонент --------------------

function WidgetWoucherListV4({
  currentRentalPointOrders,
  // userType, // Не используется в коде
  isMobile, // Используется только для пропа в OrderItem, можно при желании убрать
  totalOrders,
  rentalPointRootData,
}) {
  // Можно показать данные напрямую, без локального состояния.
  const [orderData, setOrderData] = useState({
    quantity: 0,
    total: 0,
    totalCostAditionalServices: 0,
    totalCostBike: 0,
  });

  useEffect(() => {
    setOrderData({
      quantity: totalOrders?.quantity || 0,
      total: totalOrders?.total || 0,
      totalCostAditionalServices: totalOrders?.totalCostAditionalServices || 0,
      totalCostBike: totalOrders?.totalCostBike || 0,
    });
  }, [totalOrders]);

  if (!currentRentalPointOrders?.length) {
    return <div>No orders available</div>;
  }

  // Собираем все ID в строку для QR
  const orderNumber = currentRentalPointOrders
    .map((order) => order.id)
    .join(", ");

  return (
    <>
      {rentalPointRootData && (
        <div style={commonStyles.voucherContainer}>
          <div style={commonStyles.voucherHeader}>
            <h5 style={commonStyles.whiteText}>
              {rentalPointRootData.basicInformation?.title?.titleOriginal}
            </h5>
            <PriorityFile
              style={{
                borderRadius: "80px",
                height: "56px",
                width: "56px",
              }}
              files={rentalPointRootData.files?.logoFiles}
            />
          </div>

          <div
            style={{
              width: "80%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <div
              style={{
                backgroundColor: "var(--color-white)",
                padding: "16px",
                borderRadius: "16px",
              }}
            >
              <QRCodeCanvas
                value={orderNumber}
                size={150}
                fgColor="#000000"
                bgColor="transparent"
                level="H"
              />
            </div>
            <p
              className="p-light"
              style={{
                textAlign: "center",
                color: "var(--color-white)",
                opacity: "0.5",
              }}
            >
              {`Для подтверждения брони покажите этот QR код в точке проката ${
                rentalPointRootData.basicInformation?.title?.titleOriginal || ""
              }`}
            </p>
          </div>

          <div
            style={{
              position: "absolute",
              bottom: "16px",
              left: "16px",
              right: "16px",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <span
              className="material-symbols-outlined"
              style={commonStyles.whiteText}
            >
              location_on
            </span>
            <p className="p-light" style={commonStyles.whiteText}>
              {rentalPointRootData.location?.pointAddress}
            </p>
          </div>
        </div>
      )}

      {/* Список заказов */}
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        {currentRentalPointOrders.map((item, index) => (
          <OrderItem
            key={`order-${item.id}-${index}`}
            item={item}
            isMobile={isMobile}
          />
        ))}
      </div>

      {/* Итоговая информация */}
      {rentalPointRootData && (
        <div style={commonStyles.contactContainer}>
          <p className="p-light">
            {`You have ordered ${orderData.quantity} bikes for a total of 
            ${formatCurrencyUSD(orderData.total)}.`}
            {orderData.totalCostAditionalServices > 0
              ? ` You have also selected additional services for a total of 
                ${formatCurrencyUSD(orderData.totalCostAditionalServices)}.`
              : ` No additional services were ordered.`}
            {` The total cost of your order is 
            ${formatCurrencyUSD(orderData.totalCostBike)}.`}
          </p>

          <Timetable timetable={rentalPointRootData?.timetable} />

          <p className="p-light">
            {`Your order will be ready at
            ${rentalPointRootData.basicInformation?.title?.titleOriginal}.
            Address: ${rentalPointRootData.location?.pointAddress}. Contact:`}
            <ContactInfo contacts={rentalPointRootData?.contacts} />
          </p>
        </div>
      )}
    </>
  );
}

export default WidgetWoucherListV4;
// -------------------- Дочерние компоненты --------------------

const OrderItem = memo(({ item, isMobile }) => {
  // Неиспользуемую переменную userType можно убрать, если она не нужна
  return (
    <div style={commonStyles.orderItemContainer}>
      <h6>
        {item.bikeData?.basicInformation?.title?.titleOriginal || "No name"}
      </h6>

      {item.replacementBikes &&
        Object.entries(item.replacementBikes).map(([key, bikesList], idx) => (
          <ReplacementBikesSection
            key={`${item.id}-${key}`}
            bikesList={bikesList}
            item={item}
            isMobile={isMobile}
            index={idx}
          />
        ))}
    </div>
  );
});

const ReplacementBikesSection = memo(({ bikesList, item, isMobile, index }) => {
  // Если пусто, значит пользователь выбирал "оригинальный" байк
  if (!bikesList.length) {
    return (
      <>
        <Divide title="Option (Original choice)" number={index + 1} />
        <BikesInOrder item={item} />
      </>
    );
  }

  // Иначе рендерим только те, где userChoice === true
  return bikesList.map(
    (bikeItem, idx) =>
      bikeItem.userChoice && (
        <React.Fragment key={`replacement-${bikeItem.id}-${idx}`}>
          <Divide title="Option (Replacement)" number={index + 1} />
          <BikesInOrder item={item} bikeItem={bikeItem} />
        </React.Fragment>
      )
  );
});

const BikesInOrder = memo(({ item, bikeItem }) => {
  // Упростим верстку: просто показываем сетку на 2 колонки
  return (
    <div
      style={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        alignItems: "center",
        gap: "24px",
      }}
    >
      {bikeItem ? (
        <>
          <div style={{ position: "relative", width: "100%" }}>
            <PriorityFile
              style={{ width: "100%", aspectRatio: "16/9", opacity: "0.5" }}
              files={item.bikeData?.files?.photoFiles}
            />
            <div
              style={{
                position: "absolute",
                left: "8px",
                top: "8px",
                width: "100%",
              }}
            >
              <CheckMark />
              <PriorityFile
                style={{ width: "100%", aspectRatio: "16/9" }}
                files={bikeItem.files?.photoFiles}
              />
            </div>
          </div>
          <h6>{bikeItem.basicInformation.title.titleOriginal}</h6>
        </>
      ) : (
        <>
          <div style={{ position: "relative", width: "100%" }}>
            <CheckMark />
            <PriorityFile
              style={{ width: "100%", aspectRatio: "16/9" }}
              files={item.bikeData?.files?.photoFiles}
            />
          </div>
          <h6>{item.bikeData?.basicInformation?.title?.titleOriginal}</h6>
        </>
      )}
    </div>
  );
});

const Timetable = memo(({ timetable = [] }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "2px",
      }}
    >
      {timetable.map((timetableItem, index) => (
        <TimetableItem
          key={`timetable-${index}-${timetableItem.weekday}`}
          timetableItem={timetableItem}
        />
      ))}
    </div>
  );
});

const TimetableItem = memo(({ timetableItem }) => {
  return (
    <div style={commonStyles.timetableItem}>
      <p className="p-light">
        {truncateToThreeChars(timetableItem.weekday?.weekdayOriginal)}
      </p>

      <div
        style={{
          width: "100%",
          height: "1px",
          backgroundColor: "var(--color-gray-200)",
        }}
      />
      {timetableItem.isHoliday ? (
        <p className="p-light" style={holidayStyle}>
          holiday
        </p>
      ) : (
        <div className="p-light" style={workingHoursStyle}>
          <p>
            {timetableItem.openHours}:{timetableItem.openMinutes}
          </p>
          —
          <p>
            {timetableItem.closeHours}:{timetableItem.closeMinutes}
          </p>
        </div>
      )}
    </div>
  );
});

const ContactInfo = memo(({ contacts = {} }) => {
  const { phoneNumbers = [], emailAddresses = [] } = contacts;

  return (
    <>
      {phoneNumbers.map((phoneItem, idx) => (
        <span key={phoneItem.key}>
          {phoneItem.phone}
          {idx < phoneNumbers.length - 1 ? ", " : ""}
        </span>
      ))}
      {phoneNumbers.length ? " or by " : ""}
      {emailAddresses.length > 1
        ? "emails: "
        : emailAddresses.length
        ? "email: "
        : ""}
      {emailAddresses.map((emailItem, idx) => (
        <span key={emailItem.key}>
          {emailItem.email}
          {idx < emailAddresses.length - 1 ? ", " : ""}
        </span>
      ))}
    </>
  );
});

// import React, { useEffect, useRef, useState, useCallback, memo } from "react";
// import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
// import PriorityFile from "../WidgetsProfile/UploadFiles/PriorityFile";
// import Divide from "../WidgetsProfile/Divide/Divide";
// import { QRCodeCanvas } from "qrcode.react";

// // Общие стили и константы
// const commonStyles = {
//   voucherHeader: {
//     position: "absolute",
//     left: "16px",
//     right: "16px",
//     top: "16px",
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//     boxSizing: "border-box",
//   },
//   timetableItem: {
//     display: "flex",
//     flexDirection: "row",
//     alignItems: "center",
//     justifyContent: "space-between",
//     gap: "8px",
//   },
// };

// const truncateToThreeChars = (value) =>
//   typeof value === "string" ? value.slice(0, 3) : value;

// const WidgetWoucherListV4 = ({
//   currentRentalPointOrders,
//   userType,
//   isMobile,
//   totalOrders,
//   rentalPointRootData,
// }) => {
//   const [orderData, setOrderData] = useState({
//     quantity: 0,
//     total: 0,
//     totalCostAditionalServices: 0,
//     totalCostBike: 0,
//   });

//   useEffect(() => {
//     setOrderData({
//       quantity: totalOrders?.quantity || 0,
//       total: totalOrders?.total || 0,
//       totalCostAditionalServices: totalOrders?.totalCostAditionalServices || 0,
//       totalCostBike: totalOrders?.totalCostBike || 0,
//     });
//   }, [totalOrders]);

//   const renderContactInfo = useCallback(() => {
//     const { phoneNumbers = [], emailAddresses = [] } =
//       rentalPointRootData?.contacts || {};
//     return (
//       <>
//         {phoneNumbers.map((phoneItem) => (
//           <span key={phoneItem.key}>{phoneItem.phone}, </span>
//         ))}
//         or by {emailAddresses.length > 1 ? "emails: " : "email: "}
//         {emailAddresses.map((emailItem) => (
//           <span key={emailItem.key}>{emailItem.email}, </span>
//         ))}
//       </>
//     );
//   }, [rentalPointRootData]);

//   if (!currentRentalPointOrders?.length) {
//     return <div>No orders available</div>;
//   }

//   const orderNumber = currentRentalPointOrders
//     .map((order) => order.id)
//     .join(", ");

//   return (
//     <>
//       {rentalPointRootData && (
//         <div
//           style={{
//             width: "100%",
//             aspectRatio: "9/16",
//             backgroundColor: "var(--color-primary)",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "center",
//             borderRadius: "16px",
//             padding: "16px",
//             boxSizing: "border-box",
//             position: "relative",
//           }}
//         >
//           <div style={commonStyles.voucherHeader}>
//             <h5 style={{ color: "var(--color-white)" }}>
//               {rentalPointRootData.basicInformation?.title?.titleOriginal}
//             </h5>
//             <PriorityFile
//               style={{ borderRadius: "80px", height: "56px", width: "56px" }}
//               files={rentalPointRootData.files?.logoFiles}
//             />
//           </div>

//           <div
//             style={{
//               width: "80%",
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               gap: "16px",
//             }}
//           >
//             <div
//               style={{
//                 backgroundColor: "var(--color-white)",
//                 padding: "16px",
//                 borderRadius: "16px",
//               }}
//             >
//               <QRCodeCanvas
//                 value={orderNumber}
//                 size={150}
//                 fgColor="#000000"
//                 bgColor="transparent"
//                 level="H"
//               />
//             </div>
//             <p
//               className="p-light"
//               style={{
//                 textAlign: "center",
//                 color: "var(--color-white)",
//                 opacity: "0.5",
//               }}
//             >
//               {`Для подтверждения брони предоставьте этот QR код в точке проката
//               ${rentalPointRootData.basicInformation?.title?.titleOriginal}`}
//             </p>
//           </div>

//           <div
//             style={{
//               position: "absolute",
//               bottom: "16px",
//               left: "16px",
//               right: "16px",
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               gap: "8px",
//             }}
//           >
//             <span
//               className="material-symbols-outlined"
//               style={{ color: "var(--color-white)" }}
//             >
//               location_on
//             </span>
//             <p className="p-light" style={{ color: "var(--color-white)" }}>
//               {rentalPointRootData.location?.pointAddress}
//             </p>
//           </div>
//         </div>
//       )}

//       <div
//         style={{
//           width: "100%",
//           display: "flex",
//           flexDirection: "column",
//           gap: "8px",
//         }}
//       >
//         {currentRentalPointOrders.map((item, index) => (
//           <OrderItem
//             key={`order-${item.id}-${index}`}
//             item={item}
//             isMobile={isMobile}
//           />
//         ))}
//       </div>

//       {rentalPointRootData && (
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             alignItems: "center",
//             gap: "16px",
//             backgroundColor: "var(--color-gray-100)",
//             padding: "16px",
//             borderRadius: "16px",
//           }}
//         >
//           <p className="p-light">
//             {`You have ordered ${orderData.quantity} bikes for a total of
//             ${formatCurrencyUSD(orderData.total)}.`}
//             {orderData.totalCostAditionalServices > 0
//               ? `You have also selected additional services for a total of
//                 ${formatCurrencyUSD(orderData.totalCostAditionalServices)}.`
//               : `No additional services were ordered.`}
//             {`The total cost of your order is
//             ${formatCurrencyUSD(orderData.totalCostBike)}.`}
//           </p>

//           <Timetable rentalPointRootData={rentalPointRootData} />

//           <p className="p-light">
//             Your order will be ready at{" "}
//             {rentalPointRootData.basicInformation?.title?.titleOriginal}.
//             Address: {rentalPointRootData.location?.pointAddress}. Contact:{" "}
//             {renderContactInfo()}
//           </p>
//         </div>
//       )}
//     </>
//   );
// };

// const OrderItem = memo(({ item, isMobile }) => {
//   let globalIndex = 0;
//   return (
//     <div
//       style={{
//         width: "100%",
//         display: "flex",
//         flexDirection: "column",
//         gap: "16px",
//         backgroundColor: "var(--color-gray-100)",
//         padding: "16px",
//         boxSizing: "border-box",
//         borderRadius: "16px",
//       }}
//     >
//       <h6>
//         {item.bikeData?.basicInformation?.title?.titleOriginal || "No name"}
//       </h6>

//       {item.replacementBikes &&
//         Object.entries(item.replacementBikes).map(([key, bikesList]) => (
//           <ReplacementBikesSection
//             key={`${item.id}-${key}`}
//             bikesList={bikesList}
//             item={item}
//             isMobile={isMobile}
//             globalIndex={globalIndex++}
//           />
//         ))}
//     </div>
//   );
// });

// const ReplacementBikesSection = memo(
//   ({ bikesList, item, isMobile, globalIndex }) => {
//     if (bikesList.length === 0) {
//       return (
//         <>
//           <Divide title="Option (Original choice)" number={globalIndex + 1} />
//           <BikesInOrder item={item} isMobile={isMobile} />
//         </>
//       );
//     }

//     return bikesList.map(
//       (bikeItem) =>
//         bikeItem.userChoice && (
//           <React.Fragment key={`replacement-${bikeItem.id}`}>
//             <Divide title="Option (Replacement)" number={globalIndex + 1} />
//             <BikesInOrder item={item} bikeItem={bikeItem} isMobile={isMobile} />
//           </React.Fragment>
//         )
//     );
//   }
// );

// const Timetable = memo(({ rentalPointRootData }) => (
//   <div
//     style={{
//       width: "100%",
//       display: "flex",
//       flexDirection: "column",
//       gap: "2px",
//     }}
//   >
//     {rentalPointRootData?.timetable?.map((timetableItem, index) => (
//       <TimetableItem
//         key={`timetable-${index}-${timetableItem.weekday}`}
//         timetableItem={timetableItem}
//       />
//     ))}
//   </div>
// ));

// const TimetableItem = memo(({ timetableItem }) => (
//   <div style={commonStyles.timetableItem}>
//     <p className="p-light">
//       {truncateToThreeChars(timetableItem.weekday?.weekdayOriginal)}
//     </p>

//     <div
//       style={{
//         width: "100%",
//         height: "1px",
//         backgroundColor: "var(--color-gray-200)",
//       }}
//     />
//     {timetableItem.isHoliday ? (
//       <p className="p-light" style={holidayStyle}>
//         holiday
//       </p>
//     ) : (
//       <div className="p-light" style={workingHoursStyle}>
//         <p>
//           {timetableItem.openHours}:{timetableItem.openMinutes}
//         </p>
//         —
//         <p>
//           {timetableItem.closeHours}:{timetableItem.closeMinutes}
//         </p>
//       </div>
//     )}
//   </div>
// ));

// const BikesInOrder = memo(({ item, bikeItem, isMobile }) => {
//   const divRef = useRef(null); // Создаем useRef
//   const [size, setSize] = useState({ width: 0, height: 0 });

//   useEffect(() => {
//     if (divRef.current) {
//       const { width, height } = divRef.current.getBoundingClientRect();
//       setSize({ width, height });
//     }
//   }, [bikeItem, item]);

//   return (
//     <div
//       style={{
//         width: "100%",
//         height: bikeItem
//           ? `calc(${size.height}px + 8px)`
//           : `calc(${size.height}px)`,
//         display: "grid",
//         gridTemplateColumns: "repeat(2, 1fr)",
//         alignItems: "center",
//         gap: "24px",
//       }}
//     >
//       {bikeItem ? (
//         <ReplacementBike
//           bikeItem={bikeItem}
//           item={item}
//           isMobile={isMobile}
//           divRef={divRef} // Передаем divRef
//         />
//       ) : (
//         <OriginalBike item={item} isMobile={isMobile} divRef={divRef} />
//       )}
//     </div>
//   );
// });

// const ReplacementBike = ({ bikeItem, item, isMobile, divRef }) => (
//   <>
//     <div ref={divRef} style={{ position: "relative", width: "100%" }}>
//       <PriorityFile
//         style={{ width: "100%", aspectRatio: "16/9", opacity: "0.5" }}
//         files={item.bikeData?.files?.photoFiles}
//       />
//       <div
//         style={{ position: "absolute", left: "8px", top: "8px", width: "100%" }}
//       >
//         <CheckMark />
//         <PriorityFile
//           style={{ width: "100%", aspectRatio: "16/9" }}
//           files={bikeItem.files?.photoFiles}
//         />
//       </div>
//     </div>
//     <h6>{bikeItem.basicInformation.title.titleOriginal}</h6>
//   </>
// );

// const OriginalBike = ({ item, isMobile, divRef }) => (
//   <>
//     <div ref={divRef} style={{ position: "relative", width: "100%" }}>
//       <CheckMark />
//       <PriorityFile
//         style={{ width: "100%", aspectRatio: "16/9" }}
//         files={item.bikeData?.files?.photoFiles}
//       />
//     </div>
//     <h6>{item.bikeData?.basicInformation?.title?.titleOriginal}</h6>
//   </>
// );

// const CheckMark = () => (
//   <span className="material-symbols-outlined" style={checkMarkStyle}>
//     check_small
//   </span>
// );

// // Стили
// const holidayStyle = {
//   backgroundColor: "var(--color-black)",
//   color: "var(--color-white)",
//   padding: "2px 8px",
//   borderRadius: "4px",
// };

// const workingHoursStyle = {
//   backgroundColor: "var(--color-success)",
//   color: "var(--color-white)",
//   padding: "2px 8px",
//   borderRadius: "4px",
//   display: "flex",
//   gap: "4px",
// };

// const checkMarkStyle = {
//   display: "flex",
//   alignItems: "center",
//   justifyContent: "center",
//   position: "absolute",
//   width: "24px",
//   aspectRatio: "1/1",
//   borderRadius: "50%",
//   backgroundColor: "var(--color-success)",
//   color: "var(--color-white)",
//   right: "8px",
//   top: "8px",
// };

// export default memo(WidgetWoucherListV4);
