import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  collection,
  doc,
  setDoc,
  updateDoc,
  arrayUnion,
  getDoc,
} from "firebase/firestore";
import { uploadFilesToFirebase } from "../../services/firebase/crudFirestore";
import { db } from "../../services/firebase/authFirebase";
import { v4 as uuidv4 } from "uuid";
import handleThunkError from "../../functions/handleThunkError";

// Создаем асинхронный thunk для добавления данных в Firestore
export const createDataThunkV4 = createAsyncThunk(
  "locations/createDataThunkV4", // Название экшена
  async (
    {
      rootData, // Основные данные для корневого документа
      idRootDocument, // ID корневого документа (если есть)
      previewData, // Данные для предварительного документа
      files, // Файлы для загрузки
      iconFields, // Поля для иконок
      rootCollectionPath, // Путь к коллекции для корневого документа
      previewCollectionPath, // Путь к коллекции для предварительных документов
      previewGeneralCollectionPath, // Путь к общей коллекции предварительных документов
      metadataDocumentPath, // Путь к документу метаданных
      metadataGeneralDocumentPath, // Путь к общим метаданным
      rootCurrentDocumentState, // Текущее состояние корневого документа
      previewCurrentDocumenState, // Текущее состояние предварительного документа
      previewGeneralDocumenState, // Текущее состояние общего предварительного документа
      loadingStateName, // Название состояния загрузки
      errorStateName, // Название состояния ошибки
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      console.log("Начинаем создание данных с файлами", { files });

      // 1. Создание или получение корневого документа
      const docRef = idRootDocument
        ? doc(db, ...rootCollectionPath.split("."), idRootDocument)
        : doc(collection(db, ...rootCollectionPath.split(".")));

      console.log(`Создаем/используем документ с ID: ${docRef.id}`);

      // Создаем копию rootData, чтобы не модифицировать оригинал
      const finalRootData = JSON.parse(JSON.stringify(rootData)); // Глубокое копирование для предотвращения проблем с read-only объектами

      // 2. Загрузка файлов
      if (files && Object.keys(files).length > 0) {
        console.log("Начинаем загрузку файлов", files);

        // Создаем новый объект files
        finalRootData.files = {};

        for (const [fieldName, fileArray] of Object.entries(files)) {
          if (fileArray && fileArray.length > 0) {
            console.log(
              `Загрузка ${fileArray.length} файлов для поля ${fieldName}`
            );

            try {
              const uploaded = await uploadFilesToFirebase(
                `files/${docRef.id}/${fieldName}`,
                fileArray
              );

              console.log(
                `Загружено ${uploaded.length} файлов для поля ${fieldName}`,
                uploaded
              );

              const formattedFiles = uploaded.map((file) => ({
                url: file.url,
                name: `${Date.now()}_${Math.floor(Math.random() * 10000)}`,
                priority: file.priority || false,
                id: uuidv4(),
              }));

              // Сохраняем файлы в новый объект files
              finalRootData.files[fieldName] = formattedFiles;

              console.log(
                `Файлы для поля ${fieldName} добавлены в данные`,
                formattedFiles
              );
            } catch (uploadError) {
              console.error(
                `Ошибка при загрузке файлов для поля ${fieldName}:`,
                uploadError
              );
              throw uploadError; // Пробрасываем ошибку дальше
            }
          }
        }
      }

      // 3. Записываем данные в Firestore (теперь включая файлы)
      console.log("Сохраняем данные в Firestore", finalRootData);
      await setDoc(docRef, finalRootData);

      // 4. Выбираем файл с приоритетом или первый файл для превью
      let selectedFileUrl = null;
      if (finalRootData.files && finalRootData.files[iconFields]) {
        const priorityFile = finalRootData.files[iconFields].find(
          (file) => file.priority
        );
        selectedFileUrl = priorityFile
          ? priorityFile.url
          : finalRootData.files[iconFields][0]
          ? finalRootData.files[iconFields][0].url
          : null;

        console.log("Выбран URL файла для превью:", selectedFileUrl);
      }

      // 5. Данные для предварительного документа
      const previewDocumentData = {
        ...previewData,
        idRootDocument: docRef.id,
        idPreviewDocument: "",
      };

      if (selectedFileUrl) {
        previewDocumentData.fileUrl = selectedFileUrl;
      } else {
        delete previewDocumentData.fileUrl; // Удаляем, если selectedFile отсутствует
      }

      // Рассчитываем размер объекта данных
      const objectSize = new Blob([JSON.stringify(previewDocumentData)]).size;

      // 6. Получаем метаданные последнего обновленного документа
      const languageDocRef = doc(db, ...metadataDocumentPath.split("."));
      const languageDocSnap = await getDoc(languageDocRef);

      let lastUpdatedDocId;
      let lastUpdatedDocSize;

      if (languageDocSnap.exists()) {
        const languageData = languageDocSnap.data();
        lastUpdatedDocId =
          languageData.lastUpdatedDocument?.lastUpdatedDocumentId;
        lastUpdatedDocSize =
          languageData.lastUpdatedDocument?.documentSize || 0;
      } else {
        lastUpdatedDocId = null;
        lastUpdatedDocSize = 0;
      }

      let newDocumentSize;
      let idDocumentToSave;
      let isNewDocument = false;

      // 7. Проверяем, можем ли добавить данные в существующий документ
      if (
        lastUpdatedDocId &&
        lastUpdatedDocSize + objectSize <= 1024 * 1024 - 500
      ) {
        idDocumentToSave = lastUpdatedDocId;
        newDocumentSize = lastUpdatedDocSize + objectSize;
      } else {
        // Создаем новый документ
        const newOriginalDocRef = doc(
          collection(db, ...previewCollectionPath.split("."))
        );
        idDocumentToSave = newOriginalDocRef.id;
        newDocumentSize = objectSize;
        isNewDocument = true;
      }

      // 8. Сохраняем данные предварительного документа
      const originalDocRef = doc(
        db,
        ...previewCollectionPath.split("."),
        idDocumentToSave
      );
      previewDocumentData.idPreviewDocument = idDocumentToSave;

      console.log("Сохраняем preview документ", {
        docPath: originalDocRef.path,
        previewDocumentData,
        newDocumentSize,
      });

      await setDoc(
        originalDocRef,
        {
          data: arrayUnion(previewDocumentData),
          documentSize: newDocumentSize,
        },
        { merge: true }
      );

      // 9. Обновляем данные о последнем обновленном документе
      const updatedLastUpdatedDocument = isNewDocument
        ? {
            lastUpdatedDocumentId: idDocumentToSave,
            documentSize: newDocumentSize,
          }
        : {
            documentSize: newDocumentSize,
          };

      await setDoc(
        languageDocRef,
        {
          lastUpdatedDocument: updatedLastUpdatedDocument,
        },
        { merge: true }
      );

      // 10. Работа с общими метаданными, если пути предоставлены
      if (previewGeneralCollectionPath && metadataGeneralDocumentPath) {
        const generalMetadataDocRef = doc(
          db,
          ...metadataGeneralDocumentPath.split(".")
        );
        const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

        let generalLastUpdatedDocId;
        let generalLastUpdatedDocSize;

        if (generalMetadataDocSnap.exists()) {
          const generalMetadataData = generalMetadataDocSnap.data();
          generalLastUpdatedDocId =
            generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId;
          generalLastUpdatedDocSize =
            generalMetadataData.lastUpdatedDocument?.documentSize || 0;
        } else {
          generalLastUpdatedDocId = null;
          generalLastUpdatedDocSize = 0;
        }

        let generalNewDocumentSize;
        let generalIdDocumentToSave;
        let isNewGeneralDocument = false;

        // Проверяем, можем ли добавить данные в существующий общий документ
        if (
          generalLastUpdatedDocId &&
          generalLastUpdatedDocSize + objectSize <= 1024 * 1024 - 500
        ) {
          generalIdDocumentToSave = generalLastUpdatedDocId;
          generalNewDocumentSize = generalLastUpdatedDocSize + objectSize;
        } else {
          const newGeneralDocRef = doc(
            collection(db, ...previewGeneralCollectionPath.split("."))
          );
          generalIdDocumentToSave = newGeneralDocRef.id;
          generalNewDocumentSize = objectSize;
          isNewGeneralDocument = true;
        }

        const generalDocRef = doc(
          db,
          ...previewGeneralCollectionPath.split("."),
          generalIdDocumentToSave
        );

        await setDoc(
          generalDocRef,
          {
            data: arrayUnion(previewDocumentData),
            documentSize: generalNewDocumentSize,
          },
          { merge: true }
        );

        // Обновляем метаданные общего документа
        const updatedGeneralLastUpdatedDocument = isNewGeneralDocument
          ? {
              lastUpdatedDocumentId: generalIdDocumentToSave,
              documentSize: generalNewDocumentSize,
            }
          : {
              documentSize: generalNewDocumentSize,
            };

        await setDoc(
          generalMetadataDocRef,
          {
            lastUpdatedDocument: updatedGeneralLastUpdatedDocument,
          },
          { merge: true }
        );
      }

      // 11. Обновляем новые идентификаторы данных в метаданных
      await setDoc(
        languageDocRef,
        {
          newDataIds: arrayUnion(docRef.id),
        },
        { merge: true }
      );

      if (metadataGeneralDocumentPath) {
        const generalMetadataDocRef = doc(
          db,
          ...metadataGeneralDocumentPath.split(".")
        );
        await setDoc(
          generalMetadataDocRef,
          {
            newDataIds: arrayUnion(docRef.id),
          },
          { merge: true }
        );
      }

      // 12. Подготавливаем данные для возврата
      const rootDocumentData = {
        idRootDocument: docRef.id,
        ...finalRootData, // Используем финальные данные, включая файлы
      };

      console.log("Успешно создан документ с файлами", {
        id: docRef.id,
        hasFiles:
          finalRootData.files && Object.keys(finalRootData.files).length > 0,
      });

      // 13. Возвращаем результат
      return {
        rootDocumentData,
        previewDocumentData,
        rootCollectionPath,
        previewCollectionPath,
        previewGeneralCollectionPath,
        rootCurrentDocumentState,
        previewCurrentDocumenState,
        previewGeneralDocumenState,
        loadingStateName,
        errorStateName,
      };
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);
