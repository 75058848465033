import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SEOContent = () => {
  const location = useLocation();
  // Получаем данные напрямую из Redux без создания нового объекта
  const seo_previewCurrentData = useSelector(
    (state) => state.catalogSliceV4.tempPreviewData?.seo_previewCurrentData
  );

  // Получаем текущий путь для фильтрации SEO-данных
  const currentSlug = location.pathname;

  // Находим SEO-данные для текущего пути
  const seoData = useMemo(() => {
    if (!seo_previewCurrentData || !currentSlug) return null;

    const filteredSEO = seo_previewCurrentData.filter(
      (item) => item?.slug === currentSlug
    );

    return filteredSEO.length > 0 ? filteredSEO[0] : null;
  }, [seo_previewCurrentData, currentSlug]);

  // Извлекаем данные для отображения
  const title = seoData?.title;
  const description = seoData?.description;

  // Если нет данных, не отображаем компонент
  if (!title && !description) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: "100%",
      }}
    >
      {title && <h1>{title}</h1>}
      {description && <p className="p-light">{description}</p>}
    </div>
  );
};

export default SEOContent;
