/**
 * Функция для форматирования даты или времени из timestamp.
 * @param {Object} params - Параметры форматирования.
 * @param {string | number} params.timestamp - Timestamp в миллисекундах с 1 января 1970 года (UNIX-время).
 * @param {boolean} [params.isMobile=false] - Флаг, указывающий, является ли устройство мобильным.
 * @param {boolean} [params.isDate=true] - Флаг, указывающий, нужно ли вернуть дату.
 * @param {boolean} [params.isTime=false] - Флаг, указывающий, нужно ли вернуть время.
 * @returns {string} Отформатированная дата, время или их комбинация.
 */
const dateTimeFormat = ({
  timestamp,
  isMobile = false,
  isDate = true,
  isTime = false,
}) => {
  // Проверяем тип timestamp
  if (typeof timestamp === "string") {
    timestamp = parseInt(timestamp);
  }

  // Проверяем, что значение timestamp является корректным числом
  if (isNaN(timestamp)) {
    return "Invalid Date";
  }

  // Создаем объект даты из числового timestamp
  const date = new Date(timestamp);

  // Определяем формат месяца в зависимости от устройства
  const monthFormat = isMobile ? "short" : "long";

  // Опции для форматирования даты
  const dateOptions = {
    year: "numeric",
    month: monthFormat,
    day: "numeric",
  };

  // Опции для форматирования времени
  const timeOptions = {
    hour: "2-digit",
    minute: "2-digit",
  };

  // Форматируем дату и время
  const dateStr = isDate ? date.toLocaleDateString("en-US", dateOptions) : "";
  const timeStr = isTime ? date.toLocaleTimeString("en-US", timeOptions) : "";

  // Возвращаем результат в зависимости от флагов
  if (isDate && isTime) {
    return `${timeStr} — ${dateStr}`;
  } else if (isDate) {
    return dateStr;
  } else if (isTime) {
    return timeStr;
  } else {
    return ""; // Если оба флага false, возвращаем пустую строку
  }
};

export default dateTimeFormat;
