import { createAsyncThunk } from "@reduxjs/toolkit";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../services/firebase/authFirebase";
import handleThunkError from "../../functions/handleThunkError";
import {
  setErrorState,
  setLoadingState,
  updateRootDocuments,
} from "../slice/catalogSliceV4";

// Для хранения подписок
const unsubscribeMap = new Map();

/**
 * Подписка на изменения в документе Firestore с автоматической отпиской при повторной подписке.
 * @param {Object} params - Параметры подписки.
 * @param {string} params.collectionPath - Путь к коллекции Firestore.
 * @param {string} [params.rootCurrentDocumentState] - Состояние текущего документа в Redux (рекомендуется).
 * @param {string} [params.rootCurrentDocumenState] - Устаревшее состояние текущего документа в Redux (для обратной совместимости).
 * @param {string} params.idRootDocument - ID документа Firestore.
 * @param {string} params.loadingStateName - Имя состояния загрузки в Redux.
 * @param {string} params.errorStateName - Имя состояния ошибки в Redux.
 */
export const subscribeRootDocumentsThunkV5 = createAsyncThunk(
  "catalog/subscribeRootDocumentsThunkV5",
  async (
    {
      collectionPath,
      rootCurrentDocumenState, // Старое название с опечаткой
      rootCurrentDocumentState, // Новое правильное название
      idRootDocument,
      loadingStateName,
      errorStateName,
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      console.log(
        "Инициализация подписки для документа в коллекции:",
        collectionPath
      );

      // Проверяем, существует ли уже подписка для этой коллекции
      if (unsubscribeMap.has(collectionPath)) {
        console.log(`Отписка от существующей подписки для ${collectionPath}`);
        const existingUnsubscribe = unsubscribeMap.get(collectionPath);
        existingUnsubscribe(); // Вызываем отписку
        unsubscribeMap.delete(collectionPath); // Удаляем из карты
      }

      // Создаём запрос к Firestore для конкретного документа
      const documentRef = doc(db, ...collectionPath.split("."), idRootDocument);

      // Определяем, какой параметр использовать: новое название имеет приоритет
      const stateKey = rootCurrentDocumentState || rootCurrentDocumenState;
      if (!stateKey) {
        console.warn(
          "Не передан ни rootCurrentDocumentState, ни rootCurrentDocumenState. Используется пустое значение."
        );
      }
      console.log("Используемый stateKey:", stateKey);

      // Устанавливаем новую подписку
      const unsubscribe = onSnapshot(
        documentRef,
        (snapshot) => {
          try {
            const documentData = snapshot.exists()
              ? { id: snapshot.id, ...snapshot.data() }
              : null;

            // Обновляем данные в Redux
            dispatch(
              updateRootDocuments({
                documents: documentData,
                rootCurrentDocumenState: stateKey, // Используем определённый ключ
              })
            );
            console.log(
              `Данные обновлены для документа в коллекции ${collectionPath}.`
            );

            // Обновляем состояние загрузки
            dispatch(
              setLoadingState({
                loadingStateName,
                isLoading: false,
              })
            );
          } catch (error) {
            handleThunkError(error, dispatch, rejectWithValue);
          }
        },
        (error) => {
          handleThunkError(error, dispatch, rejectWithValue);
        }
      );

      // Сохраняем новую функцию отписки в карту
      unsubscribeMap.set(collectionPath, unsubscribe);

      // Возвращаем объект для информирования о успешной подписке
      return { subscriptionEstablished: true };
    } catch (error) {
      return handleThunkError(error, dispatch, rejectWithValue);
    }
  }
);

/**
 * Thunk для остановки подписки от документа Firestore.
 * @param {string} collectionPath - Путь к коллекции Firestore.
 */
export const unsubscribeRootDocumentsThunkV5 = createAsyncThunk(
  "catalog/unsubscribeRootDocumentsThunkV5",
  async ({ collectionPath }, { dispatch }) => {
    try {
      if (unsubscribeMap.has(collectionPath)) {
        const unsubscribe = unsubscribeMap.get(collectionPath);
        unsubscribe(); // Отписываемся
        unsubscribeMap.delete(collectionPath);
        console.log(`Подписка на коллекцию ${collectionPath} отменена`);
      } else {
        console.log(`Нет активной подписки для коллекции ${collectionPath}`);
      }
      return { unsubscribed: true };
    } catch (error) {
      console.error("Ошибка при остановке подписки на Firestore:", error);
      throw error;
    }
  }
);
