import React, { useEffect, useState } from "react";
import Button from "../../../../UIComponents/Buttons/Button";
import InputText from "../../../../UIComponents/Inputs/InputText";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import Textarea from "../../../../UIComponents/Textarea/Textarea";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import Switcher from "../../../../UIComponents/Switcher/Switcher";
import { v4 as uuidv4 } from "uuid";

/**
 * Компонент для редактирования информации о цене велосипеда за определенный месяц
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {string} props.mode - Режим работы ('edit', 'moderation', 'moderatorAdded')
 * @param {number} props.index - Индекс месяца в массиве тарифов
 * @param {Function} props.onClose - Функция для закрытия модального окна
 * @param {Function} props.isChangeState - Функция для отслеживания изменения состояния
 * @returns {JSX.Element} Компонент для редактирования цены за месяц
 */
const WindowBikePricePerMonthModeration = ({
  mode,
  index,
  onClose,
  isChangeState,
}) => {
  const dispatch = useDispatch();

  const { tempRootData, rootNewData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // Основные состояния формы
  const [inputPrice, setInputPrice] = useState("");
  const [inputDiscount, setInputDiscount] = useState("");
  const [description, setDescription] = useState("");
  const [rate, setRate] = useState([]);
  const [newData, setNewData] = useState([]);
  const [isActiveRatePrice, setIsActiveRatePrice] = useState(true);

  // Состояния для управления UI
  const [clearButtonActive, setClearButtonActive] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  // Исходные значения для отслеживания изменений
  const [initialInputPrice, setInitialInputPrice] = useState("");
  const [initialInputDiscount, setInitialInputDiscount] = useState("");
  const [initialDescription, setInitialDescription] = useState("");
  const [initialIsActiveRatePrice, setInitialIsActiveRatePrice] =
    useState(true);

  // Состояния для отслеживания изменений в конкретных полях
  const [isChangeStatePrice, setIsChangeStatePrice] = useState(false);
  const [isChangeStateSale, setIsChangeStateSale] = useState(false);
  const [isChangeStateDescription, setIsChangeStateDescription] =
    useState(false);
  const [isChangeSwitcherState, setIsChangeSwitcherState] = useState(false);

  // Добавляем вывод для отладки при изменении состояния

  /**
   * Загрузка данных из Redux в зависимости от режима работы
   */
  useEffect(() => {
    let currentData = [];
    let currentRate = {};

    // Определяем источник данных в зависимости от режима
    if (mode === "edit" || mode === "moderation") {
      currentData =
        tempData?.tempData_bikeRate ||
        tempRootData?.selectedBike_rootData?.rate ||
        [];
    } else if (mode === "moderatorAdded") {
      currentData =
        tempData?.tempData_bikeRate || rootNewData?.rootBikeData?.rate || [];
    }

    setRate(currentData);
    currentRate = currentData[index] || {};

    // Извлекаем текущие значения
    const currentPrice = currentRate.price || "";
    const currentDiscount = currentRate.discount || "";
    const currentDescription =
      currentRate.description?.descriptionOriginal || "";
    const currentIsActive =
      currentRate.isActive !== undefined ? currentRate.isActive : true;

    // Устанавливаем текущие и исходные значения
    setInputPrice(currentPrice);
    setInputDiscount(currentDiscount);
    setDescription(currentDescription);
    setIsActiveRatePrice(currentIsActive);

    setInitialInputPrice(currentPrice);
    setInitialInputDiscount(currentDiscount);
    setInitialDescription(currentDescription);
    setInitialIsActiveRatePrice(currentIsActive);

    // Устанавливаем активность кнопки Clear
    setClearButtonActive(currentPrice !== "");

    // Сбрасываем состояние изменений
    setIsFormChanged(false);
    setIsChangeStatePrice(false);
    setIsChangeStateSale(false);
    setIsChangeStateDescription(false);
    setIsChangeSwitcherState(false);
  }, [
    // tempRootData?.newRootBike_create?.rate,
    tempRootData?.selectedBike_rootData,
    mode,
    index,
    tempData?.tempData_bikeRate,
    rootNewData?.rootBikeData,
  ]);

  /**
   * Отслеживание изменений во всех полях формы
   */
  useEffect(() => {
    // Проверяем, изменились ли значения относительно исходных
    const priceChanged = String(inputPrice) !== String(initialInputPrice);
    const discountChanged =
      String(inputDiscount) !== String(initialInputDiscount);
    const descriptionChanged = description !== initialDescription;
    const switcherChanged = isActiveRatePrice !== initialIsActiveRatePrice;

    const hasAnyChanges =
      priceChanged || discountChanged || descriptionChanged || switcherChanged;

    // Обновляем общий флаг изменений формы
    setIsFormChanged(hasAnyChanges);

    // Добавляем вывод для отладки изменений формы
    // console.log("Изменения формы:", {
    //   priceChanged,
    //   discountChanged,
    //   descriptionChanged,
    //   switcherChanged,
    //   hasAnyChanges,
    // });

    // Уведомляем родительский компонент об изменении данных
    if (hasAnyChanges && typeof isChangeState === "function") {
      isChangeState(true);
    }
  }, [
    inputPrice,
    inputDiscount,
    description,
    isActiveRatePrice, // Важно - этот элемент должен запускать эффект
    initialInputPrice,
    initialInputDiscount,
    initialDescription,
    initialIsActiveRatePrice,
    isChangeState,
  ]);

  /**
   * Формирование объекта данных для сохранения при изменении полей
   */
  useEffect(() => {
    // Создаем объект с данными текущего месяца
    const editableRate = {
      month: index,
      price: inputPrice === "" ? 0 : Math.max(0, Number(inputPrice)), // Гарантируем неотрицательное значение
      discount:
        inputDiscount === ""
          ? 0
          : Math.min(99, Math.max(0, Number(inputDiscount))), // Гарантируем значение между 0 и 99
      description: {
        descriptionOriginal: description,
      },
      isActive: isActiveRatePrice,
    };

    // Обновляем массив тарифов
    const updatedRate = [...rate];
    updatedRate[index] = editableRate;
    setNewData(updatedRate);
  }, [index, inputPrice, inputDiscount, description, rate, isActiveRatePrice]);

  /**
   * Сохранение данных в Redux store
   */
  const rateAssignment = () => {
    if (mode === "moderation") {
      dispatch(
        setTempData({
          tempCurrentDocumentState: "tempData_bikeRate",
          data: newData,
        })
      );
    } else if (mode === "moderatorAdded") {
      dispatch(
        setTempData({
          tempCurrentDocumentState: "tempData_bikeRate",
          data: newData,
        })
      );
    }
  };

  /**
   * Очистка данных текущего месяца
   */
  const clearRate = () => {
    // Устанавливаем пустые значения в форме
    setInputPrice("");
    setInputDiscount("");
    setDescription("");
    setIsActiveRatePrice(false);

    // Создаем объект с пустыми значениями для текущего индекса
    const emptyRate = {
      month: index,
      price: 0,
      discount: 0,
      description: {
        descriptionOriginal: "",
      },
      isActive: false,
    };

    // Обновляем массив тарифов
    const updatedRate = [...rate];
    updatedRate[index] = emptyRate;

    // Сохраняем обновленный массив в Redux
    if (mode === "moderation") {
      dispatch(
        setTempData({
          tempCurrentDocumentState: "tempData_bikeRate",
          data: updatedRate,
        })
      );
    } else if (mode === "moderatorAdded") {
      dispatch(
        setCurrentRootData({
          rootCurrentDocumentState: "newRootBike_create.rate",
          data: updatedRate,
        })
      );
    }

    // Обновляем локальный state
    setNewData(updatedRate);

    // Сбрасываем состояние изменений
    setIsFormChanged(false);
    setClearButtonActive(false);
  };

  /**
   * Обработчик изменения поля цены
   * @param {Object} event - Событие изменения
   */
  const handlePriceChange = (event) => {
    const value = event.target.value;

    // Извлекаем числовое значение из маскированного ввода
    // Сохраняем точку и до двух цифр после неё
    const numericValue = value.replace(/[^\d.]/g, "");

    // Обрабатываем случай, когда вводится точка
    if (numericValue.includes(".")) {
      const parts = numericValue.split(".");
      // Оставляем только первую точку и до двух цифр после неё
      if (parts.length > 1) {
        const decimalPart = parts[1].slice(0, 2); // Берем только до двух цифр после точки
        setInputPrice(`${parts[0]}.${decimalPart}`);
      } else {
        setInputPrice(numericValue);
      }
    } else {
      setInputPrice(numericValue);
    }

    // Обновляем статус кнопки Clear и состояние изменения
    setClearButtonActive(numericValue !== "" && parseFloat(numericValue) > 0);
    setIsChangeStatePrice(true);
  };

  /**
   * Обработчик изменения поля скидки
   * @param {Object} event - Событие изменения
   */
  const handleDiscountChange = (event) => {
    const value = event.target.value;

    // Извлекаем числовое значение из маскированного ввода
    // Сохраняем точку и до одной цифры после неё
    const numericValue = value.replace(/[^\d.]/g, "");

    // Проверяем, что значение не превышает 100
    if (numericValue !== "" && parseFloat(numericValue) > 100) {
      return; // Не обновляем состояние, если значение > 100
    }

    // Обрабатываем случай, когда вводится точка
    if (numericValue.includes(".")) {
      const parts = numericValue.split(".");
      // Оставляем только первую точку и до одной цифры после неё
      if (parts.length > 1) {
        const decimalPart = parts[1].slice(0, 1); // Берем только до одной цифры после точки для процентов
        setInputDiscount(`${parts[0]}.${decimalPart}`);
      } else {
        setInputDiscount(numericValue);
      }
    } else {
      setInputDiscount(numericValue);
    }

    setIsChangeStateSale(true);
  };

  /**
   * Обработчик изменения поля описания
   * @param {Object} event - Событие изменения
   */
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    setIsChangeStateDescription(true);
  };

  /**
   * Обработчик изменения переключателя активности тарифа
   * @param {boolean} newValue - Новое значение переключателя
   */
  const handleChangeActiveRatePrice = (newValue) => {
    setIsActiveRatePrice(newValue);
    // Переключаем состояние isChangeSwitcherState
    setIsChangeSwitcherState((prevState) => !prevState);
  };

  /**
   * Обработчик нажатия кнопки сохранения
   */
  const applyButton = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
    rateAssignment();
    onClose();
  };

  /**
   * Обработчик нажатия кнопки очистки
   */
  const handleClearRate = () => {
    clearRate();
    onClose();
  };

  // Проверяем активность кнопки Save прямо перед рендерингом
  const isSwitcherChanged = isActiveRatePrice !== initialIsActiveRatePrice;
  const isPriceValid = inputPrice !== "" && Number(inputPrice) > 0;
  const isSaveButtonActive =
    (isFormChanged || isSwitcherChanged) && isPriceValid;

  // Выводим финальное состояние для отладки
  console.log("Финальное состояние кнопки Save:", {
    isSwitcherChanged,
    isFormChanged,
    isPriceValid,
    isSaveButtonActive,
    inputPrice,
  });

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        marginBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "16px",
          }}
        >
          <InputText
            label="Price"
            type="text"
            description="Enter a price greater than 0 to enable saving."
            value={inputPrice ?? ""}
            unit="$"
            onChange={handlePriceChange}
            isChangeState={setIsChangeStatePrice}
            min="0" // Устанавливаем минимальное значение 0
          />

          <InputText
            label="Sale"
            type="text"
            description="Enter discount percentage (max 100%)."
            value={inputDiscount}
            onChange={handleDiscountChange}
            isChangeState={setIsChangeStateSale}
            unit="%"
          />
        </div>
        <Textarea
          label="Rate description"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          maxLength={1000}
          value={description}
          onChange={handleDescriptionChange}
          isChangeState={setIsChangeStateDescription}
        />
      </ContentBox>

      <ContentBox>
        <Switcher
          id={`rate-active-switch-${index}`}
          isChecked={isActiveRatePrice}
          onChange={handleChangeActiveRatePrice}
          label="Activate price"
          // description="Toggle to enable or disable the rate."
        />
      </ContentBox>
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Clear"
            active={clearButtonActive}
            onClick={handleClearRate}
            color="--color-black"
            allert={
              <div
                style={{
                  padding: "48px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                  textAlign: "center",
                }}
              >
                <h1>Are you sure you want to delete the current rate?</h1>
                <p className="p-light">
                  This action will reset all entered data.
                </p>
              </div>
            }
          />
          <Button
            type="small"
            label="Save"
            active={isSaveButtonActive}
            onClick={applyButton}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowBikePricePerMonthModeration;
