import React from "react";
import useIsMobile from "../../../../../functions/isMobile";
import AddData from "../../../../UIComponents/AddData/AddData";

const SkeletonItemBikePreview = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        gap: "8px",
      }}
    >
      <div
        style={{
          height: "100px",
          width: "100%",
          backgroundColor: "var(--color-gray-100)",
          borderRadius: "16px",
        }}
      />
    </div>
  );
};

const SkeletonRentalPointAditionalServices = ({ additionalServices }) => {
  const isMobile = useIsMobile();

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "16px",
        boxSizing: "border-box",
      }}
    >
      <AddData isLoading={true} />

      {additionalServices?.length > 0 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            backgroundColor: "var(--color-white)",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "8px",
              width: "100%",
            }}
          >
            {Array.from({ length: additionalServices?.length }, (_, i) => (
              <div key={i} style={{ width: "100%" }}>
                <SkeletonItemBikePreview />
              </div>
            ))}
          </div>
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: "8px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
        }}
      >
        <div
          style={{
            width: "30%",
            height: "8px",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "4px",
          }}
        />
      </div>
    </div>
  );
};

export default SkeletonRentalPointAditionalServices;
