import React, { useEffect, useState } from "react";
import PageBox from "../../UIComponents/ContentBox/PageBox";
import ContentBox from "../../UIComponents/ContentBox/ContentBox";
import SegmentedControls from "../../UIComponents/SegmentedControls/SegmentedControls";
import WidgetSignUp from "../../Private/Login/Auth/WidgetSignUp";
import WidgetSignIn from "../../Private/Login/Auth/WidgetSignIn";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../UIComponents/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { googleSignUpThunkV4 } from "../../../reducersToolkit/thunk/googleSignUpThunkV4";
import Preloader from "../../UIComponents/Preloader/Preloader";

const PageLogin = ({ breadcrumbPath, title }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, error, tempRootData, tempPreviewData, tempData, route } =
    useSelector((state) => state.catalogSliceV4);

  console.log("loading_user_auth_rootCurrentData", loading.loading_signIn);

  const [loginType, setLoginType] = useState("signIn");
  const [loading_signIn, setLoading_signIn] = useState(false);
  const [loading_signUp, setLoading_signUp] = useState(false);
  const [loading_signOut, setLoading_signOut] = useState(false);
  const [loading_google, setLoading_google] = useState(false);
  const [error_google, setError_google] = useState(null);

  useEffect(() => {
    setLoading_signIn(loading.loading_signIn);
    setLoading_signUp(loading.loading_signUp);
    setLoading_signOut(loading.loading_signOut);
  }, [loading.loading_signIn, loading.loading_signUp, loading.loading_signOut]);

  const handleGoogleAuth = async () => {
    setLoading_google(true);
    setError_google(null);

    dispatch(googleSignUpThunkV4())
      .unwrap()
      .then(() => {
        navigate("/profile");
      })
      .catch((error) => {
        console.error("Error during Google registration: ", error.errorMessage);
        setError_google(
          error.errorMessage || "Произошла ошибка при входе через Google"
        );
      })
      .finally(() => {
        setLoading_google(false);
      });
  };

  return (
    <PageBox title={title || "Login"}>
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ContentBox>
            <SegmentedControls
              isActive={loading_signIn || loading_signUp || loading_signOut}
              options={[
                {
                  title: "signIn",
                  description:
                    "Please specify your gender for accurate addressing in our notifications and messages. Your data will remain confidential and secure.",
                },
                {
                  title: "signUp",
                  description:
                    "Please specify your gender for accurate addressing in our notifications and messages.",
                },
              ]}
              selected={setLoginType}
              onSelect={loginType}
            />
          </ContentBox>

          <ContentBox>
            {loginType === "signIn" ? (
              <WidgetSignIn
                onError={() => {
                  /* пустая функция для предотвращения сброса */
                }}
              />
            ) : (
              <WidgetSignUp
                onError={() => {
                  /* пустая функция для предотвращения сброса */
                }}
              />
            )}
          </ContentBox>

          {error_google && (
            <div
              style={{
                color: "var(--color-alarm)",
                padding: "8px",
                fontSize: "14px",
                backgroundColor: "rgba(255, 75, 75, 0.1)",
                borderRadius: "4px",
              }}
            >
              {error_google}
            </div>
          )}

          <ContentBox>
            {loading_google ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "10px 0",
                }}
              >
                <Preloader
                  showText
                  title="Google registration in progress..."
                />
              </div>
            ) : (
              <Button
                active={true}
                color="--color-black"
                label="Registration using Google"
                onClick={handleGoogleAuth}
              />
            )}
          </ContentBox>
        </div>
      </>
    </PageBox>
  );
};

export default PageLogin;
