import React, { useEffect, useState } from "react";
import SegmentedControls from "../../../../UIComponents/SegmentedControls/SegmentedControls";
import Preloader from "../../../../UIComponents/Preloader/Preloader";
import AddData from "../../../../UIComponents/AddData/AddData";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowCatalogV4 from "./WindowCatalogV4";
import { useDispatch } from "react-redux";
import WidgetOrdersListV4 from "./WidgetOrdersListV4";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";

const WidgetOrders = ({ isLoading, currentOrders }) => {
  const dispatch = useDispatch();

  const [mode, setMode] = useState("new");
  const [orderState, setOrderState] = useState("new");
  const [filteredOrders, setFilteredOrders] = useState([]);

  // WINDOW
  const [windowCreateNewOrderV4, setWindowCreateNewOrderV4] = useState(false);

  /**
   *
   * Логика компонента
   *
   */

  // Функция фильтрации по статусу
  useEffect(() => {
    const filterOrders = () => {
      if (!Array.isArray(currentOrders)) {
        console.error("currentOrders is not an array:", currentOrders);
        return []; // Если currentOrders не массив, возвращаем пустой массив
      }

      if (orderState === "new") {
        return currentOrders?.filter((order) => order.orderStatus === "new");
      } else if (orderState === "in progress") {
        return currentOrders?.filter(
          (order) =>
            order.orderStatus === "in progress" ||
            order.orderStatus === "replacement"
        );
      } else if (orderState === "completed") {
        return currentOrders?.filter(
          (order) =>
            order.orderStatus === "completed" ||
            order.orderStatus === "confirm" ||
            order.orderStatus === "canceled"
        );
      } else if (orderState === "payment") {
        return currentOrders?.filter(
          (order) => order.orderStatus === "payment"
        );
      }
      return currentOrders; // По умолчанию возвращаем все заказы
    };

    const result = filterOrders();
    setFilteredOrders(result);
  }, [orderState, currentOrders]);

  const createNewOrder = (mode) => {
    setMode(mode);
    setWindowCreateNewOrderV4(true);
  };

  const handleClose = () => {
    setWindowCreateNewOrderV4(false);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowCreateNewOrderV4}
        onClose={handleClose}
        title={`WindowCatalogV4 - order`}
      >
        <WindowCatalogV4 mode={mode} onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}

      {!isLoading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            marginBottom: "8px",
          }}
        >
          <ContentBox position="start">
            <AddData
              onClick={() => createNewOrder("order")}
              icon={
                <span className="material-symbols-outlined">grid_view</span>
              }
              title={"Create new order"}
              description={
                "lorem ipsum dolor sit amet, consectetur adipiscing elit."
              }
            />
          </ContentBox>
          <ContentBox>
            <SegmentedControls
              options={["new", "in progress", "completed"]}
              onSelect="new"
              // label="Orders"
              selected={setOrderState}
            />
            <WidgetOrdersListV4 ordersList={filteredOrders} />
          </ContentBox>
        </div>
      ) : (
        <Preloader />
      )}
    </>
  );
};

export default WidgetOrders;
