import React, { useEffect, useImperativeHandle, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import ProtectedRoutes from "./services/route/ProtectedRoutes";
import { useDispatch, useSelector } from "react-redux";
import WidgetPublicMenu from "./components/Public/Menu/WidgetPublicMenu";
import { LoadScript } from "@react-google-maps/api";
import { setLoadingStateGoogleMapApi } from "./reducersToolkit/slice/configSlice";
import { getAuth } from "firebase/auth";
import ErrorNotification from "./components/UIComponents/ErrorNotification/ErrorNotification";

import { fetchPreviewDataV4 } from "./reducersToolkit/thunk/fetchPreviewDataV4";
import {
  subscribePreviewDocumentsThunkV4,
  unsubscribePreviewDocumentsThunkV4,
} from "./reducersToolkit/thunk/subscribePreviewDocumentsThunkV4";

import {
  subscribeRootDocumentsThunkV5,
  unsubscribeRootDocumentsThunkV5,
} from "./reducersToolkit/thunk/subscribeRootDocumentsThunkV5";

const googleAPIKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const googleMapsLibraries = ["places"];

function App() {
  const dispatch = useDispatch();
  const auth = getAuth();
  const { userId, rentalPointId } = useParams();

  const { loading, error, tempRootData, tempPreviewData, tempData, route } =
    useSelector((state) => state.catalogSliceV4);

  const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
  const [userIdRootDocument, setUserIdRootDocument] = useState(null);
  const [userRole, setUserRole] = useState("user");
  const [totalNotifications, setTotalNotifications] = useState(0);
  const [basketNotifications, setBasketNotifications] = useState(0);
  const [orderNotifications, setOrderNotifications] = useState(0);
  const [idRootDocumentUser, setIdRootDocumentUser] = useState(null);

  useEffect(() => {
    setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
    setUserIdRootDocument(
      tempRootData?.user_auth_rootCurrentData?.idRootDocument || null
    );
    setIdRootDocumentUser(tempRootData?.user_auth_rootCurrentData?.id || null);
  }, [tempRootData?.user_auth_rootCurrentData]);

  useEffect(() => {
    setOrderNotifications(
      tempPreviewData?.manager_ordersPreviewNotifications?.length || 0
    );
  }, [tempPreviewData?.manager_ordersPreviewNotifications]);

  useEffect(() => {
    setBasketNotifications(tempData?.currentCart?.length || 0);
  }, [tempData?.currentCart]);

  /** Counter data on moderation */
  useEffect(() => {
    const filteredRentalPoints =
      tempPreviewData?.general_rentalPointsPreviewNotifications?.filter(
        (itemRentalPoint) => itemRentalPoint.rentalPointStatus === "moderation"
      );

    const filteredBikes =
      tempPreviewData?.general_bikesPreviewNotifications?.filter(
        (bike) => bike.bikeStatus === "moderation"
      );

    const filteredUsers =
      tempPreviewData?.general_usersPreviewNotifications?.filter(
        (user) => user.status === "moderation"
      );

    const filteredUsersOrders =
      tempPreviewData.manager_ordersPreviewNotifications?.filter(
        (order) =>
          order.orderStatus === "new" ||
          order.orderStatus === "replacement" ||
          order.orderStatus === "canceled"
      );

    const totalRentalPointsNotifications = filteredRentalPoints?.length || 0;

    const totalBikesNotifications = filteredBikes?.length || 0;

    const totalUsersNotifications = filteredUsers?.length || 0;

    const totalUsersOrderNotifications = filteredUsersOrders?.length || 0;

    const totals =
      totalRentalPointsNotifications +
      totalBikesNotifications +
      totalUsersNotifications +
      totalUsersOrderNotifications;

    setTotalNotifications(totals);
  }, [
    tempPreviewData?.general_rentalPointsPreviewNotifications,
    tempPreviewData?.general_bikesPreviewNotifications,
    tempPreviewData?.general_usersPreviewNotifications,
    tempPreviewData.manager_ordersPreviewNotifications,
  ]);

  /** USERS */
  // ROOT
  useEffect(() => {
    if (idRootDocumentUser) {
      // Проверяем наличие idRootDocumentUser
      const collectionPath = "users";
      const rootCurrentDocumenState = "user_auth_rootCurrentData";
      const excludedIds = []; // Пустой массив, если исключения не нужны
      const errorStateName = "addErrorRootuser";
      const idRootDocument = idRootDocumentUser; // Уникальный идентификатор документа

      // Подписка при монтировании компонента
      dispatch(
        subscribeRootDocumentsThunkV5({
          collectionPath,
          rootCurrentDocumenState,
          idRootDocument,
          errorStateName,
          excludedIds, // Необязательный параметр
        })
      )
        .unwrap()
        .then((result) => {
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          console.error("Ошибка при подписке", error);
          setUser_rootCurrentData(null); // Сбрасываем данные при ошибке
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(
          unsubscribeRootDocumentsThunkV5({
            collectionPath, // Передаём collectionPath для отписки
          })
        )
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, idRootDocumentUser, setUser_rootCurrentData]);

  // PREVIEW
  useEffect(() => {
    if (userRole === "admin") {
      const collectionPath = "general_usersPreview";
      const previewCurrentDocumenState = "general_usersPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_general_usersPreview";
      const errorStateName = "currentError";

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userRole]);

  /** ORDERS CURRENT USER*/
  useEffect(() => {
    if (userIdRootDocument) {
      const collectionPath = `manager_ordersPreview.userIds.${userIdRootDocument}`;
      const previewCurrentDocumenState = "manager_ordersPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_manager_ordersPreview";
      const errorStateName = "currentError";
      const delayTime = 0; // 60 секунд

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
          delayTime,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userIdRootDocument]);

  /** RENTAL POINTS */
  useEffect(() => {
    if (userRole === "admin") {
      const collectionPath = "general_rentalPointsPreview";
      const previewCurrentDocumenState =
        "general_rentalPointsPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_general_rentalPointsPreview";
      const errorStateName = "currentError";

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userRole]);

  /** BIKES */
  useEffect(() => {
    if (userRole === "admin") {
      const collectionPath = "general_bikesPreview";
      const previewCurrentDocumenState = "general_bikesPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_general_bikesPreview";
      const errorStateName = "currentError";
      const delayTime = 0; // 60 секунд

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
          delayTime,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    } else if (userRole === "user") {
      dispatch(
        fetchPreviewDataV4({
          previewCollectionPath: `general_bikesPreview`,
          loadingStateName: "loading_general_bikesPreview",
          errorStateName: "currentError",
          previewCurrentDocumenState: "general_bikesPreviewNotifications",
        })
      );
    }
  }, [dispatch, userRole]);

  /** ORDERS PREVIEW*/
  useEffect(() => {
    if (userRole === "admin") {
      const collectionPath = "general_ordersPreview";
      const previewCurrentDocumenState = "general_ordersPreviewNotifications";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_general_ordersPreview";
      const errorStateName = "currentError";
      const delayTime = 0; // 60 секунд

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
          delayTime,
        })
      )
        .unwrap()
        .then((result) => {
          // Дополнительные действия после успешной подписки, если нужно
          console.log("Подписка установлена", result);
        })
        .catch((error) => {
          // Обработка ошибок при подписке
          console.error("Ошибка при подписке", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userRole]);

  /** Brand */
  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕчетного колличества вложенностей
        previewCollectionPath: `brandsPreview`,
        //
        loadingStateName: "loading_manager_brandsPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "brandsPreview",
      })
    );
  }, [dispatch]);

  /** Category */
  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: `categoriesPreview`,
        //
        loadingStateName: "loading_manager_categoriesPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "categoriesPreview",
      })
    );
  }, [dispatch]);

  /** SEO */
  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: `manager_seo`,
        //
        loadingStateName: "loading_manager_seoPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "seo_previewCurrentData",
      })
    );
  }, [dispatch]);

  /** Countries */
  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        previewCollectionPath: "countriesPreview",
        loadingStateName: "loading_rentalPoint_countriesPreview",
        errorStateName: "currentError",
        previewCurrentDocumenState: "tempPreviewData_countriesList",
      })
    );
  }, [dispatch]);

  return (
    <>
      <ErrorNotification
        error={error.globalError}
        isOpen={!!error.globalError}
      />

      <LoadScript
        googleMapsApiKey={googleAPIKey}
        libraries={googleMapsLibraries}
        onLoad={() => dispatch(setLoadingStateGoogleMapApi(true))}
      >
        <Router>
          <WidgetPublicMenu
            userRole={userRole}
            currentUser={user_auth_rootCurrentData}
            totalNotifications={totalNotifications}
            basketNotifications={basketNotifications}
            ordersNotifications={orderNotifications}
            // isLoading={signInLoading || signUpLoading || logOutLoading}
            // error={error.error_logOut || error.error_signIn || error.error_signUp}
          />

          <Routes>
            <Route
              path="/*"
              element={
                <ProtectedRoutes
                  currentUser={user_auth_rootCurrentData}
                  userId={userId}
                  rentalPointId={rentalPointId}
                />
              }
            />

            <Route
              path="/:language/checkout/:totalPrice"
              element={<WidgetPublicMenu />}
            />

            {/* <Route
              path={route.path ? route.path : "/*"} // Используем path, который мы сохраняем в Redux
              element={route.component ? route.component : <PageCatalogV2 />} // Отображаем компонент, который мы сохраняем в Redux
            /> */}
          </Routes>
        </Router>
      </LoadScript>
    </>
  );
}

export default App;
