import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import WidgetNavigateMenu from "./WidgetNavigateMenu";
import WidgetNavigateUser from "./WidgetNavigateUser";

const WindowNavigete = ({ onClose }) => {
  const { tempRootData, currentUser } = useSelector(
    (state) => state.catalogSliceV4
  );
  const [language, setLanguage] = useState("en");
  const [role, setRole] = useState(null);

  useEffect(() => {
    setLanguage(currentUser?.settings?.language || "en");
  }, [currentUser]);

  useEffect(() => {
    setRole(tempRootData?.user_auth_rootCurrentData?.role || null);
  }, [tempRootData.user_auth_rootCurrentData]);

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "16px" }}>
      <WidgetNavigateUser role={role} onClose={onClose} language={language} />
      <WidgetNavigateMenu onClose={onClose} language={language} />
    </div>
  );
};

export default WindowNavigete;
