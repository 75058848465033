import React, { useState, useEffect } from "react"; // Добавил useEffect на всякий случай, если понадобится
import InputText from "../../../UIComponents/Inputs/InputText";
import Button from "../../../UIComponents/Buttons/Button";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { emailSignInThunkV4 } from "../../../../reducersToolkit/thunk/emailSignInThunkV4";
import Preloader from "../../../UIComponents/Preloader/Preloader";

// Можно оставить эту функцию или использовать регулярное выражение прямо в проверке перед отправкой
// const isEmailPotentiallyValid = (email) => {
//   // Простая проверка на наличие @ и точки после неё, чтобы сделать кнопку активной
//   // Не строгая валидация, т.к. InputText сам покажет ошибку если формат неверный
//   return /^[^\s@]+@[^\s@]+\..+$/.test(email);
// };

const WidgetSignIn = ({ onClose, onError }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null); // Ошибка от API

  // InputText сам управляет своей ошибкой валидации

  const handleSetLogin = (event) => {
    setLogin(event.target.value);
    setApiError(null); // Сбрасываем ошибку API при изменении поля
  };

  const handleSetPassword = (event) => {
    setPassword(event.target.value);
    setApiError(null); // Сбрасываем ошибку API при изменении поля
  };

  const handleEmailSignIn = async () => {
    // Важно: InputText показывает ошибку валидации, но нам нужно
    // убедиться, что мы не отправляем заведомо неверные данные.
    // Проведем быструю проверку перед отправкой.
    // Используем тот же или похожий regex, что и в InputText.
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(login)) {
      // Хотя InputText должен показывать ошибку, дополнительно установим apiError
      // или просто не будем отправлять запрос.
      setApiError("Пожалуйста, введите корректный email.");
      console.log("Attempted to submit with invalid email format.");
      return; // Прерываем отправку
    }
    if (!password) {
      setApiError("Пароль не может быть пустым");
      return;
    }

    setLoading(true);
    setApiError(null);

    dispatch(emailSignInThunkV4({ email: login, password: password }))
      .unwrap()
      .then(() => {
        navigate("/profile");
        if (onClose) onClose(); // Добавил проверку на существование onClose
      })
      .catch((error) => {
        console.error("Error during login: ", error.errorMessage || error);
        const message = error.errorMessage || "Произошла ошибка при входе";
        setApiError(message);
        if (onError) onError(message); // Добавил проверку на существование onError
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Кнопка входа активна, если оба поля не пустые.
  // InputText сам покажет ошибку, если email невалиден.
  // Дополнительная проверка перед отправкой (handleEmailSignIn) не даст уйти запросу.
  const isEmailLoginActive = login !== "" && password !== "" && !loading;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "16px", // Оставим основной gap
      }}
    >
      <InputText
        type="email" // Используем встроенную валидацию InputText
        label="Email"
        name="email" // Добавил name для InputText
        description="Введите ваш email-адрес"
        value={login}
        onChange={handleSetLogin}
        disabled={loading}
        // error={apiError} // Можно передавать apiError, если InputText должен его показывать,
        // но он показывает и свою внутреннюю ошибку валидации.
        // Возможно, лучше показывать apiError отдельно.
      />
      {/* Убрали отдельный блок для ошибки валидации email, т.к. InputText ее покажет сам */}

      <InputText
        type="password"
        label="Password"
        name="password" // Добавил name для InputText
        description="Введите ваш пароль"
        value={password}
        onChange={handleSetPassword}
        disabled={loading}
      />

      {/* Ошибка API показывается отдельно */}
      {apiError && (
        <div
          style={{
            color: "var(--color-alarm)",
            padding: "8px",
            fontSize: "14px",
            backgroundColor: "rgba(255, 75, 75, 0.1)",
            borderRadius: "4px",
            marginTop: "-8px", // Немного пододвинем если есть ошибка
          }}
        >
          {apiError}
        </div>
      )}

      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginTop: apiError ? "8px" : "16px", // Динамический отступ в зависимости от наличия ошибки API
        }}
      >
        <Button
          active={isEmailLoginActive && !loading} // Активность зависит только от заполненности полей
          label="Войти"
          onClick={handleEmailSignIn}
          disabled={!isEmailLoginActive} // Явно блокируем, если не активна
        />
      </div>
    </div>
  );
};

export default WidgetSignIn;
