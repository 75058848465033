import React, { useEffect, useState } from "react";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import WidgetOrderSearchUserV4 from "./WidgetOrderSearchUserV4";
import WidgetItemUserPreview from "../Moderation/WidgetItemUserPreview";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";

const UsersList = ({ usersModerationList, setSelectedUser }) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
      }}
    >
      {usersModerationList?.map((userItem) => (
        <div
          key={uuidv4()}
          style={{
            width: "100%",
          }}
        >
          <WidgetItemUserPreview
            onClick={() => setSelectedUser(userItem)}
            user={userItem}
          />
        </div>
      ))}
    </div>
  );
};
const WindowOrderUsersListV4 = ({ onClose }) => {
  const dispatch = useDispatch();
  const { tempPreviewData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );
  // Локальное состояние для хранения введенного email
  const [emailSearchedUser, setEmailSearchedUser] = useState("");
  const [
    general_usersPreviewNotifications,
    setGeneral_usersPreviewNotifications,
  ] = useState([]);
  // const [selectedUser, setSelectedUser] = useState(null);
  // Локальное состояние для хранения пользователей, соответствующих поисковому запросу
  const [matchingUsers, setMatchingUsers] = useState([]);
  const [rootOrderData, setRootOrderData] = useState(null);
  const [currentCart, setCurrentCart] = useState(null);

  useEffect(() => {
    setGeneral_usersPreviewNotifications(
      tempPreviewData?.general_usersPreviewNotifications || []
    );
  }, [tempPreviewData?.general_usersPreviewNotifications]);

  useEffect(() => {
    setCurrentCart(tempData?.currentCart || null);
  }, [tempData?.currentCart]);

  useEffect(() => {
    if (
      emailSearchedUser.trim() &&
      Array.isArray(general_usersPreviewNotifications)
    ) {
      // Фильтруем пользователей по email
      const filteredUsers = general_usersPreviewNotifications.filter((item) =>
        item.email?.toLowerCase().includes(emailSearchedUser.toLowerCase())
      );
      setMatchingUsers(filteredUsers);
    } else {
      // Если поле пустое, сбрасываем список
      setMatchingUsers([]);
    }
  }, [emailSearchedUser, general_usersPreviewNotifications]);

  const handleSelectedUser = (userItem) => {
    // setSelectedUser(userItem);

    dispatch(
      setTempData({
        tempCurrentDocumentState: "currentOrder_selectedUser",
        data: userItem,
      })
    );
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
        }}
      >
        {/* Компонент для ввода email пользователя */}
        <ContentBox position={matchingUsers.length > 0 ? "start" : "one"}>
          <WidgetOrderSearchUserV4 setEmail={setEmailSearchedUser} />
        </ContentBox>
        {/* Список пользователей, отфильтрованный по email */}
        {matchingUsers && matchingUsers.length > 0 && (
          <ContentBox position={matchingUsers.length > 0 ? "end" : "start"}>
            <UsersList
              usersModerationList={matchingUsers} // Передаем отфильтрованных пользователей
              setSelectedUser={handleSelectedUser}
            />
          </ContentBox>
        )}
      </div>
    </>
  );
};

export default WindowOrderUsersListV4;
