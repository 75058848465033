import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  doc,
  getDoc,
  updateDoc,
  arrayRemove,
  arrayUnion,
  setDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { db } from "../../services/firebase/authFirebase";
import handleThunkError from "../../functions/handleThunkError";

// Максимальное количество попыток
const MAX_RETRIES = 3;

// Список кодов ошибок, для которых будут выполняться повторные попытки
const RETRYABLE_ERRORS = [
  "unavailable", // Firestore backend не доступен
  "deadline-exceeded", // Превышено время ожидания запроса
  "internal", // Внутренняя ошибка Firestore
  "failed-precondition", // Некорректные пред условия
  "network-error", // Ошибка сети
];

/**
 * Асинхронный thunk для удаления данных в Firestore
 *
 * @param {Object} params - Параметры удаления
 * @param {string} params.idPreviewDocument - ID документа предварительного просмотра (опционально)
 * @param {string} params.idRootDocument - ID корневого документа
 * @param {string} params.rootCollectionPath - Путь к корневой коллекции
 * @param {string} params.previewCollectionPath - Путь к коллекции предварительного просмотра
 * @param {string} params.previewGeneralCollectionPath - Путь к общей коллекции предварительного просмотра
 * @param {string} params.metadataDocumentPath - Путь к документу метаданных
 * @param {string} params.metadataGeneralDocumentPath - Путь к документу общих метаданных
 * @param {string} params.rootCurrentDocumentState - Состояние корневого документа
 * @param {string} params.previewCurrentDocumentState - Состояние документа предварительного просмотра
 * @param {string} params.previewGeneralDocumentState - Состояние общего документа предварительного просмотра
 * @param {string} params.loadingStateName - Название состояния загрузки
 * @param {string} params.errorStateName - Название состояния ошибки
 * @returns {Object} Обновленные данные после удаления
 */
export const deleteDataThunkV4 = createAsyncThunk(
  "countries/deleteDataThunkV4",
  async (
    {
      idPreviewDocument,
      idRootDocument,
      rootCollectionPath,
      previewCollectionPath,
      previewGeneralCollectionPath,
      metadataDocumentPath,
      metadataGeneralDocumentPath,
      rootCurrentDocumentState,
      previewCurrentDocumentState,
      previewGeneralDocumentState,
      loadingStateName,
      errorStateName,
    },
    { rejectWithValue, dispatch }
  ) => {
    let attempt = 0;
    let rootDocumentData = null;
    let previewDocumentData = null;
    let previewGeneralDocumentData = null;
    let isAlreadyRemoved = false; // Флаг, указывающий, что документ уже был помечен как удаленный

    while (attempt < MAX_RETRIES) {
      try {
        console.log(`--- Начало удаления: Попытка ${attempt + 1} ---`);

        // 1. Поиск idPreviewDocument, если не был передан
        if (!idPreviewDocument && idRootDocument) {
          console.log(
            `Начинаем поиск idPreviewDocument для idRootDocument: ${idRootDocument}`
          );
          console.log(`Путь поиска: ${previewCollectionPath}`);

          try {
            // Получаем все документы из указанной коллекции
            const querySnapshot = await getDocs(
              collection(db, ...previewCollectionPath.split("."))
            );

            console.log(
              `Найдено документов в коллекции: ${querySnapshot.docs.length}`
            );

            // Если документов нет вообще, выдаем соответствующее сообщение
            if (querySnapshot.docs.length === 0) {
              console.error(
                `Коллекция ${previewCollectionPath} пуста или не существует`
              );
              throw new Error(
                `Коллекция ${previewCollectionPath} пуста или не существует`
              );
            }

            // Просматриваем каждый документ
            let foundItem = null;
            for (const docSnapshot of querySnapshot.docs) {
              console.log(`Проверка документа ID: ${docSnapshot.id}`);

              const docData = docSnapshot.data();

              // Проверяем наличие массива data
              if (!docData || !Array.isArray(docData.data)) {
                console.log(
                  `Документ ${docSnapshot.id} не содержит массив data`
                );
                continue;
              }

              console.log(
                `Документ ${docSnapshot.id} содержит ${docData.data.length} элементов`
              );

              // Ищем элемент с нужным idRootDocument независимо от наличия флага remove
              foundItem = docData.data.find(
                (item) => item && item.idRootDocument === idRootDocument
              );

              if (foundItem) {
                idPreviewDocument = docSnapshot.id;
                console.log(`idPreviewDocument найден: ${idPreviewDocument}`);

                // Проверяем, не помечен ли элемент как удаленный
                if (foundItem.remove) {
                  console.warn(
                    `ВНИМАНИЕ: Найденный элемент уже имеет флаг remove = ${foundItem.remove}`
                  );
                  isAlreadyRemoved = true;
                }

                break;
              }
            }

            // Если после всех проверок idPreviewDocument не найден, попробуем расширенный поиск
            if (!idPreviewDocument) {
              console.log(
                `Стандартный поиск не дал результатов, пробуем расширенный поиск...`
              );

              // Проверим родительскую коллекцию, убрав последний сегмент из пути
              const pathSegments = previewCollectionPath.split(".");

              // Если в пути больше одного сегмента, пробуем поискать в родительской коллекции
              if (pathSegments.length > 1) {
                const parentPathSegments = pathSegments.slice(0, -1);
                const parentCollectionPath = parentPathSegments.join(".");

                console.log(
                  `Пробуем искать в родительской коллекции: ${parentCollectionPath}`
                );

                const parentQuerySnapshot = await getDocs(
                  collection(db, ...parentPathSegments)
                );

                console.log(
                  `Найдено документов в родительской коллекции: ${parentQuerySnapshot.docs.length}`
                );

                for (const docSnapshot of parentQuerySnapshot.docs) {
                  console.log(`Проверка документа ID: ${docSnapshot.id}`);

                  const docData = docSnapshot.data();

                  if (!docData || !Array.isArray(docData.data)) {
                    continue;
                  }

                  foundItem = docData.data.find(
                    (item) => item && item.idRootDocument === idRootDocument
                  );

                  if (foundItem) {
                    idPreviewDocument = docSnapshot.id;
                    console.log(
                      `idPreviewDocument найден в родительской коллекции: ${idPreviewDocument}`
                    );

                    // Проверяем, не помечен ли элемент как удаленный
                    if (foundItem.remove) {
                      console.warn(
                        `ВНИМАНИЕ: Найденный элемент уже имеет флаг remove = ${foundItem.remove}`
                      );
                      isAlreadyRemoved = true;
                    }

                    break;
                  }
                }
              }
            }

            // Если после всех попыток idPreviewDocument все равно не найден
            if (!idPreviewDocument) {
              throw new Error(
                `Не удалось найти idPreviewDocument для idRootDocument: ${idRootDocument} в коллекции ${previewCollectionPath}`
              );
            }
          } catch (error) {
            console.error(`Ошибка при поиске idPreviewDocument:`, error);
            return rejectWithValue({
              errorMessage: `Ошибка при поиске idPreviewDocument: ${error.message}`,
            });
          }
        }

        // 2. Получение и обновление корневого документа
        const rootDocRef = doc(
          db,
          ...rootCollectionPath.split("."),
          idRootDocument
        );

        const rootDocSnap = await getDoc(rootDocRef);
        if (!rootDocSnap.exists()) {
          throw new Error(
            `Документ с ID ${idRootDocument} не найден в коллекции ${rootCollectionPath}`
          );
        }

        // Проверяем, есть ли уже флаг remove в документе
        const existingRemoveFlag = rootDocSnap.data().remove;

        if (existingRemoveFlag) {
          console.log(
            `Документ ${idRootDocument} уже имеет флаг remove = ${existingRemoveFlag}. Сбрасываем флаг в null.`
          );
          // Сбрасываем флаг remove в null
          await updateDoc(rootDocRef, { remove: null });
          rootDocumentData = {
            ...rootDocSnap.data(),
            remove: null,
            idRootDocument: idRootDocument,
          };
          console.log(
            `Флаг remove сброшен в null для документа: ${idRootDocument}`
          );
        } else {
          // Установка метки времени удаления
          const timestamp = Date.now();
          await updateDoc(rootDocRef, { remove: timestamp });
          rootDocumentData = {
            ...rootDocSnap.data(),
            remove: timestamp,
            idRootDocument: idRootDocument,
          };
          console.log(
            `Корневой документ помечен как удаленный: ${idRootDocument}`
          );
        }

        // 3. Обновление preview документа
        const previewDocRef = doc(
          db,
          ...previewCollectionPath.split("."),
          idPreviewDocument
        );

        const previewDocSnap = await getDoc(previewDocRef);
        if (!previewDocSnap.exists()) {
          throw new Error(
            `Документ с ID ${idPreviewDocument} не найден в коллекции ${previewCollectionPath}`
          );
        }

        const previewData = previewDocSnap.data();
        const previewDataArray = previewData.data || [];

        const itemIndex = previewDataArray.findIndex(
          (item) => item.idRootDocument === idRootDocument
        );

        if (itemIndex === -1) {
          throw new Error(
            `Элемент с idRootDocument ${idRootDocument} не найден в массиве данных документа ${idPreviewDocument}`
          );
        }

        // Сохраняем данные перед изменением для определения размера
        previewDocumentData = { ...previewDataArray[itemIndex] };

        // Проверяем, есть ли у элемента флаг remove
        if (previewDataArray[itemIndex].remove) {
          console.log(
            `Элемент в preview уже имеет флаг remove. Сбрасываем флаг в null.`
          );
          // Сбрасываем флаг remove
          previewDataArray[itemIndex].remove = null;
        } else {
          // Устанавливаем метку времени удаления
          const timestamp = Date.now();
          previewDataArray[itemIndex].remove = timestamp;
        }

        await updateDoc(previewDocRef, { data: previewDataArray });
        console.log(`Preview документ обновлен: ${idPreviewDocument}`);

        // 4. Обновление general preview документа (если путь предоставлен)
        if (previewGeneralCollectionPath) {
          console.log(`Начало обновления general preview документа`);

          const generalPreviewDocRef = doc(
            db,
            ...previewGeneralCollectionPath.split("."),
            idPreviewDocument
          );

          const generalPreviewDocSnap = await getDoc(generalPreviewDocRef);

          // Проверяем существование документа и обрабатываем случай, когда документ не найден
          if (!generalPreviewDocSnap.exists()) {
            console.warn(
              `Документ с ID ${idPreviewDocument} не найден в коллекции ${previewGeneralCollectionPath}. Пропускаем обновление general preview.`
            );
            // Вместо выбрасывания ошибки, просто пропускаем обновление general preview
            previewGeneralDocumentData = null; // Устанавливаем в null, чтобы показать, что данные не были обновлены
          } else {
            const generalPreviewData = generalPreviewDocSnap.data();
            const generalPreviewDataArray = generalPreviewData.data || [];

            const generalItemIndex = generalPreviewDataArray.findIndex(
              (item) => item.idRootDocument === idRootDocument
            );

            // Если элемент не найден в массиве данных
            if (generalItemIndex === -1) {
              console.warn(
                `Элемент с idRootDocument ${idRootDocument} не найден в массиве данных документа ${idPreviewDocument} в general preview коллекции. Пропускаем обновление.`
              );
              // Вместо выбрасывания ошибки, просто пропускаем обновление
              previewGeneralDocumentData = null;
            } else {
              // Сохраняем данные перед изменением для определения размера
              previewGeneralDocumentData = {
                ...generalPreviewDataArray[generalItemIndex],
              };

              // Проверяем, есть ли у элемента флаг remove
              if (generalPreviewDataArray[generalItemIndex].remove) {
                console.log(
                  `Элемент в general preview уже имеет флаг remove. Сбрасываем флаг в null.`
                );
                // Сбрасываем флаг remove
                generalPreviewDataArray[generalItemIndex].remove = null;
              } else {
                // Устанавливаем метку времени удаления
                const timestamp = Date.now();
                generalPreviewDataArray[generalItemIndex].remove = timestamp;
              }

              // Обновляем документ в general preview коллекции
              await updateDoc(generalPreviewDocRef, {
                data: generalPreviewDataArray,
              });
              console.log(
                `General preview документ обновлен: ${idPreviewDocument}`
              );
            }
          }
        }

        // 5. Обновление метаданных в metadataDocumentPath
        if (metadataDocumentPath) {
          console.log(`Начало обновления метаданных в ${metadataDocumentPath}`);

          try {
            const metadataDocRef = doc(db, ...metadataDocumentPath.split("."));
            const metadataDocSnap = await getDoc(metadataDocRef);

            if (!metadataDocSnap.exists()) {
              console.warn(
                `Документ метаданных ${metadataDocumentPath} не найден. Создаем новый.`
              );

              // Создаем новый документ метаданных с начальными значениями
              await setDoc(metadataDocRef, {
                newDataIds: [],
                deletedDataIds: [idRootDocument],
                lastUpdatedDocument: {
                  lastUpdatedDocumentId: idPreviewDocument,
                  documentSize: 0,
                },
              });

              console.log(
                `Создан новый документ метаданных: ${metadataDocumentPath}`
              );
            } else {
              const metadataData = metadataDocSnap.data();
              const isInDeletedList =
                metadataData.deletedDataIds?.includes(idRootDocument);
              const isInNewList =
                metadataData.newDataIds?.includes(idRootDocument);

              // Если флаг remove сбрасывается в null, перемещаем ID из deletedDataIds в newDataIds
              if (rootDocumentData.remove === null) {
                if (isInDeletedList) {
                  await updateDoc(metadataDocRef, {
                    deletedDataIds: arrayRemove(idRootDocument),
                    newDataIds: arrayUnion(idRootDocument),
                  });
                  console.log(
                    `ID документа перемещен из deletedDataIds в newDataIds`
                  );
                }
              }
              // Если устанавливаем флаг remove, перемещаем ID из newDataIds в deletedDataIds
              else {
                if (isInNewList) {
                  await updateDoc(metadataDocRef, {
                    newDataIds: arrayRemove(idRootDocument),
                    deletedDataIds: arrayUnion(idRootDocument),
                  });
                  console.log(
                    `ID документа перемещен из newDataIds в deletedDataIds`
                  );
                }
              }

              const lastUpdatedDocSize =
                metadataData.lastUpdatedDocument?.documentSize || 0;
              const lastUpdatedDocId =
                metadataData.lastUpdatedDocument?.lastUpdatedDocumentId;

              // Определяем размер документа
              const documentSize = new Blob([
                JSON.stringify(previewDocumentData),
              ]).size;
              const newDocumentSize = Math.max(
                0,
                lastUpdatedDocSize - documentSize
              );

              await setDoc(
                metadataDocRef,
                {
                  lastUpdatedDocument: {
                    lastUpdatedDocumentId: lastUpdatedDocId,
                    documentSize: newDocumentSize,
                  },
                },
                { merge: true }
              );
              console.log(`Метаданные обновлены: ${metadataDocumentPath}`);
            }
          } catch (error) {
            console.error(`Ошибка при обновлении метаданных:`, error);
            console.warn(`Продолжаем выполнение без обновления метаданных`);
          }
        }

        // 6. Обновление метаданных в metadataGeneralDocumentPath (если предоставлен)
        if (
          metadataGeneralDocumentPath &&
          previewGeneralCollectionPath &&
          previewGeneralDocumentData // Проверяем, что данные были успешно получены выше
        ) {
          console.log(
            `Начало обновления general метаданных в ${metadataGeneralDocumentPath}`
          );

          try {
            const generalMetadataDocRef = doc(
              db,
              ...metadataGeneralDocumentPath.split(".")
            );

            // Проверяем существование документа метаданных
            const generalMetadataDocSnap = await getDoc(generalMetadataDocRef);

            if (!generalMetadataDocSnap.exists()) {
              console.warn(
                `Документ метаданных ${metadataGeneralDocumentPath} не найден. Создаем новый.`
              );

              // Создаем новый документ метаданных с начальными значениями
              await setDoc(generalMetadataDocRef, {
                newDataIds: [],
                deletedDataIds: [idRootDocument],
                lastUpdatedDocument: {
                  lastUpdatedDocumentId: idPreviewDocument,
                  documentSize: 0,
                },
              });

              console.log(
                `Создан новый документ метаданных: ${metadataGeneralDocumentPath}`
              );
            } else {
              const generalMetadataData = generalMetadataDocSnap.data();
              const isInDeletedList =
                generalMetadataData.deletedDataIds?.includes(idRootDocument);
              const isInNewList =
                generalMetadataData.newDataIds?.includes(idRootDocument);

              // Если флаг remove сбрасывается в null, перемещаем ID из deletedDataIds в newDataIds
              if (rootDocumentData.remove === null) {
                if (isInDeletedList) {
                  await updateDoc(generalMetadataDocRef, {
                    deletedDataIds: arrayRemove(idRootDocument),
                    newDataIds: arrayUnion(idRootDocument),
                  });
                  console.log(
                    `ID документа перемещен из deletedDataIds в newDataIds (general)`
                  );
                }
              }
              // Если устанавливаем флаг remove, перемещаем ID из newDataIds в deletedDataIds
              else {
                if (isInNewList) {
                  await updateDoc(generalMetadataDocRef, {
                    newDataIds: arrayRemove(idRootDocument),
                    deletedDataIds: arrayUnion(idRootDocument),
                  });
                  console.log(
                    `ID документа перемещен из newDataIds в deletedDataIds (general)`
                  );
                }
              }

              const generalLastUpdatedDocSize =
                generalMetadataData.lastUpdatedDocument?.documentSize || 0;
              const generalLastUpdatedDocId =
                generalMetadataData.lastUpdatedDocument?.lastUpdatedDocumentId;

              // Определяем размер удаленного элемента
              const documentSizeGeneral = new Blob([
                JSON.stringify(previewGeneralDocumentData),
              ]).size;
              const newGeneralDocumentSize = Math.max(
                0,
                generalLastUpdatedDocSize - documentSizeGeneral
              );

              await setDoc(
                generalMetadataDocRef,
                {
                  lastUpdatedDocument: {
                    lastUpdatedDocumentId: generalLastUpdatedDocId,
                    documentSize: newGeneralDocumentSize,
                  },
                },
                { merge: true }
              );
              console.log(
                `Общие метаданные обновлены: ${metadataGeneralDocumentPath}`
              );
            }
          } catch (error) {
            // Обрабатываем ошибку, но не прерываем выполнение функции
            console.error(`Ошибка при обновлении general метаданных:`, error);
            console.warn(
              `Продолжаем выполнение без обновления general метаданных`
            );
          }
        } else {
          console.log(
            `Пропускаем обновление general метаданных: нет необходимых данных или путей`
          );
        }

        // 7. Формирование и возврат результата
        return {
          rootDocumentData,
          previewDocumentData,
          previewGeneralDocumentData,
          rootCollectionPath,
          previewCollectionPath,
          previewGeneralCollectionPath,
          rootCurrentDocumentState,
          previewCurrentDocumentState,
          previewGeneralDocumentState,
          loadingStateName,
          errorStateName,
        };
      } catch (error) {
        console.error(`Ошибка при попытке удаления ${attempt + 1}:`, error);

        // Проверяем, нужно ли повторить попытку
        if (RETRYABLE_ERRORS.includes(error.code)) {
          attempt++;
          console.log(
            `Попытка повторного запроса ${attempt} из ${MAX_RETRIES}`
          );
          if (attempt >= MAX_RETRIES) {
            return handleThunkError(error, dispatch, rejectWithValue);
          }
        } else {
          // Для не повторяемых ошибок сразу возвращаем ошибку
          return handleThunkError(error, dispatch, rejectWithValue);
        }
      }
    }
  }
);
