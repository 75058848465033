import React, { useCallback, useEffect, useState } from "react";
import PriorityFile from "../../../../UIComponents/UploadFiles/PriorityFile";
import Divide from "../../../../UIComponents/Divide/Divide";
import { truncateText } from "../../../../../functions/truncateText";
import { useDispatch } from "react-redux";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowCatalogV4 from "./WindowCatalogV4";

const WidgetBikesReplacmentListV4 = React.memo(
  ({
    // onClick,
    // onClickDelete,
    item,
    indexBikeCurrentCart,
    order_selectedCompletedRootOrder,
    isMobile,
    currentStateOrder,
  }) => {
    const dispatch = useDispatch();
    // const [photoRootBike, setPhotoRootBike] = useState(null);
    const [titleRootbike, setTitleRootbike] = useState(null);
    const [replacementBikes, setReplacementBikes] = useState(null);
    // ------- Windows stats
    const [windowCatalogV4, setWindowCatalogV4] = useState(false);
    // ------- Внутренняя логика компоненета
    const [mode, setMode] = useState(null);

    useEffect(() => {
      if (item && item.bikeData) {
        // setPhotoRootBike(item?.bikeData?.files?.photoFiles || null);
        setTitleRootbike(
          item?.bikeData.basicInformation?.title?.titleOriginal || null
        );
        setReplacementBikes(item?.replacementBikes || null);
      }
    }, [item]);

    const handleReplacementBike = useCallback(
      ({
        mode,
        indexReplacementBike,
        indexBikeCurrentCart,
        keySlotReplacementBike,
      }) => {
        setMode(mode);

        dispatch(
          setTempData({
            tempCurrentDocumentState: "currentSlotIds",
            data: {
              indexReplacementBike,
              indexBikeCurrentCart,
              keySlotReplacementBike,
            },
          })
        );
        setWindowCatalogV4(true);
      },
      [dispatch]
    );

    const handleClose = useCallback(() => {
      setWindowCatalogV4(false);
    }, []);

    const handleDeleteReplacementBike = useCallback(
      ({
        indexBikeCurrentCart,
        indexDeletingBike,
        keySlotReplacementBike,
        order_selectedCompletedRootOrder,
      }) => {
        const { currentCart } = order_selectedCompletedRootOrder;
        const cartItem = currentCart[indexBikeCurrentCart];

        if (
          cartItem.replacementBikes &&
          cartItem.replacementBikes.hasOwnProperty(keySlotReplacementBike)
        ) {
          const replacementArray =
            cartItem.replacementBikes[keySlotReplacementBike];

          const updatedReplacementArray = [
            ...replacementArray.slice(0, indexDeletingBike),
            ...replacementArray.slice(indexDeletingBike + 1),
          ];

          const updatedCartItem = {
            ...cartItem,
            replacementBikes: {
              ...cartItem.replacementBikes,
              [keySlotReplacementBike]: updatedReplacementArray,
            },
          };

          const updatedCurrentCart = [
            ...currentCart.slice(0, indexBikeCurrentCart),
            updatedCartItem,
            ...currentCart.slice(indexBikeCurrentCart + 1),
          ];

          const updatedOrder = {
            ...order_selectedCompletedRootOrder,
            currentCart: updatedCurrentCart,
          };

          dispatch(
            setCurrentRootData({
              rootCurrentDocumentState: "order_selectedCompletedRootOrder",
              data: updatedOrder,
            })
          );
        } else {
          console.log("Key with index", keySlotReplacementBike, "not found.");
        }
      },
      [dispatch]
    );

    return (
      <>
        {/* POPUP WINDOWS */}
        <PopupDirrectory
          isOpen={windowCatalogV4}
          onClose={handleClose}
          title={`WindowCatalogV4`}
        >
          <WindowCatalogV4 mode={mode} onClose={handleClose} />
        </PopupDirrectory>
        {/* POPUP WINDOWS */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // gap: "24px",
            boxSizing: "border-box",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            padding: "16px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",

              // borderRadius: "16px",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                alignItems: "center",
                gap: "16px",
                width: "100%",
              }}
            >
              {/* Фото */}
              <div
                style={{
                  width: "100%",
                  aspectRatio: "16/9",
                }}
              >
                <PriorityFile
                  files={item.bikeData.files.photoFiles || []}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              {/* Название */}
              <h6>
                {truncateText(titleRootbike ? titleRootbike : "No name", 32)}
              </h6>
            </div>
            {/* <h6>{titleRootbike && titleRootbike}</h6> */}

            {Object.entries(replacementBikes || {}).map(
              ([key, bikeSlot], index) => (
                <>
                  <div
                    key={key}
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "16px",
                    }}
                  >
                    <Divide title="Option" number={index + 1} />
                    <div
                      style={{
                        display: "grid",
                        // padding: "8px",
                        ...(isMobile
                          ? {
                              gridTemplateColumns: "repeat(2, 1fr)",
                              gridTemplateRows: "repeat(2, 1fr)",
                            }
                          : {
                              gridTemplateColumns: "repeat(4, 1fr)",
                            }),
                        gap: "8px",
                        boxSizing: "border-box",
                      }}
                    >
                      {/* Проверка, является ли value массивом */}
                      {Array.isArray(bikeSlot) &&
                        bikeSlot.map((replacementItem, i) => (
                          <div key={i} style={{ position: "relative" }}>
                            <PriorityFile
                              style={{
                                borderRadius: "80px",
                                width: "100%",
                                height: "100%",
                                aspectRatio: "1/1",
                                gridColumn: `${index + 2}`,
                              }}
                              files={replacementItem?.files?.photoFiles}
                            />
                            {currentStateOrder !== "canceled" &&
                              currentStateOrder !== "replacement" &&
                              currentStateOrder !== "confirm" && (
                                <span
                                  onClick={() =>
                                    handleDeleteReplacementBike({
                                      keySlotReplacementBike: key,
                                      indexBikeCurrentCart:
                                        indexBikeCurrentCart,
                                      indexDeleteSlotBike: index,
                                      indexDeletingBike: i,
                                      order_selectedCompletedRootOrder:
                                        order_selectedCompletedRootOrder,
                                    })
                                  }
                                  style={{
                                    ...(isMobile
                                      ? { padding: "8px" }
                                      : { padding: "4px" }),
                                    position: "absolute",
                                    right: "0",
                                    top: "0",
                                    borderRadius: "50%",
                                    border: "0",
                                    backgroundColor: "var(--color-gray-100)",
                                    aspectRatio: "1/1",
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    boxSizing: "border-box",
                                  }}
                                  className="material-symbols-outlined"
                                >
                                  close
                                </span>
                              )}
                          </div>
                        ))}

                      {/* Кнопка "+" для того чтобы добавить в массив замену */}

                      {currentStateOrder !== "canceled" &&
                        currentStateOrder !== "replacement" &&
                        currentStateOrder !== "confirm" &&
                        Array.isArray(bikeSlot) &&
                        bikeSlot.length !== 4 && (
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              aspectRatio: "1/1",
                              borderRadius: "50%",
                              backgroundColor: "var(--color-alarm)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              boxSizing: "border-box",
                              cursor: "pointer",
                              color: "var(--color-white)",
                            }}
                            onClick={() =>
                              handleReplacementBike({
                                mode: "replacement",
                                keySlotReplacementBike: key,
                                indexReplacementBike: index,
                                indexBikeCurrentCart: indexBikeCurrentCart,
                              })
                            }
                            className="material-symbols-outlined"
                          >
                            add
                          </div>
                        )}
                      {/* Создаём массив с помощью from для создания доступных ячеек  */}
                      {Array.from(
                        {
                          length:
                            (currentStateOrder !== "replacement" &&
                            currentStateOrder !== "canceled" &&
                            currentStateOrder !== "confirm"
                              ? 3
                              : 4) - bikeSlot.length,
                        },
                        (_, i) => (
                          <div
                            key={i}
                            style={{
                              width: "100%",
                              height: "100%",
                              aspectRatio: "1/1",
                              borderRadius: "50%",
                              backgroundColor: "var(--color-white)",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              boxSizing: "border-box",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <h5 style={{ color: "var(--color-gray-300)" }}>
                                {i + bikeSlot.length + 2}
                              </h5>
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </>
    );
  }
);
export default WidgetBikesReplacmentListV4;
