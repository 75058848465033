import React, { useEffect, useState } from "react";
import "./bikes.css";
import AddData from "../../../../UIComponents/AddData/AddData";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
// import WindowBikeBasicInformation from "./WindowBikeBasicInformation";
import WindowBikeBasicInformationModeration from "../Moderation/WindowBikeBasicInformationModeration";
import CollapsableText from "../../../../UIComponents/Collapsable/CollapsableText";

const WidgetBikeBasicInformationModeration = ({
  isChangeState,
  mode,
  basicInformation,
}) => {
  const [windowBikeBasicInformation, setWindowBikeBasicInformation] =
    useState(false);
  const [
    windowBikeBasicInformationModeration,
    setWindowBikeBasicInformationModeration,
  ] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editButton, setEditButton] = useState(false);

  useEffect(() => {
    setTitle(basicInformation?.title?.titleOriginal || "Add bike name");
    setDescription(
      basicInformation?.description?.descriptionOriginal ||
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    );
    setEditButton(
      basicInformation?.title?.titleOriginal &&
        basicInformation?.description?.descriptionOriginal
    );
  }, [basicInformation]);

  const handleToggle = () => {
    if (mode === "new" || mode === "edit") {
      setWindowBikeBasicInformation(true);
    } else if (mode === "moderation" || mode === "moderatorAdded") {
      setWindowBikeBasicInformationModeration(true);
    }
  };

  const handleClose = () => {
    setWindowBikeBasicInformation(false);
    setWindowBikeBasicInformationModeration(false);
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      {/* <PopupDirrectory
        isOpen={windowBikeBasicInformation}
        onClose={handleClose}
        title="Bike name adn description"
      >
        <WindowBikeBasicInformation mode={mode} onClose={handleClose} />
      </PopupDirrectory> */}

      <PopupDirrectory
        isOpen={windowBikeBasicInformationModeration}
        onClose={handleClose}
        title="Bike name adn description moderation"
      >
        <WindowBikeBasicInformationModeration
          isChangeState={isChangeState}
          mode={mode}
          onClose={handleClose}
        />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}

      <div className="profile-widget__content-box">
        <AddData
          icon={
            editButton ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={title}
          description={description && <CollapsableText text={description} />}
          onClick={handleToggle}
          onClose={handleClose}
        />
      </div>
    </>
  );
};

export default WidgetBikeBasicInformationModeration;
