import React, { useEffect, useState } from "react";
import "../profile.css";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import AddData from "../../../../UIComponents/AddData/AddData";
import WindowEditBasicInformation from "./WindowEditBasicInformation";
import WindowEditBasicInformationModeration from "../Moderation/WindowEditBasicInformationModeration";
import CollapsableText from "../../../../UIComponents/Collapsable/CollapsableText";

const RentalPointBasicInformation = ({
  mode,
  basicInformation,
  isChangeState,
}) => {
  const [isOpenRentalPoint, setIsOpenRentalPoint] = useState(false);
  const [isOpenModeration, setIsOpenModeration] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editButton, setEditButton] = useState(false);

  useEffect(() => {
    setTitle(basicInformation?.title?.titleOriginal || "Add rental point name");
    setDescription(
      basicInformation?.description?.descriptionOriginal ||
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit."
    );
    setEditButton(
      basicInformation?.title?.titleOriginal &&
        basicInformation?.description?.descriptionOriginal
    );
  }, [basicInformation]);

  const handleToggle = () => {
    if (mode === "moderation" || mode === "moderatorAdded") {
      setIsOpenModeration(true);
    }
  };

  const handleClose = () => {
    setIsOpenModeration(false);
  };

  return (
    <>
      {/* POPUP WINDOW */}
      {/* <PopupDirrectory
        isOpen={isOpenRentalPoint}
        onClose={handleClose}
        title="Rental point inform"
      >
        <WindowEditBasicInformation mode={mode} onClose={handleClose} />
      </PopupDirrectory> */}

      <PopupDirrectory
        isOpen={isOpenModeration}
        onClose={handleClose}
        title="Rental point inform Moderation"
      >
        <WindowEditBasicInformationModeration
          mode={mode}
          onClose={handleClose}
          isChangeState={isChangeState}
        />
      </PopupDirrectory>
      {/* POPUP WINDOW */}

      <div className="profile-widget__content-box">
        <AddData
          onClick={handleToggle}
          icon={
            editButton ? (
              <span className="material-symbols-outlined">edit</span>
            ) : (
              <span className="material-symbols-outlined">add</span>
            )
          }
          title={title}
          description={<CollapsableText text={description} />}
        />
      </div>
    </>
  );
};

export default RentalPointBasicInformation;
