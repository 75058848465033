import React, { useEffect, useState, useRef, useCallback } from "react";
import Resizer from "react-image-file-resizer";
import Preloader from "../Preloader/Preloader";
import AddData from "../AddData/AddData";
import styled, { keyframes } from "styled-components";
import AnimatedDiv from "../Animation/AnimatedDiv";

const fadeInOut = keyframes`
  0% {
    background-color: rgba(128, 128, 128, 0.1);
  }
  100% {
    background-color: rgba(128, 128, 128, 0.9);
  }
`;

/**
 * Компонент для загрузки файлов с предпросмотром и возможностью сброса
 *
 * @param {Object} props - Свойства компонента
 * @param {boolean} props.isLoading - Флаг загрузки
 * @param {string|number} props.id - Уникальный идентификатор компонента
 * @param {Function} props.files - Функция обратного вызова для передачи выбранных файлов
 * @param {string} props.title - Заголовок компонента
 * @param {string} props.description - Описание компонента
 * @param {number} props.maxWidth - Максимальная ширина изображения
 * @param {number} props.maxHeight - Максимальная высота изображения
 * @param {string} props.format - Формат изображения
 * @param {number} props.quality - Качество изображения
 * @param {number} props.maxFiles - Максимальное количество файлов
 * @param {Function} props.isChangeState - Функция обратного вызова для отслеживания изменений (true - есть файлы, false - нет файлов)
 * @param {boolean} props.isReset - Флаг сброса выбранных файлов
 * @param {Function} props.setIsReset - Функция установки флага сброса
 * @returns {JSX.Element} Компонент загрузки файлов
 */
const WidgetUploadFilesV4 = ({
  isLoading,
  id,
  files,
  title,
  description,
  maxWidth = 512,
  maxHeight = 512,
  format = "JPEG",
  quality = 100,
  maxFiles = 3,
  isChangeState,
  isReset,
  setIsReset,
}) => {
  const [dragging, setDragging] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [prevIsLoading, setPrevIsLoading] = useState(false);

  // Обработка сброса файлов при isReset === true
  useEffect(() => {
    if (isReset) {
      console.log("Сброс файлов");
      // Очищаем выбранные файлы, это вызовет useEffect ниже
      setSelectedFiles([]);

      // Сбрасываем флаг isReset обратно в false
      if (typeof setIsReset === "function") {
        setIsReset(false);
      }
    }
    // Удалена зависимость files, так как все управляется через selectedFiles
  }, [isReset, setIsReset]);

  // Отправка файлов родительскому компоненту и обновление isChangeState
  useEffect(() => {
    const hasFiles = selectedFiles.length > 0;

    // Обновляем состояние isChangeState в родительском компоненте
    if (typeof isChangeState === "function") {
      console.log(`Обновляем isChangeState на ${hasFiles}`);
      isChangeState(hasFiles);
    }

    // Отправляем текущие файлы родителю
    if (typeof files === "function") {
      console.log("Отправка файлов родителю:", selectedFiles.length);
      files(selectedFiles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFiles]); // Зависимость только от selectedFiles

  // Очистка выбранных файлов после успешной загрузки (isLoading извне)
  useEffect(() => {
    if (prevIsLoading && !isLoading) {
      console.log("Внешняя загрузка завершена, очищаем файлы");
      setSelectedFiles([]);
    }
    setPrevIsLoading(isLoading);
  }, [isLoading, prevIsLoading]);

  // Обработчики событий
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    const filesToProcess = droppedFiles.slice(
      0,
      maxFiles - selectedFiles.length
    ); // Ограничиваем количество
    if (filesToProcess.length < droppedFiles.length) {
      console.warn(
        `Максимальное количество файлов (${maxFiles}) превышено. Загружено только ${filesToProcess.length} файлов.`
      );
    }
    processFiles(filesToProcess);
  };

  const handleFileSelect = (e) => {
    console.log("Выбраны новые файлы");
    const selected = Array.from(e.target.files);
    if (selected.length === 0) return; // Ничего не выбрано

    const filesToProcess = selected.slice(0, maxFiles - selectedFiles.length); // Ограничиваем количество
    if (filesToProcess.length < selected.length) {
      console.warn(
        `Максимальное количество файлов (${maxFiles}) превышено. Загружено только ${filesToProcess.length} файлов.`
      );
    }

    // Сбрасываем значение поля ввода, чтобы можно было повторно выбрать те же файлы
    e.target.value = null;

    processFiles(filesToProcess);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        maxWidth,
        maxHeight,
        format,
        quality,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const removeFile = useCallback(
    (fileToRemove) => {
      console.log("Удаление файла");
      setSelectedFiles((prevFiles) =>
        prevFiles.filter((file) => file !== fileToRemove)
      );
      // useEffect [selectedFiles] обновит isChangeState и отправит файлы
    },
    [] // Зависимости не нужны, так как все управляется через useEffect [selectedFiles]
  );

  const processFiles = async (filesToAdd) => {
    if (filesToAdd.length === 0) return; // Если нет файлов после обрезки
    console.log("Обработка файлов:", filesToAdd.length);
    setIsUploading(true);

    const resizedFilesPromises = filesToAdd.map((file) => {
      const maxSize = maxWidth * maxHeight;
      if (file.type.startsWith("image/") && file.size > maxSize) {
        return resizeFile(file);
      }
      return Promise.resolve(file);
    });

    try {
      const resizedFiles = await Promise.all(resizedFilesPromises);

      // Добавляем новые файлы
      setSelectedFiles((prevFiles) => {
        // Ограничиваем общее количество файлов
        const combinedFiles = [...prevFiles, ...resizedFiles];
        const limitedFiles = combinedFiles.slice(0, maxFiles);
        if (limitedFiles.length < combinedFiles.length) {
          console.warn(
            `Максимальное количество файлов (${maxFiles}) превышено при добавлении. Отображено ${limitedFiles.length}.`
          );
        }
        return limitedFiles;
      });
      // useEffect [selectedFiles] обновит isChangeState и отправит файлы
    } catch (error) {
      console.error("Ошибка при обработке файлов:", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <div
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        style={{
          backgroundColor: dragging
            ? "var(--color-priority)"
            : "var(--color-white)",
          width: "100%",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "16px",
          boxSizing: "border-box",
        }}
      >
        <input
          type="file"
          onChange={handleFileSelect}
          style={{ display: "none" }}
          multiple
          id={`fileInput-${id}`}
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          <AddData
            isLoading={isLoading || isUploading}
            onClick={() => document.getElementById(`fileInput-${id}`).click()}
            icon={<span className="material-symbols-outlined">add</span>}
            title={title ? title : "Add photos"}
            description={description}
          />
          {selectedFiles.length > 0 && (
            <ul
              style={{
                display: "grid",
                gridTemplateColumns: `repeat(${
                  selectedFiles && selectedFiles.length <= 3
                    ? selectedFiles.length
                    : 3
                }, 1fr)`,
                listStyleType: "none",
                gap: "8px",
                flex: "1",
                width: "100%",
                boxSizing: "border-box",
              }}
            >
              {selectedFiles?.map((file, index) => (
                <li
                  key={index}
                  style={{
                    width: "100%",
                    boxSizing: "border-box",
                    ...(index === 2
                      ? {
                          gridColumn: "1 / span 2",
                          gridRow: "1 / span 2",
                        }
                      : {
                          aspectRatio: "16/9",
                        }),
                  }}
                >
                  {file.type.startsWith("image/") ? (
                    <div
                      className="container"
                      style={{
                        backgroundImage: `url(${URL.createObjectURL(file)})`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        borderRadius: "8px",
                        overflow: "hidden",
                        position: "relative",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {!isLoading && !isUploading && (
                        <span
                          className="material-symbols-outlined hidden-element"
                          onClick={() => removeFile(file)}
                          style={{
                            position: "absolute",
                            top: "8px",
                            right: "8px",
                            cursor: "pointer",
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            borderRadius: "50%",
                            padding: "4px",
                            color: "white",
                          }}
                        >
                          close
                        </span>
                      )}
                      {(isLoading || isUploading) && (
                        <AnimatedDiv $delay={Math.floor(Math.random() * 100)} />
                      )}
                    </div>
                  ) : (
                    <span>{file.name}</span>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default WidgetUploadFilesV4;
