import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import WidgetBikesList from "../../Private/Login/Profile/BikesV3/WidgetBikesList";
import WidgetFilterBikes from "./WidgetFilterBikes";
import ContentBox from "../../UIComponents/ContentBox/ContentBox";
import WidgetSEO from "../../Private/Login/Profile/SEO/WidgetSEO";
// import Breadcrumbs from "../../UIComponents/Breadcrumbs/Breadcrumbs";
import SEOContent from "../../UIComponents/SEOContent/SEOContent";
import SEOTags from "../../UIComponents/SEOContent/SEOTags";
import { deepEqual } from "../../../functions/utils";
import PageBox from "../../UIComponents/ContentBox/PageBox";
import { setTempData } from "../../../reducersToolkit/slice/catalogSliceV4";

const filterBikes = (bikes, filters) => {
  if (!bikes) return [];
  if (!filters) return bikes;

  return bikes.filter((bike) => {
    const { brand, category, city, country, dateInterval, quantityBikes } =
      filters;
    const matchesBrand = !brand || bike.brand?.id === brand.idRootDocument;
    const matchesCategory =
      !category || bike.category?.id === category.idRootDocument;
    const matchesCity = !city || bike.city?.id === city.idRootDocument;
    const matchesCountry =
      !country || bike.country?.id === country.idRootDocument;
    const matchesDateInterval =
      !dateInterval ||
      (bike.rate &&
        bike.rate.some((rate) => {
          const rateMonthStart = new Date(dateInterval[0]).setMonth(
            rate.month,
            1
          );
          const rateMonthEnd = new Date(rateMonthStart).setMonth(
            rate.month + 1,
            0
          );
          return (
            rate.price > 0 &&
            ((dateInterval[0] >= rateMonthStart &&
              dateInterval[0] <= rateMonthEnd) ||
              (dateInterval[1] >= rateMonthStart &&
                dateInterval[1] <= rateMonthEnd) ||
              (rateMonthStart >= dateInterval[0] &&
                rateMonthEnd <= dateInterval[1]))
          );
        }));
    const matchesQuantity = !quantityBikes || bike.quantity >= quantityBikes;
    return (
      matchesBrand &&
      matchesCategory &&
      matchesCity &&
      matchesCountry &&
      matchesDateInterval &&
      matchesQuantity
    );
  });
};

const PageCatalogV2 = ({ breadcrumbPath, title, mode }) => {
  const dispatch = useDispatch();
  // const location = useLocation();

  // Получение данных из Slice
  const { tempPreviewData, tempRootData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // Состояния
  const [
    general_bikesPreviewNotifications,
    setGeneral_bikesPreviewNotifications,
  ] = useState(null);
  const [tempData_filtersParams, setTempData_filtersParams] = useState(null);
  // const [currentSlug, setCurrentSlug] = useState(null);
  const [userRole, setUserRole] = useState("user");

  // Refs
  const prevDataRef = useRef();

  // Обновление slug из URL
  // useEffect(() => {
  //   const pathname = location.pathname;
  //   // setCurrentSlug(pathname);
  // }, [location.pathname]);

  // Очистка рефов
  useEffect(() => {
    return () => {
      prevDataRef.current = null;
    };
  }, []);

  // tempPreviewData
  useEffect(() => {
    const newData = {
      notifications: tempPreviewData?.general_bikesPreviewNotifications,
    };

    if (!deepEqual(newData, prevDataRef.current)) {
      setGeneral_bikesPreviewNotifications(newData.notifications || null);
      prevDataRef.current = newData;
    }
  }, [tempPreviewData]);

  // tempData
  useEffect(() => {
    setTempData_filtersParams(tempData?.tempData_filtersParams || null);
  }, [tempData?.tempData_filtersParams]);

  // tempRootData
  useEffect(() => {
    const newData = {
      role: tempRootData?.user_auth_rootCurrentData?.role,
    };

    if (!deepEqual(newData, prevDataRef.current)) {
      setUserRole(newData.role || "user");
      prevDataRef.current = newData;
    }
  }, [tempRootData?.user_auth_rootCurrentData]);

  // Обработчик изменения фильтров
  const handleFilterChange = (filters) => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_filtersParams",
        data: filters,
      })
    );
  };

  const filteredBikesMemo = useMemo(() => {
    return filterBikes(
      general_bikesPreviewNotifications,
      tempData_filtersParams
    );
  }, [general_bikesPreviewNotifications, tempData_filtersParams]);

  // Определяем хлебные крошки по умолчанию, если не переданы из пропсов
  const defaultBreadcrumbPath = useMemo(() => {
    return [
      { item: "Home", link: "/" },
      { item: "Catalog", link: "/Catalog" },
    ];
  }, []);

  // Определяем заголовок по умолчанию, если не передан из пропсов
  const defaultTitle = "Bike Catalog";
  console.log("filterSettings tempData_filtersParams", tempData_filtersParams);
  return (
    <>
      {/* <PopupDirrectory
        isOpen={windowOrderForUserV4}
        onClose={handleClose}
        title="WindowOrderClientV4"
      >
        <WindowRentalPointOrderV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
      <PopupDirrectory
        isOpen={windowOrderFiltersBikesListV4}
        onClose={handleClose}
        title="WindowOrderFiltersBikesListV4"
      >
        <WindowOrderFiltersBikesListV4 mode="new" onClose={handleClose} />
      </PopupDirrectory> */}

      <SEOTags />
      {/* <Breadcrumbs path={breadcrumbPath} /> */}
      <PageBox
        path={breadcrumbPath || defaultBreadcrumbPath}
        title={title || defaultTitle}
      >
        <WidgetFilterBikes
          countriesList={tempPreviewData?.tempPreviewData_countriesList}
          citiesList={tempPreviewData?.rentalPoint_citiesListPreview}
          brandsList={tempPreviewData?.brandsPreview}
          categoriesList={tempPreviewData?.categoriesPreview}
          filterSettings={tempData_filtersParams}
          userSettings={tempRootData?.user_auth_rootCurrentData}
          onFilterChange={handleFilterChange}
        />

        {userRole === "admin" && (
          <ContentBox>
            <WidgetSEO />
          </ContentBox>
        )}

        <ContentBox>
          <WidgetBikesList
            bikesList={filteredBikesMemo}
            dateInterval={tempData_filtersParams?.dateInterval}
            bikeState="approved"
            mode={mode || "view"}
          />
        </ContentBox>

        <ContentBox>
          <SEOContent />
        </ContentBox>
      </PageBox>
    </>
  );
};

export default PageCatalogV2;
