import React from "react";

const SkeletonQuntityBike = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "8px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "8px",
          boxSizing: "border-box",
        }}
      >
        <div
          style={{
            backgroundColor: "var(--color-gray-100)",
            height: "48px",
            width: "48px",
            aspectRatio: "1/1",
            borderRadius: "8px",
            boxSizing: "border-box",
          }}
        />

        <div
          style={{
            backgroundColor: "var(--color-gray-100)",
            height: "48px",
            width: "80%",
            borderRadius: "8px",
          }}
        />
        <div
          style={{
            backgroundColor: "var(--color-gray-100)",
            height: "48px",
            width: "48px",
            aspectRatio: "1/1",
            borderRadius: "8px",
            boxSizing: "border-box",
          }}
        />
      </div>
      <div
        style={{
          width: "70%",
          backgroundColor: "var(--color-gray-100)",
          height: "8px",
          borderRadius: "80px",
        }}
      />
      <div
        style={{
          width: "30%",
          backgroundColor: "var(--color-gray-100)",
          height: "8px",
          borderRadius: "80px",
        }}
      />
    </div>
  );
};

export default SkeletonQuntityBike;
