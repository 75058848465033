import AnimatedDiv from "../Animation/AnimatedDiv";

const SkeletonSegmentControl = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "100%",
          backgroundColor: "var(--color-gray-100)",
          height: "56px",
          borderRadius: "16px",
          overflow: "hidden",
          padding: "4px",
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            flex: "1",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            gap: "8px",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--color-white)",
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "60%",
                height: "8px",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "60%",
                height: "8px",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
            </div>
          </div>

          <div
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "60%",
                height: "8px",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonSegmentControl;
