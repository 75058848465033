import React, { useEffect, useState } from "react";
import WidgetItemUserPreview from "../Moderation/WidgetItemUserPreview";
import AvatarUser from "../../../../UIComponents/Avatars/AvatarUser";

const WidgetOrderUsersListV4 = ({ onClick, selectedUser }) => {
  console.log("selectedUser", selectedUser);
  const [email, setEmail] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [ownerName, setOwnerName] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null);
  useEffect(() => {
    setEmail(selectedUser?.email || "No email");
    setFileUrl(selectedUser?.fileUrl);
    setOwnerName(selectedUser?.ownerName || "Select user");
    setPhoneNumber(selectedUser?.phoneNumber || "No phone");
  }, [selectedUser]);
  return (
    <div
      onClick={onClick}
      style={{
        width: "100%",
        boxSizing: "border-box",
        minHeightheight: "56px",
        // borderRadius: "8px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        // backgroundColor: "var(--color-gray-100)",
        // padding: "16px 8px 16px 16px",
        cursor: "pointer",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "inline-flex",
          alignItems: "center",
          gap: "16px",
        }}
      >
        <AvatarUser size={80} url={fileUrl} />

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "8px",
          }}
        >
          <h6>{ownerName}</h6>
        </div>
      </div>

      <div
        style={{
          display: "inline-flex",
          alignItems: "center",
        }}
      >
        <span className="material-symbols-outlined">chevron_right</span>
      </div>
    </div>
  );
};

export default WidgetOrderUsersListV4;
