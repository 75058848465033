import React, { useEffect, useState, useCallback, useMemo } from "react";
import "../profile.css"; // Импорт стилей для компонента
import SubtitleBlockSection from "../../../../UIComponents/SubtitleBlockSection/SubtitleBlockSection"; // Компонент подзаголовка секции
import InputText from "../../../../UIComponents/Inputs/InputText"; // Поле ввода текста (предполагается, что уже оптимизировано с React.memo)
import Switcher from "../../../../UIComponents/Switcher/Switcher"; // Переключатель (чекбокс)
import SegmentedControls from "../../../../UIComponents/SegmentedControls/SegmentedControls"; // Компонент сегментированного выбора
import Button from "../../../../UIComponents/Buttons/Button"; // Кнопка действия
import WidgetUserAvatar from "./WidgetUserAvatar"; // Компонент для отображения аватара пользователя
import { useDispatch, useSelector } from "react-redux"; // Хуки для работы с Redux
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox"; // Контейнер для контента
import Textarea from "../../../../UIComponents/Textarea/Textarea"; // Многострочное поле ввода (предполагается, что оптимизировано)
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar"; // Панель кнопок
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/updateDataThunkV4"; // Thunk для обновления данных в Firestore
import PageBox from "../../../../UIComponents/ContentBox/PageBox"; // Основной контейнер страницы
import WidgetUploadFilesV4 from "../../../../UIComponents/UploadFiles/WindgetUploadFilesV4"; // Компонент загрузки файлов
import { v4 as uuidv4 } from "uuid"; // Генератор уникальных идентификаторов
import WidgetListFileV4 from "../../../../UIComponents/UploadFiles/WidgetListFileV4"; // Компонент списка файлов
import {
  subscribeRootDocumentsThunkV5,
  unsubscribeRootDocumentsThunkV5,
} from "../../../../../reducersToolkit/thunk/subscribeRootDocumentsThunkV5"; // Thunk'и для подписки и отписки от данных Firestore
import deepEqual from "../../../../../functions/deepEqual"; // Функция глубокого сравнения объектов
import formatDate from "../../../../../functions/dateFormat"; // Утилита форматирования даты
import { useParams } from "react-router-dom";

// Основной компонент страницы профиля пользователя
const PageUserProfile = ({ title, breadcrumbPath }) => {
  // Инициализация диспетчера Redux для выполнения действий
  const dispatch = useDispatch();
  const { userId } = useParams();

  // Получение данных из Redux-состояния через useSelector
  const {
    loading, // Состояние загрузки (оставлено как есть, так как это имя из Redux)
    tempRootData, // Временные данные root-коллекции (из Redux)
    tempPreviewData, // Временные данные preview-коллекции (из Redux)
    previewNewData, // Новые данные preview-коллекции (из Redux)
    rootNewData, // Новые данные root-коллекции (из Redux)
  } = useSelector((state) => state.catalogSliceV4);

  // --- Состояния компонента с улучшенными именами ---
  // Текущие данные пользователя из root-коллекции
  const [currentUserData, setCurrentUserData] = useState(null);
  // Обновленные данные пользователя для отправки в root-коллекцию
  const [updatedUserData, setUpdatedUserData] = useState(null);
  // Обновленные данные пользователя для отправки в preview-коллекцию
  const [updatedPreviewData, setUpdatedPreviewData] = useState(null);
  // Состояние согласия с офертой (чекбокс)
  const [isOfferAccepted, setIsOfferAccepted] = useState(false);
  // Предпочитаемое обращение к пользователю
  const [preferredName, setPreferredName] = useState("");
  // Имя владельца
  const [firstName, setFirstName] = useState("");
  // Фамилия владельца
  const [lastName, setLastName] = useState("");
  // Email пользователя
  const [userEmail, setUserEmail] = useState("");
  // Номер телефона пользователя
  const [userPhone, setUserPhone] = useState("");
  // Пол пользователя
  const [selectedGender, setSelectedGender] = useState("");
  // Название компании
  const [companyName, setCompanyName] = useState("");
  // Почтовый адрес компании
  const [companyAddress, setCompanyAddress] = useState("");
  // Часовой пояс компании
  const [companyTimeZone, setCompanyTimeZone] = useState("");
  // Указанный пользователем пол (если "another")
  const [customGender, setCustomGender] = useState("");
  // Описание компании
  const [companyDescription, setCompanyDescription] = useState("");
  // Список файлов аватара
  const [avatarFiles, setAvatarFiles] = useState([]);
  // URL текущего аватара
  const [avatarUrl, setAvatarUrl] = useState(null);
  // Новые файлы аватара для загрузки
  const [newAvatarFiles, setNewAvatarFiles] = useState(null);
  const [isNewAvatarFiles, setIsNewAvatarFiles] = useState(false);
  const [userDocumentId, setUserDocumentId] = useState(null);
  console.log("isNewAvatarFiles", isNewAvatarFiles);
  // Состояние загрузки данных
  const [isDataLoading, setIsDataLoading] = useState(false);
  // Флаг, указывающий на изменения данных
  const [hasChanges, setHasChanges] = useState(false);
  const [offerConfirmationDate, setOfferConfirmationDate] = useState(null);
  // Шаблон preview-данных
  const [previewTemplateData, setPreviewTemplateData] = useState(null);
  // Статус пользователя
  const [userStatus, setUserStatus] = useState(null);
  // Роль пользователя
  const [userRole, setUserRole] = useState(null);

  // --- Обработчики событий с useCallback для предотвращения создания новых функций при каждом рендере ---

  // Обработчик изменения поля "Как обращаться к пользователю"
  const handlePreferredNameChange = useCallback((event) => {
    setPreferredName(event.target.value);
  }, []);

  // Обработчик изменения имени владельца
  const handleFirstNameChange = useCallback((event) => {
    setFirstName(event.target.value);
  }, []);

  // Обработчик изменения фамилии владельца
  const handleLastNameChange = useCallback((event) => {
    setLastName(event.target.value);
  }, []);

  // Обработчик изменения email
  const handleEmailChange = useCallback((event) => {
    setUserEmail(event.target.value);
  }, []);

  // Обработчик изменения номера телефона
  const handlePhoneChange = useCallback((event) => {
    setUserPhone(event.target.value);
  }, []);

  // Обработчик изменения пола (через SegmentedControls)
  const handleGenderChange = useCallback((value) => {
    setSelectedGender(value);
  }, []);

  // Обработчик изменения указанного пола (если "another")
  const handleCustomGenderChange = useCallback((event) => {
    setCustomGender(event.target.value);
  }, []);

  // Обработчик изменения названия компании
  const handleCompanyNameChange = useCallback((event) => {
    setCompanyName(event.target.value);
  }, []);

  // Обработчик изменения почтового адреса
  const handleCompanyAddressChange = useCallback((event) => {
    setCompanyAddress(event.target.value);
  }, []);

  // Обработчик изменения описания компании
  const handleCompanyDescriptionChange = useCallback((event) => {
    setCompanyDescription(event.target.value);
  }, []);

  // --- Кэширование данных из Redux с помощью useMemo ---

  // Мемоизация данных пользователя из Redux для предотвращения лишних вычислений
  const reduxUserData = useMemo(() => {
    // Данные из текущего root-документа
    const selectedUserData = tempRootData?.selectedUser_auth_rootCurrentData;
    // Дополнительные данные из root-документа
    const authorizedUuserData = tempRootData?.user_auth_rootCurrentData;

    return {
      userDocumentId: userId || authorizedUuserData?.id || null, // ID документа пользователя
      userStatus: selectedUserData?.status || null, // Статус пользователя
      userRole: selectedUserData?.role || null, // Роль пользователя
      avatarUrl: selectedUserData?.files?.userAvatarFiles || null, // URL аватара
      avatarFiles: selectedUserData?.files?.userAvatarFiles || [], // Список файлов аватара
      currentUserData: selectedUserData || null, // Полные данные пользователя
      userId: selectedUserData?.id || null, // Уникальный ID пользователя
      preferredName: selectedUserData?.user?.contactingTheUser || "", // Как обращаться
      firstName: selectedUserData?.user?.ownerName || "", // Имя владельца
      lastName: selectedUserData?.user?.ownerSurname || "", // Фамилия владельца
      selectedGender: selectedUserData?.user?.gender || "", // Пол
      customGender: selectedUserData?.user?.yourGender || "", // Указанный пол
      email: selectedUserData?.userContacts?.email || "", // Email
      phone: selectedUserData?.userContacts?.phoneNumber || "", // Телефон
      companyName: selectedUserData?.company?.companyName || "", // Название компании
      companyAddress: selectedUserData?.company?.mailingAddress || "", // Адрес
      companyTimeZone: selectedUserData?.company?.timeZone || "", // Часовой пояс
      companyDescription:
        selectedUserData?.company?.description?.descriptionOriginal || "", // Описание
      offerConfirmationDate: selectedUserData?.offerConfirm || null, // Подтверждение оферты
    };
  }, [
    userId,
    tempRootData?.selectedUser_auth_rootCurrentData, // Зависимость от текущих данных
    tempRootData?.user_auth_rootCurrentData, // Зависимость от дополнительных данных
  ]);

  // --- Синхронизация локального состояния с данными из Redux ---

  // Эффект обновляет локальные состояния при изменении данных в Redux
  useEffect(() => {
    setUserDocumentId(reduxUserData.userDocumentId);
    setUserStatus(reduxUserData.userStatus);
    setUserRole(reduxUserData.userRole);
    setAvatarUrl(reduxUserData.avatarUrl);
    setAvatarFiles(reduxUserData.avatarFiles);
    setCurrentUserData(reduxUserData.currentUserData);
    setPreferredName(reduxUserData.preferredName);
    setFirstName(reduxUserData.firstName);
    setLastName(reduxUserData.lastName);
    setSelectedGender(reduxUserData.selectedGender);
    setCustomGender(reduxUserData.customGender);
    setUserEmail(reduxUserData.email);
    setUserPhone(reduxUserData.phone);
    setCompanyName(reduxUserData.companyName);
    setCompanyAddress(reduxUserData.companyAddress);
    setCompanyTimeZone(reduxUserData.companyTimeZone);
    setCompanyDescription(reduxUserData.companyDescription);
    setOfferConfirmationDate(reduxUserData.offerConfirmationDate);
  }, [reduxUserData]); // Зависимость от кэшированных данных Redux

  // --- Обновление шаблонов данных из Redux ---

  // Эффект синхронизирует шаблоны root и preview данных
  useEffect(() => {
    setPreviewTemplateData(previewNewData.previewUserData || null); // Установка шаблона preview-данных
  }, [rootNewData.rootUserData, previewNewData.previewUserData]);

  // --- Формирование обновленных данных пользователя с мемоизацией ---

  // Мемоизация объекта updatedUserData для предотвращения лишних вычислений
  const memoizedUpdatedUserData = useMemo(() => {
    return {
      ...currentUserData, // Существующие данные пользователя
      user: {
        contactingTheUser: preferredName, // Как обращаться
        gender: selectedGender, // Пол
        yourGender: customGender, // Указанный пол
        ownerName: firstName, // Имя
        ownerSurname: lastName, // Фамилия
      },
      userContacts: {
        email: userEmail, // Email
        phoneNumber: userPhone, // Телефон
      },
      company: {
        companyName, // Название компании
        mailingAddress: companyAddress, // Адрес
        timeZone: companyTimeZone, // Часовой пояс
        description: {
          descriptionOriginal: companyDescription, // Описание
        },
      },
    };
  }, [
    currentUserData,
    preferredName,
    selectedGender,
    customGender,
    firstName,
    lastName,
    userEmail,
    userPhone,
    companyName,
    companyAddress,
    companyTimeZone,
    companyDescription,
  ]);

  // Мемоизация объекта updatedPreviewData для предотвращения лишних вычислений
  const memoizedUpdatedPreviewData = useMemo(() => {
    return {
      ...previewTemplateData, // Существующие preview-данные
      ownerName: firstName, // Имя владельца
      email: userEmail, // Email
      phoneNumber: userPhone, // Телефон
      fileUrl: avatarFiles.find((file) => file.priority === true), // Приоритетный файл аватара
      status: userStatus, // Статус
      role: userRole, // Роль
    };
  }, [
    previewTemplateData,
    firstName,
    userEmail,
    userPhone,
    avatarFiles,
    userStatus,
    userRole,
  ]);

  // --- Обновление данных и проверка изменений ---

  // Эффект синхронизирует updatedUserData и updatedPreviewData, определяет изменения
  useEffect(() => {
    setUpdatedUserData(memoizedUpdatedUserData); // Устанавливает обновленные root-данные
    setUpdatedPreviewData(memoizedUpdatedPreviewData); // Устанавливает обновленные preview-данные

    // Проверка, изменились ли данные для активации кнопки "Update"
    const hasDataChanged = !deepEqual(memoizedUpdatedUserData, currentUserData);
    setHasChanges(hasDataChanged); // Устанавливает флаг изменений
  }, [memoizedUpdatedUserData, memoizedUpdatedPreviewData, currentUserData]);

  // --- Обработчик обновления данных пользователя ---

  // Функция отправляет измененные данные в Firestore через Redux-thunk
  const handleUpdateFields = () => {
    try {
      setIsDataLoading(true); // Устанавливает состояние загрузки
      dispatch(
        updateDataThunkV4({
          idRootDocument: userDocumentId, // ID документа для обновления
          rootData: updatedUserData, // Обновленные root-данные
          previewData: updatedPreviewData, // Обновленные preview-данные
          newFiles: {
            userAvatarFiles: newAvatarFiles, // Новые файлы аватара
          },
          rootCollectionPath: "users", // Путь к root-коллекции

          previewCollectionPath: "manager_usersPreview", // Путь к preview-коллекции
          previewGeneralCollectionPath: "general_usersPreview",

          metadataDocumentPath: "manager_usersPreview.metadata", // Путь к метаданным
          metadataGeneralDocumentPath: "general_usersPreview.metadata",

          loadingStateName: "loading_update_auth_userRootCurrentData", // Имя состояния загрузки
          errorStateName: "currentError", // Имя состояния ошибки
        })
      )
        .unwrap()
        .then((result) => {
          setIsDataLoading(false); // Сбрасывает состояние загрузки
          console.log("Данные успешно обновлены:", result); // Логирование успеха
        })
        .catch((error) => {
          setIsDataLoading(false); // Сбрасывает состояние загрузки при ошибке
          console.error("Ошибка при обновлении данных:", error); // Логирование ошибки
        });
    } catch (error) {
      setIsDataLoading(false); // Сбрасывает состояние загрузки при исключении
      console.error("Ошибка вызова thunk:", error); // Логирование исключения
    }
  };

  // --- Подписка на данные пользователя из Firestore ---

  // Эффект подписывается на изменения данных в root-коллекции
  useEffect(() => {
    if (userDocumentId) {
      // Проверка наличия ID документа
      // Параметры подписки
      const collectionPath = "users"; // Путь к коллекции
      const rootCurrentDocumenState = "selectedUser_auth_rootCurrentData"; // Имя состояния для данных
      const excludedIds = []; // Исключенные ID (не используются)
      const errorStateName = "addErrorRootuser"; // Имя состояния ошибки
      const idRootDocument = userDocumentId; // ID документа

      // Выполнение подписки через Redux-thunk
      dispatch(
        subscribeRootDocumentsThunkV5({
          collectionPath,
          rootCurrentDocumenState,
          idRootDocument,
          errorStateName,
          excludedIds,
        })
      )
        .unwrap()
        .then((result) => {
          console.log("Подписка установлена", result); // Логирование успешной подписки
        })
        .catch((error) => {
          console.error("Ошибка при подписке", error); // Логирование ошибки подписки
        });

      // Функция очистки (отписка) при размонтировании компонента
      return () => {
        dispatch(
          unsubscribeRootDocumentsThunkV5({
            collectionPath,
          })
        )
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result); // Логирование успешной отписки
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error); // Логирование ошибки отписки
          });
      };
    }
  }, [dispatch, userDocumentId]); // Зависимости: диспетчер и ID документа

  // --- Структура навигации (хлебные крошки) ---

  // Массив объектов для отображения пути навигации
  // const breadcrumbPath = [
  //   { item: "Profile", link: "/profile" }, // Ссылка на страницу профиля
  //   { item: "User profile" }, // Текущая страница (без ссылки)
  // ];

  // --- Рендеринг компонента ---

  return (
    // Основной контейнер страницы с хлебными крошками и заголовком
    <PageBox path={breadcrumbPath} title={title}>
      {/* Секция аватара пользователя */}
      <ContentBox label="User avatar">
        {/* Компонент отображения аватара */}
        <WidgetUserAvatar
          isLoading={isDataLoading} // Показывает состояние загрузки
          size={160} // Размер аватара
          iconAddphoto={true} // Включает иконку добавления фото
          fileUrlAvatar={avatarUrl} // URL текущего аватара
        />
        {/* Контейнер для загрузки и списка файлов */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
          }}
        >
          {/* Компонент загрузки новых файлов аватара */}
          <WidgetUploadFilesV4
            isLoading={isDataLoading} // Состояние загрузки
            id={uuidv4()} // Уникальный ID для input
            title="Add user avatar" // Заголовок
            description="Lorem ipsum dolor sit amet, consectetur adipiscing elit." // Описание
            files={setNewAvatarFiles} // Callback для сохранения новых файлов
            maxWidth={800} // Максимальная ширина изображения
            maxHeight={600} // Максимальная высота изображения
            format="PNG" // Формат сжатия
            quality={80} // Качество сжатия
            isChangeState={setIsNewAvatarFiles}
          />
          {/* Компонент списка существующих файлов */}
          <WidgetListFileV4
            isLoading={isDataLoading} // Состояние загрузки
            viewFiles={avatarUrl} // Список файлов для отображения
            fieldName="files.userAvatarFiles" // Имя поля для файлов
            rootCollectionPath="users" // Путь к root-коллекции
            previewCollectionPath="manager_usersPreview" // Путь к preview-коллекции
            previewGeneralCollectionPath="general_usersPreview"
            metadataDocumentPath="manager_usersPreview.metadata" // Путь к метаданным
            metadataGeneralDocumentPath="general_usersPreview.metadata"
            idRootDocument={userDocumentId} // ID документа
            loadingStateName="loading_userFiles" // Имя состояния загрузки
            errorStateName="currentError" // Имя состояния ошибки
            rootCurrentDocumentState="user_auth_rootCurrentData" // Имя состояния данных
            mode="edit" // Режим редактирования
          />
        </div>
        {/* Поле ввода "Как обращаться" */}
        <InputText
          type="text"
          label="How can I call you?"
          description="Please enter your name as you would like to be addressed."
          value={preferredName}
          onChange={handlePreferredNameChange}
        />
      </ContentBox>

      {/* Секция контактной информации */}
      <ContentBox label="User contacts">
        <SubtitleBlockSection
          title="User contacts"
          description="Contact information for quick communication with you."
        />
        {/* Поле ввода email */}
        <InputText
          type="email"
          label="Email"
          value={userEmail}
          onChange={handleEmailChange}
        />
        {/* Поле ввода телефона */}
        <InputText
          type="tel"
          label="Phone"
          value={userPhone}
          onChange={handlePhoneChange}
        />
      </ContentBox>

      {/* Секция персональной информации */}
      <ContentBox label="How can I call you?">
        <SubtitleBlockSection
          title="How can I call you?"
          description="We use this data to send you important notifications."
        />
        {/* Поле ввода имени */}
        <InputText
          type="text"
          label="Owner's name"
          value={firstName}
          onChange={handleFirstNameChange}
        />
        {/* Поле ввода фамилии */}
        <InputText
          type="text"
          label="Surname"
          value={lastName}
          onChange={handleLastNameChange}
        />
        {/* Выбор пола */}
        <SegmentedControls
          options={["male", "female", "another"]}
          label="Your gender"
          description="Please specify your gender."
          selected={handleGenderChange}
          onSelect={selectedGender}
        />
        {/* Условное поле для указания пола, если "another" */}
        {selectedGender && selectedGender === "another" && (
          <InputText
            type="text"
            label="Your gender"
            value={customGender}
            onChange={handleCustomGenderChange}
          />
        )}
      </ContentBox>

      {/* Секция информации о компании */}
      <ContentBox label="Company information">
        <SubtitleBlockSection
          title="Company information"
          description="Please provide information about your company."
        />
        {/* Поле ввода названия компании */}
        <InputText
          type="text"
          label="Company name"
          value={companyName}
          onChange={handleCompanyNameChange}
        />
        {/* Многострочное поле для описания компании */}
        <Textarea
          type="text"
          label="Description company"
          description="Please provide the address."
          value={companyDescription}
          onChange={handleCompanyDescriptionChange}
          maxLength={2000}
        />
        {/* Поле ввода почтового адреса */}
        <InputText
          type="text"
          label="Mailing address"
          description="Please provide the address."
          value={companyAddress}
          onChange={handleCompanyAddressChange}
        />
        {/* Панель кнопок с условным отображением */}
        <ButtonsBar>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "16px",
            }}
          >
            {/* Условие: если оферта подтверждена */}
            {!!offerConfirmationDate ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                {/* Отображение даты подтверждения оферты */}
                <p className="p-light">{`Вы подтвердили оферту: ${formatDate(
                  offerConfirmationDate
                )}`}</p>
                {/* Кнопка обновления данных */}
                <Button
                  type="small"
                  active={hasChanges}
                  label="Update"
                  onClick={handleUpdateFields}
                  allert={
                    <h1 style={{ padding: "80px", textAlign: "center" }}>
                      Please confirm that you want to update the user
                      information.
                    </h1>
                  }
                />
              </div>
            ) : (
              // Условие: если оферта не подтверждена
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "8px",
                }}
              >
                {/* Переключатель для подтверждения оферты */}
                <Switcher
                  isChecked={isOfferAccepted}
                  onChange={setIsOfferAccepted}
                  label="I confirm my agreement with the offer"
                />
                {/* Кнопка сохранения данных */}
                <Button
                  type="small"
                  active={(isOfferAccepted && hasChanges) || isNewAvatarFiles}
                  label="Save"
                  onClick={handleUpdateFields}
                  allert={
                    <h1 style={{ padding: "80px", textAlign: "center" }}>
                      Please confirm that you want to update the user
                      information.
                    </h1>
                  }
                />
              </div>
            )}
          </div>
        </ButtonsBar>
      </ContentBox>
    </PageBox>
  );
};

export default PageUserProfile;
