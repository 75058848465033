import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
// import WidgetListFileV4 from "../../../../UIComponents/UploadFiles/WidgetListFileV4";
import WidgetViewBikeDetail from "./WidgetViewBikeDetail";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import Button from "../../../../UIComponents/Buttons/Button";
import PriorityFile from "../../../../UIComponents/UploadFiles/PriorityFile";
import CollapsableText from "../../../../UIComponents/Collapsable/CollapsableText";
import { v4 as uuidv4 } from "uuid";
import priorityFile from "../../../../../functions/priorityFile";
// import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/catalog/V4/CRUD/updateDataThunkV4";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4";
import {
  subscribeRootDocumentsThunkV4,
  unsubscribeRootDocument,
} from "../../../../../reducersToolkit/thunk/subscribeRootDocumentsThunkV4";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowOrderDetailModeration from "../OrdersV3/WindowOrderDetailModeration";
import WidgetAlert from "../../../../UIComponents/Alerts/WidgetAlert";
import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/fetchRootDataThunkV4";
const truncateToThreeChars = (value) => {
  // Проверяем, является ли value строкой
  if (typeof value === "string") {
    return value.slice(0, 3); // Обрезаем строку до 3 символов
  }
  return value; // Возвращаем значение без изменений, если это не строка
};

function updateUserChoiceFlag({
  order_selectedCompletedRootOrder,
  indexBikeCurrentCart,
  keySlotReplacementBike,
  indexReplacementBikes,
  flag,
  oneOnly,
}) {
  // Проверяем наличие текущей корзины и других вложенных объектов

  if (
    !order_selectedCompletedRootOrder?.currentCart ||
    !order_selectedCompletedRootOrder?.currentCart[indexBikeCurrentCart] ||
    !order_selectedCompletedRootOrder?.currentCart[indexBikeCurrentCart]
      .replacementBikes ||
    !order_selectedCompletedRootOrder?.currentCart[indexBikeCurrentCart]
      .replacementBikes[keySlotReplacementBike]
  ) {
    console.error("Некорректные данные или структура объекта");
    return null;
  }

  // Создаем копию order_selectedCompletedRootOrder для предотвращения мутации исходного объекта
  const updatedOrder = JSON.parse(
    JSON.stringify(order_selectedCompletedRootOrder)
  );

  // Получаем ссылку на массив по ключу keySlotReplacementBike
  const replacementBikes =
    updatedOrder?.currentCart[indexBikeCurrentCart]?.replacementBikes[
      keySlotReplacementBike
    ];

  // Проверяем существование элемента в массиве по индексу indexReplacementBikes
  if (!replacementBikes[indexReplacementBikes]) {
    console.error("Элемент с указанным индексом не найден в replacementBikes");
    return null;
  }

  // Если oneOnly === true, сбрасываем все флаги userChoice в false
  if (oneOnly) {
    replacementBikes.forEach((bike) => {
      bike.userChoice = false; // сбрасываем все флаги
    });
  }

  // Устанавливаем флаг userChoice в flag для указанного элемента
  replacementBikes[indexReplacementBikes].userChoice = flag;

  // Возвращаем обновленный объект
  return updatedOrder;
}

const updateBikeSlot = ({
  currentSlotIds,
  selectedBike_rootData,
  order_selectedCompletedRootOrder,
  keySlotReplacementBike,
}) => {
  const { currentCart } = order_selectedCompletedRootOrder;

  // Проверяем индекс
  console.log(
    "replacement updateBikeSlot***** 200",
    currentSlotIds?.indexBikeCurrentCart
  );

  // Находим cartItem по индексу
  const cartItem = currentCart[currentSlotIds?.indexBikeCurrentCart];

  // Логируем найденный cartItem
  console.log("replacement updateBikeSlot***** 300", cartItem);

  // Проверяем, что replacementBikes существует
  if (cartItem?.replacementBikes) {
    console.log(
      "replacement updateBikeSlot***** 400 - replacementBikes:",
      cartItem?.replacementBikes
    );

    // Проверяем, что ключ с нужным индексом существует в replacementBikes
    if (cartItem?.replacementBikes.hasOwnProperty(keySlotReplacementBike)) {
      const replacementArray =
        cartItem.replacementBikes[keySlotReplacementBike];
      console.log(
        "replacement updateBikeSlot***** 500 - replacementBikes[currentSlotIds]:",
        replacementArray
      );

      // Создаем новый объект с обновленным массивом, добавляя selectedBike_rootData
      const updatedReplacementArray = [
        ...replacementArray,
        selectedBike_rootData,
      ];
      console.log(
        "replacement updateBikeSlot***** 600 - После добавления в массив:",
        updatedReplacementArray
      );

      // Обновляем cartItem с новым массивом replacementBikes
      const updatedCartItem = {
        ...cartItem,
        replacementBikes: {
          ...cartItem.replacementBikes,
          [keySlotReplacementBike]: updatedReplacementArray,
        },
      };

      // Обновляем currentCart с измененным cartItem
      const updatedCurrentCart = [
        ...currentCart?.slice(0, currentSlotIds.indexBikeCurrentCart),
        updatedCartItem,
        ...currentCart?.slice(currentSlotIds.indexBikeCurrentCart + 1),
      ];

      console.log("replacement updateBikeSlot***** 700:", {
        ...order_selectedCompletedRootOrder,
        currentCart: updatedCurrentCart,
      });

      // Возвращаем новый объект с обновленным currentCart
      return {
        ...order_selectedCompletedRootOrder,
        currentCart: updatedCurrentCart,
      };
    } else {
      console.log(
        "replacement updateBikeSlot***** 500 - Нет такого ключа в replacementBikes"
      );
    }
  } else {
    console.log(
      "replacement updateBikeSlot***** 400 - replacementBikes не существует"
    );
  }

  return order_selectedCompletedRootOrder;
};

const WindowViewBikeDetail = ({ mode, onClose, closeParent }) => {
  const dispatch = useDispatch();

  const {
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    loading,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  // tempPreviewData
  const [idRootDocumentBike, setidRootDocumentBike] = useState(null);
  console.log("WindowViewBikeDetail idRootDocumentBike", idRootDocumentBike);
  // tempRootData
  const [photoFiles, setPhotoFiles] = useState(null);
  const [basicInformation, setBasicInformation] = useState(null);
  const [replacementBike, setReplacementBike] = useState(null);
  const [directory, setDirectory] = useState(null);
  const [rate, setRate] = useState(null);
  const [startBookingDates, setStartBookingDates] = useState(0);
  const [endBookingDates, setEndBookingDates] = useState(0);

  const [indexBikeCurrentCart, setIndexBikeCurrentCart] = useState(null);
  const [
    order_selectedCompletedRootOrder,
    setOrder_selectedCompletedRootOrder,
  ] = useState(null);
  const [indexReplacementBikes, setIndexReplacementBikes] = useState(0);
  const [keySlotReplacementBike, setKeySlotReplacementBike] = useState(null);
  const [userChoice, setUserChoice] = useState(false);

  const [selectedBike_rootData, setSelectedBike_rootData] = useState(null);
  const [idRootDocumentRentalPoint, setIdRootDocumentRentalPoint] =
    useState(null);
  const [rentalPointRootData, setRentalPointRootData] = useState(null);
  const [windowOrderDetailModeration, setWindowOrderDetailModeration] =
    useState(false);
  const [orderedBike, setOrderedBike] = useState(null);
  const [orderedBikeTitle, setOrderedBikeTitle] = useState(null);

  // tempData
  const [currentSlotIds, setCurrentSlotIds] = useState(null);

  /**
   * Наполнение useState – блок для tempRootData
   */
  useEffect(() => {
    if (mode === "replacement") {
      const currentCart =
        tempRootData?.order_selectedCompletedRootOrder?.currentCart;
      const indexBike = tempData?.currentSlotIds?.indexBikeCurrentCart;

      if (!currentCart || indexBike === undefined || !currentCart[indexBike]) {
        console.warn(
          "⚠️ Ошибка: currentCart или indexBikeCurrentCart отсутствует!"
        );
        setStartBookingDates(0);
        setEndBookingDates(0);
      } else {
        const bikeData = currentCart[indexBike];
        // Получаем totals, если он существует
        const totals = bikeData?.totals;
        // Проверяем, что totals.dateInterval существует и является массивом
        const dateInterval =
          totals && Array.isArray(totals.dateInterval)
            ? totals.dateInterval
            : null;

        if (dateInterval && dateInterval.length >= 2) {
          setStartBookingDates(dateInterval[0] ?? 0);
          setEndBookingDates(dateInterval[1] ?? 0);
        } else {
          console.warn(
            "⚠️ Ошибка: dateInterval отсутствует или имеет некорректную структуру!"
          );
          setStartBookingDates(0);
          setEndBookingDates(0);
        }
      }
      setPhotoFiles(
        tempRootData?.selectedBike_rootData?.files?.photoFiles || []
      );
      setBasicInformation(
        tempRootData?.selectedBike_rootData?.basicInformation || null
      );
      setReplacementBike(
        tempRootData?.selectedBike_rootData?.basicInformation?.title
          ?.titleOriginal || null
      );
      setDirectory(tempRootData?.selectedBike_rootData?.directory || null);
      setRate(tempRootData?.selectedBike_rootData?.rate || null);
      setRentalPointRootData(
        tempRootData?.selectedRentalPoint_rootData || null
      );
    } else if (mode === "selected") {
      const currentCart =
        tempPreviewData?.choosingSelectedBike_previewData?.currentCart;
      // const indexBike = tempData?.currentSlotIds?.indexBikeCurrentCart;
      if (
        !currentCart ||
        indexBikeCurrentCart === undefined ||
        !currentCart[indexBikeCurrentCart]
      ) {
        console.warn(
          "⚠️ Ошибка: currentCart или indexBikeCurrentCart отсутствует!"
        );
        setStartBookingDates(0);
        setEndBookingDates(0);
      } else {
        const bikeData =
          currentCart[indexBikeCurrentCart]?.replacementBikes?.[
            keySlotReplacementBike
          ]?.[indexReplacementBikes] || null;

        if (!bikeData) {
          console.warn("⚠️ Ошибка: bikeData не найдено!");
          setStartBookingDates(0);
          setEndBookingDates(0);
        } else {
          // Получаем totals, если он существует
          const totals = currentCart[indexBikeCurrentCart]?.totals;
          // Проверяем, что totals.dateInterval существует и является массивом
          const dateInterval =
            totals && Array.isArray(totals.dateInterval)
              ? totals.dateInterval
              : [];

          setPhotoFiles(bikeData?.files?.photoFiles || []);
          setBasicInformation(bikeData?.basicInformation || null);
          setReplacementBike(
            bikeData?.basicInformation?.title?.titleOriginal || null
          );
          setDirectory(bikeData?.directory || null);
          setRate(bikeData?.rate || null);

          // Проверяем, что dateInterval содержит хотя бы 2 элемента
          if (dateInterval.length >= 2) {
            setStartBookingDates(dateInterval[0] ?? 0);
            setEndBookingDates(dateInterval[1] ?? 0);
          } else {
            console.warn(
              "⚠️ Ошибка: dateInterval отсутствует или имеет некорректную структуру!"
            );
            setStartBookingDates(0);
            setEndBookingDates(0);
          }
        }
      }

      // if (
      //   !currentCart ||
      //   indexBikeCurrentCart === undefined ||
      //   !currentCart[indexBikeCurrentCart]
      // ) {
      //   console.warn(
      //     "⚠️ Ошибка: currentCart или indexBikeCurrentCart отсутствует!"
      //   );
      //   setStartBookingDates(0);
      //   setEndBookingDates(0);
      // } else {
      //   const bikeData =
      //     currentCart[indexBikeCurrentCart]?.replacementBikes[
      //       keySlotReplacementBike
      //     ][indexReplacementBikes];
      //   // Получаем totals, если он существует
      //   const totals = currentCart[indexBikeCurrentCart]?.totals;
      //   // Проверяем, что totals.dateInterval существует и является массивом
      //   const dateInterval =
      //     totals && Array.isArray(totals.dateInterval)
      //       ? totals.dateInterval
      //       : null;
      //   setPhotoFiles(bikeData?.files?.photoFiles || []);
      //   setBasicInformation(bikeData?.basicInformation || null);
      //   setReplacementBike(
      //     bikeData?.basicInformation?.title?.titleOriginal || null
      //   );
      //   setDirectory(bikeData?.directory || null);
      //   setRate(bikeData?.rate || null);
      //   // setRentalPointRootData(
      //   //   tempRootData?.selectedRentalPoint_rootData || null
      //   // );

      //   if (dateInterval && dateInterval.length >= 2) {
      //     setStartBookingDates(dateInterval[0] ?? 0);
      //     setEndBookingDates(dateInterval[1] ?? 0);
      //   } else {
      //     console.warn(
      //       "⚠️ Ошибка: dateInterval отсутствует или имеет некорректную структуру!"
      //     );
      //     setStartBookingDates(0);
      //     setEndBookingDates(0);
      //   }
      // }
    } else if (mode === "order") {
      setPhotoFiles(
        tempRootData?.selectedBike_rootData?.files?.photoFiles || []
      );
      setBasicInformation(
        tempRootData?.selectedBike_rootData?.basicInformation || null
      );
      setReplacementBike(
        tempRootData?.selectedBike_rootData?.basicInformation?.title
          ?.titleOriginal || null
      );
      setDirectory(tempRootData?.selectedBike_rootData?.directory || null);
      setRate(tempRootData?.selectedBike_rootData?.rate || null);
      setRentalPointRootData(
        tempRootData?.selectedRentalPoint_rootData || null
      );
    } else {
      setPhotoFiles(
        tempRootData?.selectedBike_rootData?.files?.photoFiles || []
      );
      setBasicInformation(
        tempRootData?.selectedBike_rootData?.basicInformation || null
      );
      setReplacementBike(
        tempRootData?.selectedBike_rootData?.basicInformation?.title
          ?.titleOriginal || null
      );
      setDirectory(tempRootData?.selectedBike_rootData?.directory || null);
      setRate(tempRootData?.selectedBike_rootData?.rate || null);
      setRentalPointRootData(
        tempRootData?.selectedRentalPoint_rootData || null
      );
    }

    setOrder_selectedCompletedRootOrder(
      tempRootData?.order_selectedCompletedRootOrder || null
    );
    setOrderedBike(
      tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
        tempData?.currentSlotIds?.indexBikeCurrentCart
      ]?.bikeData || null
    );
    setOrderedBikeTitle(
      tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
        tempData?.currentSlotIds?.indexBikeCurrentCart
      ]?.bikeData?.basicInformation?.title?.titleOriginal || null
    );
    setUserChoice(
      tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
        tempData?.currentSlotIds?.indexBikeCurrentCart
      ]?.replacementBikes?.[keySlotReplacementBike]?.[indexReplacementBikes]
        ?.userChoice || false
    );

    setSelectedBike_rootData(tempRootData?.selectedBike_rootData || {});
  }, [
    tempRootData?.selectedBike_rootData,
    tempRootData?.selectedRentalPoint_rootData,
    tempRootData?.order_selectedCompletedRootOrder,
    tempData?.currentSlotIds?.indexBikeCurrentCart,
    tempPreviewData?.choosingSelectedBike_previewData,
    indexReplacementBikes,
    keySlotReplacementBike,
    indexBikeCurrentCart,
    mode,
  ]);

  /**
   * Подписка на tempPreviewData – блок для режима order/replacement/selected
   */
  useEffect(() => {
    if (mode === "order") {
      setidRootDocumentBike(
        tempPreviewData?.choosingBikeForOrder_previewData?.idRootDocument ||
          null
      );
      setIdRootDocumentRentalPoint(
        tempPreviewData?.choosingBikeForOrder_previewData
          ?.idRootDocumentRentalPoint || null
      );
    } else if (mode === "replacement") {
      setidRootDocumentBike(
        tempPreviewData?.choosingBikeToCreateReplacement_previewData
          ?.idRootDocument || null
      );
      setIdRootDocumentRentalPoint(
        tempPreviewData?.choosingBikeToCreateReplacement_previewData
          ?.idRootDocumentRentalPoint || null
      );
    } else if (mode === "selected") {
      setidRootDocumentBike(
        tempPreviewData?.choosingSelectedBike_previewData?.currentCart[
          indexBikeCurrentCart
        ]?.bikeData?.id || null
      );
      setIdRootDocumentRentalPoint(
        tempPreviewData?.choosingSelectedBike_previewData?.currentCart[
          indexBikeCurrentCart
        ]?.rentalPointRootData?.id || null
      );
    } else {
      console.log(
        "choosingBikeForView_previewData",
        tempPreviewData?.choosingBikeForView_previewData
      );
      setidRootDocumentBike(
        tempPreviewData?.choosingBikeForView_previewData?.idRootDocument || null
      );
      setIdRootDocumentRentalPoint(
        tempPreviewData?.choosingBikeForView_previewData
          ?.idRootDocumentRentalPoint || null
      );
    }
  }, [
    mode,
    indexBikeCurrentCart,
    tempPreviewData?.choosingBikeForView_previewData,
    tempPreviewData?.choosingBikeForOrder_previewData,
    tempPreviewData?.choosingBikeToCreateReplacement_previewData,
    tempPreviewData?.choosingSelectedBike_previewData,
  ]);

  /**
   * Блок для tempData
   */
  useEffect(() => {
    if (mode === "order") {
      setStartBookingDates(
        tempData?.manage_fdiltersBikes?.dateInterval?.[0] || null
      );
      setEndBookingDates(
        tempData?.manage_fdiltersBikes?.dateInterval?.[1] || null
      );
    }
    setCurrentSlotIds(tempData?.currentSlotIds || null);
    setKeySlotReplacementBike(
      tempData?.currentSlotIds?.keySlotReplacementBike || null
    );
    setIndexReplacementBikes(
      tempData?.currentSlotIds?.indexReplacementBike || 0
    );
    setIndexBikeCurrentCart(
      tempData?.currentSlotIds?.indexBikeCurrentCart || 0
    );
  }, [
    tempData?.currentSlotIds,
    mode,
    tempData?.manage_fdiltersBikes?.dateInterval,
  ]);

  /**
   * Fetch функции для подписки на документы
   */
  // Подписка для bikes
  useEffect(() => {
    if (
      (mode === "moderation" ||
        mode === "replacement" ||
        mode === "order" ||
        mode === "selected") &&
      idRootDocumentBike
    ) {
      console.log(`Выполняем подписку на документ: ${idRootDocumentBike}`);

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV4({
              collectionPath: "bikes",
              rootCurrentDocumenState: "selectedBike_rootData",
              idRootDocument: idRootDocumentBike,
              loadingStateName: "loading_bike",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      return () => {
        unsubscribeRootDocument(idRootDocumentBike);
      };
    } else if (idRootDocumentBike) {
      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: idRootDocumentBike,
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          rootCollectionPath: "bikes",
          // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
          rootCurrentDocumentState: "selectedBike_rootData",
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "loading_bike",
          errorStateName: "currentError",
        })
      );
    }
  }, [dispatch, mode, idRootDocumentBike]);

  // Подписка для rentalPoints
  useEffect(() => {
    if (
      (mode === "moderation" ||
        mode === "replacement" ||
        mode === "order" ||
        mode === "selected") &&
      idRootDocumentRentalPoint
    ) {
      console.log(
        `Выполняем подписку на документ: ${idRootDocumentRentalPoint}`
      );

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV4({
              collectionPath: "rentalPoints",
              rootCurrentDocumenState: "selectedRentalPoint_rootData",
              idRootDocument: idRootDocumentRentalPoint,
              loadingStateName: "loading_RentalPoint",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      return () => {
        unsubscribeRootDocument(idRootDocumentRentalPoint);
      };
    }
  }, [dispatch, mode, idRootDocumentRentalPoint]);

  /**
   * Внутренняя логика компонента (обработчики)
   */
  const addCart = () => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "orderedBike",
        data: selectedBike_rootData || null,
      })
    );
    setWindowOrderDetailModeration(true);
  };

  const handleReplacement = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: "order_selectedCompletedRootOrder",
        data: updateBikeSlot({
          currentSlotIds: currentSlotIds,
          selectedBike_rootData: selectedBike_rootData,
          order_selectedCompletedRootOrder: order_selectedCompletedRootOrder,
          keySlotReplacementBike: keySlotReplacementBike,
        }),
      })
    );
    onClose();
    closeParent();
  };

  const handleSelected = () => {
    dispatch(
      setCurrentRootData({
        rootCurrentDocumentState: "order_selectedCompletedRootOrder",
        data: updateUserChoiceFlag({
          order_selectedCompletedRootOrder: order_selectedCompletedRootOrder,
          indexBikeCurrentCart: indexBikeCurrentCart,
          keySlotReplacementBike: keySlotReplacementBike,
          indexReplacementBikes: indexReplacementBikes,
          flag: true,
          oneOnly: true,
        }),
      })
    );

    onClose();
    closeParent();
  };

  const handleClose = () => {
    setWindowOrderDetailModeration(false);
  };

  if (mode === "moderation" || mode === "replacement" || mode === "order") {
    return (
      <>
        {/* POPUP WINDOWS */}
        <PopupDirrectory
          isOpen={windowOrderDetailModeration}
          onClose={handleClose}
          title="WindowOrderDetailModeration"
        >
          <WindowOrderDetailModeration
            mode="new"
            onClose={handleClose}
            closeParent={onClose}
          />
        </PopupDirrectory>
        {/* Основной контент */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            paddingBottom: "72px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <ContentBox position="start" isLoading={loading.loading_bike}>
              <WidgetViewBikeDetail
                photoFiles={photoFiles}
                basicInformation={basicInformation}
                directory={directory}
                rate={rate}
                startBookingDates={startBookingDates}
                endBookingDates={endBookingDates}
              />
            </ContentBox>
            {/* Rental point data */}
            <ContentBox
              isLoading={loading.loading_RentalPoint}
              header={
                <HeaderRentalPointPreview
                  rentalPointRootData={rentalPointRootData}
                  backgroundRentalPoint={
                    priorityFile(rentalPointRootData?.files?.photoFiles)?.url
                  }
                />
              }
            >
              <RentalPointData rentalPointRootData={rentalPointRootData} />
            </ContentBox>
          </div>
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "8px",
              }}
            >
              {mode === "replacement" && (
                <>
                  <Button
                    type="small"
                    active={true}
                    label="Cancel"
                    onClick={onClose}
                    color="--color-black"
                  />
                  <Button
                    type="small"
                    active={true}
                    label="Replacement"
                    onClick={handleReplacement}
                    allert={
                      <WidgetAlert
                        title={`Are you sure you want to buy "${orderedBikeTitle}" refund on "${replacementBike}"?`}
                        description={`Please remember that if the replacement offered is not exactly what the user ordered, it may reduce the chances that they will agree to rent a bike from you.`}
                      />
                    }
                  />
                </>
              )}
              {mode === "order" && (
                <>
                  <Button
                    type="small"
                    color="--color-black"
                    label="Cancel"
                    active={true}
                    onClick={onClose}
                  />
                  <Button
                    type="small"
                    label="Add cart"
                    active={true}
                    onClick={addCart}
                  />
                </>
              )}
            </div>
          </ButtonsBar>
        </div>
      </>
    );
  } else if (mode === "selected") {
    return (
      <>
        {/* POPUP WINDOWS */}
        <PopupDirrectory
          isOpen={windowOrderDetailModeration}
          onClose={handleClose}
          title="WindowOrderDetailModeration"
        >
          <WindowOrderDetailModeration
            mode="new"
            onClose={handleClose}
            closeParent={onClose}
          />
        </PopupDirrectory>
        {/* Основной контент */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "8px",
            paddingBottom: "72px",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <ContentBox position="start" isLoading={loading.loading_bike}>
              <WidgetViewBikeDetail
                photoFiles={photoFiles}
                basicInformation={basicInformation}
                directory={directory}
                rate={rate}
                startBookingDates={startBookingDates}
                endBookingDates={endBookingDates}
              />
            </ContentBox>
          </div>
          <ButtonsBar>
            <div
              style={{
                width: "100%",
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "8px",
              }}
            >
              {mode === "selected" && (
                <>
                  <Button
                    type="small"
                    active={true}
                    label="Cancel"
                    onClick={onClose}
                    color="--color-black"
                  />
                  <Button
                    type="small"
                    active={true}
                    label="Replacement"
                    onClick={handleSelected}
                    allert={
                      <WidgetAlert
                        title={`Are you sure you want to confirm the exchange "${orderedBikeTitle}" to "${replacementBike}"?`}
                      />
                    }
                  />
                </>
              )}
            </div>
          </ButtonsBar>
        </div>
      </>
    );
  } else {
    return (
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          paddingBottom: "72px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <ContentBox position="start" isLoading={loading.loading_bike}>
            <WidgetViewBikeDetail
              photoFiles={photoFiles}
              basicInformation={basicInformation}
              directory={directory}
              rate={rate}
              startBookingDates={startBookingDates}
              endBookingDates={endBookingDates}
            />
          </ContentBox>
          {/* Rental point data */}
          <ContentBox
            isLoading={loading.loading_RentalPoint}
            header={
              <HeaderRentalPointPreview
                rentalPointRootData={rentalPointRootData}
                backgroundRentalPoint={
                  priorityFile(rentalPointRootData?.files?.photoFiles)?.url
                }
              />
            }
          >
            <RentalPointData rentalPointRootData={rentalPointRootData} />
          </ContentBox>
        </div>
      </div>
    );
  }
};

export default WindowViewBikeDetail;

const RentalPointData = ({ rentalPointRootData }) => {
  const isMobile = window.innerWidth <= 400;

  return (
    <div
      key={uuidv4()}
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "16px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "8px",
        }}
      >
        <div>
          {/* rental point name */}
          <h5>{rentalPointRootData?.basicInformation?.title?.titleOriginal}</h5>

          {/* location */}
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.pointAddress}
            </p>
          </div>
        </div>
      </div>
      {/* <WidgetListFileV4
          viewFiles={item?.rentalPointRootData?.files?.photoFiles || []}
          // loadingStateName=""
        /> */}
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gridTemplateRows: "repeat(2, 1fr)",
          boxSizing: "border-box",
          gap: "2px",
        }}
      >
        {/* timetable */}
        <div
          style={{
            width: "100%",
            gridColumn: "1",
            gridRow: "span 2",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            // gap: "16px",
            backgroundColor: "var(--color-gray-100)",
            boxSizing: "border-box",
            borderRadius: "16px",
            // aspectRatio: "1/1",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              boxSizing: "border-box",
              padding: "16px",
            }}
          >
            {rentalPointRootData?.timetable && (
              <>
                <h6
                  style={{
                    width: "100%",
                    // color: "var(--color-gray-200)",
                  }}
                >
                  Timetable
                </h6>
                <span
                  // style={{
                  //   color: "var(--color-gray-300)",
                  // }}
                  className="material-symbols-outlined"
                >
                  alarm_on
                </span>
              </>
            )}
          </div>

          <div
            style={{
              width: "100%",
              height: "100%",
              padding: "0 16px 16px 16px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              gap: "2px",
              boxSizing: "border-box",
            }}
          >
            {rentalPointRootData?.timetable?.map((timetableItem, index) =>
              !timetableItem.isHoliday ? (
                <div
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p className="p-light">
                    {truncateToThreeChars(
                      timetableItem.weekday.weekdayOriginal
                    )}
                  </p>

                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />

                  <div
                    className="p-light"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "var(--color-success)",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                      boxSizing: "border-box",
                    }}
                  >
                    <p>
                      {timetableItem?.openHours}:{timetableItem?.openMinutes}
                    </p>
                    —
                    <p>
                      {timetableItem?.closeHours}:{timetableItem?.closeMinutes}
                    </p>
                  </div>
                </div>
              ) : (
                <div
                  key={uuidv4()}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <p className="p-light">
                    {truncateToThreeChars(
                      timetableItem?.weekday?.weekdayOriginal
                    )}
                  </p>
                  <div
                    style={{
                      width: "100%",
                      height: "1px",
                      backgroundColor: "var(--color-gray-200)",
                    }}
                  />
                  <p
                    className="p-light"
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "var(--color-black)",
                      padding: "2px 8px",
                      borderRadius: "4px",
                      color: "var(--color-white)",
                      boxSizing: "border-box",
                    }}
                  >
                    holiday
                  </p>
                </div>
              )
            )}
          </div>
        </div>

        {/* contacts */}
        {/* emails */}
        <div
          style={{
            gridColumn: "2",
            gridRow: "1",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "end",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ContactsList
            contacts={rentalPointRootData?.contacts?.emailAddresses}
            fildname="email"
            title="Emails: "
            isMobile={isMobile}
            icon={
              <span
                // style={{
                //   color: "var(--color-gray-300)",
                // }}
                className="material-symbols-outlined"
              >
                mail
              </span>
            }
          />
        </div>
        {/* phones */}

        <div
          style={{
            gridColumn: "2",
            gridRow: "2",
            backgroundColor: "var(--color-gray-100)",
            borderRadius: "16px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            justifyContent: "end",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <ContactsList
            contacts={rentalPointRootData?.contacts?.phoneNumbers}
            fildname="phone"
            title="Phones: "
            isMobile={isMobile}
            icon={
              <span
                // style={{
                //   color: "var(--color-gray-300)",
                // }}
                className="material-symbols-outlined"
              >
                call
              </span>
            }
          />
        </div>
      </div>
      {/* rental poiunt description */}
      {rentalPointRootData?.basicInformation?.description
        ?.descriptionOriginal && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <h6>Description rental point</h6>
          <CollapsableText
            text={
              rentalPointRootData?.basicInformation?.description
                ?.descriptionOriginal || null
            }
          />
        </div>
      )}
    </div>
  );
};

const HeaderRentalPointPreview = ({
  rentalPointRootData,
  backgroundRentalPoint,
  title,
  address,
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: "160px",
        backgroundColor: "var(--color-gray-200)",
        position: "relative",
        backgroundImage: `url('${backgroundRentalPoint}')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "end",
        padding: "16px 24px 42px 24px",
        boxSizing: "border-box",
        marginBottom: "48px",
      }}
    >
      <div
        style={{
          position: "absolute",
          left: "24px",
          bottom: "-48px",
        }}
      >
        <PriorityFile
          style={{
            borderRadius: "80px",
            height: "80px",
            width: "80px",
            border: "solid 4px var(--color-white)",
          }}
          files={rentalPointRootData?.files?.logoFiles}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "4px",
          position: "absolute",
          right: "24px",
          bottom: "-28px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
              padding: "2px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.country?.title}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "4px",
              padding: "2px 8px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <p className="p-light">
              {rentalPointRootData?.location?.city?.title}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const ContactsList = ({ contacts, fildname, title, isMobile, icon }) => {
  const [showAll, setShowAll] = useState(false);

  const visibleContacts = contacts?.slice(0, 1);

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: contacts?.length > 1 ? "space-between" : "flex-end",
        boxSizing: "border-box",
      }}
    >
      {Array.isArray(contacts) && contacts?.length > 1 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            boxSizing: "border-box",
            // backgroundColor: "var(--color-gray-800)",
            padding: "16px",
          }}
        >
          <p
            className="p-light"
            // style={{
            //   color: "var(--color-gray-500)",
            // }}
          >
            {title && (
              <h6
              // style={{
              //   color: "var(--color-gray-200)",
              // }}
              >
                {title}
                {contacts.length}
              </h6>
            )}
          </p>
          {icon ? (
            <span
              onClick={() => setShowAll(true)}
              // style={{
              //   color: "var(--color-gray-300)",
              // }}
            >
              {icon}
            </span>
          ) : (
            <span
              onClick={() => setShowAll(true)}
              // style={{
              //   color: "var(--color-gray-300)",
              // }}
              className="material-symbols-outlined"
            >
              keyboard_arrow_right
            </span>
          )}
        </div>
      )}

      <div
        style={{
          width: "100%",
          padding: "0 16px 8px 16px",
          boxSizing: "border-box",
        }}
      >
        {visibleContacts?.map((contactItem, index) => (
          <div
            key={contactItem.key || index}
            style={{
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "end",
              boxSizing: "border-box",
            }}
          >
            <p
              className="p-light"
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "20ch" : "40ch",
                // color: "var(--color-gray-500)",
                boxSizing: "border-box",
              }}
            >
              {fildname && contactItem[fildname]}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};
