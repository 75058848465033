import React, { useEffect, useState } from "react";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { truncateText } from "../../../../../functions/truncateText";
import { dateFormat } from "../../../../../functions/convertDate";
import PriorityFile from "../../../../UIComponents/UploadFiles/PriorityFile";

const WidgetBikeItemDataV4 = React.memo(
  ({
    item,
    index,
    groupItems,
    onUpdateQuantity,
    onRemoveItem,
    handleOpenWindowReplacementBikeV4,
    isMobile,
  }) => {
    const singleBikeCost = item?.totalCostBike / (item?.totals?.quantity || 1);
    const updatedTotalCostBike = singleBikeCost * item.totals.quantity;
    const updatedTotalCost =
      updatedTotalCostBike + (item.totalCostAditioalServices || 0);

    const styles = {
      tagBike: {
        backgroundColor: "var(--color-gray-100)",
        padding: "2px 8px",
        borderRadius: "4px",
      },
      selectParams: {
        color: "var(--color-black)",
        fontWeight: "600",
        whiteSpace: "nowrap",
      },
    };
    return (
      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          //   padding: "16px",
          borderRadius: "16px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          padding: "16px",
          //   borderRadius:
          //     groupItems.length === 1
          //       ? "16px"
          //       : `${
          //           index === 0
          //             ? "16px 16px 0 0"
          //             : index === groupItems.length - 1
          //             ? "0 0 16px 16px"
          //             : "0"
          //         }`,
        }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
            width: "100%",
          }}
        >
          {/* Фото */}
          <div
            style={{
              width: "100%",
              aspectRatio: "16/9",
            }}
          >
            <PriorityFile
              files={item.bikeData.files.photoFiles || []}
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          </div>
          {/* Название */}
          <h6>
            {truncateText(
              item?.bikeData?.basicInformation?.title?.titleOriginal,
              32
            )}
          </h6>
        </div>

        {/* Данные резерва */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
          }}
        >
          {/* Диапазон дат */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light" style={styles.selectParams}>
              {dateFormat(item?.totals?.dateInterval[0] || 0, isMobile)}
            </p>

            <div
              style={{
                width: "100%",
                backgroundColor: "var(--color-gray-200)",
                height: "1px",
              }}
            />
            <p className="p-light" style={styles.selectParams}>
              {dateFormat(item?.totals?.dateInterval[1] || 0, isMobile)}
            </p>
          </div>
          {/* Колличество дней */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light">
              Day
              {item?.totals?.daysInInterval > 1 && "s"}
            </p>
            <span
              style={{
                width: "100%",
                backgroundColor: "var(--color-gray-200)",
                height: "1px",
              }}
            />
            <p className="p-light" style={styles.selectParams}>
              {item?.totals?.daysInInterval || 0}
            </p>
          </div>
          {/* Колличество байуов */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <p className="p-light" style={{ whiteSpace: "nowrap" }}>
              Number of bikes
            </p>
            <span
              style={{
                width: "100%",
                backgroundColor: "var(--color-gray-200)",
                height: "1px",
              }}
            />
            <p className="p-light" style={styles.selectParams}>
              {item?.totals?.quantity || 1}
            </p>
          </div>
        </div>
        {/* Цена байка */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "8px",
          }}
        >
          <p
            className="p-light"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            Total cost bike
            {item?.totals?.daysInInterval > 1 && "s"}
          </p>
          <span
            style={{
              width: "100%",
              backgroundColor: "var(--color-gray-200)",
              height: "1px",
            }}
          />
          <p className="p-light" style={styles.selectParams}>
            {formatCurrencyUSD(item?.totals?.totalCostBike || 0)}
          </p>
        </div>

        {/* Дополнительные сервисы */}

        {item?.orderedAditionalServices?.length > 0 && (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {item?.orderedAditionalServices?.map((service, idx) => (
                <div
                  key={service?.id || idx}
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "8px",
                  }}
                >
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <p
                      className="p-light"
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {truncateText(service?.title?.titleOriginal, 55)}
                    </p>
                    <span
                      style={{
                        color: "var(--color-gray-400)",
                      }}
                      className="material-symbols-outlined p-light"
                    >
                      close_small
                    </span>
                    <p
                      className="p-light"
                      style={{
                        color: "var(--color-gray-400)",
                      }}
                    >
                      {service?.countOrderedServices}
                    </p>
                  </span>

                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "var(--color-gray-200)",
                      height: "1px",
                    }}
                  />
                  <p className="p-light" style={styles.selectParams}>
                    {formatCurrencyUSD(
                      service?.price * service?.countOrderedServices || 0
                    )}
                  </p>
                </div>
              ))}
            </div>

            {/* Итоговая стоимость */}
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <p className="p-light" style={{ whiteSpace: "nowrap" }}>
                Total cost:
              </p>
              <span
                style={{
                  width: "100%",
                  backgroundColor: "var(--color-gray-200)",
                  height: "1px",
                }}
              />
              <p className="p-light" style={styles.selectParams}>
                {formatCurrencyUSD(item?.totals?.total || 0)}
              </p>
            </div>
          </>
        )}
      </div>
    );
  }
);

export default WidgetBikeItemDataV4;
