import React, { useState } from "react";
import "../profile.css";
import AddData from "../../../../UIComponents/AddData/AddData";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowAditionalServiceDetailModeration from "../Moderation/WindowAditionalServiceDetailModeration";
import { useDispatch } from "react-redux";
import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import PreviewItemCard from "../../../../UIComponents/Preview/PreviewItemCard";

const WidgetAditionalServicesV4 = ({
  mode,
  additionalServices,
  isChangeState,
}) => {
  const dispatch = useDispatch();
  const [modeAdded, setModeAdded] = useState(null);
  const [isOpenWindowAddAditionalService, setIsOpenWindowAddAditionalService] =
    useState(false);

  const [
    isOpenWindowAddAditionalServiceModeration,
    setIsOpenWindowAddAditionalServiceModeration,
  ] = useState(false);

  // Состояния для редактирования существующей услуги
  const [selectedAditionalService, setSelectedAditionalService] = useState({});
  const [
    isOpenWidgetEditAditionalServiceModeration,
    setIsOpenWidgetEditAditionalServiceModeration,
  ] = useState(false);

  const handleCloseWidgetAddAditionalService = () => {
    setIsOpenWindowAddAditionalService(false);
    setIsOpenWindowAddAditionalServiceModeration(false);
  };

  const handleCloseWidgetEditAditionalService = () => {
    setIsOpenWidgetEditAditionalServiceModeration(false);
  };

  // const handleToggleWidgetAddAditionalService = () => {
  //   dispatch(
  //     setCurrentRootData({
  //       rootCurrentDocumentState: "previewSeletedRentalPoint",
  //       data: null,
  //     })
  //   );
  //   setIsOpenWindowAddAditionalServiceModeration(true);
  //   setModeAdded(mode); // Используем текущий режим вместо жесткого значения

  //   // Очищаем выбранную услугу при добавлении новой
  // };

  const isOpenWindowAditionalServiceDetailModeration = (
    additionalServiceItem
  ) => {
    // Сохраняем выбранную услугу в Redux, чтобы WindowAditionalServiceDetailModeration мог ее получить
    dispatch(
      setTempData({
        tempCurrentDocumentState: "previewSeletedRentalPoint",
        data: additionalServiceItem,
      })
    );

    setIsOpenWidgetEditAditionalServiceModeration(true);
  };

  // Сортировка массива additionalServices по полю updateat, если оно существует
  const sortedAdditionalServices =
    additionalServices && additionalServices.length > 0
      ? [...additionalServices].sort((a, b) => {
          // Проверяем наличие поля updateat в обоих объектах
          if (a.updateat && b.updateat) {
            // Сортировка по убыванию (от новых к старым)
            return b.updateat - a.updateat;
          }
          // Если поле updateat отсутствует, оставляем порядок без изменений
          return 0;
        })
      : additionalServices;

  return (
    <>
      {/* POPUP WINDOWS */}

      <PopupDirrectory
        isOpen={isOpenWidgetEditAditionalServiceModeration}
        onClose={handleCloseWidgetEditAditionalService}
        title="Edit aditional services moderation"
      >
        <WindowAditionalServiceDetailModeration
          // selectedAditionalService={selectedAditionalService}
          onClose={handleCloseWidgetEditAditionalService}
          isChangeState={isChangeState}
          mode={mode}
        />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}

      <div className="profile-widget__content-box">
        <AddData
          icon={<span className="material-symbols-outlined">add</span>}
          title="Add aditional services"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
          onClick={() => isOpenWindowAditionalServiceDetailModeration(null)}
        />

        {/* Список дополнительных услуг (перенесено из WidgetAditionalServicesListV4) */}
        {sortedAdditionalServices?.length > 0 && (
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            {sortedAdditionalServices?.map((additionalServiceItem, index) => (
              <div key={additionalServiceItem?.id || index}>
                <PreviewItemCard
                  index={index}
                  length={sortedAdditionalServices?.length}
                  title={
                    <span>
                      {additionalServiceItem?.title?.titleOriginal}&nbsp;—&nbsp;
                      {additionalServiceItem?.counter || 0}
                      &nbsp;PC
                    </span>
                  }
                  // description={
                  //   additionalServiceItem?.description?.descriptionOriginal
                  // }
                  price={additionalServiceItem?.price}
                  // LEFT ICIN
                  iconLeft={
                    !additionalServiceItem?.isActive ? (
                      <span className="material-symbols-outlined">
                        disabled_visible
                      </span>
                    ) : additionalServiceItem?.remove ? (
                      <span className="material-symbols-outlined">delete</span>
                    ) : (
                      <span className="material-symbols-outlined">sell</span>
                    )
                  }
                  iconLeftStyle={{
                    color: additionalServiceItem?.remove
                      ? "var(--color-alarm)"
                      : "var(--color-black)",
                  }}
                  containerIconLeftStyle={{
                    backgroundColor: additionalServiceItem?.remove
                      ? "var(--color-alarm-alpha-30)"
                      : !additionalServiceItem?.isActive
                      ? "var(--color-warning-alpha-30)"
                      : "var(--color-gray-900-alpha-30)",
                  }}
                  // CONTAINER CARD
                  containerCardStyle={{
                    backgroundColor: additionalServiceItem?.remove
                      ? "var(--color-alarm-alpha-30)"
                      : !additionalServiceItem?.isActive
                      ? "var(--color-warning-alpha-30)"
                      : "var(--color-gray-900-alpha-30)",
                  }}
                  onClick={() =>
                    isOpenWindowAditionalServiceDetailModeration(
                      additionalServiceItem
                    )
                  }
                >
                  <p>
                    {additionalServiceItem?.price &&
                    !additionalServiceItem?.isFreePrice
                      ? formatCurrencyUSD(additionalServiceItem?.price)
                      : ""}
                  </p>
                </PreviewItemCard>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default WidgetAditionalServicesV4;
