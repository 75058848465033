import React, { useState, useEffect } from "react";
import DropdownList from "../../../../UIComponents/DropdownList/DropdownList";
import { useDispatch } from "react-redux";

import {
  setCurrentRootData,
  setTempData,
} from "../../../../../reducersToolkit/slice/catalogSliceV4";

const WidgetBrandCategoryModeration = ({
  mode,
  brands,
  categories,
  initialSelectedBrand,
  initialSelectedCategory,
  isChangeState,
}) => {
  const dispatch = useDispatch();
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [brandOptions, setBrandOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);

  /**
   *
   * CRUD функции
   *
   */
  // Сохрание в slice данных о бренде
  const selectBrand = (brand) => {
    // if (mode === "new" || mode === "moderatorAdded") {
    //   dispatch(
    //     setCurrentRootData({
    //       rootCurrentDocumentState: "newRootBike_create.directory.brand",
    //       data: brand,
    //     })
    //   );
    // } else if (mode === "edit") {
    //   //  Добавить корректный dispatch
    // } else if (mode === "moderation") {
    // dispatch(
    //   setCurrentRootData({
    //     rootCurrentDocumentState: "selectedBike_rootData.directory.brand",
    //     data: brand,
    //   })
    // );
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_brand",
        data: brand,
      })
    );
    // }

    // Устанавливаем флаг изменения бренда/категории
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
  };

  // Сохрание в slice данных о категории
  const selectCategory = (category) => {
    // if (mode === "new" || mode === "moderatorAdded") {
    //   dispatch(
    //     setTempData({
    //       tempCurrentDocumentState: "tempData_category",
    //       data: category,
    //     })
    //   );
    // } else if (mode === "edit") {
    //   //  Добавить корректный dispatch
    // } else if (mode === "moderation") {
    // dispatch(
    //   setCurrentRootData({
    //     rootCurrentDocumentState: "selectedBike_rootData.directory.category",
    //     data: category,
    //   })
    // );
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_category",
        data: category,
      })
    );
    // }

    // Устанавливаем флаг изменения бренда/категории
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
  };
  /**
   *
   * Логика компонента
   *
   */
  useEffect(() => {
    // Формируем массив объектов для брендов (Требуется определенная структура длдя универсальной работы компонента)
    const formattedBrands = brands?.map((brand) => ({
      id: brand?.idRootDocument,
      title: brand?.title,
    }));
    setBrandOptions(formattedBrands);

    // Формируем массив объектов для категорий (Требуется определенная структура длдя универсальной работы компонента)
    const formattedCategories = categories?.map((category) => ({
      id: category?.idRootDocument,
      title: category?.title,
    }));
    setCategoryOptions(formattedCategories);

    // Находим выбранный бренд
    const initialBrand = formattedBrands?.find(
      (brand) => brand.id === initialSelectedBrand?.id
    );

    // Находим выбранную категорию
    const initialCategory = formattedCategories?.find(
      (category) => category.id === initialSelectedCategory?.id
    );

    setSelectedBrand(initialBrand);
    setSelectedCategory(initialCategory);
  }, [brands, categories, initialSelectedBrand, initialSelectedCategory]);

  // Кнопки
  const controlSelectBrand = (brand) => {
    selectBrand(brand);
    setSelectedBrand(brand);
  };
  const controlSelectCategory = (category) => {
    selectCategory(category);
    setSelectedCategory(category);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        gap: "8px",
        width: "100%",
      }}
    >
      <DropdownList
        options={brandOptions}
        onSelect={controlSelectBrand}
        label="Select Brand"
        description="Please select a brand."
        fieldView="title"
        fieldId="id"
        initialSelectedOption={selectedBrand}
      />
      <DropdownList
        options={categoryOptions}
        onSelect={controlSelectCategory}
        label="Select Category"
        description="Please select a category."
        fieldView="title"
        fieldId="id"
        initialSelectedOption={selectedCategory}
      />
    </div>
  );
};

export default WidgetBrandCategoryModeration;
