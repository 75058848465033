import React, { useEffect, useState } from "react";
import Counter from "../../../../UIComponents/Counter/Counter";
import { useDispatch } from "react-redux";

/**
 * Компонент для управления количеством велосипедов с поддержкой сброса
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {string} props.mode - Режим работы компонента
 * @param {Object} props.metricsBike - Метрики велосипеда с количеством
 * @param {Function} props.setQuantityBikes - Функция обратного вызова для установки количества велосипедов
 * @param {Function} props.isChangeState - Функция обратного вызова для отслеживания изменений
 * @param {boolean} props.isReset - Флаг сброса счётчика к начальному значению
 * @param {Function} props.setIsReset - Функция установки флага сброса
 * @returns {JSX.Element} Компонент счётчика для количества велосипедов
 */
const WidgetQuntityBikeModeration = ({
  mode,
  metricsBike,
  setQuantityBikes,
  isChangeState,
  isReset,
  setIsReset,
}) => {
  const dispatch = useDispatch();
  const [counterValue, setCounterValue] = useState(0);
  const [initialQuantity, setInitialQuantity] = useState(0); // Сохраняем начальное значение

  // Синхронизация counterValue с metricsBike.quantityBikes
  useEffect(() => {
    const initialValue = metricsBike?.quantityBikes ?? 0;
    setCounterValue(initialValue);
    setInitialQuantity(initialValue); // Устанавливаем начальное значение
    setQuantityBikes(initialValue); // Устанавливаем начальное значение как число
    if (typeof isChangeState === "function") {
      isChangeState(false); // Сбрасываем состояние изменения при синхронизации
    }
  }, [metricsBike, setQuantityBikes, isChangeState]);

  // Обработка сброса счётчика при isReset === true
  useEffect(() => {
    if (isReset) {
      // Сбрасываем счётчик к начальному значению
      const initialValue = metricsBike?.quantityBikes ?? 0;
      setCounterValue(initialValue);
      setQuantityBikes(initialValue);

      // Уведомляем родительский компонент об отсутствии изменений
      if (typeof isChangeState === "function") {
        isChangeState(false);
      }

      // Сбрасываем флаг isReset обратно в false
      if (typeof setIsReset === "function") {
        setIsReset(false);
      }
    }
  }, [isReset, metricsBike, setQuantityBikes, isChangeState, setIsReset]);

  // Обработчик изменения значения Counter
  const handleCounterChange = (newValue) => {
    setCounterValue(newValue);
    setQuantityBikes(newValue); // Передаем число в родительский компонент
    if (typeof isChangeState === "function") {
      isChangeState(newValue !== initialQuantity); // Устанавливаем true, если значение изменилось
    }
  };

  return (
    <Counter
      minValue={0}
      maxValue={100}
      value={counterValue}
      onChange={handleCounterChange}
      label="Quantity bikes"
      description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
    />
  );
};

export default WidgetQuntityBikeModeration;
