import React from "react";
import { useSelector } from "react-redux";
import ContentBoxV4 from "../../UIComponents/ContentBox/ContentBoxV4";

const WidgetNavigateUser = ({ onClose, language, role }) => {
  const { tempRootData } = useSelector((state) => state.catalogSliceV4);

  // Если пользователь не авторизован или это не админ
  if (!role || role !== "admin") {
    return null;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "16px",
      }}
    >
      <ContentBoxV4
        key="/profile"
        label="Profile"
        icon={
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "3rem",
            }}
          >
            account_circle
          </span>
        }
        execute={`/${language}/profile`}
        style={{
          padding: "16px",
          boxSizing: "border-box",
          borderRadius: "16px",
          backgroundColor: "var(--color-gray-100)",
          aspectRatio: "1/1",
        }}
        onClickAfter={onClose}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <h1 style={{ fontWeight: "100" }}>Admin</h1>
          <p>panel</p>
        </div>
      </ContentBoxV4>
      <ContentBoxV4
        key="/notifications"
        label="Notifications"
        icon={
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "3rem",
            }}
          >
            notifications
          </span>
        }
        execute={`/${language}/notifications`}
        style={{
          padding: "16px",
          boxSizing: "border-box",
          borderRadius: "16px",
          backgroundColor: "var(--color-gray-100)",
          aspectRatio: "1/1",
        }}
        onClickAfter={onClose}
      />
      <ContentBoxV4
        key="/basket"
        label="Basket"
        icon={
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "3rem",
            }}
          >
            shopping_cart
          </span>
        }
        execute={`/${language}/basket`}
        style={{
          padding: "16px",
          boxSizing: "border-box",
          borderRadius: "16px",
          backgroundColor: "var(--color-gray-100)",
          aspectRatio: "1/1",
        }}
        onClickAfter={onClose}
      />
      <ContentBoxV4
        key="/userProfile"
        label="User Profile"
        icon={
          <span
            className="material-symbols-outlined"
            style={{
              fontSize: "3rem",
            }}
          >
            person
          </span>
        }
        execute={`/${language}/userProfile`}
        style={{
          padding: "16px",
          boxSizing: "border-box",
          borderRadius: "16px",
          backgroundColor: "var(--color-gray-100)",
          aspectRatio: "1/1",
        }}
        onClickAfter={onClose}
      />
    </div>
  );
};

export default WidgetNavigateUser;
