import AnimatedDiv from "../Animation/AnimatedDiv";

const SkeletonAddData = () => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "16px",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          height: "48px",
          width: "48px",
          aspectRatio: "1/1",
          borderRadius: "16px",
          overflow: "hidden",
          boxSizing: "border-box",
        }}
      >
        <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          width: "70%",
        }}
      >
        <div
          style={{
            width: "100%",
            backgroundColor: "var(--color-gray-100)",
            height: "8px",
            borderRadius: "100px",
            overflow: "hidden",
          }}
        >
          <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
        </div>
        <div
          style={{
            width: "50%",
            backgroundColor: "var(--color-gray-100)",
            height: "8px",
            borderRadius: "80px",
            overflow: "hidden",
          }}
        >
          <AnimatedDiv $delay={Math.floor(Math.random() * 1000)} />
        </div>
      </div>
    </div>
  );
};

export default SkeletonAddData;
