import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Компоненты UI
import PageBox from "../../../../UIComponents/ContentBox/PageBox";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import Button from "../../../../UIComponents/Buttons/Button";

// Виджеты
import WidgetUserPreview from "../UserProfile/WidgetUserPreview";
import WidgetUserModerationV4 from "./WidgetUserModerationV4";
import WidgetRentalPointsListV4 from "./WidgetRentalPointsListV4";
import WidgetAllertDelete from "./WidgetAllertDelete";

// Actions, thunks и селекторы Redux
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import { fetchRootDataThunkV4 } from "../../../../../reducersToolkit/thunk/fetchRootDataThunkV4";
import { updateDataThunkV4 } from "../../../../../reducersToolkit/thunk/updateDataThunkV4";
import { deleteDataThunkV4 } from "../../../../../reducersToolkit/thunk/deleteDataThunkV4";
import {
  subscribePreviewDocumentsThunkV4,
  unsubscribePreviewDocumentsThunkV4,
} from "../../../../../reducersToolkit/thunk/subscribePreviewDocumentsThunkV4";
import {
  subscribeRootDocumentsThunkV5,
  unsubscribeRootDocumentsThunkV5,
} from "../../../../../reducersToolkit/thunk/subscribeRootDocumentsThunkV5";
import SkeletonRentalPointAditionalServices from "./SkeletonRentalPointAditionalServices";
import SkeletonRentalPointsList from "./SkeletonRentalPointsList";
import SkeletonTitleDescription from "./SkeletonBikeModeration";
import SkeletonUserPreview from "./SkeletonUserPreview";
import WidgetRentalPointModeration from "./WidgetRentalPointModeration";

/**
 * Компонент отображения детализации пользователя и функций модерации.
 * Позволяет просматривать данные пользователя, модерировать их и управлять
 * точками проката, связанными с пользователем.
 *
 * @param {Array} breadcrumbPath - Массив с данными для навигационных крошек
 * @param {string} title - Заголовок страницы
 */
const PageUserDetailModerationV4 = ({ breadcrumbPath, title }) => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const navigate = useNavigate();

  // 1. ПОЛУЧЕНИЕ ДАННЫХ ИЗ SLICE

  const { loading, tempRootData, tempPreviewData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  // 2. ФОРМИРОВАНИЕ СТЕКА СТЕЙТОВ

  // Данные пользователя
  const [userRootData, setUserRootData] = useState(null);
  const [userPreviewData, setUserPreviewData] = useState(null);

  // Фильтрованный список точек проката текущего пользователя
  const [filteredRentalPoints, setFilteredRentalPoints] = useState([]);

  // Идентификаторы документов
  const [previewDocumentId, setPreviewDocumentId] = useState(null);

  // Модерация
  const [moderation, setModeration] = useState(null);
  const [commentsModerator, setCommentsModerator] = useState(null);
  const [userStatus, setUserStatus] = useState(null);
  const [deleteState, setDeleteState] = useState(null);

  // Состояния загрузки
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);

  // Флаги изменений контента
  const [isChangeUserModeration, setIsChangeUserModeration] = useState(false);
  const [isDeleteState, setIsDeleteState] = useState(null);

  // Состояние управления кнопками и сбросом
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(true);
  const [isReset, setIsReset] = useState(false);

  // Данные для CRUD операций
  const [editRootUserData, setEditRootUserData] = useState(null);
  const [editPreviewUserData, setEditPreviewUserData] = useState(null);

  // Состояния загрузки для CRUD
  const [isLoadingUpdateUser, setIsLoadingUpdateUser] = useState(false);
  const [isLoadingDeleteUser, setIsLoadingDeleteUser] = useState(false);

  // Коллекции уведомлений

  const [allRentalPointsPreviewData, setAllRentalPointsPreviewData] =
    useState(null);

  // 3. ИНИЦИАЛИЗАЦИЯ ДАННЫХ В SLICE

  // Инициализация slice для обмена данными между компонентами
  useEffect(() => {
    // Инициализируем временные данные для обмена между компонентами
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_userStatus",
        data: null,
      })
    );

    // Сброс всех флагов изменений при isReset = true
    if (isReset) {
      setIsChangeUserModeration(false);

      // Сброс флага сброса
      setIsReset(false);
    }
  }, [dispatch, isReset]);

  // 4. ЭФФЕКТЫ С USEEFFECT

  // Загрузка данных пользователя из корневой коллекции
  useEffect(() => {
    if (userId) {
      dispatch(
        fetchRootDataThunkV4({
          idRootDocument: userId,
          rootCollectionPath: "users",
          rootCurrentDocumentState: "manager_selectedRootUserModeration",
          loadingStateName: "loading_selectedUserModeration",
          errorStateName: "currentError",
        })
      );
    }
  }, [dispatch, userId]);

  // Подписка на данные пользователя
  useEffect(() => {
    if (userId) {
      console.log(`Выполняем подписку на документ пользователя: ${userId}`);

      const subscribeToDocument = async () => {
        try {
          const result = await dispatch(
            subscribeRootDocumentsThunkV5({
              collectionPath: "users",
              rootCurrentDocumentState: "manager_selectedRootUserModeration",
              idRootDocument: userId,
              loadingStateName: "loading_selectedUserModeration",
              errorStateName: "currentError",
            })
          ).unwrap();

          if (result.subscriptionEstablished) {
            console.log("Подписка на документ пользователя выполнена успешно");
          } else {
            console.error("Подписка не была успешно установлена");
          }
        } catch (error) {
          console.error("Ошибка при подписке на документ:", error);
        }
      };

      subscribeToDocument();

      // Отписка при размонтировании компонента
      return () => {
        dispatch(
          unsubscribeRootDocumentsThunkV5({
            collectionPath: "users",
          })
        );
      };
    }
  }, [dispatch, userId]);

  // Подписка на точки проката пользователя
  useEffect(() => {
    if (userId) {
      const collectionPath = `manager_rentalPointsPrevirew.userIds.${userId}`;
      const previewCurrentDocumenState = "user_rentalPoints";
      const excludedIds = ["metadata"];
      const loadingStateName = "loading_subscribe_rentalPointsPreview";
      const errorStateName = "currentError";

      console.log(
        `Выполняем подписку для точек проката пользователя: ${collectionPath}`
      );

      // Подписка при монтировании компонента
      dispatch(
        subscribePreviewDocumentsThunkV4({
          collectionPath,
          previewCurrentDocumenState,
          excludedIds,
          loadingStateName,
          errorStateName,
        })
      )
        .unwrap()
        .then((result) => {
          console.log("Подписка на точки проката установлена", result);
        })
        .catch((error) => {
          console.error("Ошибка при подписке на точки проката", error);
        });

      // Отписка при размонтировании компонента
      return () => {
        dispatch(unsubscribePreviewDocumentsThunkV4(collectionPath))
          .unwrap()
          .then((result) => {
            console.log("Отписка выполнена", result);
          })
          .catch((error) => {
            console.error("Ошибка при отписке", error);
          });
      };
    }
  }, [dispatch, userId]);

  // Отслеживание состояний загрузки данных пользователя
  useEffect(() => {
    setIsUserDataLoading(loading?.loading_selectedUserModeration || false);
  }, [loading?.loading_selectedUserModeration]);

  // Обновление данных из Redux в локальные стейты
  useEffect(() => {
    if (tempRootData?.manager_selectedRootUserModeration) {
      const userData = tempRootData.manager_selectedRootUserModeration;
      setUserRootData(userData);

      // Обновляем состояние пользователя из структуры statusHistory
      setUserStatus(userData?.statusHistory?.currentStatus || "moderation");
      setCommentsModerator(
        tempData?.tempData_userStatus?.comments ||
          userData?.statusHistory?.comments ||
          []
      );

      // Другие обновления состояний
      setModeration(userData.statusHistory || null);
      setIsDeleteState(!!userData?.remove || null);
      setDeleteState(userData?.remove || null);
    }
  }, [
    tempData?.tempData_userStatus?.comments,
    tempRootData?.manager_selectedRootUserModeration,
  ]);

  // Загрузка данных пользователя из preview коллекции
  useEffect(() => {
    setPreviewDocumentId(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData
        ?.idPreviewDocument || null
    );

    setUserPreviewData(
      tempPreviewData?.user_moderation_selectedPreviewCurrentData || null
    );

    setAllRentalPointsPreviewData(
      tempPreviewData?.general_rentalPointsPreviewNotifications || null
    );
  }, [
    tempPreviewData?.general_rentalPointsPreviewNotifications,
    tempPreviewData?.user_moderation_selectedPreviewCurrentData,
    tempPreviewData?.general_usersPreviewNotifications,
  ]);

  // Фильтрация точек проката пользователя
  useEffect(() => {
    if (userId) {
      const userRentalPoints = tempPreviewData?.user_rentalPoints?.filter(
        (rentalPoint) => rentalPoint?.idRootDocumentUser === userId
      );

      setFilteredRentalPoints(userRentalPoints);
    } else {
      const userRentalPoints = tempPreviewData?.user_rentalPoints || [];
      setFilteredRentalPoints(userRentalPoints);
    }
  }, [userId, tempPreviewData?.user_rentalPoints]);

  // 5. ФОРМИРОВАНИЕ ОБЪЕКТОВ ДЛЯ CRUD

  // Формирование объектов для обновления пользователя
  useEffect(() => {
    // Получение текущего статуса из временных данных или из данных пользователя
    const tempUserStatus = tempData?.tempData_userStatus;
    const currentUserStatus = tempUserStatus?.currentStatus || userStatus;
    const currentComments = tempUserStatus?.comments || commentsModerator;

    // Объект для обновления ROOT
    if (userRootData) {
      setEditRootUserData({
        ...userRootData,
        statusHistory: {
          currentStatus: currentUserStatus,
          comments: currentComments || [],
        },
        updated: Date.now(),
      });
    }

    // Объект для обновления PREVIEW
    if (userPreviewData) {
      setEditPreviewUserData({
        ...userPreviewData,
        status: currentUserStatus,
        updated: Date.now(),
      });
    }
  }, [
    userRootData,
    userPreviewData,
    userStatus,
    commentsModerator,
    tempData?.tempData_userStatus,
  ]);

  // Отслеживание изменений для активации кнопок
  useEffect(() => {
    // Проверяем, есть ли хотя бы одно изменение
    const hasAnyChanges =
      isChangeUserModeration || tempData?.tempData_userStatus !== null;

    // Устанавливаем активность кнопки
    setIsButtonActive(hasAnyChanges);
  }, [isChangeUserModeration, tempData?.tempData_userStatus]);
  console.log("tempData?.tempData_userStatus", tempData?.tempData_userStatus);
  // 6. РЕАЛИЗАЦИЯ CRUD

  /**
   * Обновление пользователя
   * @async
   */
  const updateUser = async () => {
    if (!userId) return;

    try {
      setIsLoadingUpdateUser(true);
      setIsDeleteButtonActive(false);

      // Проверяем, что у нас есть необходимые данные для обновления
      if (!editRootUserData || !editPreviewUserData) {
        console.error("Missing data for user update");
        return;
      }

      await dispatch(
        updateDataThunkV4({
          rootData: editRootUserData,
          previewData: editPreviewUserData,
          idPreviewDocument: previewDocumentId,
          idRootDocument: userId,
          rootCollectionPath: "users",

          previewCollectionPath: "manager_usersPreview", // Путь к preview-коллекции
          previewGeneralCollectionPath: "general_usersPreview",

          metadataDocumentPath: "manager_usersPreview.metadata", // Путь к метаданным
          metadataGeneralDocumentPath: "general_usersPreview.metadata",

          rootCurrentDocumentState: "manager_selectedRootUserModeration",
          previewCurrentDocumenState:
            "user_moderation_selectedPreviewCurrentData",
          loadingStateName: "loading_updateUserData",
          errorStateName: "currentError",
          isChangeStat: null,
        })
      ).unwrap();

      // Сброс временных данных после успешного обновления
      dispatch(
        setTempData({
          tempCurrentDocumentState: "tempData_userStatus",
          data: null,
        })
      );

      // Сброс всех флагов изменений после успешного обновления
      setIsChangeUserModeration(false);

      console.log(`Пользователь успешно обновлен ${userId}`);
    } catch (error) {
      console.error("Ошибка при обновлении пользователя:", error);
    } finally {
      setIsLoadingUpdateUser(false);
      setIsDeleteButtonActive(true);
    }
  };

  /**
   * Удаление пользователя
   * @async
   */
  const deleteUser = async () => {
    if (!userId) return;

    try {
      setIsLoadingDeleteUser(true);
      setIsDeleteButtonActive(false);

      await dispatch(
        deleteDataThunkV4({
          // idPreviewDocument: previewDocumentId,
          idRootDocument: userId,
          rootCollectionPath: "users",

          previewCollectionPath: "manager_usersPreview", // Путь к preview-коллекции
          previewGeneralCollectionPath: "general_usersPreview",

          metadataDocumentPath: "manager_usersPreview.metadata", // Путь к метаданным
          metadataGeneralDocumentPath: "general_usersPreview.metadata",

          rootCurrentDocumentState: "manager_selectedRootUserModeration",
          previewCurrentDocumentState:
            "user_moderation_selectedPreviewCurrentData",
          // previewGeneralDocumentState: "general_usersPreviewNotifications",
          loadingStateName: "loading_deleteUser",
          errorStateName: "currentError",
        })
      ).unwrap();

      console.log("Пользователь успешно удален");

      // После успешного удаления выполняем переадресацию
      // navigate("/ModerationUsers");
    } catch (error) {
      console.error("Ошибка при удалении пользователя:", error);
    } finally {
      setIsLoadingDeleteUser(false);
      setIsDeleteButtonActive(true);
    }
  };

  // 7. ВНУТРЕННЯЯ ЛОГИКА

  // Обработчики событий
  const handleCancel = () => {
    setIsReset(true);

    // Сбрасываем данные модерации
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_userStatus",
        data: null,
      })
    );
  };

  const handleUpdateUser = () => {
    setIsButtonActive(false);
    updateUser();
  };

  const handleDeleteUser = () => {
    deleteUser();
  };

  // 8. РЕНДЕРИНГ КОМПОНЕНТА

  return (
    <>
      {/* Основной контент */}
      <PageBox path={breadcrumbPath} title={title}>
        {/* Состояние пользователя для удаления */}
        {isDeleteState && (
          <ContentBox
            style={{
              backgroundColor: "var(--color-alarm)",
              padding: "8px 0",
            }}
          >
            <WidgetAllertDelete
              title="Удаляем пользователя"
              onClick={deleteUser}
              deleteState={deleteState}
            />
          </ContentBox>
        )}

        {/* Блок с основной информацией и модерацией */}
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          {/* Предпросмотр пользователя */}
          <ContentBox
            position="end"
            label="User Preview"
            isLoading={
              isUserDataLoading || isLoadingUpdateUser || isLoadingDeleteUser
            }
            skeleton={<SkeletonUserPreview />}
          >
            <WidgetUserPreview user={userRootData} />
          </ContentBox>
          {/* Модерация пользователя */}
          <ContentBox
            position="start"
            label="Moderation"
            isLoading={
              isUserDataLoading || isLoadingUpdateUser || isLoadingDeleteUser
            }
            skeleton={<SkeletonTitleDescription />}
          >
            <WidgetUserModerationV4
              mode="moderation"
              comments={commentsModerator}
              isChangeState={setIsChangeUserModeration}
            />
          </ContentBox>
        </div>

        {/* Список точек проката пользователя */}
        <ContentBox
          label="Rental Points List"
          isLoading={
            isUserDataLoading || isLoadingUpdateUser || isLoadingDeleteUser
          }
          skeleton={
            <SkeletonRentalPointsList
              additionalServices={filteredRentalPoints}
            />
          }
        >
          {/* Список точек проката */}
          <WidgetRentalPointsListV4
            rentalPointsList={filteredRentalPoints}
            mode="moderation"
            userId={userId}
          />
        </ContentBox>
      </PageBox>

      {/* Панель кнопок внизу страницы */}
      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          {deleteState ? (
            <Button
              type="small"
              color="--color-success"
              label="Restore"
              active={isDeleteButtonActive && !isButtonActive}
              onClick={handleDeleteUser}
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>Восстанавливаем пользователя?</h1>
                </div>
              }
            />
          ) : (
            <Button
              type="small"
              color="--color-alarm"
              label="Delete"
              active={isDeleteButtonActive && !isButtonActive}
              onClick={handleDeleteUser}
              allert={
                <div
                  style={{
                    padding: "48px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: "16px",
                    textAlign: "center",
                  }}
                >
                  <h1>Удаляем пользователя?</h1>
                </div>
              }
            />
          )}

          {/* Кнопка отмены */}
          <Button
            type="small"
            active={isButtonActive}
            label="Отмена"
            color="--color-black"
            onClick={handleCancel}
            allert={
              <h1
                style={{
                  padding: "80px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to discard all changes?
              </h1>
            }
          />

          {/* Кнопка обновления */}
          <Button
            type="small"
            label="Update"
            color="--color-primary"
            active={isButtonActive}
            onClick={handleUpdateUser}
            allert={
              <div
                style={{
                  padding: "24px",
                  boxSizing: "border-box",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "16px",
                }}
              >
                <h1>Are you sure you want to update this user?</h1>
                <p className="p-light" style={{ width: "70%" }}>
                  You can always change the status of the user in case of
                  violation of any conditions.
                </p>
              </div>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default PageUserDetailModerationV4;
