import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CarouselMenu from "../../UIComponents/Carousel/CarouselMenu";
import DropdownFilterItem from "../../UIComponents/DropdownList/DropdownFilterItem";
import useIsMobile from "../../../functions/isMobile";
import { setTempData } from "../../../reducersToolkit/slice/catalogSliceV4";
import { fetchPreviewDataV4 } from "../../../reducersToolkit/thunk/fetchPreviewDataV4";
import { deepEqual } from "../../../functions/utils";
import debounce from "lodash/debounce";
import PopupDirrectory from "../../UIComponents/Popups/PopupDirrectory";
import WindowCalendarV4 from "../../UIComponents/Date/WindowCalendarV4";
import WindowOrderQuantityBikesV4 from "../../Private/Login/Profile/OrdersV3/WindowOrderQuantityBikesV4";

// const getDateInterval = (interval, month, useCurrentDay = false) => {
//   const currentDate = new Date();
//   const currentYear = currentDate.getFullYear();
//   // Получаем текущий месяц
//   const currentMonth = currentDate.getMonth();
//   const currentDay = currentDate.getDate();

//   if (interval && Array.isArray(interval) && interval[0] && interval[1]) {
//     return interval;
//   }

//   const monthNumber =
//     month && !isNaN(Number(month)) ? Number(month) : currentMonth;
//   let start, end;

//   if (useCurrentDay && monthNumber === currentDate.getMonth()) {
//     start = new Date(currentYear, monthNumber, currentDay);
//     start.setHours(0, 0, 0, 0);
//     end = new Date(start.getTime() + 6 * 24 * 60 * 60 * 1000);
//     end.setHours(23, 59, 59, 999);
//   } else {
//     const adjustedYear =
//       monthNumber && monthNumber < currentDate.getMonth()
//         ? currentYear + 1
//         : currentYear;
//     start = new Date(adjustedYear, monthNumber, 1);
//     start.setHours(0, 0, 0, 0);
//     end = new Date(start);
//     end.setDate(start.getDate() + 6);
//     end.setHours(23, 59, 59, 999);
//   }

//   return [Math.floor(start.getTime()), Math.floor(end.getTime())];
// };

const getDifferenceInDays = (interval) => {
  if (!interval || !Array.isArray(interval) || !interval[0] || !interval[1]) {
    return 0;
  }

  const [startTimestamp, endTimestamp] = interval;
  const start = new Date(startTimestamp);
  const end = new Date(endTimestamp);
  const millisecondsInDay = 24 * 60 * 60 * 1000;
  return Math.ceil((end - start) / millisecondsInDay) || 0;
};

const getComputedDateInterval = (dateInterval, month) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  // Получаем текущий месяц
  const currentMonth = currentDate.getMonth();
  const currentDay = currentDate.getDate();

  // Определяем номер месяца с приоритетом
  const monthNumber =
    month && !isNaN(Number(month)) ? Number(month) : currentMonth;

  if (dateInterval && !isNaN(dateInterval[0]) && !isNaN(dateInterval[1])) {
    return dateInterval;
  }

  let firstDayTimestamp, sevenDaysLaterTimestamp;
  if (monthNumber === currentDate.getMonth()) {
    const firstDayOfMonth = new Date(currentYear, monthNumber, currentDay);
    firstDayTimestamp = firstDayOfMonth.setHours(0, 0, 0, 0);
    const sevenDaysLater = new Date(firstDayTimestamp);
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 6);
    sevenDaysLaterTimestamp = sevenDaysLater.setHours(23, 59, 59, 999);
  } else {
    const adjustedYear =
      monthNumber < currentDate.getMonth() ? currentYear + 1 : currentYear;
    const firstDayOfMonth = new Date(adjustedYear, monthNumber, 1);
    firstDayTimestamp = firstDayOfMonth.setHours(0, 0, 0, 0);
    const sevenDaysLater = new Date(firstDayTimestamp);
    sevenDaysLater.setDate(sevenDaysLater.getDate() + 6);
    sevenDaysLaterTimestamp = sevenDaysLater.setHours(23, 59, 59, 999);
  }

  return [firstDayTimestamp, sevenDaysLaterTimestamp];
};

const styles = {
  locatopnCountry: { borderRadius: "80px 0 0 80px" },
  locatopnCity: { borderRadius: "0 80px 80px 0" },
  paddingFilter: { padding: "4px 4px 4px 16px" },
  paddingIcon: { padding: "4px 4px 4px 4px" },
  optionsStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "var(--color-gray-100)",
    borderRadius: "80px",
    boxSizing: "border-box",
    cursor: "pointer",
  },
  optionItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 4px 0 16px",
    height: "100%",
  },
  optionItemWithBorderRadius: {
    borderRadius: "80px",
    height: "100%",
    backgroundColor: "var(--color-black)",
  },
  optionItemWithBackgroundColor: { backgroundColor: "var(--color-gray-100)" },
  optionItemWithSpacing: {
    padding: "0 4px 0 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  optionItemWithRowSpacing: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 4px 0 16px",
  },
  optionItemWithMarginLeft: { marginLeft: "48px" },
  optionItemWithMarginRight: { marginRight: "48px" },
};

const ItemFilters = ({ title, param }) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      gap: "8px",
      width: "100%",
      color: "rgba(0, 0, 0, 0.8)",
    }}
  >
    {title}
    <div
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        color: "rgba(0, 0, 0, 0.9)",
        fontWeight: "600",
        height: "24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "0 8px",
        borderRadius: "16px",
      }}
    >
      {param}
    </div>
  </div>
);

const WidgetFilterBikes = ({
  countriesList,
  citiesList,
  brandsList,
  categoriesList,
  filterSettings,
  userSettings,
  onFilterChange,
}) => {
  console.log("filterSettings", filterSettings);

  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();

  const { language, countryId, cityId, brandId, categoryId, month } =
    useParams();

  // Состояния для модальных окон
  const [windowOrderDeteIntervalV4, setWindowOrderDeteIntervalV4] =
    useState(false);
  const [windowOrderQuantityBikesV4, setWindowOrderQuantityBikesV4] =
    useState(false);

  // Остальные состояния
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [brandsPreview, setBrandsPreview] = useState([]);
  const [categoriesPreview, setCategoriesPreview] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [
    rentalPoint_countriesListPreview,
    setRentalPoint_countriesListPreview,
  ] = useState([]);
  const [rentalPoint_citiesListPreview, setRentalPoint_citiesListPreview] =
    useState([]);
  const [dateInterval, setDateInterval] = useState(null);
  const [differenceInDays, setDifferenceInDays] = useState(0);
  const [quantityBikes, setQuantityBikes] = useState(1);
  const [userLanguage, setUserLanguage] = useState("en");

  // Refs
  const prevParamsRef = useRef();
  const prevDataRef = useRef();
  const prevFilterDataRef = useRef();

  // Обработчики для модальных окон
  const handleClose = () => {
    setWindowOrderDeteIntervalV4(false);
    setWindowOrderQuantityBikesV4(false);
  };

  const handleOpenWindowOrderDeteIntervalV4 = () => {
    setWindowOrderDeteIntervalV4(true);
  };

  const handleOpenWindowOrderQuantityBikesV4 = () => {
    setWindowOrderQuantityBikesV4(true);
  };

  // Мемоизированные вычисления
  const generateSlug = useMemo(() => {
    // Получаем текущий месяц (март - 2)
    const currentMonth = new Date().getMonth(); // 0 для января, 2 для марта и т.д.

    // Определяем monthNumber с приоритетной логикой:
    let monthNumber;

    // 1. Если есть dateInterval с валидным началом, берем месяц из него
    if (dateInterval && Array.isArray(dateInterval) && dateInterval[0]) {
      monthNumber = new Date(dateInterval[0]).getMonth();
    }
    // 2. Если есть параметр month в URL и он валидный, используем его
    else if (month !== undefined && month !== null && !isNaN(Number(month))) {
      monthNumber = Number(month);
    }
    // 3. В противном случае используем текущий месяц
    else {
      monthNumber = currentMonth; // Текущий месяц (2 для марта)
    }

    return (
      `/${userLanguage || "en"}/Catalog/` +
      `${
        filterSettings?.country?.idRootDocument ||
        selectedCountry?.idRootDocument ||
        "null"
      }/` +
      `${
        filterSettings?.city?.idRootDocument ||
        selectedCity?.idRootDocument ||
        "null"
      }/` +
      `${
        filterSettings?.brand?.idRootDocument ||
        selectedBrand?.idRootDocument ||
        "null"
      }/` +
      `${
        filterSettings?.category?.idRootDocument ||
        selectedCategory?.idRootDocument ||
        "null"
      }/` +
      `${monthNumber}`
    );
  }, [
    filterSettings,
    selectedCountry,
    selectedCity,
    selectedBrand,
    selectedCategory,
    dateInterval,
    userLanguage,
    month,
  ]);

  const computedDateInterval = useMemo(() => {
    return getComputedDateInterval(dateInterval, month);
  }, [dateInterval, month]);

  // Очистка рефов
  useEffect(() => {
    return () => {
      prevParamsRef.current = null;
      prevDataRef.current = null;
      prevFilterDataRef.current = null;
    };
  }, []);

  // Разбор slug
  useEffect(() => {
    const params = {
      language,
      countryId,
      cityId,
      brandId,
      categoryId,
      month,
      countries: rentalPoint_countriesListPreview,
      cities: rentalPoint_citiesListPreview,
      brands: brandsPreview,
      categories: categoriesPreview,
      pathname: location.pathname,
    };

    if (!deepEqual(params, prevParamsRef.current)) {
      if (rentalPoint_countriesListPreview && countryId) {
        const countryInFilter = rentalPoint_countriesListPreview.filter(
          (item) => item.idRootDocument === countryId
        );
        if (countryInFilter.length > 0) {
          setSelectedCountry(countryInFilter[0]);
          setSelectedCity(null);
        }
      }

      if (rentalPoint_citiesListPreview && cityId) {
        const cityInFilter = rentalPoint_citiesListPreview.filter(
          (item) => item.idRootDocument === cityId
        );
        if (cityInFilter.length > 0) setSelectedCity(cityInFilter[0]);
      }

      if (brandsPreview && brandId) {
        const brandsFilter = brandsPreview.filter(
          (item) => item.idRootDocument === brandId
        );
        if (brandsFilter.length > 0) setSelectedBrand(brandsFilter[0]);
      }

      if (categoriesPreview && categoryId) {
        const categoriesFilter = categoriesPreview.filter(
          (item) => item.idRootDocument === categoryId
        );
        if (categoriesFilter.length > 0)
          setSelectedCategory(categoriesFilter[0]);
      }

      prevParamsRef.current = params;
    }
  }, [
    language,
    countryId,
    cityId,
    brandId,
    categoryId,
    month,
    rentalPoint_countriesListPreview,
    rentalPoint_citiesListPreview,
    brandsPreview,
    categoriesPreview,
    location.pathname,
  ]);

  // tempData - обновляем для работы с filterSettings
  useEffect(() => {
    if (!filterSettings) return;

    const interval = filterSettings.dateInterval || null;
    const newQuantity = filterSettings.quantityBikes || 1;

    const newData = {
      interval,
      quantity: newQuantity,
    };

    if (!deepEqual(newData, prevDataRef.current)) {
      setDateInterval(interval);
      const differenceInDays = getDifferenceInDays(interval);
      setDifferenceInDays(differenceInDays);
      setQuantityBikes(newQuantity);
      prevDataRef.current = newData;
    }
  }, [filterSettings]);

  // tempPreviewData - обновляем для работы с переданными списками
  useEffect(() => {
    const newData = {
      countries: countriesList,
      cities: citiesList,
      brands: brandsList,
      categories: categoriesList,
    };

    if (!deepEqual(newData, prevDataRef?.current)) {
      setRentalPoint_countriesListPreview(newData?.countries || []);
      setRentalPoint_citiesListPreview(newData?.cities || []);
      setBrandsPreview(newData?.brands || []);
      setCategoriesPreview(newData?.categories || []);
      prevDataRef.current = newData;
    }
  }, [countriesList, citiesList, brandsList, categoriesList]);

  // tempRootData - обновляем для работы с userSettings
  useEffect(() => {
    if (!userSettings) return;

    const newData = {
      language: userSettings?.settings?.language,
    };

    if (!deepEqual(newData, prevDataRef.current)) {
      setUserLanguage(newData.language || "en");
      prevDataRef.current = newData;
    }
  }, [userSettings]);

  // Формирование tempData_filtersParams
  useEffect(() => {
    const filterData = {
      dateInterval,
      city: selectedCity,
      country: selectedCountry,
      category: selectedCategory,
      brand: selectedBrand,
      quantity: quantityBikes,
      month,
    };

    if (!deepEqual(filterData, prevFilterDataRef.current)) {
      const data = {
        city: filterData.city || null,
        country: filterData.country || null,
        category: filterData.category || null,
        brand: filterData.brand || null,
        dateInterval: computedDateInterval,
        quantityBikes: filterData.quantity || 1,
      };

      const currentTempData = filterSettings || {};

      if (!deepEqual(data, currentTempData)) {
        console.log("filterSettings data", data);
        dispatch(
          setTempData({
            tempCurrentDocumentState: "tempData_filtersParams",
            data,
          })
        );

        if (onFilterChange) {
          onFilterChange(data);
        }
      }

      prevFilterDataRef.current = filterData;
    }
  }, [
    dispatch,
    dateInterval,
    selectedCity,
    selectedCountry,
    selectedCategory,
    selectedBrand,
    quantityBikes,
    month,
    filterSettings,
    computedDateInterval,
    onFilterChange,
  ]);

  // Навигация с дебаунсингом
  useEffect(() => {
    const debouncedNavigate = debounce((slug) => {
      if (slug) {
        navigate(slug);
      }
    }, 400);

    debouncedNavigate(generateSlug);

    return () => {
      debouncedNavigate.cancel();
    };
  }, [navigate, generateSlug]);

  // Обработчики
  const handleCountrySelect = useCallback(
    (data) => {
      if (data) {
        dispatch(
          fetchPreviewDataV4({
            previewCollectionPath: `citiesPreview.countryIds.${data?.idRootDocument}`,
            loadingStateName: "windowCountryDetailV4LoadingFetchPreviewCities",
            errorStateName: "currentError",
            previewCurrentDocumenState: "rentalPoint_citiesListPreview",
          })
        );
        setSelectedCountry(data);
        setSelectedCity(null);
      } else {
        setSelectedCountry(null);
        setSelectedCity(null);
      }
    },
    [dispatch]
  );

  const handleCitySelect = useCallback(
    (data) => {
      if (data?.idRootDocument !== selectedCity?.idRootDocument) {
        setSelectedCity(data);
      }
    },
    [selectedCity]
  );

  const controlSelectBrand = (brand) => setSelectedBrand(brand);
  const controlSelectCategory = (category) => setSelectedCategory(category);

  // Опции фильтров
  const options = useMemo(
    () =>
      [
        {
          item: (
            <div
              style={{
                ...(isMobile ? { marginLeft: "16px" } : { marginLeft: "24px" }),
                ...styles.optionsStyle,
              }}
            >
              <div
                style={{
                  ...(selectedCountry && styles.optionItemWithBorderRadius),
                  ...styles.paddingFilter,
                  ...(selectedCity && styles.locatopnCountry),
                }}
              >
                <DropdownFilterItem
                  options={rentalPoint_countriesListPreview}
                  onSelect={handleCountrySelect}
                  label="Select country"
                  description="Please select a country."
                  fieldView="title"
                  fieldId="idRootDocument"
                  initialSelectedOption={selectedCountry}
                  active={true}
                  style={{
                    ...(selectedCountry && { color: "var(--color-white)" }),
                  }}
                />
              </div>
              <div
                style={{
                  ...(selectedCity && styles.optionItemWithBorderRadius),
                  ...styles.paddingFilter,
                  ...(selectedCity && styles.locatopnCity),
                }}
              >
                <DropdownFilterItem
                  options={rentalPoint_citiesListPreview}
                  onSelect={handleCitySelect}
                  label="Select city"
                  description="Please select a city."
                  fieldView="title"
                  fieldId="idRootDocument"
                  initialSelectedOption={selectedCity}
                  active={!!selectedCountry}
                  style={{
                    ...(selectedCity && { color: "var(--color-white)" }),
                  }}
                />
              </div>
            </div>
          ),
        },
        {
          item: (
            <div
              style={{
                ...styles.optionsStyle,
                ...(selectedBrand && styles.optionItemWithBorderRadius),
                ...styles.paddingFilter,
              }}
            >
              <DropdownFilterItem
                options={brandsPreview}
                onSelect={controlSelectBrand}
                label="Select Brand"
                description="Please select a brand."
                fieldView="title"
                fieldId="idRootDocument"
                initialSelectedOption={selectedBrand}
                active={true}
                style={{
                  ...(selectedBrand && { color: "var(--color-white)" }),
                }}
              />
            </div>
          ),
        },
        {
          item: (
            <div
              style={{
                ...styles.optionsStyle,
                ...(selectedCategory && styles.optionItemWithBorderRadius),
                ...styles.paddingFilter,
              }}
            >
              <DropdownFilterItem
                options={categoriesPreview}
                onSelect={controlSelectCategory}
                label="Select Category"
                description="Please select a category."
                fieldView="title"
                fieldId="idRootDocument"
                initialSelectedOption={selectedCategory}
                active={true}
                style={{
                  ...(selectedCategory && { color: "var(--color-white)" }),
                }}
              />
            </div>
          ),
        },
        {
          item: (
            <div
              style={{
                ...styles.optionsStyle,
                ...(Array.isArray(dateInterval) &&
                dateInterval[0] &&
                dateInterval[1]
                  ? styles.optionItemWithBackgroundColor
                  : {}),
                ...styles.paddingFilter,
              }}
            >
              <ItemFilters
                title={
                  Array.isArray(dateInterval) &&
                  dateInterval[0] &&
                  dateInterval[1]
                    ? `${new Date(
                        dateInterval[0]
                      ).toLocaleDateString()} — ${new Date(
                        dateInterval[1]
                      ).toLocaleDateString()}`
                    : "Select dates"
                }
                param={
                  differenceInDays > 0
                    ? differenceInDays > 1
                      ? `${differenceInDays} days`
                      : `${differenceInDays} day`
                    : "7 days"
                }
              />
            </div>
          ),
          onClick: handleOpenWindowOrderDeteIntervalV4,
        },
        {
          item: (
            <div
              style={{
                ...styles.optionsStyle,
                ...(quantityBikes > 1
                  ? styles.optionItemWithBackgroundColor
                  : {}),
                ...(isMobile ? {} : { marginRight: "23px" }),
                ...styles.paddingFilter,
              }}
            >
              <ItemFilters
                title="Quantity bikes:"
                param={
                  quantityBikes > 1
                    ? `${quantityBikes} bikes`
                    : `${quantityBikes} bike`
                }
              />
            </div>
          ),
          onClick: handleOpenWindowOrderQuantityBikesV4,
        },
      ].filter(Boolean),
    [
      isMobile,
      selectedCountry,
      selectedCity,
      rentalPoint_countriesListPreview,
      rentalPoint_citiesListPreview,
      selectedBrand,
      brandsPreview,
      selectedCategory,
      categoriesPreview,
      dateInterval,
      differenceInDays,
      quantityBikes,
      handleCitySelect,
      handleCountrySelect,
    ]
  );

  return (
    <>
      <PopupDirrectory
        isOpen={windowOrderDeteIntervalV4}
        onClose={handleClose}
        title="WindowCalendarV4"
      >
        <WindowCalendarV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>
      <PopupDirrectory
        isOpen={windowOrderQuantityBikesV4}
        onClose={handleClose}
        title="WindowOrderQuantityBikesV4"
      >
        <WindowOrderQuantityBikesV4 onClose={handleClose} />
      </PopupDirrectory>

      <CarouselMenu options={options} padding="8px" />
    </>
  );
};

export default WidgetFilterBikes;
