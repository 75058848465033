import React, { useEffect, useState } from "react";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowOrderFiltersBikesListV4 from "./WindowOrderFiltersBikesListV4";
import WindowRentalPointOrderV4 from "./WindowRentalPointOrderV4";
import WidgetBikesList from "../BikesV3/WidgetBikesList";
import { useDispatch, useSelector } from "react-redux";
import CarouselMenu from "../../../../UIComponents/Carousel/CarouselMenu";
import WindowOrderQuantityBikesV4 from "./WindowOrderQuantityBikesV4";
import DropdownFilterItem from "../../../../UIComponents/DropdownList/DropdownFilterItem";
import { fetchPreviewDataV4 } from "../../../../../reducersToolkit/thunk/fetchPreviewDataV4";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import WindowCalendarV4 from "../../../../UIComponents/Date/WindowCalendarV4";
import useIsMobile from "../../../../../functions/isMobile";

const filterBikes = (bikes, filters, idRootDocumentRentalPoint) => {
  return bikes.filter((bike) => {
    const { brand, category, city, country, dateInterval, quantityBikes } =
      filters;

    const matchesBrand = !brand || bike.brand?.id === brand.idRootDocument;
    const matchesCategory =
      !category || bike.category?.id === category.idRootDocument;
    const matchesCity = !city || bike.city?.id === city.idRootDocument;
    const matchesCountry =
      !country || bike.country?.id === country.idRootDocument;

    // Проверка на совпадение idRootDocumentRentalPoint, с учетом его отсутствия
    const matchesIdRootDocumentRentalPoint =
      !idRootDocumentRentalPoint ||
      bike.idRootDocumentRentalPoint === idRootDocumentRentalPoint;

    // Проверка на совпадение интервала дат и цены
    const matchesDateInterval =
      !dateInterval ||
      (bike.rate &&
        bike.rate.some((rate) => {
          // Начало месяца
          const rateMonthStart = new Date(dateInterval[0]).setMonth(
            rate.month,
            1
          );

          // Конец месяца
          const rateMonthEnd = new Date(rateMonthStart).setMonth(
            rate.month + 1,
            0
          );

          // Проверяем, пересекаются ли интервал даты и месяц
          return (
            rate.price > 0 && // Цена должна быть больше 0
            ((dateInterval[0] >= rateMonthStart &&
              dateInterval[0] <= rateMonthEnd) || // Начало диапазона внутри месяца
              (dateInterval[1] >= rateMonthStart &&
                dateInterval[1] <= rateMonthEnd) || // Конец диапазона внутри месяца
              (rateMonthStart >= dateInterval[0] &&
                rateMonthEnd <= dateInterval[1])) // Месяц полностью внутри диапазона
          );
        }));

    const matchesQuantity = !quantityBikes || bike.quantity >= quantityBikes;

    return (
      matchesBrand &&
      matchesCategory &&
      matchesCity &&
      matchesCountry &&
      matchesDateInterval &&
      matchesQuantity &&
      matchesIdRootDocumentRentalPoint // Используем обновленное условие
    );
  });
};

const styles = {
  paddingFilter: {
    padding: "4px 4px 4px 16px",
  },

  paddingIcon: {
    padding: "4px 4px 4px 4px",
  },

  optionsStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "16px",
    backgroundColor: "var(--color-gray-100)",
    borderRadius: "80px",
    boxSizing: "border-box",
    cursor: "pointer",
  },

  optionItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 4px 0 16px",
    height: "100%",
  },

  optionItemWithBorderRadius: {
    borderRadius: "80px",
  },

  optionItemWithBackgroundColor: {
    backgroundColor: "var(--color-gray-100)",
  },

  optionItemWithSpacing: {
    padding: "0 4px 0 16px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  optionItemWithRowSpacing: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "0 4px 0 16px",
  },

  optionItemWithMarginLeft: {
    marginLeft: "48px",
  },

  optionItemWithMarginRight: {
    marginRight: "48px",
  },
};

const WindowCatalogV4 = ({ onClose, mode }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);

  // const [orderBike, setOrderBike] = useState(null);
  const [windowOrderFiltersBikesListV4, setWindowOrderFiltersBikesListV4] =
    useState(false);
  const [windowOrderForUserV4, setWindowOrderForUserV4] = useState(false);
  const [windowOrderDeteIntervalV4, setWindowOrderDeteIntervalV4] =
    useState(false);
  const [windowOrderQuantityBikesV4, setWindowOrderQuantityBikesV4] =
    useState(false);
  const [
    general_bikesPreviewNotifications,
    setGeneral_bikesPreviewNotifications,
  ] = useState(null);
  const [
    rentalPoint_countriesListPreview,
    setRentalPoint_countriesListPreview,
  ] = useState([]);
  const [rentalPoint_citiesListPreview, setRentalPoint_citiesListPreview] =
    useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [brandsPreview, setBrandsPreview] = useState([]);
  const [categoriesPreview, setCategoriesPreview] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [dateInterval, setDateInterval] = useState(null);
  const [differenceInDays, setDifferenceInDays] = useState(0);
  const [newRootFilterData, setNewRootFilterData] = useState(null);
  const [quantityBikes, setQuantityBikes] = useState(1);
  const [manage_fdiltersBikes, setManage_fdiltersBikes] = useState(null);
  const [filteredBikes, setFilteredBikes] = useState([]);
  const [idRootDocumentRentalPoint, setIdRootDocumentRentalPoint] =
    useState(null);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */
  useEffect(() => {
    if (mode === "replacement") {
      const dateInterval =
        tempRootData?.order_selectedCompletedRootOrder?.currentCart?.[
          tempData?.currentSlotIds?.indexBikeCurrentCart
        ]?.totals?.dateInterval;

      setDateInterval(dateInterval);
    }
  }, [
    mode,
    tempRootData?.order_selectedCompletedRootOrder?.currentCart,
    tempData?.currentSlotIds?.indexBikeCurrentCart,
    tempRootData?.user_auth_rootCurrentData,
  ]);

  useEffect(() => {
    if (mode === "order") {
      setDateInterval(tempData?.manage_fdiltersBikes?.dateInterval || null);
    }
    if (
      Array.isArray(tempData?.manage_fdiltersBikes?.dateInterval) &&
      tempData?.manage_fdiltersBikes?.dateInterval.length === 2
    ) {
      const start = new Date(tempData?.manage_fdiltersBikes?.dateInterval[0]); // Начальная дата

      const end = new Date(tempData?.manage_fdiltersBikes?.dateInterval[1]); // Конечная дата

      // Вычисляем количество миллисекунд в одном дне
      const millisecondsInDay = 24 * 60 * 60 * 1000;

      // Разница между датами в миллисекундах
      const differenceInMilliseconds = end - start;

      // Конвертируем разницу в дни
      const differenceInDays = Math.ceil(
        differenceInMilliseconds / millisecondsInDay
      );
      setDifferenceInDays(differenceInDays);
    }

    setManage_fdiltersBikes(tempData?.manage_fdiltersBikes || null);

    setQuantityBikes(tempData?.manage_fdiltersBikes?.quantityBikes || 1);

    // setOrderBike(tempData?.rootCart_orderedBike || null);
  }, [
    mode,
    tempData?.manage_fdiltersBikes,
    // tempData?.rootCart_orderedBike,
  ]);

  useEffect(() => {
    setRentalPoint_countriesListPreview(
      tempPreviewData?.rentalPoint_countriesListPreview || []
    );
    setRentalPoint_citiesListPreview(
      tempPreviewData?.rentalPoint_citiesListPreview || []
    );
    setBrandsPreview(tempPreviewData?.brandsPreview || []);
    setCategoriesPreview(tempPreviewData?.categoriesPreview || []);
  }, [
    mode,
    tempPreviewData?.rentalPoint_countriesListPreview,
    tempPreviewData?.rentalPoint_citiesListPreview,
    tempPreviewData?.brandsPreview,
    tempPreviewData?.categoriesPreview,
    // tempPreviewData?.choosingBikeForOrder_previewData,
    tempPreviewData?.choosingBikeToCreateReplacement_previewData,
  ]);

  /**
   *
   * Формирование объектов root и preview
   *
   */

  useEffect(() => {
    setNewRootFilterData({
      city: selectedCity || null,
      country: selectedCountry || null,
      category: selectedCategory || null,
      brand: selectedBrand || null,
      dateInterval: dateInterval || [
        new Date().setHours(0, 0, 0, 0), // Начало текущего дня (время в миллисекундах)
        new Date(new Date().setDate(new Date().getDate() + 7)).setHours(
          23,
          59,
          59,
          999
        ), // Конец 7-го дня (время в миллисекундах)
      ],

      quantityBikes: quantityBikes || 1,
    });
  }, [
    dateInterval,
    selectedCity,
    selectedCountry,
    selectedCategory,
    selectedBrand,
    quantityBikes,
  ]);

  useEffect(() => {
    dispatch(
      setTempData({
        tempCurrentDocumentState: "manage_fdiltersBikes",
        data: newRootFilterData,
      })
    );
  }, [dispatch, newRootFilterData]);

  /**
   *
   * Fetch Функции - с целью получения данных из slice
   *
   */

  useEffect(() => {
    dispatch(
      fetchPreviewDataV4({
        // Путь до коллекции указывается из НЕ четного колличества вложенностей
        previewCollectionPath: "countriesPreview",
        //
        loadingStateName: "loading_rentalPoint_countriesPreview",
        errorStateName: "currentError",
        // Сохраняет с state МАССИВ объектов как результат выполнения thunk
        previewCurrentDocumenState: "rentalPoint_countriesListPreview",
      })
    );
  }, [dispatch]);

  const handleCountrySelect = (data) => {
    if (data?.idRootDocument !== selectedCountry?.idRootDocument) {
      setSelectedCountry(data);

      setSelectedCity(null);

      dispatch(
        fetchPreviewDataV4({
          // Путь до коллекции указывается из НЕ четного колличества вложенностей
          previewCollectionPath: `citiesPreview.countryIds.${data?.idRootDocument}`,
          // Название state который будет помогать отслежывать результаты работы thunk
          loadingStateName: "windowCountryDetailV4LoadingFetchPreviewCities",
          errorStateName: "currentError",
          // Сохраняет в state МАССИВ объектов как результат выполнения thunk
          previewCurrentDocumenState: "rentalPoint_citiesListPreview",
        })
      );
    }
  };

  /**
   *
   * Логика компонента
   *
   */
  // useEffect(() => {
  //   if (orderBike) {
  //     setTemporaryBasketData((prevBasket) => {
  //       const updatedBasket = [...prevBasket, orderBike];

  //       // После обновления temporaryBasket выполняем dispatch
  //       dispatch(
  //         setTempData({
  //           tempCurrentDocumentState: "rootCart_orderedBike",
  //           data: null,
  //         })
  //       );

  //       return updatedBasket;
  //     });
  //   }
  // }, [orderBike, dispatch]);

  // Получение данных для фильтров
  useEffect(() => {
    setManage_fdiltersBikes(tempData?.manage_fdiltersBikes || null);
    setGeneral_bikesPreviewNotifications(
      tempPreviewData?.general_bikesPreviewNotifications || null
    );
  }, [
    tempData?.manage_fdiltersBikes,
    tempPreviewData?.general_bikesPreviewNotifications,
  ]);

  // Фильтрация байков
  useEffect(() => {
    if (general_bikesPreviewNotifications && manage_fdiltersBikes) {
      const filtered = filterBikes(
        general_bikesPreviewNotifications,
        manage_fdiltersBikes,
        idRootDocumentRentalPoint
      );
      setFilteredBikes(filtered);
    }
  }, [
    general_bikesPreviewNotifications,
    manage_fdiltersBikes,
    idRootDocumentRentalPoint,
  ]);

  const handleCitySelect = (data) => {
    if (data?.idRootDocument !== selectedCity?.idRootDocument) {
      setSelectedCity(data);
    }
  };

  useEffect(() => {
    setGeneral_bikesPreviewNotifications(
      tempPreviewData?.general_bikesPreviewNotifications || null
    );
  }, [tempPreviewData?.general_bikesPreviewNotifications]);

  // const handleWindowOrderFiltersBikesListV4 = () => {
  //   setWindowOrderFiltersBikesListV4(true);
  // };

  const handleClose = () => {
    setWindowOrderFiltersBikesListV4(false);
    setWindowOrderForUserV4(false);
    setWindowOrderDeteIntervalV4(false);
    setWindowOrderQuantityBikesV4(false);
  };

  // const handleOpenWindowOrderForUserV4 = () => {
  //   setWindowOrderForUserV4(true);
  // };

  const handleOpenWindowOrderDeteIntervalV4 = () => {
    setWindowOrderDeteIntervalV4(true);
  };

  const handleOpenWindowOrderQuantityBikesV4 = () => {
    setWindowOrderQuantityBikesV4(true);
  };

  const controlSelectBrand = (brand) => {
    setSelectedBrand(brand);
  };
  const controlSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  // Наколнение коллекции SEO
  // const createSEO = async () => {
  //   try {
  //     const newRootSlugData = {
  //       test: "100500",
  //       id: slug,
  //     };

  //     const newPreviewSlugData = {
  //       test: "100500",
  //       id: slug,
  //     };

  //     dispatch(
  //       createDataThunkV4({
  //         // Данные которые будут использоваться для создания коллекции в firestore
  //         rootData: newRootSlugData,
  //         // idRootDocument:'',
  //         previewData: newPreviewSlugData,

  //         // files: {
  //         //   photoFiles: newPhotoFiles,
  //         //   //  Ниже можно использовать другие массивы файлов, если требуется
  //         // },

  //         // Имя того массива файлов который будет использоваться для назначения иконки
  //         // iconFields: "photoFiles",

  //         // Определяем пути для коллекций и метаданных с точками в качестве разделителей
  //         // Путь до коллекции указывается из НЕ четного колличества вложенностей
  //         rootCollectionPath: "seo",
  //         previewCollectionPath: `manager_seo`,
  //         // previewGeneralCollectionPath: "general_seo",

  //         // Путь до документа указывается из ЧЕТНОГО колличества вложенностей
  //         metadataDocumentPath: `manager_seo.metadata`,
  //         // metadataGeneralDocumentPath: "general_seo.metadata",

  //         // Сохраняет в state ОБЪЕКТ как результат выполнения thunk
  //         rootCurrentDocumentState: "seo_rootCurrentData",

  //         // Сохраняет в state МАССИВ объектов как результат выполнения thunk
  //         previewCurrentDocumenState: "seo_previewCurrentData",

  //         // Сохраняет в общий список массивов объектов
  //         // previewGeneralDocumenState: "general_slugPreview",

  //         // Название state который будет помогать отслежывать результаты работы thunk
  //         loadingStateName: "loading_seo_previewCurrentData",
  //         errorStateName: "error_seo_previewCurrentData",
  //       })
  //     )
  //       .unwrap()
  //       .then((response) => {
  //         console.log("seo успешно создан:", response);
  //         onClose();
  //       })
  //       .catch((error) => {
  //         console.error("Ошибка при создании seo:", error);
  //       });
  //   } catch (error) {
  //     console.error("Ошибка при создании или обновлении bikes:", error);
  //   }
  // };

  const options = [
    {
      item: (
        <div
          style={{
            ...(isMobile ? { marginLeft: "16px" } : { marginLeft: "24px" }),
            ...styles.optionsStyle,
            ...styles.paddingFilter,
          }}
        >
          <div
            style={{
              ...(selectedCountry && styles.optionItemWithBorderRadius),
            }}
          >
            <DropdownFilterItem
              options={rentalPoint_countriesListPreview}
              onSelect={handleCountrySelect}
              label="Select country"
              description="Please select a country."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCountry}
              active={true}
            />
          </div>

          <div
            style={{
              ...(selectedCity && styles.optionItemWithBorderRadius),
              ...(selectedCountry && {}),
            }}
          >
            <DropdownFilterItem
              options={rentalPoint_citiesListPreview}
              onSelect={handleCitySelect}
              label="Select city"
              description="Please select a city."
              fieldView="title"
              fieldId="idRootDocument"
              initialSelectedOption={selectedCity}
              active={!!selectedCountry}
            />
          </div>
        </div>
      ),
    },
    {
      item: (
        <div
          style={{
            ...styles.optionsStyle,
            ...(selectedBrand && styles.optionItemWithBorderRadius),
            ...styles.paddingFilter,
          }}
        >
          <DropdownFilterItem
            options={brandsPreview}
            onSelect={controlSelectBrand}
            label="Select Brand"
            description="Please select a brand."
            fieldView="title"
            fieldId="idRootDocument"
            initialSelectedOption={selectedBrand}
            active={true}
          />
        </div>
      ),
    },
    {
      item: (
        <div
          style={{
            ...styles.optionsStyle,
            ...(selectedCategory && styles.optionItemWithBorderRadius),
            ...styles.paddingFilter,
          }}
        >
          <DropdownFilterItem
            options={categoriesPreview}
            onSelect={controlSelectCategory}
            label="Select Category"
            description="Please select a category."
            fieldView="title"
            fieldId="idRootDocument"
            initialSelectedOption={selectedCategory}
            active={true}
          />
        </div>
      ),
    },
    // Добавляем условие для 'replacement'
    mode !== "replacement" && {
      item: (
        <div
          style={{
            ...styles.optionsStyle,
            ...(Array.isArray(dateInterval) &&
            dateInterval[0] &&
            dateInterval[1]
              ? styles.optionItemWithBackgroundColor
              : {}),
            ...styles.paddingFilter,
          }}
        >
          <ItemFilters
            title={`${
              Array.isArray(dateInterval) && dateInterval[0]
                ? new Date(dateInterval[0])?.toLocaleDateString()
                : ""
            } — ${
              Array.isArray(dateInterval) && dateInterval[1]
                ? new Date(dateInterval[1])?.toLocaleDateString()
                : ""
            }`}
            param={
              differenceInDays && differenceInDays > 1
                ? `${differenceInDays} days`
                : `${differenceInDays} day`
            }
          />
        </div>
      ),
      onClick: handleOpenWindowOrderDeteIntervalV4,
    },
    {
      item: (
        <div
          style={{
            ...styles.optionsStyle,
            ...(quantityBikes > 1
              ? {
                  ...styles.optionItemWithBackgroundColor,
                }
              : {}),
            ...(isMobile ? {} : { marginRight: "23px" }),
            ...styles.paddingFilter,
          }}
        >
          <ItemFilters
            title="Quantity bikes:"
            param={
              quantityBikes > 1
                ? `${quantityBikes} bikes`
                : `${quantityBikes} bike`
            }
          />
        </div>
      ),
      onClick: handleOpenWindowOrderQuantityBikesV4,
    },
  ].filter(Boolean);

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowOrderDeteIntervalV4}
        onClose={handleClose}
        title={`WindowCalendarV4`}
      >
        <WindowCalendarV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowOrderQuantityBikesV4}
        onClose={handleClose}
        title={`WindowOrderQuantityBikesV4`}
      >
        <WindowOrderQuantityBikesV4 onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowOrderForUserV4}
        onClose={handleClose}
        title={`WindowOrderClientV4`}
      >
        <WindowRentalPointOrderV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      <PopupDirrectory
        isOpen={windowOrderFiltersBikesListV4}
        onClose={handleClose}
        title={`WindowOrderFiltersBikesListV4`}
      >
        <WindowOrderFiltersBikesListV4 mode="new" onClose={handleClose} />
      </PopupDirrectory>

      {/* POPUP WINDOWS */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          // marginBottom: "72px",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
          }}
        >
          <CarouselMenu options={options} padding="8px" />
          <ContentBox position="one">
            <WidgetBikesList
              bikesList={filteredBikes}
              bikeState="approved"
              mode={mode}
              closeParent={onClose}
              dateInterval={dateInterval}
            />
          </ContentBox>
        </div>
      </div>
    </>
  );
};

export default WindowCatalogV4;

const ItemFilters = ({ title, param }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "8px",
        width: "100%",
        color: "rgba(0, 0, 0, 0.8)",
      }}
    >
      {title}
      <div
        style={{
          backgroundColor: "rgba(255, 255, 255, 0.9)",
          color: "rgba(0, 0, 0, 0.9)",
          fontWeight: "600",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 8px",
          borderRadius: "16px",
        }}
      >
        {param}
      </div>
    </div>
  );
};
