import React, { useState, useEffect } from "react";
import InputText from "../../../../UIComponents/Inputs/InputText";
import Button from "../../../../UIComponents/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";

const WindowEditPhoneModeration = ({ mode, onClose, isChangeState }) => {
  const dispatch = useDispatch();

  const isModerationMode = mode === "moderation";
  const isModeratorAddedMode = mode === "moderatorAdded";

  const { tempRootData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [phone, setPhone] = useState("+");
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [newPhoneNumbers, setNewPhoneNumbers] = useState([]);
  const [removedPhoneNumbers, setRemovedPhoneNumbers] = useState([]);
  // Состояние для отслеживания ошибки валидации
  const [validationError, setValidationError] = useState(null);

  // Состояние для активации кнопки Save
  const [saveButtonActive, setSaveButtonActive] = useState(false);

  // Состояние для отслеживания исходных данных
  const [initialPhoneNumbers, setInitialPhoneNumbers] = useState([]);

  // Максимальное количество цифр в номере телефона (стандарт E.164)
  const MAX_DIGITS = 15;

  // Функция для форматирования номера телефона
  const formatPhoneNumber = (phoneNumber) => {
    // Убираем все нецифровые символы, кроме "+"
    const cleaned = phoneNumber.replace(/[^\d+]/g, "");

    // Убеждаемся, что номер начинается с "+"
    const withPlus = cleaned.startsWith("+") ? cleaned : "+" + cleaned;

    // Получаем только цифры для определения формата
    const digits = withPlus.replace(/\D/g, "");

    // Ограничиваем количество цифр до MAX_DIGITS
    const limitedDigits = digits.substring(0, MAX_DIGITS);

    // Если количество цифр было ограничено, создаем новую строку с ограниченным количеством цифр
    const limitedWithPlus = "+" + limitedDigits;

    // Форматируем номер в зависимости от количества цифр
    if (limitedDigits.length <= 1) {
      // Только код страны начинается
      return limitedWithPlus;
    } else if (limitedDigits.length <= 3) {
      // Код страны (1-3 цифры)
      const countryCode = limitedWithPlus.substring(0, limitedWithPlus.length);
      return countryCode;
    } else if (limitedDigits.length <= 6) {
      // Код страны + начало номера
      const countryCode = limitedWithPlus.substring(0, 2);
      const areaCode = limitedWithPlus.substring(2);
      return `${countryCode} ${areaCode}`;
    } else if (limitedDigits.length <= 10) {
      // Стандартный формат для многих стран
      const countryCode = limitedWithPlus.substring(0, 2);
      const areaCode = limitedWithPlus.substring(2, 5);
      const firstPart = limitedWithPlus.substring(5, 8);
      const lastPart = limitedWithPlus.substring(8);
      return `${countryCode} ${areaCode} ${firstPart}${
        lastPart ? " " + lastPart : ""
      }`;
    } else {
      // Более длинные номера
      const countryCode = limitedWithPlus.substring(0, 2);
      const areaCode = limitedWithPlus.substring(2, 5);
      const firstPart = limitedWithPlus.substring(5, 8);
      const secondPart = limitedWithPlus.substring(8, 12);
      const lastPart = limitedWithPlus.substring(12);
      return `${countryCode} ${areaCode} ${firstPart} ${secondPart}${
        lastPart ? " " + lastPart : ""
      }`;
    }
  };

  // Функция валидации телефонного номера
  const validatePhoneNumber = (value) => {
    // Проверяем, что значение не пустое и не содержит только пробелы или только "+"
    if (!value || value.trim() === "" || value.trim() === "+") {
      return "Phone number is required";
    }

    // Удаляем все нецифровые символы для подсчета цифр
    const digitsOnly = value.replace(/\D/g, "");

    // Проверяем минимальную длину (8 цифр для большинства международных номеров)
    if (digitsOnly.length < 8) {
      return "Phone number must contain at least 8 digits";
    }

    // Проверка на максимальную длину не нужна здесь, так как мы уже ограничиваем ввод в handlePhoneChange
    // и никогда не позволяем установить значение с более чем MAX_DIGITS цифрами

    return true;
  };

  useEffect(() => {
    if (isModeratorAddedMode) {
      const phones =
        tempData?.tempData_rentalPoint_contacts?.phoneNumbers || [];
      setPhoneNumbers(phones);
      setInitialPhoneNumbers([...phones]); // Сохраняем исходные данные
    } else if (isModerationMode) {
      const phones =
        tempData?.tempData_rentalPoint_contacts?.phoneNumbers ||
        tempRootData?.selectedBike_rentalPont?.contacts?.phoneNumbers ||
        [];
      setPhoneNumbers(phones);
      setInitialPhoneNumbers([...phones]); // Сохраняем исходные данные
    }
  }, [
    mode,
    tempRootData?.selectedBike_rentalPont?.contacts?.phoneNumbers,
    tempRootData?.rentalPoint_moderation_rootRerntalPoint?.contacts
      ?.phoneNumbers,
    tempData?.tempData_rentalPoint_contacts,
    isModerationMode,
    isModeratorAddedMode,
  ]);

  // Логика активации кнопки Save
  useEffect(() => {
    // Проверяем, есть ли изменения в списке телефонных номеров
    const hasNewPhones = newPhoneNumbers.length > 0;
    const hasRemovedPhones = removedPhoneNumbers.length > 0;
    const currentPhonesChanged =
      JSON.stringify(phoneNumbers) !== JSON.stringify(initialPhoneNumbers);

    // Кнопка Save активна, если есть какие-либо изменения
    setSaveButtonActive(
      hasNewPhones || hasRemovedPhones || currentPhonesChanged
    );
  }, [newPhoneNumbers, removedPhoneNumbers, phoneNumbers, initialPhoneNumbers]);

  // Обработчик изменения номера телефона
  const handlePhoneChange = (e) => {
    const newValue = e.target.value;

    // Удаляем все форматирование и получаем только значимые символы
    let rawValue = newValue.replace(/\s+/g, "");

    // Если пользователь удалил "+", добавляем его обратно
    if (!rawValue.startsWith("+")) {
      rawValue = "+" + rawValue.replace(/^\+*/g, "");
    }

    // Проверяем, не превышает ли количество цифр максимально допустимое
    const digitsOnly = rawValue.replace(/\D/g, "");

    // Если количество цифр превышает максимум, не обновляем значение
    // и сбрасываем ошибку валидации, чтобы не показывать сообщение об ошибке
    if (digitsOnly.length > MAX_DIGITS) {
      // Сбрасываем ошибку валидации, если она есть
      if (validationError) {
        setValidationError(null);
      }
      return;
    }

    // Применяем форматирование к номеру
    const formattedValue = formatPhoneNumber(rawValue);

    // Обновляем значение телефона
    setPhone(formattedValue);

    // Проверяем валидность нового значения и обновляем состояние ошибки
    const validationResult = validatePhoneNumber(formattedValue);
    if (validationResult !== true) {
      setValidationError(validationResult);
    } else {
      setValidationError(null);
    }
  };

  // Добавление нового телефонного номера в локальное состояние
  const handleAddPhone = () => {
    if (!phone || phone.trim() === "" || phone.trim() === "+") return;

    // Очищаем номер от лишних пробелов
    const cleanedPhone = phone.trim().replace(/\s+/g, " ");

    // Проверка минимального количества цифр
    const digitsOnly = cleanedPhone.replace(/\D/g, "");
    if (digitsOnly.length < 8) {
      return; // Не добавляем номер, если в нем меньше 8 цифр
    }

    const phoneObject = {
      key: Date.now().toString(),
      phone: digitsOnly, // Сохраняем только цифры для базы данных
      displayPhone: cleanedPhone, // Сохраняем форматированный номер для отображения
    };
    setNewPhoneNumbers([...newPhoneNumbers, phoneObject]);
    setPhone("+"); // Сбрасываем поле ввода к начальному значению "+"
    setValidationError(null); // Сбрасываем ошибку валидации
  };

  // Удаление нового добавленного телефона из локального состояния
  const handleRemovePhone = (index) => {
    setNewPhoneNumbers(newPhoneNumbers.filter((_, i) => i !== index));
  };

  // Удаление загруженного телефона из локального состояния, но не из Redux
  const handleRemoveLoadedPhone = (phoneObject) => {
    setPhoneNumbers(
      phoneNumbers.filter((phone) => phone.key !== phoneObject.key)
    );
    setRemovedPhoneNumbers([...removedPhoneNumbers, phoneObject]);
  };

  const handleApply = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    // if (mode === "moderation") {
    const updatedPhoneNumbers = [...phoneNumbers, ...newPhoneNumbers];
    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_rentalPoint_contacts.phoneNumbers",
        data: updatedPhoneNumbers,
      })
    );
    onClose();
    // } else if (mode === "moderatorAdded") {
    //   const updatedPhoneNumbers = [...phoneNumbers, ...newPhoneNumbers];
    //   dispatch(
    //     setCurrentRootData({
    //       rootCurrentDocumentState:
    //         "rentalPoint_create_createRerntalPoint.contacts.phoneNumbers",
    //       data: updatedPhoneNumbers,
    //     })
    //   );
    //   onClose();
    // }
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <InputText
              value={phone}
              onChange={handlePhoneChange}
              label="Phone number"
              description="Enter international phone number with country code (e.g. +1 234 567 8901). Maximum 15 digits."
              type="tel"
              error={validationError}
            />
            <div style={{ width: "auto" }}>
              <Button
                label="Add"
                active={phone && phone !== "+" && !validationError}
                onClick={handleAddPhone}
              />
            </div>
          </div>
        </ContentBox>

        {(phoneNumbers.length > 0 || newPhoneNumbers.length > 0) && (
          <ContentBox>
            <ul
              style={{
                width: "100%",
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {/* Список существующих телефонов */}
              {phoneNumbers.map((phoneObj) => (
                <li
                  key={phoneObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>
                    {phoneObj.displayPhone ||
                      phoneObj.phone ||
                      "No phone number"}
                  </h6>
                  <div style={{ width: "auto" }}>
                    <Button
                      onClick={() => handleRemoveLoadedPhone(phoneObj)}
                      allert={
                        <h1
                          style={{
                            padding: "48px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this phone number?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}

              {/* Список новых телефонов */}
              {newPhoneNumbers.map((phoneObj, index) => (
                <li
                  key={phoneObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>
                    {phoneObj.displayPhone ||
                      phoneObj.phone ||
                      "No phone number"}
                  </h6>
                  <div style={{ width: "auto" }}>
                    <Button
                      onClick={() => handleRemovePhone(index)}
                      allert={
                        <h1
                          style={{
                            padding: "48px",
                            textAlign: "center",
                          }}
                        >
                          Are you sure you want to delete this phone number?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </ContentBox>
        )}
      </div>

      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1 style={{ padding: "80px", textAlign: "center" }}>
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            active={saveButtonActive}
            label="Save"
            color="--color-primary"
            onClick={handleApply}
            allert={
              <h1 style={{ padding: "80px", textAlign: "center" }}>
                Please confirm that you want to update the phone information.
              </h1>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditPhoneModeration;
