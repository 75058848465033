import React, { useEffect, useState } from "react";
import PopupDirrectory from "../Popups/PopupDirrectory";
import "./dropdownList.css";
import Button from "../Buttons/Button";
import ContentBox from "../ContentBox/ContentBox";

const DoubleDropdownList = ({
  optionsFirst,
  optionsSecond,
  onSelectFirst,
  onSelectSecond,
  label,
  description,
  selectedOptionFirst: initialSelectedOptionFirst,
  selectedOptionSecond: initialSelectedOptionSecond,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptionFirst, setSelectedOptionFirst] = useState(null);
  const [selectedOptionSecond, setSelectedOptionSecond] = useState(null);

  useEffect(() => {
    setSelectedOptionFirst(initialSelectedOptionFirst);
    setSelectedOptionSecond(initialSelectedOptionSecond);
  }, [initialSelectedOptionFirst, initialSelectedOptionSecond]);

  const handleOptionSelectFirst = (option) => {
    setSelectedOptionFirst(option);
  };

  const handleOptionSelectSecond = (option) => {
    setSelectedOptionSecond(option);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleApply = () => {
    if (selectedOptionFirst !== null && selectedOptionSecond !== null) {
      onSelectFirst(selectedOptionFirst);
      onSelectSecond(selectedOptionSecond);
      setIsOpen(false);
    }
  };

  return (
    <>
      <div
        className="profile-widget__content-box"
        style={{
          gap: "8px",
        }}
      >
        <div className="dropdown-list" onClick={handleToggle}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "0 16px",
            }}
          >
            {selectedOptionFirst !== null && selectedOptionSecond !== null
              ? ` ${selectedOptionFirst}:${selectedOptionSecond}`
              : label}
            <span>
              {isOpen ? (
                <span className="material-symbols-outlined">unfold_more</span>
              ) : (
                <span className="material-symbols-outlined">unfold_more</span>
              )}
            </span>
          </div>
        </div>
        {description && (
          <div
            style={{
              width: "100%",
            }}
          >
            <p
              style={{
                width: "70%",
              }}
              className="p-light"
            >
              {description}
            </p>
          </div>
        )}
      </div>
      <PopupDirrectory
        isOpen={isOpen}
        onClose={handleClose}
        options={optionsFirst.value}
        title={label}
      >
        <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              height: "100%",
              width: "100%",
            }}
          >
            <p
              className="p-light"
              style={{
                padding: "16px 24px",
                backgroundColor: "var(--color-white)",
              }}
            >
              {optionsFirst.label}
            </p>
            <p
              className="p-light"
              style={{
                padding: "16px 24px",
                backgroundColor: "var(--color-white)",
              }}
            >
              {optionsSecond.label}
            </p>
            <ul
              className="dropdown-list__options"
              style={{
                overflowY: "auto",
              }}
            >
              {optionsFirst.value.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionSelectFirst(option)}
                  style={{
                    ...(selectedOptionFirst?.toString() ===
                      option.toString() && {
                      backgroundColor: "var(--color-gray-100)",
                      fontWeight: "500",
                    }),
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>

            <ul
              className="dropdown-list__options"
              style={{
                overflowY: "auto",
              }}
            >
              {optionsSecond.value.map((option, index) => (
                <li
                  key={index}
                  onClick={() => handleOptionSelectSecond(option)}
                  style={{
                    ...(selectedOptionSecond?.toString() ===
                      option.toString() && {
                      backgroundColor: "var(--color-gray-100)",
                      fontWeight: "500",
                    }),
                  }}
                >
                  {option}
                </li>
              ))}
            </ul>

            <div
              style={{
                gridColumn: "1 / span 2",
                marginTop: "8px",
              }}
            >
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "16px",
                  width: "100%",
                  padding: "16px 24px",
                  boxSizing: "border-box",
                  backgroundColor: "var(--color-gray-200)",
                  borderRadius: "24px 24px 0 0",
                }}
              >
                <Button
                  type="small"
                  label="Close"
                  color="--color-black"
                  onClick={handleClose}
                  active={
                    selectedOptionFirst !== null &&
                    selectedOptionSecond !== null
                  }
                />
                <Button
                  type="small"
                  label="Apply"
                  onClick={handleApply}
                  active={
                    selectedOptionFirst !== null &&
                    selectedOptionSecond !== null
                  }
                />
              </div>
            </div>
          </div>
        </>
      </PopupDirrectory>
    </>
  );
};

export default DoubleDropdownList;
