/**
 * Функция для форматирования даты из timestamp.
 * @param {string | number} timestamp - Timestamp, представляющий дату и время в миллисекундах с 1 января 1970 года (UNIX-время).
 * @param {boolean} isMobile - Флаг, указывающий, является ли устройство мобильным.
 * @returns {string} Отформатированная дата.
 */
const formatDate = (timestamp, isMobile, time) => {
  // Проверяем тип timestamp
  if (typeof timestamp === "string") {
    // Преобразуем строковое значение timestamp в числовой формат
    timestamp = parseInt(timestamp);
  }

  // Проверяем, что значение timestamp является корректным числом
  if (isNaN(timestamp)) {
    return "Invalid Date";
  }

  // Создаем объект даты из числового timestamp
  const date = new Date(timestamp);

  // Определяем формат месяца в зависимости от устройства (мобильное или нет)
  const monthFormat = isMobile ? "short" : "long"; // Для мобильных устройств месяц будет сокращенным

  // Опции для форматирования даты
  const options = {
    year: "numeric",
    month: monthFormat,
    day: "numeric",
  };

  // Применяем метод toLocaleDateString для получения отформатированной даты в виде строки
  return date.toLocaleDateString("en-US", options);
};

export default formatDate;
