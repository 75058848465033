import React, { useState } from "react";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import { v4 as uuidv4 } from "uuid";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";
import { useDispatch } from "react-redux";
import PriorityFile from "../../../../UIComponents/UploadFiles/PriorityFile";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import { dateFormat } from "../../../../../functions/convertDate";
import WindowNewOrderDetailV5 from "./WindowNewOrderDetailV5";
import PreviewItemCard from "../../../../UIComponents/Preview/PreviewItemCard";

function getPaymentMethodStatus(method) {
  console.log("getPaymentMethodStatus method", method);
  switch (method) {
    case "cryptocurrency":
      return "Crypto Payment";
    case "electronicWallets":
      return "E-Wallet Payment";
    case "bankCard":
      return "Card Payment";
    default:
      return "Unknown Payment Method";
  }
}

function WidgetOrdersListV4({ ordersList }) {
  const dispatch = useDispatch();

  const [windowOrderDetailV5, setWindowOrderDetailV5] = useState(false);

  // Функция для проверки, был ли заказ создан не более 60 секунд назад
  const isRecentlyCreated = (created) => {
    if (!created) return false;
    const now = Date.now();
    const sixtySecondsAgo = now - 60 * 1000; // 60 секунд в миллисекундах
    return created > sixtySecondsAgo;
  };

  const handleOpenWindowOrderDetailV4 = (order) => {
    // Сохраняем данные выбранного preview заказа
    dispatch(
      setTempData({
        tempCurrentDocumentState: "selectedCompletedOrderPreview",
        data: order,
      })
    );

    setWindowOrderDetailV5(true);
  };

  const handleClose = () => {
    setWindowOrderDetailV5(false);
  };

  // Функция для получения иконки в зависимости от статуса заказа
  const getOrderStatusIcon = (status) => {
    switch (status) {
      case "new":
        return <span className="material-symbols-outlined">shopping_cart</span>;
      case "confirm":
        return <span className="material-symbols-outlined">check_circle</span>;
      case "canceled":
        return <span className="material-symbols-outlined">cancel</span>;
      case "replacement":
        return <span className="material-symbols-outlined">swap_horiz</span>;
      default:
        return <span className="material-symbols-outlined">inventory_2</span>;
    }
  };

  return (
    <>
      {/* POPUP WINDOWS */}
      <PopupDirrectory
        isOpen={windowOrderDetailV5}
        onClose={handleClose}
        title={`Order Details`}
      >
        <WindowNewOrderDetailV5 onClose={handleClose} />
      </PopupDirrectory>
      {/* POPUP WINDOWS */}

      {ordersList && ordersList.length > 0 && (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "2px",
            boxSizing: "border-box",
          }}
        >
          {ordersList?.map((order, index) => (
            <PreviewItemCard
              key={order.idRootDocument || uuidv4()}
              index={index}
              length={ordersList.length}
              title={order?.client?.ownerName || "No name"}
              stateItem={order?.orderStatus}
              //  icon left
              iconLeft={getOrderStatusIcon(order?.orderStatus)}
              iconLeftStyle={{
                color:
                  order?.orderStatus === "new"
                    ? "var(--color-success)"
                    : order?.orderStatus === "canceled"
                    ? "var(--color-alarm)"
                    : order?.orderStatus === "replacement"
                    ? "var(--color-warning)"
                    : "var(--color-black)",
              }}
              containerIconLeftStyle={{
                backgroundColor: isRecentlyCreated(order.created)
                  ? "var(--color-success-alpha-30)"
                  : order?.orderStatus === "new"
                  ? "var(--color-success-alpha-30)"
                  : order?.orderStatus === "canceled"
                  ? "var(--color-alarm-alpha-30)"
                  : order?.orderStatus === "replacement"
                  ? "var(--color-warning-alpha-30)"
                  : "var(--color-gray-900-alpha-30)",
              }}
              // icon right
              iconRight={
                <span className="material-symbols-outlined">link</span>
              }
              iconRightStyle={{
                color: "var(--color-black)",
              }}
              containerIconRightStyle={{}}
              // container card
              containerCardStyle={{
                backgroundColor: isRecentlyCreated(order.created)
                  ? "var(--color-success-alpha-30)"
                  : order?.orderStatus === "new"
                  ? "var(--color-success-alpha-30)"
                  : order?.orderStatus === "canceled"
                  ? "var(--color-alarm-alpha-30)"
                  : order?.orderStatus === "replacement"
                  ? "var(--color-warning-alpha-30)"
                  : "var(--color-gray-900-alpha-30)",
              }}
              onClick={() => handleOpenWindowOrderDetailV4(order)}
              itemKey={order.idRootDocument}
            />
          ))}
        </div>
      )}
    </>
  );
}

export default WidgetOrdersListV4;
