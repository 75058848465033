import React, { useState } from "react";
import { formatCurrencyUSD } from "../../../../../functions/currencyFormatter";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowOrderAddToCartAdditionalService from "./WindowOrderAddToCartAdditionalService";
import WidgetOrderAditionalServicesTotal from "./WidgetOrderAditionalServicesTotal";
import PreviewItemCard from "../../../../UIComponents/Preview/PreviewItemCard";

const WidgetOrderAdditionalServicesList = ({ mode, additionalServices }) => {
  const [
    windowOrderAddToCartAdditionalService,
    setWindowOrderAddToCartAdditionalService,
  ] = useState(false);
  const [additionalServiceId, setAdditionalServiceId] = useState(null);

  const handleOpen = (additionalServiceId) => {
    setAdditionalServiceId(additionalServiceId);
    setWindowOrderAddToCartAdditionalService(true);
  };

  const handleClose = () => {
    setWindowOrderAddToCartAdditionalService(false);
  };

  return (
    <>
      {additionalServices?.length > 0 && (
        <>
          <PopupDirrectory
            isOpen={windowOrderAddToCartAdditionalService}
            onClose={handleClose}
            title="Order additional services"
          >
            <WindowOrderAddToCartAdditionalService
              mode={mode}
              additionalServiceId={additionalServiceId}
              onClose={handleClose}
            />
          </PopupDirrectory>
          {/* <TitleBlockSection
            title="List of additional services"
            description="Lorem Ipsum is simply dummy text of the printing and typesetting industry."
          /> */}
          <WidgetOrderAditionalServicesTotal
            mode={mode}
            orderedAditionalServices={additionalServices}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "2px",
            }}
          >
            {additionalServices?.map((additionalServiceItem, index) => (
              <PreviewItemCard
                key={index}
                index={index}
                length={additionalServices.length}
                title={additionalServiceItem?.title?.titleOriginal}
                description={formatCurrencyUSD(additionalServiceItem?.price)}
                // stateItem={additionalServiceItem}
                iconLeft={
                  <span className="material-symbols-outlined">sell</span>
                }
                iconLeftStyle={{
                  color: "var(--color-black)",
                }}
                // iconRight={}
                onClick={() => handleOpen(additionalServiceItem.id)}
                titleCardStyle={{
                  fontWeight: "normal",
                }}
                descriptionCardStyle={{
                  fontWeight: "600",
                }}
              />
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default WidgetOrderAdditionalServicesList;
