import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import "./PopupWindow.css";
import Preloader from "../Preloader/Preloader";

const PopupWindow = ({
  isOpen,
  onClose,
  children,
  title,
  isLoading,
  zIndex = 9999,
  className = "",
}) => {
  const [menuTransform, setMenuTransform] = useState(
    "scale(0.9) translateY(20px)"
  );
  const [overlayOpacity, setOverlayOpacity] = useState(0);
  const popupIdRef = useRef(
    `popup-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`
  );

  const closePopup = () => {
    setMenuTransform("scale(0.9) translateY(20px)");
    setOverlayOpacity(0);

    setTimeout(() => {
      onClose();
    }, 300);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");

      setTimeout(() => {
        setMenuTransform("scale(1) translateY(0)");
        setOverlayOpacity(0.5);
      }, 10);
    } else {
      document.body.classList.remove("no-scroll");
      setMenuTransform("scale(0.9) translateY(20px)");
      setOverlayOpacity(0);
    }

    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: zIndex,
        boxSizing: "border-box",
        backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`,
        backdropFilter: "blur(8px)",
        transition: "background-color 0.3s ease",
      }}
      onClick={closePopup}
      data-popup-id={popupIdRef.current}
    >
      <div
        className={className}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "90%",
          borderRadius: "16px",
          maxWidth: "var(--width-mobile-content)",
          maxHeight: "calc(100vh - 160px)",
          backgroundColor: "white",
          transform: menuTransform,
          transition: "transform 0.3s ease",
          overflow: "hidden",
          boxShadow: "0 4px 24px rgba(0, 0, 0, 0.15)",
          boxSizing: "border-box",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <div
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            borderTopLeftRadius: "inherit",
            borderTopRightRadius: "inherit",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            gap: "16px",
            width: "100%",
            padding: "16px",
            boxSizing: "border-box",
            borderBottom: "1px solid var(--color-gray-200)",
          }}
        >
          <h5
            style={{
              textTransform: "capitalize",
              margin: 0,
              color: "var(--color-black)",
            }}
          >
            {title}
          </h5>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                height: "32px",
                aspectRatio: "1/1",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={closePopup}
            >
              <span className="material-symbols-outlined">close</span>
            </div>
          </div>
        </div>

        <div
          style={{
            overflowY: "auto",
            width: "100%",
            padding: "16px",
            boxSizing: "border-box",
            maxHeight: "calc(100vh - 180px)",
          }}
        >
          {isLoading ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "24px 0",
              }}
            >
              <Preloader />
            </div>
          ) : (
            <div>{children}</div>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default React.memo(PopupWindow, (prevProps, nextProps) => {
  return (
    prevProps.isOpen === nextProps.isOpen &&
    prevProps.title === nextProps.title &&
    prevProps.isLoading === nextProps.isLoading &&
    prevProps.zIndex === nextProps.zIndex &&
    prevProps.className === nextProps.className
  );
});
