import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import "./popup.css";
import PopupAllert from "./PopupAllert";

const PopupDirrectory = ({ isOpen, onClose, children, title, allert }) => {
  const [isOpenAllertWindow, setIsOpenAllertWindow] = useState(false);
  const [menuTransform, setMenuTransform] = useState("translateY(100%)");
  const [overlayOpacity, setOverlayOpacity] = useState(0);
  const [contentHeight, setContentHeight] = useState("auto");
  const popupIdRef = useRef(
    `popup-${Date.now()}-${Math.random().toString(36).substring(2, 9)}`
  );

  const menuRef = useRef(null);
  const contentRef = useRef(null);
  const resizeObserverRef = useRef(null);
  const mutationObserverRef = useRef(null);
  const startY = useRef(0);
  const currentY = useRef(0);
  const isDragging = useRef(false);
  const resizeTimerRef = useRef(null);
  const isUpdatingRef = useRef(false);
  const lastContentHeightRef = useRef(0);
  const forceUpdateRef = useRef(0);

  const handleClose = () => {
    setIsOpenAllertWindow(false);
  };

  const handleToggle = () => {
    if (allert) {
      setIsOpenAllertWindow(true);
    } else {
      closePopup();
    }
  };

  const closePopup = () => {
    setMenuTransform("translateY(100%)");
    setOverlayOpacity(0);

    setTimeout(() => {
      onClose();
    }, 300);
  };

  const handleTouchStart = (e) => {
    startY.current = e.touches[0].clientY;
    isDragging.current = true;
  };

  const handleTouchMove = (e) => {
    if (!isDragging.current) return;

    currentY.current = e.touches[0].clientY;
    const deltaY = currentY.current - startY.current;

    if (deltaY > 0) {
      setMenuTransform(`translateY(${deltaY}px)`);
      setOverlayOpacity(Math.max(0, 0.5 - deltaY / 1000));
    }
  };

  const handleTouchEnd = () => {
    if (!isDragging.current) return;

    isDragging.current = false;
    const deltaY = currentY.current - startY.current;

    if (deltaY > 100) {
      closePopup();
    } else {
      setMenuTransform("translateY(0)");
      setOverlayOpacity(0.5);
    }
  };

  const forceUpdate = () => {
    forceUpdateRef.current = forceUpdateRef.current + 1;
    updateContentHeight(true);
  };

  const updateContentHeight = (force = false) => {
    if ((!contentRef.current || !isOpen) && !force) return;
    if (isUpdatingRef.current && !force) return;

    try {
      isUpdatingRef.current = true;

      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }

      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }

      if (contentRef.current) {
        contentRef.current.style.height = "auto";

        const originalDisplay = contentRef.current.style.display;
        contentRef.current.style.display = "none";
        void contentRef.current.offsetHeight;
        contentRef.current.style.display = originalDisplay;
      }

      requestAnimationFrame(() => {
        if (!contentRef.current || !isOpen) {
          isUpdatingRef.current = false;
          return;
        }

        const contentScrollHeight = contentRef.current.scrollHeight;
        const headerHeight = 56;
        const indicatorHeight = 20;
        const padding = 8;
        const maxAvailableHeight = window.innerHeight * 0.95;

        const currentHeight = contentScrollHeight;
        const heightChanged =
          Math.abs(currentHeight - lastContentHeightRef.current) > 5;
        lastContentHeightRef.current = currentHeight;

        const totalContentHeight =
          contentScrollHeight + headerHeight + indicatorHeight + padding;

        if (totalContentHeight > maxAvailableHeight) {
          const newHeight = `calc(${maxAvailableHeight}px - ${
            headerHeight + indicatorHeight + padding
          }px)`;
          setContentHeight(newHeight);
        } else {
          setContentHeight(`${contentScrollHeight}px`);
        }

        if (heightChanged || force) {
          setTimeout(() => {
            if (isOpen && contentRef.current) {
              updateContentHeight();
            }
          }, 30);
        } else {
          setTimeout(() => {
            setupObservers();
            isUpdatingRef.current = false;
          }, 20);
        }
      });
    } catch (error) {
      console.warn("Error updating height:", error);
      isUpdatingRef.current = false;

      setTimeout(() => {
        setupObservers();
      }, 100);
    }
  };

  const setupObservers = () => {
    if (!contentRef.current || !isOpen) return;

    try {
      if (typeof ResizeObserver !== "undefined") {
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }

        resizeObserverRef.current = new ResizeObserver(() => {
          if (!isUpdatingRef.current) {
            requestAnimationFrame(() => updateContentHeight());
          }
        });

        resizeObserverRef.current.observe(contentRef.current);

        const observeAllChildren = (element) => {
          if (!element) return;

          Array.from(element.children).forEach((child) => {
            try {
              resizeObserverRef.current.observe(child);
            } catch (e) {
              console.warn("Failed to observe:", e);
            }
            observeAllChildren(child);
          });
        };

        observeAllChildren(contentRef.current);
      }

      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }

      mutationObserverRef.current = new MutationObserver((mutations) => {
        const hasRelevantChanges = mutations.some(
          (mutation) =>
            mutation.type === "childList" ||
            (mutation.type === "attributes" &&
              mutation.target.style &&
              (mutation.attributeName === "style" ||
                mutation.attributeName === "class" ||
                mutation.attributeName === "hidden"))
        );

        if (hasRelevantChanges && !isUpdatingRef.current) {
          setTimeout(() => {
            forceUpdate();
          }, 10);
        }
      });

      mutationObserverRef.current.observe(contentRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
        attributes: true,
        attributeFilter: ["style", "class", "hidden", "display"],
      });
    } catch (error) {
      console.warn("Error setting up observers:", error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setContentHeight("auto");
      lastContentHeightRef.current = 0;
      isUpdatingRef.current = false;

      const setupWithDelays = () => {
        setupObservers();
        updateContentHeight(true);

        setTimeout(() => updateContentHeight(true), 50);
        setTimeout(() => updateContentHeight(true), 300);
        setTimeout(() => updateContentHeight(true), 1000);
      };

      setTimeout(setupWithDelays, 30);
    }

    return () => {
      if (resizeObserverRef.current) {
        resizeObserverRef.current.disconnect();
      }
      if (mutationObserverRef.current) {
        mutationObserverRef.current.disconnect();
      }
    };
  }, [isOpen, children, forceUpdateRef.current]);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("no-scroll");
      isUpdatingRef.current = false;
      lastContentHeightRef.current = 0;
      setContentHeight("auto");

      setTimeout(() => {
        setMenuTransform("translateY(0)");
        setOverlayOpacity(0.5);

        setTimeout(() => {
          updateContentHeight(true);
        }, 50);
      }, 10);

      resizeTimerRef.current = setInterval(() => {
        if (!isUpdatingRef.current) {
          updateContentHeight();
        }
      }, 300);
    } else {
      document.body.classList.remove("no-scroll");
      setMenuTransform("translateY(100%)");
      setOverlayOpacity(0);

      if (resizeTimerRef.current) {
        clearInterval(resizeTimerRef.current);
      }
    }

    return () => {
      document.body.classList.remove("no-scroll");
      if (resizeTimerRef.current) {
        clearInterval(resizeTimerRef.current);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    const handleResize = () => {
      if (isOpen) {
        updateContentHeight(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  return ReactDOM.createPortal(
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 1000,
          boxSizing: "border-box",
          backgroundColor: `rgba(0, 0, 0, ${overlayOpacity})`,
          backdropFilter: "blur(8px)",
          transition: "background-color 0.3s ease",
        }}
        onClick={closePopup}
        data-popup-id={popupIdRef.current}
      >
        <div
          ref={menuRef}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            borderRadius: "16px 16px 0 0",
            maxWidth: "var(--width-mobile-content)",
            height: "auto",
            overflow: "hidden",
            backgroundColor: "white",
            transform: menuTransform,
            transition: "transform 0.3s ease, height 0.3s ease",
            touchAction: "none",
            maxHeight: "calc(100vh - 80px)",
            boxSizing: "border-box",
            position: "relative",
          }}
          onClick={(e) => e.stopPropagation()}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div
            style={{
              position: "absolute",
              top: "4px",
              left: "50%",
              transform: "translateX(-50%)",
              width: "48px",
              height: "4px",
              backgroundColor: "var(--color-gray-300)",
              borderRadius: "2px",
              margin: "8px auto",
              boxSizing: "border-box",
            }}
          />

          <div
            style={{
              position: "sticky",
              top: 0,
              zIndex: 1,
              borderTopLeftRadius: "inherit",
              borderTopRightRadius: "inherit",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              width: "100%",
              // height: "56px",
              padding: "24px 8px 8px 16px",
              boxSizing: "border-box",
              borderBottom: "1px solid var(--color-gray-200)",
            }}
          >
            <h5
              style={{
                textTransform: "capitalize",
                margin: 0,
                color: "var(--color-black)",
              }}
            >
              {title}
            </h5>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-end",
                boxSizing: "border-box",
              }}
            >
              <div
                style={{
                  height: "32px",
                  aspectRatio: "1/1",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                }}
                onClick={handleToggle}
              >
                <span className="material-symbols-outlined">close</span>
              </div>
            </div>
          </div>

          <div
            ref={contentRef}
            style={{
              overflowY: contentHeight === "auto" ? "visible" : "auto",
              height: contentHeight,
              boxSizing: "border-box",
              width: "100%",
              backgroundPosition: "center 16px",
              backgroundRepeat: "no-repeat",
              backgroundSize: "auto 80px",
              transition: "height 0.2s ease",
            }}
          >
            {children}
          </div>
        </div>
      </div>

      <PopupAllert
        onConfirm={() => closePopup()}
        isOpen={isOpenAllertWindow}
        onClose={handleClose}
        title="Attention!"
      >
        {allert}
      </PopupAllert>
    </>,
    document.body
  );
};

export default React.memo(PopupDirrectory, (prevProps, nextProps) => {
  return (
    prevProps.isOpen === nextProps.isOpen && prevProps.title === nextProps.title
  );
});
