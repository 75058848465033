import React, { useState, useRef, useEffect, useCallback } from "react";
import InputMask from "react-input-mask";
// import "./inputs.css"; // Если вы используете отдельный CSS файл

/**
 * Компонент текстового поля ввода с поддержкой масок и отслеживанием изменений
 *
 * @component
 * @param {Object} props - Свойства компонента
 * @param {string} props.label - Текстовая метка поля ввода
 * @param {string} props.type - Тип поля ввода (text, password, email, tel, number и т.д.)
 * @param {string} props.name - Имя поля ввода для идентификации в форме
 * @param {string} [props.value=""] - Значение поля ввода
 * @param {Function} props.onChange - Обработчик изменения значения поля
 * @param {string} [props.description] - Описание поля, отображается под полем ввода при отсутствии ошибок
 * @param {number} [props.maxLength] - Максимальная длина вводимого текста (используется для email)
 * @param {string} [props.title] - Заголовок поля (для всплывающих подсказок)
 * @param {string} [props.error] - Внешний текст ошибки (имеет приоритет над внутренней)
 * @param {string} [props.mask] - Маска для форматирования ввода (кроме type="tel", где своя маска)
 * @param {Function} [props.isChangeState] - Функция для отслеживания изменений значения поля. Поведение зависит от checkValidityForChangeState.
 * @param {boolean} [props.checkValidityForChangeState=false] - Если true, isChangeState(true) сработает только если значение изменено И валидно. Если false (по умолчанию), то сработает, если значение просто изменено.
 * @param {Function} [props.onValidate] - Функция для пользовательской валидации поля
 * @param {string} [props.pattern] - Регулярное выражение для валидации ввода (HTML5 pattern)
 * @param {string} [props.unit] - Единица измерения, отображаемая рядом с полем ввода
 * @param {boolean} [props.disabled=false] - Флаг отключения поля
 * @returns {JSX.Element} Компонент текстового поля ввода
 */
const InputText = ({
  label,
  type,
  name,
  value = "",
  onChange,
  description,
  maxLength,
  title,
  error, // Внешняя ошибка
  mask,
  isChangeState,
  checkValidityForChangeState = false,
  onValidate,
  pattern,
  unit,
  disabled = false,
}) => {
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const [initialValue, setInitialValue] = useState(value);
  const [internalError, setInternalError] = useState("");
  const [isValid, setIsValid] = useState(true);
  // showError state больше не нужен

  // --- Константы валидации ---
  const MAX_EMAIL_LENGTH = maxLength || 254;
  const MIN_PHONE_DIGITS = 8; // Пример (можно изменить)
  const MAX_PHONE_DIGITS = 15; // Пример (можно изменить)

  // --- Стили компонента (определены внутри для инкапсуляции) ---
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "4px",
      width: "100%",
    },
    contentBox: {
      position: "relative",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
    },
    label: {
      position: "absolute",
      top: "24%",
      margin: "0 0 0 10px",
      padding: "4px 8px",
      borderRadius: "6px",
      fontSize: "1em",
      color: "var(--color-black)",
      /* backgroundColor: "var(--color-white)", */ boxSizing: "content-box",
      transition:
        "top 0.3s ease-in-out, font-size 0.3s ease-in-out, background-color 0.3s ease-in-out, color 0.3s ease-in-out",
      textTransform: "capitalize",
      cursor: "pointer",
      zIndex: 1,
      pointerEvents: "none" /* Чтобы клик проходил на input */,
    },
    labelUp: { top: "-18%", fontSize: "0.8em" },
    labelDisabled: { color: "var(--color-gray-500)", cursor: "not-allowed" },
    labelError: { color: "var(--color-alarm)" },
    input: {
      borderWidth: "2px",
      borderStyle: "solid",
      borderColor: "var(--color-gray-200)",
      backgroundColor: "var(--color-white)",
      fontSize: "1em",
      padding: "16px",
      width: "100%",
      height: "56px",
      boxSizing: "border-box",
      transition:
        "border-color 0.3s, background-color 0.3s, color 0.3s, opacity 0.3s",
      borderRadius: "8px",
    },
    inputFocus: { outline: "none", borderColor: "var(--color-black)" },
    // *** ИСПРАВЛЕНО: inputError должен иметь цвет ошибки ***
    inputError: {
      borderColor: "var(--color-alarm)" /* был var(--color-black) !important */,
    },
    errorMessage: {
      color: "var(--color-alarm)",
      fontSize: "0.75rem" /* marginTop: "4px", - убрано, т.к. в span */,
    },
    inputDisabled: {
      opacity: 0.6,
      cursor: "not-allowed",
      backgroundColor: "var(--color-gray-100)",
      color: "var(--color-gray-500)",
      borderColor: "var(--color-gray-100)",
    },
    descriptionContainer: { width: "100%", marginTop: "4px" }, // Отступ для блока
    description: {
      width: "90%",
      paddingLeft: "18px",
      color: "var(--color-gray-700)",
      fontSize: "0.75rem",
      minHeight: "1.2em" /* Резерв места */,
    },
    unitContainer: {
      position: "absolute",
      right: "16px",
      top: "50%",
      transform: "translateY(-50%)",
      pointerEvents: "none",
      color: "var(--color-gray-700)",
      fontSize: "1em",
      transition: "color 0.3s ease-in-out",
    },
    unitDisabled: { color: "var(--color-gray-500)" },
    // Добавленные недостающие стили
    descriptionErrorContainer: {
      width: "100%",
      marginTop: "4px",
      paddingLeft: "18px",
      fontSize: "0.75rem",
      minHeight: "1.2em",
    },
    errorText: {
      color: "var(--color-alarm)",
      width: "70%",
      paddingLeft: "16px",
    },
    descriptionText: {
      color: "var(--color-gray-700)",
      width: "90%",
      paddingLeft: "16px",
    },
  };

  // --- Обработчики ---
  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  // Функция валидации (возвращает boolean)
  const validateInput = useCallback(
    (inputValue) => {
      let currentInternalError = "";
      let currentIsValid = true;

      // 1. Пустое значение (считаем валидным)
      if (
        !inputValue ||
        (typeof inputValue === "string" && inputValue.trim() === "")
      ) {
        // Оставляем currentInternalError = "" и currentIsValid = true
      }
      // 2. Внешняя валидация
      else if (typeof onValidate === "function") {
        const r = onValidate(inputValue);
        if (typeof r === "string") {
          currentInternalError = r;
          currentIsValid = false;
        } else if (r === false) {
          currentInternalError = `Invalid ${type || "input"} format`;
          currentIsValid = false;
        }
      }
      // 3. Email (только если не было внешней ошибки)
      else if (type === "email") {
        if (inputValue.length > MAX_EMAIL_LENGTH) {
          currentInternalError = `Email cannot exceed ${MAX_EMAIL_LENGTH} characters`;
          currentIsValid = false;
        } else {
          const rx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
          if (!rx.test(inputValue)) {
            currentInternalError =
              "Please enter a valid email address (example@domain.com)";
            currentIsValid = false;
          }
        }
      }
      // 4. Tel (базовая, если не используется InputMask)
      else if (type === "tel" && !mask) {
        const digitsOnly = inputValue.replace(/\D/g, "");
        if (!inputValue.startsWith("+")) {
          currentInternalError = "Phone number must start with '+'";
          currentIsValid = false;
        } else if (digitsOnly.length < MIN_PHONE_DIGITS) {
          currentInternalError = `Phone number must contain at least ${MIN_PHONE_DIGITS} digits`;
          currentIsValid = false;
        } else if (digitsOnly.length > MAX_PHONE_DIGITS) {
          currentInternalError = `Phone number cannot exceed ${MAX_PHONE_DIGITS} digits`;
          currentIsValid = false;
        }
      }
      // 5. Pattern (только если не было предыдущих ошибок)
      else if (pattern && inputValue && currentIsValid) {
        try {
          const rx = new RegExp(pattern);
          if (!rx.test(inputValue)) {
            currentInternalError = `Invalid ${
              type || "input"
            } format based on pattern`;
            currentIsValid = false;
          }
        } catch (e) {
          console.error("Invalid regex pattern:", pattern, e);
        }
      }

      // Обновляем состояния
      setInternalError(currentInternalError);
      setIsValid(currentIsValid);
      return currentIsValid; // Возвращаем результат валидации
    },
    [type, pattern, onValidate, mask, MAX_EMAIL_LENGTH]
  );

  const handleBlur = useCallback(() => {
    setIsFocused(false);
    validateInput(value); // Валидируем текущее значение при потере фокуса
  }, [value, validateInput]);

  // Определяем, отключено ли поле
  const isDisabled = disabled;

  // Клик на область (для фокусировки при клике на "пустое" место метки)
  const handleContainerClick = useCallback(() => {
    if (!isDisabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isDisabled]);

  // Обработчик изменения
  const handleChange = useCallback(
    (e) => {
      const currentValue = e.target.value;
      const currentValidity = validateInput(currentValue); // Валидация при вводе
      if (typeof onChange === "function") {
        onChange(e);
      }
      if (typeof isChangeState === "function") {
        const hasChanged = currentValue !== initialValue;
        if (checkValidityForChangeState) {
          isChangeState(hasChanged && currentValidity);
        } else {
          isChangeState(hasChanged);
        }
      }
    },
    [
      onChange,
      isChangeState,
      initialValue,
      validateInput,
      checkValidityForChangeState,
    ]
  );

  // --- Эффекты ---
  useEffect(() => {
    setInitialValue(value);
    validateInput(value); // Начальная валидация
    if (typeof isChangeState === "function") {
      isChangeState(false);
    } // Начальное состояние
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // --- Расчет стилей и переменных для рендера ---
  const displayError = error || internalError; // Финальное сообщение об ошибке
  // Ошибку показываем текстом только если она есть И поле не в фокусе
  const shouldShowErrorText = !!displayError && !isFocused;
  const hasContentToShowBelow = description || displayError; // Есть ли вообще что показывать под инпутом

  // Динамические стили инпута
  const inputCombinedStyle = {
    ...styles.input,
    borderColor: shouldShowErrorText
      ? styles.inputError.borderColor
      : isFocused
      ? styles.inputFocus.borderColor
      : styles.input.borderColor,
    ...(isDisabled && styles.inputDisabled),
    ...(unit && { paddingRight: "40px" }), // Добавляем отступ справа, если есть unit
  };

  // Динамические стили метки
  const labelCombinedStyle = {
    ...styles.label,
    ...((isFocused || value) && styles.labelUp), // Стиль поднятой метки
    ...(shouldShowErrorText && styles.labelError), // Цвет метки при ошибке (когда не в фокусе)
    ...(isFocused &&
      !shouldShowErrorText && { color: styles.inputFocus.borderColor }), // Цвет метки при фокусе (если нет ошибки)
    ...(isDisabled && styles.labelDisabled), // Стиль неактивной метки
    // Фон метки при поднятии
    backgroundColor: isDisabled
      ? styles.inputDisabled.backgroundColor
      : isFocused || value
      ? styles.input.backgroundColor
      : "transparent",
  };

  // Динамические стили блока описания/ошибки
  const descriptionErrorCombinedStyle = {
    ...styles.descriptionErrorContainer,
    // Используем стиль ошибки если она должна быть показана, иначе стиль описания
    ...(shouldShowErrorText ? styles.errorText : styles.descriptionText),
  };

  // --- JSX ---
  return (
    <div style={styles.container}>
      {/* Кликабельный контейнер для фокусировки инпута */}
      <div style={styles.contentBox} onClick={handleContainerClick}>
        <label htmlFor={name} style={labelCombinedStyle}>
          {label}
        </label>

        {/* --- Условный рендеринг Input или InputMask --- */}
        {type === "tel" ? (
          <InputMask
            mask="+99 999 999 99 99"
            maskChar={null}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isDisabled}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                ref={inputRef}
                style={inputCombinedStyle}
                type="tel"
                id={name}
                name={name}
                placeholder=" "
                autoComplete="tel"
                title={title}
              />
            )}
          </InputMask>
        ) : mask ? (
          <InputMask
            mask={mask}
            maskChar={null}
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            disabled={isDisabled}
          >
            {(inputProps) => (
              <input
                {...inputProps}
                ref={inputRef}
                style={inputCombinedStyle}
                type={type}
                id={name}
                name={name}
                placeholder=" "
                autoComplete={type === "password" ? "new-password" : "off"}
                title={title}
                maxLength={maxLength}
              />
            )}
          </InputMask>
        ) : (
          <input
            ref={inputRef}
            style={inputCombinedStyle}
            type={type}
            id={name}
            name={name}
            placeholder=" "
            value={value}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoComplete={
              type === "password"
                ? "new-password"
                : type === "email"
                ? "email"
                : "off"
            }
            minLength={type === "password" ? 6 : undefined}
            title={title}
            maxLength={maxLength}
            pattern={pattern}
            disabled={isDisabled}
          />
        )}

        {/* Unit */}
        {unit && (
          <div
            style={{
              ...styles.unitContainer,
              ...(isDisabled && styles.unitDisabled),
            }}
          >
            {unit}
          </div>
        )}
      </div>

      {/* Блок для Описания или Ошибки */}
      {/* Показываем блок только если поле НЕ в фокусе И есть описание или ошибка */}
      {hasContentToShowBelow && (
        <div style={descriptionErrorCombinedStyle} className="p-light">
          {/* Приоритет: Ошибка -> Описание */}
          {displayError ? displayError : description}
        </div>
      )}
    </div>
  );
};

export default React.memo(InputText);
