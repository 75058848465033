import React, { useMemo } from "react";
import AvatarUser from "../../../../UIComponents/Avatars/AvatarUser";
import formatDate from "../../../../../functions/dateFormat";
import dateTimeFormat from "../../../../../functions/dateTimeFormat";

const WidgetDialogModeratorToClient = ({ comments }) => {
  // Обработка и сортировка комментариев
  console.log("comments", comments);
  const enrichedComments = useMemo(() => {
    if (!Array.isArray(comments)) return [];

    return comments
      .map((comment) => ({
        ...comment,
        // Убедимся, что комментарий всегда имеет поле timestamp
        timestamp: comment.created || comment.timestamp || Date.now(),
        // Определяем, является ли сообщение от модератора (администратора)
        isAdmin: comment.user?.role === "admin",
      }))
      .sort((a, b) => b.timestamp - a.timestamp); // Сортировка по времени (от новых к старым)
  }, [comments]);

  // Функция для отображения времени
  const renderTimestamp = (timestamp) => {
    return dateTimeFormat({
      timestamp: timestamp,
      isMobile: false,
      isDate: true,
      isTime: true,
    });
  };

  // Функция для отображения индикатора статуса
  const renderStatusIndicator = (status) => {
    return (
      <div
        style={{
          width: "8px",
          borderRadius: "80px",
          aspectRatio: "1/1",
          backgroundColor:
            status === "moderation"
              ? "var(--color-warning)"
              : status === "approved" || status === "accept"
              ? "var(--color-success)"
              : "var(--color-alarm)",
        }}
      />
    );
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Область сообщений */}
      <div>
        {enrichedComments.length > 0 ? (
          enrichedComments.map((comment) => {
            // Определяем, является ли комментарий от администратора
            if (comment.isAdmin) {
              // Блок комментария администратора (справа)
              return (
                <div
                  key={comment.id || `comment-${comment.timestamp}`}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-start",
                    marginBottom: "8px",
                  }}
                >
                  {/* Облако сообщения */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      maxWidth: "70%",
                      backgroundColor: "var(--color-gray-100)",
                      padding: "16px",
                      borderRadius: "8px",
                      position: "relative",
                    }}
                  >
                    {/* Текст сообщения */}
                    <p
                      style={{
                        margin: "0 0 4px 0",
                        wordWrap: "break-word",
                      }}
                    >
                      {comment.content}
                    </p>

                    {/* Информация о времени и отправителе */}
                    <div
                      style={{
                        fontSize: "11px",
                        color: "var(--color-gray-700)",
                        textAlign: "right",
                        marginLeft: "4px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "8px",
                      }}
                    >
                      <span>{renderTimestamp(comment.timestamp)}</span>
                      {renderStatusIndicator(comment.status)}
                      <span>{comment.user?.name || "Администратор"}</span>
                    </div>
                  </div>

                  {/* Аватар администратора (справа) */}
                  <div style={{ marginLeft: "8px" }}>
                    <AvatarUser
                      size={40}
                      url={comment.user?.avatarUrl || null}
                    />
                  </div>
                </div>
              );
            } else {
              // Блок комментария клиента (слева)
              return (
                <div
                  key={comment.id || `comment-${comment.timestamp}`}
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginBottom: "8px",
                  }}
                >
                  {/* Аватар клиента (слева) */}
                  <div style={{ marginRight: "8px" }}>
                    <AvatarUser
                      size={40}
                      url={comment.user?.avatarUrl || null}
                    />
                  </div>

                  {/* Облако сообщения */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                      maxWidth: "70%",
                      backgroundColor: "var(--color-gray-100)",
                      padding: "16px",
                      borderRadius: "8px",
                      position: "relative",
                    }}
                  >
                    {/* Текст сообщения */}
                    <p
                      style={{
                        margin: "0 0 4px 0",
                        wordWrap: "break-word",
                      }}
                    >
                      {comment.content}
                    </p>

                    {/* Информация о времени и отправителе */}
                    <div
                      style={{
                        fontSize: "11px",
                        color: "var(--color-gray-700)",
                        textAlign: "right",
                        marginLeft: "4px",
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        gap: "8px",
                      }}
                    >
                      <span>{renderTimestamp(comment.timestamp)}</span>
                      <span style={{ marginLeft: "4px" }}>— Клиент</span>
                    </div>
                  </div>
                </div>
              );
            }
          })
        ) : (
          <p
            style={{
              textAlign: "center",
              color: "#666",
              fontSize: "14px",
              marginTop: "20px",
            }}
          >
            Сообщений пока нет
          </p>
        )}
      </div>
    </div>
  );
};

export default WidgetDialogModeratorToClient;
