import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContentBox from "../../../UIComponents/ContentBox/ContentBox";
import SegmentedControls from "../../../UIComponents/SegmentedControls/SegmentedControls";
import WidgetRentalPointsListV4 from "./Moderation/WidgetRentalPointsListV4";
import WidgetBikesList from "./BikesV3/WidgetBikesList";
import WidgetUsersListModerationV4 from "./Moderation/WidgetUsersListModerationV4";
import WidgetOrders from "./OrdersV3/WidgetOrders";

const UINotificationsV3 = () => {
  const {
    loading,
    previewNewData,
    rootNewData,
    tempPreviewData,
    tempRootData,
    tempData,
    bikes,
  } = useSelector((state) => state.catalogSliceV4);
  // const userRole = "admin";
  console.log(
    "manager_ordersPreviewNotifications",
    tempPreviewData.manager_ordersPreviewNotifications
  );
  /**
   *
   * LocalStats - инициализируем переменные в localState с целью их оперативного обновления и человека читаемости кода
   *
   */
  const [filteredOrders, setFilteredOrders] = useState([]);

  const [orderState, setOrderState] = useState(null);
  // ------- Windows stats
  // ------- Loading stats
  // ------- Buttons stats
  // ------- Widgets stats
  // ------- Files

  // ------- previewNewData
  const [
    general_usersPreviewNotifications,
    setGeneral_usersPreviewNotifications,
  ] = useState(null);
  const [
    general_rentalPointsPreviewNotifications,
    setGeneral_rentalPointsPreviewNotifications,
  ] = useState(null);
  const [
    general_bikesPreviewNotifications,
    setGeneral_bikesPreviewNotifications,
  ] = useState(null);
  // ------- rootNewData

  // ------- tempPreviewData
  const [
    manager_ordersPreviewNotifications,
    setManager_ordersPreviewNotifications,
  ] = useState(null);
  const [
    general_ordersPreviewNotifications,
    setGeneral_ordersPreviewNotifications,
  ] = useState(null);
  // ------- tempRootData
  // ------- tempData

  // ------- loading
  // ------- error
  // ------- localStates
  const [moderationType, setModerationType] = useState("users");
  const [user_auth_rootCurrentData, setUser_rootCurrentData] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    setUser_rootCurrentData(tempRootData?.user_auth_rootCurrentData || null);
    setUserRole(tempRootData?.user_auth_rootCurrentData?.role || null);
    setUserId(tempRootData?.user_auth_rootCurrentData?.idRootDocument || null);
  }, [tempRootData.user_auth_rootCurrentData]);

  /**
   *
   * Наполнение LocalStats - наполнение и обновление LocalStats посредством useEffect
   *
   */
  useEffect(() => {
    setGeneral_usersPreviewNotifications(
      tempPreviewData?.general_usersPreviewNotifications || null
    );
    setGeneral_rentalPointsPreviewNotifications(
      tempPreviewData?.general_rentalPointsPreviewNotifications || null
    );
    setGeneral_bikesPreviewNotifications(
      tempPreviewData?.general_bikesPreviewNotifications || null
    );
    setManager_ordersPreviewNotifications(
      tempPreviewData.manager_ordersPreviewNotifications || null
    );
  }, [
    tempPreviewData?.general_usersPreviewNotifications,
    tempPreviewData?.general_rentalPointsPreviewNotifications,
    tempPreviewData?.general_bikesPreviewNotifications,
    tempPreviewData.manager_ordersPreviewNotifications,
  ]);

  useEffect(() => {
    setGeneral_ordersPreviewNotifications(
      tempPreviewData?.general_ordersPreviewNotifications || null
    );
  }, [tempPreviewData?.general_ordersPreviewNotifications]);
  /**
   *
   * Логика компонента
   *
   */
  // Функция фильтрации по статусу
  useEffect(() => {
    const filterOrders = () => {
      if (!Array.isArray(manager_ordersPreviewNotifications)) {
        console.error(
          "currentOrders is not an array:",
          manager_ordersPreviewNotifications
        );
        return []; // Если currentOrders не массив, возвращаем пустой массив
      }

      if (manager_ordersPreviewNotifications === "new") {
        return manager_ordersPreviewNotifications?.filter(
          (order) => order.orderStatus === "new"
        );
      } else if (orderState === "in progress") {
        return manager_ordersPreviewNotifications?.filter(
          (order) =>
            order.orderStatus === "in progress" ||
            order.orderStatus === "replacement"
        );
      } else if (orderState === "completed" || orderState === "confirm") {
        return manager_ordersPreviewNotifications?.filter(
          (order) =>
            order.orderStatus === "completed" || order.orderStatus === "confirm"
        );
      } else if (orderState === "payment") {
        return manager_ordersPreviewNotifications?.filter(
          (order) => order.orderStatus === "payment"
        );
      }
      return manager_ordersPreviewNotifications; // По умолчанию возвращаем все заказы
    };

    const result = filterOrders();
    setFilteredOrders(result);
  }, [orderState, manager_ordersPreviewNotifications]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <div
        style={{
          paddingTop: "80px",
          maxWidth: "var(--width-mobile-content)",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "8px",
          boxSizing: "border-box",
        }}
      >
        {userRole === "admin" && (
          <ContentBox>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "8px",
              }}
            >
              <SegmentedControls
                options={["users", "rental points", "bikes"]}
                onSelect={moderationType}
                selected={setModerationType}
              />

              {moderationType === "users" ? (
                <>
                  <WidgetUsersListModerationV4
                    usersModerationList={general_usersPreviewNotifications}
                  />
                </>
              ) : moderationType === "rental points" ? (
                <>
                  <WidgetRentalPointsListV4
                    rentalPointsList={general_rentalPointsPreviewNotifications}
                    mode="moderation"
                  />
                </>
              ) : (
                moderationType === "bikes" && (
                  <>
                    <WidgetBikesList
                      bikesList={general_bikesPreviewNotifications}
                      mode="moderation"
                    />
                  </>
                )
              )}
            </div>
          </ContentBox>
        )}
        <ContentBox>
          <WidgetOrders
            clientId={userId}
            currentOrders={manager_ordersPreviewNotifications}
          />
        </ContentBox>
      </div>
    </div>
  );
};

export default UINotificationsV3;
