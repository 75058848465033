import React, { useState, useEffect } from "react";
import PopupDirrectory from "../Popups/PopupDirrectory";
import "./dropdownList.css";

const DropdownList = ({
  options,
  onSelect,
  label,
  description,
  fieldView,
  fieldId,
  initialSelectedOption,
  type,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setSelectedOption(initialSelectedOption);
  }, [initialSelectedOption]);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(true);
  };

  const handleCloseDropdownList = () => {
    setIsOpen(false);
  };

  // Функция для безопасного получения отображаемого значения
  const getDisplayValue = (option) => {
    if (!option) return "";

    // Если свойство fieldView существует в option, используем его
    if (fieldView && option[fieldView] !== undefined) {
      return option[fieldView];
    }

    // Иначе проверяем наличие свойства title.titleOriginal
    if (option.title && option.title.titleOriginal) {
      return option.title.titleOriginal;
    }

    // Если ничего подходящего не найдено
    return option.title || option.name || label || "Выберите";
  };

  return (
    <>
      {type === "small" ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
              height: "32px",
              backgroundColor: "var(--color-gray-100)",
              borderRadius: "16px",
              cursor: "pointer",
              boxSizing: "border-box",
            }}
            onClick={handleToggle}
          >
            <p
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "4px 10px",
                fontSize: "14px",
                fontWeight: "300",
                color: "var(--color-gray-900)",
                margin: 0,
              }}
            >
              {selectedOption ? getDisplayValue(selectedOption) : label}
            </p>
          </div>
          {description && (
            <div
              style={{
                width: "100%",
              }}
            >
              <p
                style={{
                  width: "70%",
                  fontSize: "14px",
                  fontWeight: "300",
                  color: "var(--color-gray-500)",
                  margin: "4px 0 0 0",
                }}
              >
                {description}
              </p>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            boxSizing: "border-box",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "56px",
              borderRadius: "16px",
              backgroundColor: "var(--color-gray-100)",
              cursor: "pointer",
              boxSizing: "border-box",
            }}
            onClick={handleToggle}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                padding: "0 16px",
                boxSizing: "border-box",
              }}
            >
              {selectedOption ? getDisplayValue(selectedOption) : label}
              <span>
                {isOpen ? (
                  <span className="material-symbols-outlined">unfold_more</span>
                ) : (
                  <span className="material-symbols-outlined">unfold_more</span>
                )}
              </span>
            </div>
          </div>
          {description && (
            <div
              style={{
                width: "100%",
              }}
            >
              <p
                style={{
                  width: "70%",
                  fontSize: "14px",
                  fontWeight: "300",
                  color: "var(--color-gray-500)",
                  margin: 0,
                }}
              >
                {description}
              </p>
            </div>
          )}
        </div>
      )}

      <PopupDirrectory
        isOpen={isOpen}
        onClose={handleCloseDropdownList}
        title={label}
      >
        {isOpen && (
          <ul
            style={{
              listStyle: "none",
              margin: 0,
              padding: 0,
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            {options.map((option) => (
              <li
                key={option[fieldId]}
                onClick={() => handleOptionSelect(option)}
                style={{
                  padding: "16px",
                  borderBottom: "1px solid var(--color-gray-200)",
                  cursor: "pointer",
                  boxSizing: "border-box",
                  ...(selectedOption &&
                    selectedOption[fieldId] === option[fieldId] && {
                      backgroundColor: "var(--color-gray-100)",
                      fontWeight: "500",
                    }),
                }}
              >
                {getDisplayValue(option)}
              </li>
            ))}
          </ul>
        )}
      </PopupDirrectory>
    </>
  );
};

export default DropdownList;
