import React, { useState, useRef, useEffect } from "react";
import "./textarea.css";
const Textarea = ({
  label,
  name,
  value,
  onChange,
  index,
  maxLength,
  description,
  errDescription,
  unit,
  isChangeState = false,
}) => {
  const [inputValue, setInputValue] = useState(value || "");
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef(null);
  const labelRef = useRef(null);

  const handleInputChange = (e) => {
    let newValue = e.target.value;

    // Проверка maxLength
    if (maxLength && newValue.length > maxLength) {
      newValue = newValue.slice(0, maxLength);
    }

    setInputValue(newValue);
    adjustTextareaHeight();
    if (onChange && typeof onChange === "function") {
      onChange(e, index, newValue);
    }

    // Вызов isChangeState с true если поле не пустое, false если пустое
    if (typeof isChangeState === "function") {
      isChangeState(newValue.trim().length > 0);
    }
  };

  // Вызываем isChangeState при монтировании и изменении value
  useEffect(() => {
    if (typeof isChangeState === "function") {
      const hasValue = (value || "").trim().length > 0;
      isChangeState(hasValue);
    }
  }, [value, isChangeState]);

  const adjustTextareaHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.style.height = textarea.scrollHeight + "px";
    }
  };

  // Устанавливаем отступ для textarea равный высоте заголовка
  const adjustTextareaMargin = () => {
    if (labelRef.current && textareaRef.current) {
      const labelHeight = labelRef.current.offsetHeight;
      textareaRef.current.style.marginTop = `${labelHeight}px`;
    } else if (textareaRef.current) {
      // Если заголовок отсутствует или не отображается, сбрасываем отступ
      textareaRef.current.style.marginTop = "0";
    }
  };

  useEffect(() => {
    setInputValue(value || "");
    // Сначала корректируем отступ, затем высоту
    setTimeout(() => {
      adjustTextareaMargin();
      adjustTextareaHeight();
    }, 0);
  }, [value]);

  // Пересчитываем отступ при изменении размера окна
  useEffect(() => {
    const handleResize = () => {
      adjustTextareaMargin();
      adjustTextareaHeight();
    };

    // Инициализация при монтировании компонента
    setTimeout(() => {
      adjustTextareaMargin();
      adjustTextareaHeight();
    }, 0);

    // Добавляем слушатель события изменения размера окна
    window.addEventListener("resize", handleResize);

    // Удаляем слушатель при размонтировании
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleContainerClick = () => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const remainingChars = maxLength ? maxLength - inputValue.length : null;
  const showLabel = label && maxLength && remainingChars !== null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        className={`textarea__container ${isFocused ? "focused" : ""}`}
        onClick={handleContainerClick}
        style={{
          height: "100%",
          position: "relative",
        }}
      >
        {/* Заголовок - отображается только если есть метка и maxLength */}
        {showLabel && (
          <div
            ref={labelRef}
            htmlFor={name}
            style={{
              position: "absolute",
              top: "4px",
              left: "4px",
              right: "4px",
              padding: "16px",
              borderRadius: "6px",
              textTransform: "capitalize",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "32px",
              backgroundColor: "var(--color-gray-100)",
              ...(errDescription
                ? {
                    color: "var(--color-alarm)",
                  }
                : {
                    color: "var(--color-gray-700)",
                  }),
            }}
          >
            <p className="textarea__label">{label}</p>
            <p
              style={{
                padding: "4px 16px",
                backgroundColor: "var(--color-gray-100)",
                color: "var(--color-gray-700)",
                borderRadius: "80px",
              }}
              className="p-light"
            >
              Characters left:&nbsp;{remainingChars}
            </p>
          </div>
        )}

        <textarea
          className="textarea__input"
          id={name}
          name={name}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          ref={textareaRef}
          maxLength={maxLength}
          style={unit ? { paddingRight: "40px" } : {}}
        />

        {unit && (
          <div
            style={{
              position: "absolute",
              right: "16px",
              bottom: "16px",
              pointerEvents: "none",
              color: "var(--color-gray-700)",
              fontSize: "1em",
            }}
          >
            {unit}
          </div>
        )}
      </div>

      {description && (
        <div
          style={{
            width: "100%",
            paddingLeft: "16px",
          }}
        >
          <p
            style={{
              width: "70%",
            }}
            className="p-light"
          >
            {description}
          </p>
        </div>
      )}
    </div>
  );
};

export default React.memo(Textarea);
