import React, { useState, useEffect } from "react";
import InputText from "../../../../UIComponents/Inputs/InputText";
import Button from "../../../../UIComponents/Buttons/Button";
import { useDispatch, useSelector } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";

const WindowEditEmailModeration = ({ mode, onClose, isChangeState }) => {
  const dispatch = useDispatch();

  const isModerationMode = mode === "moderation";
  const isModeratorAddedMode = mode === "moderatorAdded";

  const { tempRootData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [email, setEmail] = useState("");
  const [emailAddresses, setEmailAddresses] = useState([]);
  const [newEmailAddresses, setNewEmailAddresses] = useState([]);
  const [removedEmailAddresses, setRemovedEmailAddresses] = useState([]);

  // Состояние для валидации email
  const [emailError, setEmailError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);

  // Состояние для активации кнопки Save
  const [saveButtonActive, setSaveButtonActive] = useState(false);

  // Состояние для отслеживания исходных данных
  const [initialEmailAddresses, setInitialEmailAddresses] = useState([]);
  console.log("");
  // Регулярное выражение для проверки формата email
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  useEffect(() => {
    if (isModeratorAddedMode) {
      const emails =
        tempData?.tempData_rentalPoint_contacts?.emailAddresses || [];
      setEmailAddresses(emails);
      setInitialEmailAddresses([...emails]); // Сохраняем исходные данные
    } else if (isModerationMode) {
      const emails =
        tempData?.tempData_rentalPoint_contacts?.emailAddresses ||
        tempRootData?.selectedBike_rentalPont?.contacts?.emailAddresses ||
        [];
      setEmailAddresses(emails);
      setInitialEmailAddresses([...emails]); // Сохраняем исходные данные
    }
  }, [
    mode,
    tempRootData?.rentalPoint_create_createRerntalPoint?.contacts
      ?.emailAddresses,
    tempRootData?.selectedBike_rentalPont?.contacts?.emailAddresses,
    tempData?.tempData_rentalPoint_contacts?.emailAddresses,
    isModerationMode,
    isModeratorAddedMode,
  ]);

  // Логика активации кнопки Save
  useEffect(() => {
    // Проверяем, есть ли изменения в списке email-адресов
    const hasNewEmails = newEmailAddresses.length > 0;
    const hasRemovedEmails = removedEmailAddresses.length > 0;
    const currentEmailsChanged =
      JSON.stringify(emailAddresses) !== JSON.stringify(initialEmailAddresses);

    // Кнопка Save активна, если есть какие-либо изменения
    setSaveButtonActive(
      hasNewEmails || hasRemovedEmails || currentEmailsChanged
    );
  }, [
    newEmailAddresses,
    removedEmailAddresses,
    emailAddresses,
    initialEmailAddresses,
  ]);

  // Валидация email при изменении
  useEffect(() => {
    if (!email || email.trim() === "") {
      setEmailError("");
      setIsEmailValid(false);
      return;
    }

    const isValid = emailRegex.test(email);
    setIsEmailValid(isValid);

    if (!isValid) {
      setEmailError("Please enter a valid email address (example@domain.com)");
    } else {
      setEmailError("");
    }
  }, [email]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleAddEmail = () => {
    if (!email || email.trim() === "") return;

    // Проверяем валидность email перед добавлением
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address (example@domain.com)");
      return;
    }

    const emailObject = {
      key: Date.now().toString(),
      email,
    };
    setNewEmailAddresses([...newEmailAddresses, emailObject]);
    setEmail("");
    setEmailError("");
    setIsEmailValid(false);
  };

  const handleRemoveEmail = (index) => {
    setNewEmailAddresses(newEmailAddresses.filter((_, i) => i !== index));
  };

  const handleRemoveLoadedEmail = (emailObject) => {
    // Удаляем email из локального состояния, а не из Redux
    setEmailAddresses(
      emailAddresses.filter((email) => email.key !== emailObject.key)
    );

    // Добавляем email в список удаленных
    setRemovedEmailAddresses([...removedEmailAddresses, emailObject]);
  };

  const handleApply = () => {
    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }

    const updatedEmailAddresses = [...emailAddresses, ...newEmailAddresses];
    dispatch(
      setTempData({
        tempCurrentDocumentState:
          "tempData_rentalPoint_contacts.emailAddresses",
        data: updatedEmailAddresses,
      })
    );
    onClose();
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "8px",
          marginBottom: "72px",
        }}
      >
        <ContentBox position="start">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              gap: "16px",
            }}
          >
            <InputText
              value={email}
              onChange={handleEmailChange}
              label="Email address"
              type="email"
              error={emailError}
              description="Enter a valid email address in the format: example@domain.com"
              isChangeState={(isChanged) => {
                // Можно использовать для дополнительной логики при изменении
              }}
            />
            <div style={{ width: "auto" }}>
              <Button
                label="Add"
                active={email && isEmailValid}
                onClick={handleAddEmail}
              />
            </div>
          </div>
        </ContentBox>

        {(emailAddresses.length > 0 || newEmailAddresses.length > 0) && (
          <ContentBox>
            <ul
              style={{
                width: "100%",
                listStyle: "none",
                display: "flex",
                flexDirection: "column",
                gap: "8px",
              }}
            >
              {emailAddresses.map((emailObj) => (
                <li
                  key={emailObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{emailObj.email}</h6>
                  <div style={{ width: "auto" }}>
                    <Button
                      onClick={() => handleRemoveLoadedEmail(emailObj)}
                      allert={
                        <h1 style={{ padding: "48px", textAlign: "center" }}>
                          Are you sure you want to delete this email address?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}

              {newEmailAddresses.map((emailObj, index) => (
                <li
                  key={emailObj.key}
                  style={{
                    height: "56px",
                    width: "100%",
                    boxSizing: "border-box",
                    borderRadius: "8px",
                    backgroundColor: "var(--color-gray-100)",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 8px 0 16px",
                  }}
                >
                  <h6>{emailObj.email}</h6>
                  <div style={{ width: "auto" }}>
                    <Button
                      onClick={() => handleRemoveEmail(index)}
                      allert={
                        <h1 style={{ padding: "48px", textAlign: "center" }}>
                          Are you sure you want to delete this email address?
                          Please confirm your action.
                        </h1>
                      }
                      active={true}
                      color="--color-gray-100"
                      type="icon"
                      icon={
                        <span className="material-symbols-outlined">
                          delete
                        </span>
                      }
                    />
                  </div>
                </li>
              ))}
            </ul>
          </ContentBox>
        )}
      </div>

      <ButtonsBar>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            active={true}
            label="Cancel"
            color="--color-black"
            onClick={onClose}
            allert={
              <h1 style={{ padding: "80px", textAlign: "center" }}>
                Are you sure you want to leave the editing window?
              </h1>
            }
          />

          <Button
            type="small"
            active={saveButtonActive}
            label="Save"
            color="--color-primary"
            onClick={handleApply}
            allert={
              <h1 style={{ padding: "80px", textAlign: "center" }}>
                Please confirm that you want to update the email information.
              </h1>
            }
          />
        </div>
      </ButtonsBar>
    </>
  );
};

export default WindowEditEmailModeration;
