import React, { useEffect, useState } from "react";
import AddData from "../../../../UIComponents/AddData/AddData";
import PopupDirrectory from "../../../../UIComponents/Popups/PopupDirrectory";
import WindowRentalPointModeration from "./WindowRentalPointModeration";
import CollapsableText from "../../../../UIComponents/Collapsable/CollapsableText";

const WidgetRentalPointModeration = ({
  mode,
  comments,
  statusHistory,
  isChangeState,
}) => {
  const [windowRentalPointModeration, setWindowRentalPointModeration] =
    useState(false);
  const [latestComment, setLatestComment] = useState(null);
  const [displayStatus, setDisplayStatus] = useState("moderation");

  const handleOpenWindowRentalPointModeration = () => {
    setWindowRentalPointModeration(true);
  };

  const handleClose = () => {
    setWindowRentalPointModeration(false);
  };

  useEffect(() => {
    if (typeof statusHistory === "object" && statusHistory?.currentStatus) {
      setDisplayStatus(statusHistory.currentStatus);
    } else if (typeof statusHistory === "string") {
      setDisplayStatus(statusHistory);
    } else {
      setDisplayStatus("moderation");
    }
  }, [statusHistory]);

  useEffect(() => {
    if (comments && Array.isArray(comments)) {
      const sortedComments = [...comments].sort((a, b) => {
        const timeA = a.timestamp || a.created || 0;
        const timeB = b.timestamp || b.created || 0;
        return timeB - timeA;
      });

      setLatestComment(sortedComments.length > 0 ? sortedComments[0] : null);
    }
  }, [comments]);

  const handleModerationComplete = () => {
    handleClose();
    if (isChangeState) {
      isChangeState(true);
    }
  };

  const getStatusDescription = () => {
    if (latestComment?.content) {
      return latestComment.content;
    }

    switch (displayStatus) {
      case "moderation":
        return "Point is currently under moderation. Waiting for approval.";
      case "approved":
        return "This rental point has been approved and is available to all users.";
      case "banned":
        return "This rental point has been banned and is not available to users.";
      default:
        return "Status information unavailable.";
    }
  };

  const getStatusTitle = () => {
    switch (displayStatus) {
      case "moderation":
        return "Moderation";
      case "approved":
        return "Approved";
      case "banned":
        return "Banned";
      default:
        return "Unknown Status";
    }
  };

  return (
    mode === "moderation" && (
      <>
        <PopupDirrectory
          isOpen={windowRentalPointModeration}
          onClose={handleClose}
          title="Rental point moderation"
        >
          <WindowRentalPointModeration
            mode={mode}
            onClose={handleModerationComplete}
            isChangeState={isChangeState}
            initialStatus={displayStatus}
          />
        </PopupDirrectory>
        <AddData
          icon={<span className="material-symbols-outlined">edit</span>}
          title={getStatusTitle()}
          description={<CollapsableText text={getStatusDescription()} />}
          onClick={handleOpenWindowRentalPointModeration}
        />
      </>
    )
  );
};

export default WidgetRentalPointModeration;
