import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ContentBox from "../ContentBox/ContentBox";
import Button from "../Buttons/Button";
import { setTempData } from "../../../reducersToolkit/slice/catalogSliceV4";
import { useDispatch, useSelector } from "react-redux";
import ButtonsBar from "../ButtonsBar/ButtonsBar";
import "react-datepicker/dist/react-datepicker.css";

const WindowCalendarV4 = ({ mode, onClose }) => {
  const dispatch = useDispatch();
  const { tempData } = useSelector((state) => state.catalogSliceV4);
  const [dates, setDates] = useState([]);
  const [processedDates, setProcessedDates] = useState([]);

  // Инициализация данных из tempData.tempData_filtersParams.dateInterval
  useEffect(() => {
    const dateInterval = tempData?.tempData_filtersParams?.dateInterval;
    if (Array.isArray(dateInterval) && dateInterval.length === 2) {
      setDates([new Date(dateInterval[0]), new Date(dateInterval[1])]);
    }
  }, [tempData]);

  useEffect(() => {
    if (dates.length === 2) {
      // Устанавливаем начало и конец дня для выбранных дат
      const start = new Date(dates[0]);
      start.setHours(0, 0, 0, 0);

      const end = new Date(dates[1]);
      end.setHours(23, 59, 59, 999);

      setProcessedDates([start, end]);
    }
  }, [dates]);

  const handleApplyButton = () => {
    if (processedDates.length === 2 && processedDates[0] && processedDates[1]) {
      dispatch(
        setTempData({
          tempCurrentDocumentState: "tempData_filtersParams.dateInterval",
          data: [processedDates[0].getTime(), processedDates[1].getTime()],
        })
      );
      onClose();
    } else {
      console.error("Processed dates array does not contain enough elements");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        paddingBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <DatePicker
          // Дата, которая будет выбрана изначально
          selected={dates[0]}
          // Обработчик изменений
          onChange={(dateRange) => {
            setDates(dateRange);
          }}
          // Начальная дата диапазона
          startDate={dates[0]}
          // Конечная дата диапазона
          endDate={dates[1]}
          // Включает выбор диапазона дат
          selectsRange
          // Показывает компонент встраиваемым
          inline
          // Количество месяцев, отображаемых одновременно в календаре
          monthsShown={1}
          // Минимальная дата (текущая)
          minDate={new Date()}
        />
      </ContentBox>

      <ButtonsBar>
        <div
          style={{
            width: "100%",
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            type="small"
            color="--color-black"
            label="Cancel selections"
            onClick={onClose}
            active={true}
          />
          <Button
            type="small"
            color="--color-primary"
            label="Apply"
            active={true}
            onClick={handleApplyButton}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowCalendarV4;
