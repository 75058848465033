import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import PriorityImage from "../../../../../UIComponents/PhotosGallery/PriorityImage";
import { setCurrentPreviewData } from "../../../../../../reducersToolkit/slice/catalogSliceV4";
import PopupDirrectory from "../../../../../UIComponents/Popups/PopupDirrectory";
import WindowCategoryDetailV4 from "./WindowCategoryDetailV4";

const CategoryItem = React.memo(
  ({ categoryItem, handleToggleOpenWindowCategoryDetail }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [fileUrl, setFileUrl] = useState([]);
    const [remove, setRemove] = useState(null);

    useEffect(() => {
      setTitle(categoryItem?.title || "No title");
      setDescription(categoryItem?.description || "No description");
      setFileUrl(categoryItem?.fileUrl || "");
      setRemove(categoryItem?.remove || null);
    }, [categoryItem]);

    return (
      <div
        style={{
          backgroundColor: "var(--color-gray-100)",
          padding: "16px 8px 16px 16px ",
          boxSizing: "border-box",
          borderRadius: "16px",
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "16px",
          ...(remove && {
            opacity: "0.2",
          }),
        }}
        onClick={() =>
          handleToggleOpenWindowCategoryDetail({
            selectedCategory: categoryItem,
            mode: "edit",
          })
        }
      >
        <div>
          <PriorityImage
            photos={fileUrl}
            style={{
              aspectRatio: "1/1",
              borderRadius: "100%",
              width: "48px",
            }}
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "4px",
          }}
        >
          <p
            style={{
              fontWeight: "600",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </p>
          <p
            className="p-light"
            style={{
              color: "var(--color-black)",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </p>
        </div>

        <span className="material-symbols-outlined">chevron_right</span>
      </div>
    );
  }
);

const WidgetCategoriesListV4 = ({ directories }) => {
  const dispatch = useDispatch();

  const [openWindowCountryDetail, setOpenWindowCountryDetail] = useState(false);
  const [sortedDirectoryList, setSortedDirectoryList] = useState([]);

  const [mode, setMode] = useState("new");

  useEffect(() => {
    if (directories) {
      const sortedList = [...directories].sort(
        (a, b) => b.createdat - a.createdat
      );
      setSortedDirectoryList(sortedList || []);
    }
  }, [directories]);

  const handleToggleOpenWindowCategoryDetail = useCallback(
    ({ selectedCategory, mode }) => {
      setMode(mode);
      dispatch(
        setCurrentPreviewData({
          currentPreviewDocumenState: "selectedCategory",
          data: selectedCategory,
        })
      );

      setOpenWindowCountryDetail(true);
    },
    [dispatch]
  );

  const handleClose = useCallback(() => {
    setOpenWindowCountryDetail(false);
  }, []);

  return (
    directories?.length > 0 && (
      <>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {sortedDirectoryList?.map((categoryItem) => (
            <CategoryItem
              key={categoryItem.id || uuidv4()}
              categoryItem={categoryItem}
              handleToggleOpenWindowCategoryDetail={
                handleToggleOpenWindowCategoryDetail
              }
            />
          ))}
        </div>
        <PopupDirrectory
          isOpen={openWindowCountryDetail}
          onClose={handleClose}
          title={"Edit category"}
        >
          <WindowCategoryDetailV4 mode={mode} onClose={handleClose} />
        </PopupDirrectory>
      </>
    )
  );
};

export default WidgetCategoriesListV4;

// import React, { useEffect, useState } from "react";

// const WidgetCategoryslist = ({ directories }) => {
//   const [sortedDirectoryList, setSortedDirectoryList] = useState([]);

//   useEffect(() => {
//     if (directories) {
//       const sortedList = [...directories].sort(
//         (a, b) => b.createdat - a.created
//       );
//       setSortedDirectoryList(sortedList || []);
//     }
//   }, [directories]);

//   const handleToggle = (directoryItem, mode) => {};

//   return (
//     <div
//       style={{
//         width: "100%",
//         display: "flex",
//         flexDirection: "column",
//         gap: "8px",
//       }}
//     >
//       {directories?.length > 0 &&
//         sortedDirectoryList.map((directoryItem, index) => (
//           <div
//             key={index}
//             onClick={() => handleToggle(directoryItem, "edit")}
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               alignItems: "center",
//               justifyContent: "space-between",
//               width: "100%",
//               backgroundColor: "var(--color-gray-100)",
//               borderRadius: "16px",
//               padding: "16px 8px 16px 16px",
//               boxSizing: "border-box",
//               cursor: "pointer",
//             }}
//           >
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "column",
//                 gap: "4px",
//                 alignItems: "flex-start",
//                 justifyContent: "space-between",
//                 width: "70%",
//               }}
//             >
//               <div
//                 style={{
//                   fontWeight: "600",
//                   color: "var(--color-black)",
//                   display: "-webkit-box",
//                   WebkitLineClamp: 1,
//                   WebkitBoxOrient: "vertical",
//                   overflow: "hidden",
//                   textOverflow: "ellipsis",
//                 }}
//               >
//                 {directoryItem?.title}
//               </div>

//               <div
//                 className="p-light"
//                 style={{
//                   // color: "var(--color-black)",
//                   display: "-webkit-box",
//                   WebkitLineClamp: 3,
//                   WebkitBoxOrient: "vertical",
//                   overflow: "hidden",
//                   textOverflow: "ellipsis",
//                 }}
//               >
//                 {directoryItem?.description}
//               </div>
//             </div>
//             <span className="material-symbols-outlined">chevron_right</span>
//           </div>
//         ))}
//     </div>
//   );
// };

// export default WidgetCategoryslist;
