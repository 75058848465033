import React, { useEffect, useState } from "react";
import InputText from "../../../../UIComponents/Inputs/InputText";
import Textarea from "../../../../UIComponents/Textarea/Textarea";
import Button from "../../../../UIComponents/Buttons/Button";
import { useSelector, useDispatch } from "react-redux";
import ContentBox from "../../../../UIComponents/ContentBox/ContentBox";
import ButtonsBar from "../../../../UIComponents/ButtonsBar/ButtonsBar";
import { setTempData } from "../../../../../reducersToolkit/slice/catalogSliceV4";

const WindowBikeBasicInformationModeration = ({
  isChangeState,
  onClose,
  mode,
}) => {
  const dispatch = useDispatch();

  const { selectedBike, rootNewData, tempRootData, tempData } = useSelector(
    (state) => state.catalogSliceV4
  );

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [applyButtonActive, setApplyButtonActive] = useState(false);
  const [newData, setNewData] = useState(false);

  // Добавляем переменные для хранения исходных значений
  const [initialTitle, setInitialTitle] = useState("");
  const [initialDescription, setInitialDescription] = useState("");

  /**
   *
   * Формирование newData объекта
   *
   */

  useEffect(() => {
    setNewData({
      title: {
        titleOriginal: title,
      },

      description: {
        descriptionOriginal: description,
      },
    });
  }, [title, description]);

  /**
   *
   * Наполнение useState
   *
   * */

  useEffect(() => {
    let currentTitle = "";
    let currentDescription = "";

    if (mode === "moderation") {
      currentTitle =
        tempData.tempData_BbasicInformation?.title?.titleOriginal ||
        tempRootData?.selectedBike_rootData?.basicInformation?.title
          ?.titleOriginal ||
        "";
      currentDescription =
        tempData.tempData_BbasicInformation?.description?.descriptionOriginal ||
        tempRootData?.selectedBike_rootData?.basicInformation?.description
          ?.descriptionOriginal ||
        "";
    } else if (mode === "moderatorAdded") {
      currentTitle =
        tempData?.tempData_BbasicInformation?.title?.titleOriginal ||
        rootNewData?.rootBikeData?.basicInformation?.title?.titleOriginal ||
        "";
      currentDescription =
        tempData?.tempData_BbasicInformation?.description
          ?.descriptionOriginal ||
        rootNewData?.rootBikeData?.basicInformation?.description
          ?.descriptionOriginal ||
        "";

      // currentTitle =
      //   rootNewData?.rootBikeData?.basicInformation?.title?.titleOriginal ||
      //   tempRootData?.selectedBike_rootData?.basicInformation?.title
      //     ?.titleOriginal ||
      //   "";
      // currentDescription = currentTitle =
      //   rootNewData?.rootBikeData?.basicInformation?.description
      //     ?.descriptionOriginal ||
      //   tempRootData?.selectedBike_rootData?.basicInformation?.description
      //     ?.descriptionOriginal ||
      //   "";
    }

    // Устанавливаем текущие и исходные значения
    setTitle(currentTitle);
    setDescription(currentDescription);
    setInitialTitle(currentTitle);
    setInitialDescription(currentDescription);
  }, [
    mode,
    tempData.tempData_BbasicInformation,
    tempRootData?.selectedBike_rootData?.basicInformation,
    rootNewData?.rootBikeData?.basicInformation,
    // newBike,
  ]);

  /**
   *
   * CRUD функции
   *
   */

  const applyChanges = () => {
    // if (mode === "moderation") {
    // dispatch(
    //   setCurrentRootData({
    //     rootCurrentDocumentState: "selectedBike_rootData.basicInformation",
    //     data: newData,
    //   })
    // );

    dispatch(
      setTempData({
        tempCurrentDocumentState: "tempData_BbasicInformation",
        data: newData,
      })
    );
    //   dispatch(
    //   setTempData({
    //     tempCurrentDocumentState: "tempData_basicInformationRentalPoint",
    //     data: newData,
    //   })
    // );

    //   onClose();
    // } else if (mode === "moderatorAdded") {
    // dispatch(
    //   setTempData({
    //     tempCurrentDocumentState: "tempData_BbasicInformation",
    //     data: newData,
    //   })
    // );

    onClose();
    // }
  };

  /**
   *
   * Логика компонента
   *
   */

  // Функция для обработки изменения заголовка
  const handleTitleChange = (event) => {
    setTitle(event.target.value);

    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
  };

  // Функция для обработки изменения описания
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);

    // Уведомляем родительский компонент об изменении данных
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
  };

  // Активация кнопки apply
  useEffect(() => {
    // Проверяем, изменились ли данные относительно исходных значений
    const hasChanges =
      title !== initialTitle || description !== initialDescription;

    // Обновляем состояние кнопки Apply
    setApplyButtonActive(hasChanges);
  }, [title, description, initialTitle, initialDescription]);

  // Кнопки
  const applyButton = () => {
    if (typeof isChangeState === "function") {
      isChangeState(true);
    }
    applyChanges();
  };

  const cancelButton = () => {
    onClose();
  };

  return (
    <div
      style={{
        width: "100%",
        // height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "8px",
        marginBottom: "72px",
      }}
    >
      <ContentBox position="start">
        <InputText
          label="Bike name"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          value={title}
          onChange={handleTitleChange}
        />

        <Textarea
          label="Bike description"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
          maxLength={1000}
          value={description}
          onChange={handleDescriptionChange}
        />
      </ContentBox>

      <ButtonsBar position="end">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            type="small"
            label="Cancel"
            active={true}
            color="--color-black"
            onClick={cancelButton}
          />
          <Button
            type="small"
            label="Apply"
            active={applyButtonActive}
            onClick={applyButton}
          />
        </div>
      </ButtonsBar>
    </div>
  );
};

export default WindowBikeBasicInformationModeration;
