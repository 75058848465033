import React, { useEffect, useState } from "react";
import Preloader from "../Preloader/Preloader";

const PriorityImage = ({ photos = [], onClick, info, isLoading, style }) => {
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);
  const [isLoadingFiles, setIsLoadingFiles] = useState(true);
  const [isTooltipVisible, setTooltipVisible] = useState(false);

  // Обработка случаев, когда photos - это строка URL или массив объектов
  const photosArray = Array.isArray(photos)
    ? photos
    : photos
    ? [{ url: photos }]
    : [];

  // Найти фото с флагом priority или использовать первый элемент массива
  const priorityPhotoIndex = photosArray.findIndex((photo) => photo?.priority);
  const initialPhotoIndex = priorityPhotoIndex !== -1 ? priorityPhotoIndex : 0;

  useEffect(() => {
    setCurrentPhotoIndex(initialPhotoIndex);
  }, [initialPhotoIndex]);

  const handleImageLoad = () => {
    setIsLoadingFiles(false);
  };

  return (
    <div
      style={{
        width: "100%",
        aspectRatio: "16/9",
        backgroundColor: "var(--color-gray-100)",
        boxSizing: "border-box",
        borderRadius: "16px",
        position: "relative",
        backgroundImage: `url(${
          photosArray[currentPhotoIndex]?.url ||
          "https://firebasestorage.googleapis.com/v0/b/biketripste.appspot.com/o/static%2Ffiles%2FnoPhotoLogo.jpg?alt=media&token=f6ae07cb-6130-45f4-88fd-9b29a321fb82"
        })`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        ...style,
      }}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {info !== undefined && isTooltipVisible && (
        <div
          style={{
            position: "absolute",
            backgroundColor: "white",
            border: "0px solid gray",
            padding: "24px 16px",
            top: "100%",
            left: "0",
            zIndex: 1,
            width: "300px",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            borderRadius: "16px",
            boxShadow: "0 4px 16px rgba(0,0,0,0.1)",
          }}
        >
          {info}
        </div>
      )}
      <div
        style={{
          width: "100%",
          height: "84%",
        }}
        onClick={onClick}
      />

      {isLoadingFiles && photosArray.length !== 0 && (
        <div
          onClick={onClick}
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1,
            // backgroundColor: "rgba(255, 255, 255, 0.5)",
          }}
        >
          <Preloader
            colorSecond="--color-white"
            colorPrimary="--color-primary"
          />
        </div>
      )}
      <img
        src={photosArray[currentPhotoIndex]?.url}
        alt={`Photo ${currentPhotoIndex}`}
        onLoad={handleImageLoad}
        style={{ display: "none" }}
      />
    </div>
  );
};

export default PriorityImage;
