import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const SEOTags = () => {
  const location = useLocation();

  // Получаем данные напрямую из Redux без создания нового объекта
  const seo_previewCurrentData = useSelector(
    (state) => state.catalogSliceV4.tempPreviewData?.seo_previewCurrentData
  );

  // Получаем текущий путь для фильтрации SEO-данных
  const currentSlug = location.pathname;

  // Находим SEO-данные для текущего пути
  const seoData = useMemo(() => {
    if (!seo_previewCurrentData || !currentSlug) return null;

    const filteredSEO = seo_previewCurrentData.filter(
      (item) => item?.slug === currentSlug
    );

    return filteredSEO.length > 0 ? filteredSEO[0] : null;
  }, [seo_previewCurrentData, currentSlug]);

  // Извлекаем данные для SEO-тегов
  const title = seoData?.title;
  const description = seoData?.description;
  const keywords = seoData?.keywords;
  const image = seoData?.image;

  // Значения по умолчанию
  const defaultTitle = "Default Page Title";
  const defaultDescription = "Default page description";
  const defaultKeywords = "default, keywords";

  // Проверка, отсутствуют ли все ключевые SEO-пропсы
  const shouldBlockIndexing = !title && !description && !keywords;

  return (
    <Helmet>
      {/* Запрет индексации, если нет title, description и keywords */}
      {shouldBlockIndexing && <meta name="robots" content="noindex" />}

      {/* Заголовок страницы */}
      <title>{title || defaultTitle}</title>

      {/* Мета-описание */}
      <meta name="description" content={description || defaultDescription} />

      {/* Ключевые слова */}
      <meta name="keywords" content={keywords || defaultKeywords} />

      {/* Open Graph: заголовок */}
      <meta property="og:title" content={title || defaultTitle} />

      {/* Open Graph: описание */}
      <meta
        property="og:description"
        content={description || defaultDescription}
      />

      {/* Open Graph: изображение (если передано через пропсы) */}
      {image && <meta property="og:image" content={image} />}

      {/* Open Graph: URL */}
      <meta property="og:url" content={window.location.href} />

      {/* Open Graph: тип контента */}
      <meta property="og:type" content="product" />

      {/* Канонический URL */}
      <link rel="canonical" href={window.location.href} />
    </Helmet>
  );
};

export default SEOTags;
